<template>
  <div>
    <small class="text-muted mb-3">Face Photo</small>
    <MobileUpload
      key="mobileUploadPersonal"
      background-type="personal"
      :dashboard="true"
      :existing-photo="personalForComponent"
      :teleport-cta="false"
      @submitAnswerDashboard="setPhoto('personal', $event)"
    />

    <small class="text-muted mb-3">ID Photo</small>
    <MobileUpload
      key="mobileUploadId"
      background-type="id"
      :dashboard="true"
      :existing-photo="idForComponent"
      :teleport-cta="false"
      @submitAnswerDashboard="setPhoto('id', $event)"
    />
  </div>
</template>

<script>
import MobileUpload from '@/components/partials/MobileUpload';

export default {
  components: {
    MobileUpload,
  },
  props: {
    personalPhoto: {
      type: String,
      default: '',
    },
    idPhoto: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      personalForComponent: '',
      idForComponent: '',
    };
  },
  mounted() {
    if (this.personalPhoto) {
      this.personalForComponent = this.personalPhoto;
    }
    if (this.idPhoto) {
      this.idForComponent = this.idPhoto;
    }
  },
  methods: {
    setPhoto(type, event) {
      this.$emit('setPhotoMobile', type, event);
    },
  },
};
</script>
