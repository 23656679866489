<template>
  <div
    class="tw-bg-white tw-rounded-2xl tw-flex tw-flex-col lg:tw-flex-row tw-relative"
    :class="{'tw-overflow-hidden': isCreamBannerVisible}"
  >
    <div
      v-if="isCreamBannerVisible"
      class="tw-bg-[#FF9D42] tw-p-2 tw-absolute tw-z-20 tw-top-0 tw-left-0 tw-right-0 tw-block lg:tw-hidden"
    >
      <p class="tw-mb-0 tw-text-center tw-text-sm md:tw-text-base">
        Get the Estriol Cream with Tretinoin for a discounted price of <span class="tw-font-bold">$99 RIGHT NOW ONLY!</span>
      </p>
    </div>
    <div
      class="tw-w-full lg:tw-w-2/5 tw-relative tw-flex tw-p-4 lg:tw-p-0"
      :class="{ 'tw-pt-16 sm:tw-pt-12 md:tw-pt-12 lg:tw-pt-0': isCreamBannerVisible }"
    >
      <div class="tw-relative tw-w-full">
        <img
          :src="productData.images[0]"
          class="tw-h-[316px] lg:tw-h-full tw-w-full tw-object-center tw-object-cover tw-rounded-2xl lg:tw-rounded-none"
        >
        <template v-if="productData.metadata.bfcm === 'true' && !isCreamBannerVisible">
          <BFCMBadge
            :upsell-price="upsellPrice"
            class="tw-scale-75 sm:tw-scale-90 lg:tw-scale-75 -tw-top-14 -tw-right-14 sm:-tw-top-9 sm:-tw-right-8 lg:-tw-top-4 lg:tw-right-0 lg:-tw-left-4 tw-rotate-[-20deg] tw-size-[180px] tw-absolute tw-z-20"
          />
        </template>
        <template v-else>
          <div
            class="tw-inline-flex tw-justify-center tw-items-center tw-absolute tw-top-[5px] lg:tw-top-4 tw-right-[5px] lg:tw-right-auto tw-left-auto lg:tw-left-4 tw-rounded-full tw-text-white tw-text-center tw-size-[100px] lg:tw-size-[125px] tw-rotate-[15deg] lg:tw-rotate-[-15deg] tw-z-10 lg:tw-z-auto"
            :class="productData.upsellcode === 'CREAM' ? 'tw-bg-[#1a6a72]' : 'tw-bg-[#2b2660]'"
          >
            <div class="tw-max-w-[60px] lg:tw-max-w-[80px]">
              <p
                v-if="(productData.firstorder || productData.upsellcode === 'BLOSSOM') && !productData.isExternalLink"
                class="tw-mb-0 tw-text-base lg:tw-text-xl tw-line-through tw-text-[#9692af]"
              >
                {{ productData.metadata.strikethroughprice }}
              </p>
              <p class="tw-mb-0 tw-text-2xl lg:tw-text-[2rem]">
                {{ upsellPrice }}
              </p>
              <p class="tw-mb-0 tw-text-[0.6rem] lg:tw-text-xs">
                {{ productData.metadata.upsellpriceunit }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="tw-p-4 lg:tw-p-14 tw-w-full lg:tw-w-3/5 tw-relative tw-overflow-hidden">
      <div
        v-if="isCreamBannerVisible"
        class="tw-bg-[#FF9D42] tw-p-2 tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-hidden lg:tw-block"
      >
        <p class="tw-mb-0 tw-text-center">
          Get the Estriol Cream with Tretinoin for a discounted price of <span class="tw-font-bold">$99 RIGHT NOW ONLY!</span>
        </p>
      </div>
      <p
        class="tw-font-hatton tw-text-[1.75rem] lg:tw-text-4xl tw-font-bold tw-mb-2"
        :class="{'lg:tw-mt-5': isCreamBannerVisible}"
      >
        {{ productData.upsellcode === 'CREAM' ? 'Rx Estriol Cream with Tretinoin' : 'Rx Arousal Cream (Blossom)' }}
      </p>
      <p
        v-if="productData.metadata.bfcm === 'true' && !isCreamBannerVisible"
        class="tw-flex tw-text-xl tw-mb-2"
      >
        {{ upsellPrice }} - limited time only
      </p>
      <p
        v-else
        class="tw-flex tw-text-xl tw-mb-2"
      >
        <span
          v-if="(productData.firstorder || productData.upsellcode === 'BLOSSOM') && !productData.isExternalLink"
          class="tw-text-muted tw-mr-2 tw-line-through"
        >
          {{ productData.metadata.strikethroughprice }}
        </span>
        <span>
          {{ upsellPrice }}
          {{ productData.metadata.upsellpriceunit }}
        </span>
      </p>
      <p
        v-if="productData.upsellcode === 'CREAM'"
        class="tw-text-[#1a6a72]"
      >
        <template v-if="productData.metadata.bfcm === 'true' && !isCreamBannerVisible">
          Auto-refilled at normal price of {{ productData.metadata.strikethroughprice }}
        </template>
        <template v-else-if="productData.firstorder && !productData.isExternalLink">
          Auto-refilled, subsequent orders at full price of {{ productData.metadata.strikethroughprice }}
        </template>
        <template v-else>
          Auto-refilled
        </template>
      </p>
      <p
        v-else
        class="tw-text-[#1a6a72]"
      >
        One time purchase. Subsequent orders at full price of {{ productData.metadata.strikethroughprice }}
      </p>
      <div
        class="upsell-card__info tw-mt-4 tw-relative lg:tw-static tw-h-[100px] lg:tw-h-auto tw-overflow-hidden lg:tw-overflow-auto tw-mb-6 lg:tw-mb-0"
        :class="{ 'upsell-card__info--expanded': isInfoExpanded }"
        @click="onExpandUpsellInfo"
      >
        <p
          class="tw-text-xl"
          v-html="productData.description.html"
        ></p>
        <b-button
          variant="link"
          size="sm"
          class="tw-px-0"
          block
        >
          <a
            :href="learnMoreLink"
            target="_blank"
            class="tw-underline tw-flex tw-justify-start tw-items-center"
          >
            <span>Learn more about the {{ productData.metadata.shortname }}
              <svg
                class="tw-hidden sm:tw-inline-block tw-w-[25px] tw-ml-2"
                width="34"
                height="17"
                viewBox="0 0 34 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 7.84619L33.9999 7.84619"
                  stroke="currentColor"
                  stroke-width="1.2"
                />
                <path
                  d="M26.1538 0C26.1538 2.61538 28.7694 7.1923 34 7.1923"
                  stroke="currentColor"
                  stroke-width="1.2"
                />
                <path
                  d="M26.1538 16.3462C26.1538 13.7308 28.7693 8.50005 34 8.50005"
                  stroke="currentColor"
                  stroke-width="1.2"
                />
              </svg>
            </span>
            
          </a>
        </b-button>
        <hr class="tw-border-[#2B2660]">
        <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-3 tw-mb-6">
          <div
            v-for="(benefit, index) in benefitsList"
            :key="`benefit-${index}`"
            class="tw-gap-2 tw-text-base tw-flex tw-items-center"
          >
            <IconCheckCircleOutline class="tw-text-[#1a6a72]" />
            <p class="tw-mb-0">
              {{ benefit }}
            </p>
          </div>
        </div>
      </div>
      <BaseCheckbox
        v-if="upsellFrom === 'Onboarding Upsell'"
        :value="productData.selected"
        class="upsell-card__checkbox tw-text-white tw-rounded-xl tw-transition-all"
        :class="{ 'tw-opacity-75 tw-cursor-not-allowed tw-pointer-events-none': processing }"
        :color="productData.selected ? '#9692af' : '#2B2660'"
        @input="onUpsellCheckboxChange"
      >
        {{
          productData.selected
            ? `The ${productData.metadata.shortname} has been added to your treatment plan.`
            : `Add ${productData.metadata.shortname} to my treatment plan.`
        }}
        {{ product.selected }}
      </BaseCheckbox>
    </div>
  </div>
</template>

<script>
// services
import { PrescriptionService } from '@/services/prescription.service';

// components
import IconCheckCircleOutline from '@/components/icon/upsell/IconCheckCircleOutline.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BFCMBadge from '@/components/dashboard/bfcm/BFCMBadge.vue';

export default {
  name: 'SelectProductUpsell',
  components: { IconCheckCircleOutline, BaseCheckbox, BFCMBadge },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
      default: 0,
    },
    upsellFrom: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      productData: null,
      upsellId: '',
      processing: false,
      isInfoExpanded: false,
    };
  },
  computed: {
    benefitsList() {
      return this.productData.metadata.new_benefits
        .split(',')
        .map(benefit => {
          return benefit.trim();
        });
    },
    isCreamBannerVisible() {
      return this.productData.upsellcode === 'CREAM' && this.upsellFrom === 'Onboarding Upsell';
    },
    upsellPrice() {
      if (this.upsellFrom === 'Onboarding Upsell' && this.productData.upsellcode === 'CREAM') {
        return this.productData.metadata.onboarding_upsellprice
      }

      if (this.productData.isExternalLink) {
        return this.productData.metadata.upsellprice;
      }

      if (this.productData.firstorder) {
        return this.productData.metadata.firsttime_upsellprice || this.productData.metadata.upsellprice;
      }

      return this.productData.metadata.upsellprice;
    },
    learnMoreLink() {
      if (this.productData.metadata.bfcm === 'true') {
        return process.env.VUE_APP_SERVER === 'production' ? 'https://bywinona.com/product/estriol-cream-with-tretinoin' : 'https://staging-nuxt3.bywinona.com/product/estriol-cream-with-tretinoin'
      }

      return this.productData.metadata.new_url;
    },
  },
  async created() {
    this.$set(this, 'productData', { ...this.product, selected: false });

    if (this.upsellFrom !== 'Onboarding Upsell') {
      this.onUpsellCheckboxChange(true);
    }
  },
  methods: {
    async onUpsellCheckboxChange(selected) {
      try {
        this.processing = true;
        this.$emit('upsell-processing', true);

        const pricing = this.productData.pricing?.[0];
        if (!this.productData.requested) {
          if (selected) {
            let addUpsellResponse
            if (!this.productData?.upsellid) {
              addUpsellResponse = await this.$store.dispatch(
                'upsell/addUpsell',
                {
                  product: this.productData.upsellcode,
                }
              );
            }
            this.upsellId = addUpsellResponse?.data?.id || this.productData.upsellid;
  
            this.$emit('upsell-change', {
              upsellId: this.upsellId,
              productId: pricing.product,
              pricingId: pricing.id,
              productName: this.productData.name,
              idx: this.idx,
              upsellcode: this.productData.upsellcode,
              ...(this.productData.metadata?.bfcm === 'true' && {
                bfcm: true,
              }),
            });
          } else {
            await this.$store.dispatch('upsell/denyUpsell', {
              upsellId: this.upsellId,
            });
            this.$emit('upsell-remove', this.idx);
          }
        } else {
          this.$emit('upsell-change', {
            upsellId: '',
            productId: pricing.product,
            pricingId: pricing.id,
            productName: this.productData.name,
            idx: this.idx,
            upsellcode: this.productData.upsellcode,
          });
        }
        this.$set(this.productData, 'selected', selected);
      } catch (e) {
        this.$emit('error')
        console.error(e);
      } finally {
        this.processing = false;
        this.$emit('upsell-processing', false);
      }
    },
    onExpandUpsellInfo() {
      this.isInfoExpanded = !this.isInfoExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .upsell-card__checkbox.checkbox-container {
  padding: 0.75rem;
}

::v-deep .upsell-card__checkbox .checkbox-box {
  border-radius: 0.4rem;
}

.upsell-card__info--expanded {
  height: auto !important;
}

.upsell-card__info.upsell-card__info--expanded::after {
  display: none !important;
}

@media (max-width: 1023px) {
  .upsell-card__info::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px; /* Height of the fade effect */
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }
}
</style>
