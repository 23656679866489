export default {
  namespaced: true,
  state: {
    isModalOpen: false,
    previousRouteName: null
  },
  mutations: {
    SET_MODAL_STATE(state, modalState) {
      state.isModalOpen = modalState
    },
    SET_STATE (state, payload) {
      state[payload.state] = payload.value
    }
  },
  getters: {},
  actions: {}
}