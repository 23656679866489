/**
import analyticsMixin from "@/mixins/analytics";
mixins: [analyticsMixin],
 */

export default {
  methods: {
    async $_analytics_track(event, properties) {
      console.log(`Tracked "${event}"`);

      const [
        deviceDetailsForContext,
        deviceDetails,
        everflowDetails,
        impactDetails,
        voluumDetails,
      ] = await Promise.all([
        this.$store.dispatch('getDeviceDetailsForContext'),
        this.$store.dispatch('getDeviceDetails'),
        this.$store.dispatch('getEverflowDetails'),
        this.$store.dispatch('getImpactId'),
        this.$store.dispatch('getVoluumDetails'),
      ]);

      const referrerData = Object.keys(impactDetails).length > 0 ? impactDetails : everflowDetails;

      window.analytics.track(
        event,
        {
          ...properties,
          ...deviceDetailsForContext,
          ...voluumDetails,
        },
        {
          referrer: referrerData,
          context: deviceDetails,
        }
      );
    },

    async $_analytics_page() {
      const [everflowDetails, impactDetails] = await Promise.all([
        this.$store.dispatch('getEverflowDetails'),
        this.$store.dispatch('getImpactId'),
      ]);

      const referrerData = Object.keys(impactDetails).length > 0 ? impactDetails : everflowDetails;
      
      window.analytics.page(
        {
          referrer: referrerData,
        }
      )
    }
  },
};
