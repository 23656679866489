<template>
  <div class="eligibilityBirthday">
    <h2 class="question-title with-subtext">
      When is your birthday?
    </h2>
    <p class="sub-text group-mb">
      Age helps us to determine if you are eligible for treatment.
    </p>
    <b-row>
      <b-col>
        <div
          role="group"
          class="mb-3 pb-1"
        >
          <label
            for="birthday"
            class="input-label"
          >Date of birth*</label>
          <b-form-input
            id="birthday"
            v-model="birthday"
            v-mask="'##/##/####'"
            placeholder="MM/DD/YYYY"
            class="fs-exclude onboarding-input birthdate"
            type="text"
            pattern="[0-9]*"
            inputmode="numeric"
            autocomplete="off"
          />
        </div>

        <div>
          <img
            src="@/assets/images/cro/hsa-fsa-eligible-badge.png"
            alt=""
            class="tw-h-[18px] tw-mx-auto"
          >
        </div>

        <OnboardingCTAContainer>
          <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
            <b-button
              pill
              block
              variant="info"
              class="slight-rounded"
              :disabled="buttonDisabled"
              @click="submitAnswer"
            >
              Continue
            </b-button>
          </div>
        </OnboardingCTAContainer>
      </b-col>
    </b-row>
    <Modal
      v-if="showModal"
      :body="ageModal"
      @submitAnswer="$emit('exit')"
    />
  </div>
</template>

<script>
// components
import Modal from '@/components/onboarding/questions/Modal';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';

// mixins
import mixins from '@/components/form/mixins';
import analyticsMixin from '@/mixins/analytics';

export default {
  components: {
    OnboardingCTAContainer,
    Modal,
  },
  mixins: [mixins, analyticsMixin],
  props: {
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    preAnswer: {
      type: String,
      default: '',
    },
    externalid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dateFormat: 'MM/DD/YYYY',
      birthday: '',
      buttonDisabled: false,
      showModal: false,
      ageModal: {
        id: 'ageModal',
        title: 'You must be 35 years of age or older to proceed',
        answerlist: [
          {
            name: 'continue',
          },
        ],
      },
    };
  },
  watch: {
    async showModal(show) {
      if (show) {
        const rejectBody = {
          externalid: this.externalid,
          question: 'How old are you?',
          answer: await this.calculateAge(),
          onboardingflow: this.onboardingflow,
        };

        this.$_analytics_track(
          'Onboarding Reject',
          {
            ...rejectBody,
          },
        );
      }
    },
  },
  async mounted() {
    if (this.preAnswer) {
      this.birthday = this.formatBirthdayForDisplay(this.preAnswer);
    }

    this.$_analytics_track(
      'Onboarding Screen Viewed',
      {
        screen: '1.2-A',
        onboardingflow: this.onboardingflow,
      }
    );
  },
  methods: {
    async calculateAge() {
      const dateArray = this.getDateArray(this.birthday);
      const newDate = new Date(dateArray[2], dateArray[0], dateArray[1]);
      const ageDifMs = Date.now() - newDate.getTime();
      if (ageDifMs <= 0) {
        this.$emit('showAlert', 'Please input a valid birthday.');
        return false;
      }
      const ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    getDateArray(date) {
      // converts MM/DD/YYYY string to numbers
      const dateArray = date.split('/').map(x => +x);
      // Month is 0 indexed
      dateArray[0] = dateArray[0] - 1;
      return dateArray;
    },
    formatBirthdayForDisplay(date) {
      const dateString = date.split('-');
      return `${dateString[1]}/${dateString[2]}/${dateString[0]}`;
    },
    formatBirthday(date) {
      const dateString = date.split('/');
      const yyyy = dateString[2];
      const mm = dateString[0];
      const dd = dateString[1];
      return `${yyyy}-${mm}-${dd}`;
    },
    async submitAnswer() {
      const dateString = this.birthday.split('/');
      if (dateString.length == 3) {
        dateString[2] = dateString[2].replace(/^0+/, '');
      } else {
        this.buttonDisabled = false;
        this.$emit('showAlert', 'Please input a valid birthday.');
        return;
      }

      this.birthday = `${dateString[0]}/${dateString[1]}/${dateString[2]}`;
      if (!this.moment(this.birthday, this.dateFormat, true).isValid()) {
        this.$emit('showAlert', 'Please input a valid birthday.');
        return;
      }

      // Checks if age is within the limits -- false is returned if a future date is entered
      const age = await this.calculateAge();
      if (age < 0 || age > 100 || age == false) {
        this.buttonDisabled = false;
        this.$emit('showAlert', 'Please input a valid birthday.');
        return;
      } else if (age < 35) {
        this.buttonDisabled = false;
        this.showModal = true;
        return;
      }
      this.$emit('setParams', 'birthday', this.formatBirthday(this.birthday));

      // Checks if empty
      if (this.birthday === '') {
        this.buttonDisabled = false;
        this.$emit('showAlert', 'Enter your birthday');
        return;
      } else if (age >= 60) {
        this.buttonDisabled = false;
        const rejectBody = {
          externalid: this.externalid,
          question: 'How old are you?',
          answer: age,
          onboardingflow: this.onboardingflow,
        };

        this.$_analytics_track(
          'Onboarding Reject',
          {
            ...rejectBody,
          },
        );

        this.$emit('setStep', 'over60');
      } else {
        this.buttonDisabled = false;
        this.$emit('setStep', 'sex');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
</style>
