import user from './user';
import notification from './notification';
import global from './global';
import onboarding from './onboarding';
import upsell from './upsell';
import message from './message';
import support from './support';
import socket from './socket';
import configuration from './configuration';
import cro from './cro';
import adminChat from './admin-chat';

export default {
  // step,
  configuration,
  user,
  notification,
  global,
  onboarding,
  upsell,
  message,
  support,
  socket,
  cro,
  adminChat,
};
