<template>
  <div class="verification-info">
    <h2 class="question-title with-subtext mt-2">
      Ok great! The Winona physician is ready to review your information.
    </h2>
    <p class="sub-text mb-3">
      You're almost done. In the next step, we'll verify your identity so your
      doctor can legally write you a prescription.
    </p>
    <p
      class="sub-text mb-2"
      style="color: #a783ff"
    >
      2 minute task
    </p>
    <div class="mt-5">
      <h2>Here's what's needed:</h2>
      <h2 class="med-font mb-4 row align-items-center">
        <b-col cols="auto">
          <b-avatar
            variant="primary"
            text="1"
            size="1.5rem"
          />
        </b-col>
        <b-col>
          A photo of your ID (required by your state to verify identity)
        </b-col>
      </h2>
      <h2 class="med-font row align-items-center">
        <b-col cols="auto">
          <b-avatar
            variant="primary"
            text="2"
            size="1.5rem"
          />
        </b-col>
        <b-col> A photo of your face (used to confirm the ID is yours) </b-col>
      </h2>
    </div>

    <p class="sub-text my-5 tw-mb-[130px] sm:tw-mb-0">
      A government-issued ID is required when purchasing any prescription
      medication, as the medical provider will need to verify your identity
      before they can determine if prescription medication is right for you.
      Your personal identifying information, medical history or ID will never
      be shared outside of our secure system.
    </p>

    <div class="tw-text-center">
      <OnboardingTLSSecurity />
    </div>

    <OnboardingCTAContainer>
      <div class="tw-flex tw-flex-col tw-w-full tw-gap-2">
        <b-button
          class="slight-rounded"
          variant="info"
          pill
          block
          @click="next"
        >
          <b-icon-lock />Continue
        </b-button>
      </div>
    </OnboardingCTAContainer>

    <SideModal
      :show="showWhyDialog"
      body-class="why-body"
      @exit="hideWhyDialog()"
    >
      <template #header>
        <h1 class="h2 mt-6 mb-0 heading text-primary">
          Onboarding
        </h1>
      </template>
      <template #body>
        <b-row>
          <b-col>
            <b-row class="mt-5">
              <b-col><h3>Identity Verification</h3></b-col>
            </b-row>
            <b-row>
              <b-col
                cols="9"
                class="d-flex flex-column justify-content-between"
              >
                <span
                  class="text-small text-muted"
                >Why we need this information?</span>
                <p>
                  Government issued ID is required when purchasing any
                  prescription medication, as the medical provider will need to
                  verify your identity before they can determine if prescription
                  medication is right for you. Your personal identifying
                  information, medical history or ID will never be shared
                  outside of our secure system.
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="align-self-center text-right"
          >
            <b-button
              pill
              variant="info"
              style="min-width: 250px"
              @click="hideWhyDialog()"
            >
              OK
            </b-button>
          </b-col>
        </b-row>
      </template>
    </SideModal>
    <div class="bg-overlay"></div>
  </div>
</template>

<script>
// services
import { OnboardingService } from '@/services/onboarding.service';

// components
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';
import SideModal from '@/components/partials/SideModal';
import OnboardingTLSSecurity from '@/components/onboarding/OnboardingTLSSecurity'

// mixins
import analyticsMixin from '@/mixins/analytics';

export default {
  components: {
    SideModal,
    OnboardingCTAContainer,
    OnboardingTLSSecurity
  },
  mixins: [analyticsMixin],
  props: {
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    analyticsId: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
    phoneno: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    firstname: {
      type: String,
      default: '',
    },
    lastname: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showWhyDialog: false,
    };
  },
  async mounted() {
    const onboarding = await OnboardingService.getPatientOnboarding();

    try {
      const event_checkoutstarted = await OnboardingService.triggerOnboardingEvents({
        eventname: 'VerificationStarted',
        path: window.location.pathname,
      })
      if (event_checkoutstarted.data.status === 'new') {
        await this.$_analytics_track(
          'Identity Verification Started',
          {
            customerId: this.analyticsId,
            onboardingflow: this.onboardingflow,
            state: this.state,
            phoneno: this.phoneno,
            email: this.email,
            messageID: event_checkoutstarted.data.id,
            firstname: this.firstname,
            lastname: this.lastname,
          },
        );

        // WIN-14971
        // WIN-20853 - Remove Northbeam from Onboarding
        // window.Northbeam.fireCustomGoal("Verification Started", {});
        // console.log("Northbeam: Verification Started");

        // GTM: Mariwin
        // window.dataLayer = window.dataLayer || [];
        // window.dataLayer.push({
        //   event: "Identity Verification Started",
        //   customerID: onboarding.data.id,
        //   firstName: onboarding.data.firstname,
        //   lastName: onboarding.data.lastname,
        //   phone: onboarding.data.phoneno,
        // });

        if (event_checkoutstarted.data.id) {
          this.fbqTrack(onboarding.data, event_checkoutstarted.data.id);
        } else if (
          event_checkoutstarted.data.id &&
            process.env.VUE_APP_SERVER != 'production'
        ) {
          console.log('FB TRACKING VerificationStarted');
          console.log(
            'FB TRACKING VerificationStarted: ' +
                event_checkoutstarted.data.id
          );
        }
      }
    } catch (err) {
      console.log(err);
    }

    await this.$_analytics_track(
      'Onboarding Screen Viewed',
      {
        customerId: this.analyticsId,
        screen: '4.1-A',
        onboardingflow: this.onboardingflow,
        firstname: this.firstname,
        lastname: this.lastname,
      },
    );
  },
  methods: {
    showWhyNeedDialog() {
      this.elementTag('hidden', true);
    },
    hideWhyDialog() {
      this.elementTag(null, false);
    },
    elementTag(style, Boolean) {
      this.showWhyDialog = Boolean;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    next() {
      this.$emit('setStep', 'idphoto');
    },
    async fbqTrack(data, eventID) {
      // FB Pixel Tracking
      try {
        fbq('init', '348219289912097', {
          em: data.email,
          fn: data.firstname,
          ln: data.lastname,
          ph: data.phoneno,
          external_id: data.id,
          extern_id: data.id,
        });
        // fbq("track", "PageView");
        const pageViewBody = { userid: data.id };
        OnboardingService.onboardingEvent('PageView', pageViewBody);
        fbq(
          'trackCustom',
          'VerificationStarted',
          {
            external_id: data.id,
            extern_id: data.id,
            state: this.state,
            ...(await this.$store.dispatch('getFBQParams')),
          },
          {
            eventID: eventID,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
