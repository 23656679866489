<template>
  <div class="preferred-treatment">
    <h2 class="question-title">
      What treatment would you prefer?
    </h2>
    <div
      role="group"
      class="group-mb"
    >
      <v-select
        v-model="selectedTreatment"
        class="custom-input-style"
        :options="options"
        label="text"
        :reduce="(options) => options.value"
        append-to-body
        :searchable="false"
        :calculate-position="withPopper"
        placeholder="Select treatment type"
      >
        <template #open-indicator="{ attributes }">
          <b-icon
            v-bind="attributes"
            icon="chevron-down"
          />
        </template>
      </v-select>
    </div>

    <OnboardingCTAContainer>
      <b-row>
        <b-col
          cols="12"
          md="9"
          class="mb-3"
        >
          <b-button
            variant="info"
            block
            pill
            :disabled="saving"
            @click="submitAnswer"
          >
            {{ saving ? "Saving" : "Continue" }}
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="9"
        >
          <b-button
            v-if="!saving"
            variant="outline-info"
            block
            pill
            :disabled="saving"
            @click="submitDoctorChoice"
          >
            {{ saving ? "Saving" : "I want doctor to recommend" }}
          </b-button>
        </b-col>
      </b-row>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
import { LookUpService } from '@/services/lookup.service';
import popper from '@/assets/js/popper.js';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';

export default {
  components: {
    OnboardingCTAContainer,
  },
  mixins: [popper],
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTreatment: '',
      options: [],
    };
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    async getOptions() {
      let { data } = await LookUpService.getLookUp('treatmentform');

      data = data.filter(x => x.othername2 != undefined);

      for (const item of data) {
        this.options.push({
          value: item.id,
          text: item.othername2,
        });
      }
    },
    submitAnswer() {
      if (this.selectedTreatment == '' || this.selectedTreatment == null) {
        this.$emit('alert', 'Please state your preferred treatment');
        return;
      }

      this.$emit(
        'submitAnswer',
        { doctorPref: false },
        this.selectedTreatment
      );
    },
    submitDoctorChoice() {
      this.$emit('submitAnswer', { doctorPref: true });
    },
  },
};
</script>

<style scoped>
::v-deep .vs__dropdown-toggle:not(.custom-select) {
  padding-right: 0.75em;
}

::v-deep .custom-input-style .vs__dropdown-toggle {
  margin-top: 10px;
  /* padding-left: 0.75em; */
  border: 0;
  background-color: rgba(242, 242, 242, 0.5);
  border-radius: 0.5rem;
}

.vs__dropdown-toggle {
  height: 52px;
}

.preferred-treatment > .ash-bg {
  background: rgba(242, 242, 242, 0.5);
}
</style>

<style lang="scss" scoped>
.custom-control-label {
  line-height: 1.1;
}
</style>
