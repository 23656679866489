<template>
  <div
    class="billing-input"
  >
    <div
      class="accordion"
      role="tablist"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <div
          :aria-expanded="isCreditOrDebitVisible ? 'true' : 'false'"
          aria-controls="accordion-1"
          header-tag="header"
          role="tab"
          class="d-flex payment-option"
          @click="isCreditOrDebitVisible = !isCreditOrDebitVisible"
        >
          <div>
            <span
              v-if="isCreditOrDebitVisible"
              class="when-opened"
            >
              <b-avatar
                variant="tertiary"
                size="22px"
              >
                <b-icon
                  icon="check"
                  font-scale="1.2"
                  style="color: #cdfcb1"
                />
              </b-avatar>
            </span>
            <span
              v-else
              class="when-closed"
            >
              <b-avatar
                style="background-color: #e0e0e0"
                icon="chevron-up"
                size="22px"
              >
                <b-icon
                  icon="circle-fill"
                  font-scale="1.2"
                  style="color: #e0e0e0"
                />
              </b-avatar>
            </span>
          </div>
          <div
            class="payment-title"
            style="flex-grow: 1"
          >
            Credit or Debit Card
          </div>
          <div class="tw-flex">
            <img
              src="@/assets/images/payment-methods/jcb.svg"
              width="20px"
            >
            <img
              src="@/assets/images/payment-methods/visa.svg"
              width="20px"
            >
            <img
              src="@/assets/images/payment-methods/mastercard.svg"
              width="20px"
            >
          </div>
        </div>
        <b-collapse
          id="accordion-1"
          v-model="isCreditOrDebitVisible"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-row>
              <b-col>
                <div
                  ref="card"
                  class="form-control m-2"
                >
                  <!-- A Stripe Element will be inserted here. -->
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card
        v-show="canMakePayment"
        no-body
        class="mb-4"
      >
        <div
          :aria-expanded="isPayWithVisible ? 'true' : 'false'"
          aria-controls="accordion-2"
          header-tag="header"
          role="tab"
          class="d-flex payment-option"
          @click="isPayWithVisible = !isPayWithVisible"
        >
          <div>
            <span
              v-if="isPayWithVisible"
              class="when-opened"
            >
              <b-avatar
                variant="tertiary"
                size="22px"
              >
                <b-icon
                  icon="check"
                  font-scale="1.2"
                  style="color: #cdfcb1"
                />
              </b-avatar>
            </span>
            <span
              v-else
              class="when-closed"
            >
              <b-avatar
                style="background-color: #e0e0e0"
                icon="chevron-up"
                size="22px"
              >
                <b-icon
                  icon="circle-fill"
                  font-scale="1.2"
                  style="color: #e0e0e0"
                />
              </b-avatar>
            </span>
          </div>
          <div
            class="payment-title"
            style="flex-grow: 1"
          >
            Pay with
          </div>
          <img
            src="@/assets/images/payment-methods/apple-pay.svg"
            width="20px"
          >
          <img
            src="@/assets/images/payment-methods/google-pay.svg"
            width="20px"
          >
        </div>
        <b-collapse
          id="accordion-2"
          v-model="isPayWithVisible"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <b-row>
              <b-col>
                <div
                  id="payment-request-button"
                  ref="paymentRequestButton"
                ></div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <b-row>
      <b-col cols="12">
        <b-form-checkbox
          v-model="sameaddress"
          class="mt-3 mb-3 checkbox-label"
          value="true"
          unchecked-value="false"
        >
          My billing information is the same as my shipping information
        </b-form-checkbox>
        <BillingAddress
          v-if="sameaddress != 'true'"
          ref="addressComponent"
          class="mt-5"
        />
      </b-col>
    </b-row>

    <div class="checkout-agreement tw-mt-6">
      <p class="tw-text-sm">
        By adding a payment method and clicking "Submit," you're agreeing to
        opt into a subscription with Winona. However, you won't be charged
        unless your physician prescribes a treatment plan. If prescribed,
        your payment method will be charged at that time for the cost of
        your prescription(s). Your subscription will renew every month or
        every 3 months, depending on your preference. Please note you can
        cancel your subscription and stop receiving treatment at any time,
        by contacting the Patient Care Team.
      </p>
    </div>

    <div class="tw-text-center">
      <OnboardingTLSSecurity />
    </div>

    <OnboardingCTAContainer>
      <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full slight-rounded">
        <div>
          <b-button
            pill
            block
            variant="info"
            :disabled="disabled"
            class="slight-rounded"
            @click="checkAnswers({ paymentmethodid: null })"
          >
            Submit
          </b-button>
        </div>
      </div>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
// components
import BillingAddress from '@/components/onboarding/checkout/BillingAddress';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';
import OnboardingTLSSecurity from '@/components/onboarding/OnboardingTLSSecurity'
import CheckoutHappyPatientsBadge from '@/components/partials/CheckoutHappyPatientsBadge.vue';

// services
import { LookUpService } from '@/services/lookup.service';

export default {
  components: {
    OnboardingCTAContainer,
    BillingAddress,
    OnboardingTLSSecurity,
    CheckoutHappyPatientsBadge
  },
  props: {
    body: {
      type: Object,
      default: () => ({}),
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pk: process.env.VUE_APP_STRIPE_CLIENT_KEYS,
      stripe: undefined,
      card: undefined,
      discountcode: '',
      sameaddress: 'true',
      loading: false,
      states: [],
      total: 0,
      footerarray: [],
      disabled: false,
      isCreditOrDebitVisible: true,
      isPayWithVisible: false,
      canMakePayment: false,
    };
  },
  watch: {
    isCreditOrDebitVisible(val, prevVal) {
      if (this.isPayWithVisible === false && prevVal === true) {
        this.isCreditOrDebitVisible = true;
      }
    },
    isPayWithVisible(val, prevVal) {
      if (this.isCreditOrDebitVisible === false && prevVal === true) {
        this.isPayWithVisible = true;
      }

      if (val) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    totalPrice() {
      this.loadPaymentRequest();
    },
  },
  async mounted () {
    this.loadStripe();
    this.loadStates();
    this.loadPaymentRequest();

    if (this.body.hasOwnProperty('desiredplan')) {
      for (const plan of this.body.desiredplan) {
        let subscription = '';
        if (plan.pricingdetail.type == 'recurring') {
          if (plan.pricingdetail.recurring.interval_count == '90') {
            subscription = '3 months';
          } else if (plan.pricingdetail.recurring.interval_count == '30') {
            subscription = 'monthly';
          }
        }

        const notes = `If prescribed ${plan.name}, you are enrolling in a ${subscription} subscription for $${plan.price}.`;
        this.footerarray.push(notes);
      }
      this.footerarray.push('You can cancel your subscription(s) at any time.');
    }
    if (this.body.hasOwnProperty('desiredaddon')) {
      for (const addon of this.body.desiredaddon) {
        this.total += addon.price;
        this.totalPrice += addon.price;
      }
    }
    if (this.body.hasOwnProperty('couponid')) {
      this.discountcode = this.body.couponid;
      // Add discount context
      this.footerarray.push(
        'A discount code is only applied to the first prescription and not the subsequent subscriptions.'
      );
    }
  },
  methods: {
    loadStripe() {
      this.stripe = Stripe(this.pk);
      this.card = this.stripe.elements().create('card', {
        hidePostalCode: true,
      });
      this.card.mount(this.$refs.card);
    },
    async loadStates() {
      const { data } = await LookUpService.getLookUp('state');
      this.states = data;
    },
    loadPaymentRequest() {
      const stripe = Stripe(this.pk);
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: this.totalPrice * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      const elements = stripe.elements();
      const prButton = elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
      });
      paymentRequest.canMakePayment().then(result => {
        this.canMakePayment =
          result != null &&
          (result.hasOwnProperty('applePay') ||
            result.hasOwnProperty('googlePay'));
        if (result) {
          prButton.mount('#payment-request-button');
        } else {
          document.getElementById('payment-request-button').style.display =
            'none';
        }
      });
      paymentRequest.on('paymentmethod', ev => {
        const paymentmethodid = ev.paymentMethod.id;
        this.checkAnswers({ paymentmethodid });
        ev.complete('success');
      });
    },
    async checkAnswers({ paymentmethodid }) {
      this.disabled = true;
      let formattedAnswer;
      // * Pre-formats answer
      if (this.sameaddress == 'true') {
        formattedAnswer = {
          source: '',
          sameaddress: true,
        };
      } else {
        const billingaddress = {
          ...this.$refs.addressComponent.billingaddress,
        };

        if (
          !billingaddress.address1 ||
          !billingaddress.city ||
          !billingaddress.state ||
          !billingaddress.zipcode ||
          !billingaddress.phoneno
        ) {
          this.$emit(
            'showAlert',
            'Please complete the billing address fields.'
          );
          this.disabled = false;
          return;
        }

        formattedAnswer = {
          source: '',
          sameaddress: false,
          billingaddress: billingaddress,
        };

        if (formattedAnswer.billingaddress.address2 == null) {
          delete formattedAnswer.billingaddress['address2'];
        }

        formattedAnswer.billingaddress.state = this.states.filter(
          state =>
            state.name == this.$refs.addressComponent.billingaddress.state
        )[0].id;
      }

      if (paymentmethodid) {
        delete formattedAnswer.source;
        formattedAnswer.paymentmethod = paymentmethodid;
      }

      try {
        if (formattedAnswer.paymentmethod) {
          this.$emit('nextStep', formattedAnswer, this.total);
        } else {
          this.$emit('hideAlert');
          const token = await this.stripe.createToken(this.card);
          if (token.error) {
            this.$emit('showAlert', token.error.message);
            this.disabled = false;
            return;
          }
          formattedAnswer.source = token.token.id;
          this.$emit('nextStep', formattedAnswer, this.total);
          this.loading = true;
        }
      } catch (err) {
        this.loadPaymentRequest();
        this.$emit('showAlert', err);
        this.disabled = false;
        this.loading = false;
      } finally {
        this.disabled = false;
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.discount-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #cdfcb1;
  background-color: #b293ff;
  border-color: #b293ff;
  text-decoration: none;
}

.badge-tertiary {
  background-color: $tertiary;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid #edf2f9;
}

.collapsed .when-opened,
.not-collapsed .when-closed {
  display: none;
}

.collapse.show,
.not-collapsed {
  background-color: #f9f9f9;
}

.payment-option {
  padding: 8px 12px;
  outline: none;
  cursor: pointer;

  @include media-breakpoint-up(xxl) {
    padding: 8px * $scale-font 12px * $scale-font;
  }

  .payment-title {
    padding-left: 13px;

    @include media-breakpoint-up(xxl) {
      padding-left: 13px * $scale-font;
    }
  }
}

img {
  margin-left: 5px;
  width: 35px;
  @include media-breakpoint-down(md) {
    width: 20px;
  }
}

.checkout-agreement {
  ul {
    list-style-type: none;
    li::marker {
      content: "-";
    }
    li {
      padding-left: 30px;
    }
    padding-left: 5px;
  }
}
</style>
