<template>
  <b-row
    align-v="stretch"
    class="nothing"
  >
    <b-col align-self="center">
      <h2 class="question-title with-subtext">
        {{ body.title }}
      </h2>
      <p
        v-if="body.description"
        class="sub-text mb-0"
      >
        {{ body.description }}
      </p>
      <div
        v-if="body.image"
        class="image-holder"
      >
        <img
          :src="body.image"
          alt=""
        >
      </div>

      <OnboardingCTAContainer>
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
          <b-button
            class="slight-rounded"
            pill
            block
            variant="info"
            :disabled="disabled"
            @click="submitAnswer"
          >
            Continue
          </b-button>
        </div>
      </OnboardingCTAContainer>
    </b-col>
  </b-row>
</template>

<script>
// components
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer.vue';

export default {
  components: {
    OnboardingCTAContainer,
  },
  props: {
    body: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submitAnswer() {
      this.$emit('submitAnswer', this.body.id, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.image-holder {
  height: 350px;
  margin-bottom: 1rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>
