import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class AdminChatService extends BaseService {
  static async getMessages(patientid, nextpage) {
    try {
      const response = await this.request({ auth: true }, 'v3').get(
        `/admin/patients/${patientid}/messages`, {
          params: {
            limit: 50,
            cursor: nextpage
          }
        }
      )
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAssignedAdmin(patientid) {
    try {
      const response = await this.request({ auth: true }, 'v3').get(
        `/admin-patient-chat/conversations/${patientid}`
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getArticlesById(articles) {
    try {
      const response = await this.request({ auth: true }, 'v2').post(
        '/intercom/articles',
        { articleIds: articles }
      );
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
