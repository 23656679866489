import { OnboardingService } from '@/services/onboarding.service';
import { ReferralService } from '@/services/referral.service.js';
import store from '../store/index.js';

const onboardingRoutes = [
  '/health-questions',
  '/treatment-options',
  '/treatment-addons',
  '/identity-verification',
  '/checkout',
];

const publicRoutes = ['/eligibility', '/login'];

export default async function onboardingRouter (to, from, next) {
  store.dispatch('setUTMS', to.query);

  // Share A Sale
  if (
    onboardingRoutes.includes(onboardingRoutes.includes(to.path)) ||
    publicRoutes.includes(to.path)
  ) {
    const sas = document.getElementById('shareasale');
    if (!sas) {
      const head = document.head;
      const script = document.createElement('script');
      script.id = 'shareasale';
      script.type = 'text/javascript';
      script.src = 'https://www.dwin1.com/19038.js';
      script.defer = 'defer';
      head.appendChild(script);
    }
  }

  if (
    !window.localStorage.getItem('accessToken') &&
    (onboardingRoutes.includes(to.path) || to.path == '/eligibility')
  ) {
    if (to.path == '/eligibility') {
      next();
    } else {
      // Forward to eligiblity
      next({ path: '/eligibility' });
    }
    return;
  }

  // Variables
  let data;
  let onboardingstep;
  try {
    if (window.localStorage.getItem('accessToken')) {
      const res = await OnboardingService.getPatientOnboarding();
      data = res.data;
      onboardingstep = data.onboardingstep;
    } else {
      throw 'Not logged in';
    }
  } catch (err) {
    if (err.status == 401) {
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('token');
    }
    if (publicRoutes.includes(to.path)) {
      next();
    } else {
      next({ name: 'login' });
    }
    return;
  }

  // * Share a Sale
  if (data.shareasaleclickid) {
    if ($cookies.get('sas_m_awin')) {
      if (data.shareasaleclickid != $cookies.get('sas_m_awin')) {
        await OnboardingService.sendSASClickId(
          $cookies.get('sas_m_awin').clickId
        );
        $cookies.remove('sas_m_awin');
      }
    }
  } else {
    if ($cookies.get('sas_m_awin')) {
      await OnboardingService.sendSASClickId(
        $cookies.get('sas_m_awin').clickId
      );
      $cookies.remove('sas_m_awin');
    }
  }

  // WIN-27262
  if (data.couponid && $cookies.get('referralToken')) {
    await ReferralService.overwritePromocode($cookies.get('referralToken'));
  }

  if (data.onboardingstatus == 'DONE') {
    if (
      onboardingRoutes.includes(to.path) ||
      to.path == '/' ||
      to.path == '/home' ||
      publicRoutes.includes(to.path)
    ) {
      next({ name: 'home' });
      return;
    }
  } else {
    // WIN-15564
    if ($cookies.get('promocodeExistingID')) {
      $cookies.remove('promocode');
      $cookies.remove('promocodeExistingID');
    }

    if (to.path == '/' || to.path == '/eligibility') {
      if (onboardingstep == 1 && to.path == '/') {
        next({ path: '/eligibility' });
      } else if (onboardingstep == 1 && to.path == '/eligibility') {
        next();
      } else if (
        (onboardingstep >= 2 && onboardingstep < 3) ||
        onboardingstep == 1.5 ||
        onboardingstep == 3.1
      ) {
        next({ path: '/health-questions' });
      } else if (onboardingstep >= 3 && onboardingstep < 5) {
        next({ path: '/treatment-options' });
      } else if (
        onboardingstep >= 5 &&
        onboardingstep != 5.4 &&
        onboardingstep < 6
      ) {
        next({ path: '/identity-verification' });
      } else if (onboardingstep >= 6 || onboardingstep == 5.4) {
        next({ path: '/checkout' });
      }
    } else {
      next();
    }
  }
}

export { onboardingRoutes };
