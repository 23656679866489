<template>
  <b-row
    class="pl-3"
  >
    <h2 class="question-title">
      What symptoms would you like to treat?
    </h2>

    <b-alert
      :show="alert.show"
      :variant="alert.type"
    >
      {{ alert.message }}
    </b-alert>

    <b-col
      cols="12"
      class="fs-exclude pl-0 pr-0"
    >
      <div
        v-if="isLoading"
        class="symptoms"
      >
        <BSkeleton
          v-for="i in 15"
          :key="i"
          class="tw-inline tw-gap-4"
        />
      </div>

      <ul
        v-else
        class="list-unstyled symptoms"
      >
        <li
          v-for="(symptom, i) in symptoms"
          :key="i"
        >
          <input
            :id="'checkbox-' + i"
            v-model="selected"
            type="checkbox"
            :value="symptom.id"
          >

          <label :for="'checkbox-' + i">
            <SymptomsLabel :symptom="symptom" />
            <div
              v-if="0"
              class="symptoms-icon"
            >
              <img
                :src="preloadedIcons[symptom.id]"
                :alt="symptom.name"
                class="symptoms-icon__img"
              >

              <span class="symptoms-icon__name">
                {{ symptom.name }}
              </span>
            </div>
          </label>
        </li>
      </ul>
    </b-col>

    <OnboardingCTAContainer>
      <b-button
        pill
        block
        variant="info"
        class="btn slight-rounded"
        :disabled="disabled"
        @click="submitSymptoms"
      >
        Continue
      </b-button>
    </OnboardingCTAContainer>
  </b-row>
</template>

<script>
import { LookUpService } from '@/services/lookup.service';

// libs
import { mapState } from 'vuex';

// mixins
import analyticsMixin from '@/mixins/analytics';

// components
import SymptomsLabel from './SymptomsLabel.vue';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer.vue';

// utils
import { waitCallbackTruthy } from '@/utils/waitCallbackTruthy';
import { getElementHeightAsync } from '@corefront/utils/getElementHeight'

export default {
  components: {
    SymptomsLabel,
    OnboardingCTAContainer
  },
  mixins: [analyticsMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    userid: {
      type: String,
      default: '',
    },
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    firstname: {
      type: String,
      default: '',
    },
    lastname: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      alert: {
        show: false,
        type: '',
        message: '',
      },
      symptoms: [],
      selected: [],
      preloadedIcons: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapState('onboarding', ['onboardingPatient']),
  },
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.populateSymptoms(),
      this.populateSelectedSymptoms(),
    ]);
    this.isLoading = false;
    this.$_analytics_track('Onboarding Screen Viewed', {
      customerId: this.userid,
      screen: '2.0-A',
      onboardingflow: this.onboardingflow,
      firstname: this.firstname,
      lastname: this.lastname,
    });
  },
  methods: {
    async populateSymptoms() {
      if (this.symptoms.length === 0) {
        const symptomData = await LookUpService.getLookUp('symptom');
        this.symptoms = symptomData.data;
      }
    },
    async populateSelectedSymptoms() {
      const data = await waitCallbackTruthy(() => this.onboardingPatient);

      if (data.symptomid) {
        this.selected = data.symptomid;
      } else if ($cookies.get('winona-quiz-v2-answers')) {
        const quizanswers = $cookies.get('winona-quiz-v2-answers');
        this.selected = quizanswers.selectedSymptoms.filter(
          e => e !== 'other'
        );
      } else {
        this.selected = [];
      }
    },
    async submitSymptoms() {
      this.selected = this.selected.filter(item => item);
      this.$emit('submitSymptoms', this.selected);
    },
  },
};
</script>
