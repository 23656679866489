/**
import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';
*/

import Vue from 'vue'
import { mapState } from 'vuex';

import { prefixObservable } from '@corefront/utils/prefixObservable'
import { prefixMethods } from '@corefront/utils/prefixMethods'

import { PROMO_CODES } from '@/constants/promoCodes'
import $cookies from '@/utils/cookies';

const PREFIX = '$_fsaHsaPromo_'

const data = Vue.observable({
  sessionPromo: null,
  hideBanner: false,
  observer: null,
})

const methods = {
  forceHideBanner() {
    data.hideBanner = true
    sessionStorage.setItem('hideSessionPromoBanner', 'true');
  },
  resetBanner(){
    sessionStorage.removeItem('hideSessionPromoBanner');
  },
  setSessionBannerShownToPatient(){
    if (this[`${PREFIX}isActiveFsaHsaPromo`]){
      sessionStorage.setItem('promoBannerShownTo', this.onboardingPatient?.email || '');
    }
  },
  evaluateSessionBanner(state){
    const shownTo = sessionStorage.getItem('promoBannerShownTo') || '';

    if (data.hideBanner && shownTo === state.onboardingPatient?.email){
      data.hideBanner = false
      sessionStorage.removeItem('hideSessionPromoBanner')
    }
  },
  observeCheckoutDisplay(path) {
    const targetNode = document.body;

    data.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          const billingCheckoutElement = document.getElementById('billing-checkout-display');
          const checkoutSuccessElement = document.getElementById('checkout-success-display');
          
          if (path === '/checkout') {
            data.hideBanner = false
            if (billingCheckoutElement || checkoutSuccessElement) {
              data.hideBanner = true
            }
          }
        }
      });
    });

    // Start observing the target node for child element additions
    data.observer.observe(targetNode, {
      childList: true, // Observe direct children being added/removed
      subtree: true // Observe all descendants
    });
  }
}

export const fsaHsaPromoMixin = {
  computed: {
    ...prefixObservable(data, PREFIX),
    ...mapState('onboarding', ['onboardingPatient']),
    [`${PREFIX}isActiveFsaStore30`]: function() {
      const cookiesSessionPromo = $cookies.get('sessionPromo');
      return (data.sessionPromo === PROMO_CODES.FSASTORE30 || cookiesSessionPromo === PROMO_CODES.FSASTORE30) && !data.hideBanner
    },
    [`${PREFIX}isActiveHsaStore30`]: function() {
      const cookiesSessionPromo = $cookies.get('sessionPromo');
      return (data.sessionPromo === PROMO_CODES.HSASTORE30 || cookiesSessionPromo === PROMO_CODES.HSASTORE30) && !data.hideBanner
    },
    [`${PREFIX}isActiveFsaHsaPromo`]: function(){
      return this[`${PREFIX}isActiveFsaStore30`] || this[`${PREFIX}isActiveHsaStore30`]
    },
    [`${PREFIX}sessionPromoValue`]: function () {
      const cookiesSessionPromo = $cookies.get('sessionPromo');
      return (
        data.sessionPromo ||
        cookiesSessionPromo ||
        this.onboardingPatient?.couponid || ''
      );
    },
  },
  mounted() {
    const path = this.$route.path
    const urlSessionPromo = this.$route.query.sessionPromo;
    
    const cookiesSessionPromo = $cookies.get('sessionPromo');
    const hideSessionPromoBanner = sessionStorage.getItem('hideSessionPromoBanner');

    let promo = ''

    if (cookiesSessionPromo){
      promo = cookiesSessionPromo
      console.log('Cookie sessionPromo retrieved from marketing');
    } else if (Array.isArray(urlSessionPromo)) { 
      promo = urlSessionPromo[0] || ''
      $cookies.set('sessionPromo', promo, '48h')
    } else {
      promo = urlSessionPromo || ''
      $cookies.set('sessionPromo', promo, '48h')
    }

    if (promo){
      data.sessionPromo = promo
      $cookies.remove('promocode')
    }
    
    if (hideSessionPromoBanner === 'true'){
      data.hideBanner = true
    }
    methods.evaluateSessionBanner(this)
    methods.observeCheckoutDisplay(path);
  },
  methods: prefixMethods(methods, PREFIX),
  beforeDestroy() {
    if (data.observer) {
      data.observer.disconnect();
    }
  },
  watch: {
    onboardingPatient: {
      immediate: true,
      handler(){
        methods.evaluateSessionBanner(this)
      }
    }
  }
}