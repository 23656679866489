<template>
  <div class="edit-checkout">
    <b-row>
      <b-col>
        <b-alert
          :show="alert.show"
          variant="warning"
        >
          {{ alert.message }}
        </b-alert>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <label class="sub-text"> Preferred Treatment Type </label>
        <v-select
          v-model="selectedTreatment"
          class="custom-input-style treatment-type"
          :options="options"
          label="text"
          :reduce="(options) => options.value"
          :clearable="false"
          :searchable="false"
          placeholder="Select treatment type"
        >
          <template #open-indicator="{ attributes }">
            <b-icon
              v-bind="attributes"
              icon="chevron-down"
            />
          </template>
        </v-select>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col v-if="editedPlan.length > 0">
        <ProductRevealCheckbox
          v-for="product in editedPlan"
          ref="ProductRevealCheckbox"
          :key="product.id"
          :product="product"
          @set-selection="updateSelection"
          @update-shipping="updateShipping"
        />
      </b-col>
    </b-row>

    <b-modal
      v-model="progesteroneModal"
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      backdrop.preventDefaultult
      no-close-on-esc
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo dark"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <p
          class="sub-text"
          style="color: inherit"
        >
          Because of your medical history, it would not be safe to take Estrogen
          without Progesterone.
        </p>
        <b-button
          variant="info"
          pill
          block
          class="slight-rounded"
          @click="progesteroneModal = false"
        >
          Continue
        </b-button>
      </div>
    </b-modal>

    <b-row class="d-flex jutify-content-end tw-mb-20">
      <b-col>
        <b-button
          variant="outline-info"
          pill
          size="lg"
          class="mr-2 mb-2 slight-rounded"
          :disabled="buttonDisabled"
          @click="$emit('close-edit')"
        >
          Cancel
        </b-button>
        <b-button
          variant="info"
          pill
          size="lg"
          class="mb-2 slight-rounded"
          :disabled="buttonDisabled"
          @click="submitAnswer"
        >
          Update
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { OnboardingService } from '@/services/onboarding.service';
import { LookUpService } from '@/services/lookup.service';
import ProductRevealCheckbox from '@/components/onboarding/treatments/ProductRevealCheckbox';

export default {
  components: {
    ProductRevealCheckbox,
  },
  props: {
    desiredPlan: {
      type: Array,
      default() {
        return [];
      },
    },
    productReveal: {
      type: Array,
      default() {
        return [];
      },
    },
    treatmentForm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      alert: {
        show: false,
        message: '',
      },
      editedPlan: [],
      progesteroneModal: false,
      buttonDisabled: false,
      options: [],
      selectedTreatment: '',
      loaded: false,
    };
  },
  watch: {
    productReveal: {
      handler: function () {
        console.log('productReveal', this.productReveal);
        this.editedPlan = this.productReveal;
      },
      deep: true,
    },
    async selectedTreatment() {
      if (this.loaded && this.selectedTreatment == 'nopreference') {
        this.selectedTreatment = 'cream';
        return;
      }
      if (this.loaded && this.selectedTreatment != '') {
        const { data } = await OnboardingService.getProductRevealView(
          this.selectedTreatment
        );
        console.log(data);
        this.editedPlan = data;
      }
    },
    editedPlan() {
      if (!this.loaded && this.editedPlan.length > 0) {
        console.log('editedPlan', this.editedPlan);
        this.selectedTreatment = this.treatmentForm;

        this.$nextTick(() => {
          const self = this;
          for (const currentProduct of self.$refs.ProductRevealCheckbox) {
            if (
              self.desiredPlan.some(
                o => o.productid == currentProduct.product.id
              )
            ) {
              currentProduct.setSelected(true);
              const currentPricing = self.desiredPlan.filter(
                o => o.productid == currentProduct.product.id
              )[0].pricingdetail.pricingid;
              currentProduct.selectedpricing = currentPricing;
            } else {
              currentProduct.setSelected(false);
            }
          }
        });
        this.loaded = true;
      }
    },
  },
  mounted() {
    // Autoselection
    this.getOptions();
    this.editedPlan = this.productReveal;
  },
  methods: {
    showAlert(message) {
      this.alert.show = true;
      this.alert.message = message;
    },
    updateSelection(id, selected, pricing, productselect, productdeselect) {
      if (selected) {
        if (!this.editedPlan.some(obj => obj.productid != id)) {
          this.editedPlan.push({
            productid: id,
            pricingid: pricing,
          });
        } else {
          this.editedPlan.forEach(o => {
            if (o.productid == id && o.pricingid != pricing) {
              o.pricingid = pricing;
            }
          });
        }
      } else {
        this.editedPlan = this.editedPlan.filter(obj => obj.productid != id);
      }
      for (const currentProduct of this.$refs.ProductRevealCheckbox) {
        if (productselect != undefined) {
          if (productselect.includes(currentProduct.product.id) && selected) {
            currentProduct.setSelected(true);
          }
        }

        if (productdeselect != undefined) {
          if (
            productdeselect.includes(currentProduct.product.id) &&
            !selected
          ) {
            if (currentProduct.selected) {
              this.progesteroneModal = true;
            }
            currentProduct.setSelected(false);
          }
        }
      }
    },
    updateShipping(
      id,
      pricing,
      productselect,
      productdeselect,
      availablepricing
    ) {
      this.editedPlan.forEach(o => {
        if (o.productid == id && o.pricingid != pricing) {
          o.pricingid = pricing;
        }
      });

      const currentSubscriptionType = availablepricing.find(o => {
        return o.pricingid == pricing;
      }).subscriptiontype;

      if (productselect != undefined || productdeselect != undefined) {
        for (const currentProduct of this.$refs.ProductRevealCheckbox) {
          if (
            (productselect != undefined &&
              productselect.includes(currentProduct.product.id)) ||
            (productdeselect != undefined &&
              productdeselect.includes(currentProduct.product.id))
          ) {
            if (currentProduct.selected) {
              const targetPricings = currentProduct.product.availablepricing;
              currentProduct.selectedpricing = targetPricings.find(o => {
                return o.subscriptiontype == currentSubscriptionType;
              }).pricingid;
            }
          }
        }
      }
    },
    async submitAnswer() {
      this.alert.show = false;
      this.buttonDisabled = true;
      const updatedPlan = [];

      if (!this.selectedTreatment) {
        this.showAlert('Please select at least one treatment form.');
        this.buttonDisabled = false;
        return;
      }

      // Change treatmentform
      const updateTreatmentForm = await OnboardingService.updateTreatmentForm(
        {
          treatmentform: this.selectedTreatment,
        }
      );

      for (const currentProduct of this.$refs.ProductRevealCheckbox) {
        if (currentProduct.selected) {
          updatedPlan.push({
            productid: currentProduct.product.id,
            pricingid: currentProduct.selectedpricing,
          });
        }
      }

      if (updatedPlan.length <= 0) {
        this.showAlert('Please select at least one product.');
        this.buttonDisabled = false;
        return;
      }

      const params = {
        agreedtomedication: true,
        desiredplan: updatedPlan,
      };
      console.log(updatedPlan);
      const updateOnboarding = await OnboardingService.updatePatientOnboarding(
        3.2,
        params
      );
      this.$emit('update-checkout');
      this.buttonDisabled = false;
    },
    async getOptions() {
      let { data } = await LookUpService.getLookUp('treatmentform');
      data = data.filter(x => x.othername2 != undefined);
      for (const item of data) {
        this.options.push({
          value: item.id,
          text: item.othername2,
        });
      }
    },
  },
};
</script>

<style></style>
