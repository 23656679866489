<template>
  <div class="shipping-info tw-mb-[130px] sm:tw-mb-0">
    <!-- Shipping address confirmation modal -->
    <b-modal
      v-model="showModal"
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo dark"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2
          class="mb-3 heading color-inherit"
          style="font-size: 26px; line-height: 32px"
        >
          We are unable to verify your address.
        </h2>
        <p style="font-size: 0.8rem">
          {{ shippingString }}
        </p>
        <p class="mb-5">
          Are you sure you want to ship here?
        </p>
        <b-button
          class="slight-rounded"
          pill
          block
          variant="info"
          :disabled="buttonDisabled"
          @click="showModal = false"
        >
          Update Address
        </b-button>
        <b-button
          class="slight-rounded"
          pill
          block
          variant="outline-secondary"
          :disabled="buttonDisabled"
          @click="() => submitForm()"
        >
          Yes, it is correct
        </b-button>
      </div>
    </b-modal>

    <b-modal
      v-model="showUnavailableStateModal"
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
    >
      <h2
        class="mb-3 heading color-inherit"
        style="font-size: 26px; line-height: 32px"
      >
        Winona is currently unavailable for {{ selectedState.name }}.
      </h2>
      <p>
        Due to state-by-state medical laws, Winona is unable to ship to this
        state as a primary residence.
      </p>
      <p>To continue, please enter residence in one of the following states:</p>
      <ul>
        <div
          v-for="state in states"
          :key="state.id"
        >
          <li v-if="state.hasOwnProperty('supported')">
            {{ state.id }}
          </li>
        </div>
      </ul>
      <b-row>
        <b-col
          cols="4"
          offset="8"
        >
          <b-button
            class="slight-rounded"
            pill
            block
            variant="outline-secondary"
            @click="showUnavailableStateModal = false"
          >
            Close
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <h2 class="question-title with-subtext">
      Shipping Information &amp; Address
    </h2>
    <p class="sub-text">
      Please confirm the address of where you would like your Winona products
      shipped. We ship USPS Priority unless otherwise requested.
    </p>
    <b-row
      v-if="nonrx"
      class="mt-5"
    >
      <b-col
        cols="12"
        md="6"
      >
        <label
          for="firstname"
          class="input-label"
        >First Name*</label>
        <b-form-input
          v-model="user.firstname"
          class="fs-mask onboarding-input"
          name="firstname"
          placeholder="First Name"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <label
          for="lastname"
          class="input-label"
        >Last Name*</label>
        <b-form-input
          v-model="user.lastname"
          class="fs-mask onboarding-input"
          name="lastname"
          placeholder="Last Name"
        />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col
        cols="12"
        md="12"
        class="mb-3"
      >
        <label
          for="address1"
          class="input-label"
        >Street Address*</label>
        <vue-google-autocomplete
          id="map"
          ref="streetAddress"
          classname="form-control"
          placeholder="Street Address"
          :country="['us', 'pr']"
          class="fs-mask onboarding-input"
          @placechanged="getAddressData"
          @inputChange="onStreetAddressInputChange"
          @change="onStreetAddressChange"
        />
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="mb-3"
      >
        <label
          for="address2"
          class="input-label"
        >Apartment/Suite</label>
        <b-form-input
          v-model.trim="address2"
          class="fs-mask onboarding-input"
          name="address2"
          placeholder="Apartment/Suite"
          :formatter="removeAccents"
          @input="fromAutocomplete = false"
        />
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="mb-3"
      >
        <label
          for="city"
          class="input-label"
        >City*</label>
        <b-form-input
          v-model.trim="answers.city"
          class="fs-mask onboarding-input"
          name="city"
          placeholder="City"
          :formatter="removeAccents"
          @input="fromAutocomplete = false"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="mb-3"
      >
        <label
          for="state"
          class="input-label"
        >State*</label>
        <b-form-select
          v-model.trim="selectedState"
          :options="stateDropdownOptions"
          class="fs-mask onboarding-input"
          name="state"
          readonly
          placeholder="State"
          :formatter="removeAccents"
          @input="fromAutocomplete = false"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="mb-3"
      >
        <label
          for="zipcode"
          class="input-label"
        >Zip Code*</label>
        <b-form-input
          v-model.trim="answers.zipcode"
          class="fs-mask onboarding-input"
          name="zipcode"
          placeholder="Zip Code"
          @input="fromAutocomplete = false"
        />
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="mb-3"
      >
        <label
          for="phone"
          class="input-label"
        >Phone*</label>
        <b-form-input
          v-model.trim="phone"
          class="fs-mask onboarding-input"
          name="phone"
          placeholder="Phone Number"
          :formatter="formatPhoneNumber"
        />
      </b-col>
    </b-row>

    <OnboardingCTAContainer>
      <div class="tw-w-full">
        <b-button
          class="slight-rounded"
          pill
          block
          variant="info"
          :disabled="buttonDisabled || loading"
          @click="() => submitForm()"
        >
          {{ loading ? "Loading" : !saving ? "Continue" : "Saving" }}
        </b-button>
        <PaymentMethod
          class="tw-pt-4"
          :total-price="totalPrice"
          @success="onPaymentMethodSuccess"
        />
      </div>
    </OnboardingCTAContainer>

    <p class="mt-5 tw-pb-14">
      Treatment will ship by USPS. If your address cannot receive USPS postage
      please let your physician know.
    </p>

    <SideModal
      :show="showSideModal"
      header-class="terms-title"
      body-class="terms-body"
      feature="address"
      @exit="hideSideModal()"
    >
      <template #header>
        <div class="mt-5">
          <h2 class="question-title with-subtext">
            Shipping Information &amp; Address
          </h2>
          <p class="sub-text mb-0">
            Please confirm the address of where you would like your Winona
            products shipped. We ship USPS Priority unless otherwise requested.
          </p>
        </div>
      </template>
      <template #body>
        <div>
          <w-checkbox
            v-for="(suggestedAddress, suggestedAddressKey) in suggestedAddresses"
            :id="`address${suggestedAddressKey}`"
            :key="suggestedAddressKey"
            v-model="selectedAddressSuggestion"
            :value="`${suggestedAddressKey}`"
            type="radio"
            has-content
            label=""
          >
            <small>
              {{ suggestedAddressKey === 0 ? "Original" : "Suggested" }} Address
            </small>

            <div class="mt-2">
              <p>{{ suggestedAddress.street }}</p>
              <p>{{ suggestedAddress.city }}</p>
              <p>{{ parseState(suggestedAddress.state) }}</p>
              <p>{{ suggestedAddress.country }}</p>
              <p>{{ suggestedAddress.zip }}</p>
            </div>
          </w-checkbox>
          <div class="pb-5">
            <b-button
              class="slight-rounded"
              pill
              block
              variant="info"
              :disabled="
                buttonDisabled || loading || selectedAddressSuggestion == ''
              "
              @click="onUseSuggestedAddress"
            >
              Use this Address
            </b-button>
            <b-button
              class="slight-rounded"
              pill
              block
              variant="link"
              :disabled="buttonDisabled || loading"
              @click="showSideModal = false"
            >
              Back
            </b-button>
          </div>
        </div>
      </template>
    </SideModal>
    <div
      class="bg-overlay"
      @click="hideSideModal()"
    ></div>
  </div>
</template>

<script>
// services
import { OnboardingService } from '@/services/onboarding.service';
import { LookUpService } from '@/services/lookup.service';

// mixins
import mixins from '@/components/form/mixins';
import analyticsMixin from '@/mixins/analytics';

// libs
import VueGoogleAutocomplete from 'vue-google-autocomplete';

// components
import SideModal from '@/components/partials/SideModal.vue';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';
import PaymentMethod from '@/components/onboarding/checkout/PaymentMethod.vue';

export default {
  components: {
    VueGoogleAutocomplete,
    SideModal,
    OnboardingCTAContainer,
    PaymentMethod
  },
  mixins: [mixins, analyticsMixin],
  props: {
    body: {
      type: Object,
      default: () => ({}),
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    nonrx: {
      type: Boolean,
      default: false,
    },
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    analyticsId: {
      type: String,
      default: '',
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      states: [],
      answers: {
        address1: '',
        city: '',
        country: 'US',
        state: '',
        zipcode: null,
        // allowsms: true,
        // optforpromotions: true,
      },
      user: {
        firstname: '',
        lastname: '',
        birthday: '',
        phoneno: null,
      },
      phone: '',
      address2: '',
      selectedState: {},
      address: '',
      fromAutocomplete: false,
      showModal: false,
      showSideModal: false,
      selectedAddressSuggestion: '',
      suggestedAddresses: [],
      showUnavailableStateModal: false,
    };
  },
  computed: {
    stateDropdownOptions() {
      const states = [];
      for (const state of this.states) {
        states.push({ value: state, text: state.name });
      }
      return states;
    },
    shippingString() {
      return `${this.answers.address1}, ${
        this.address2 ? this.address2 + ',' : ''
      } ${this.answers.city}, ${this.answers.state}`;
    },
  },
  watch: {
    'user.phoneno'() {
      this.phone = this.formatPhoneNumber(this.user.phoneno);
    },
    selectedState(val) {
      this.answers.state = val.id;
    },
  },
  async mounted() {
    window.scrollTo(0, 0);

    try {
      this.loading = true;
      const { data } = await LookUpService.getLookUp('state');
      const userData = await OnboardingService.getPatientOnboarding();
      await this.$_analytics_track(
        'Onboarding Screen Viewed',
        {
          customerId: this.analyticsId,
          screen: '5.2-A',
          firstname: this.body.firstname,
          lastname: this.body.lastname,
          onboardingflow: this.onboardingflow,
        },
      );
      if (userData.data.address1) {
        this.fromAutocomplete = true;
      }
      this.answers.allowsms = userData.data.allowsms;
      this.$nextTick(() => {
        this.$refs.streetAddress.update(userData.data.address1);
      });
      this.answers.address1 = userData.data.address1;
      this.address2 = userData.data.address2;
      this.answers.city = userData.data.city;
      this.answers.zipcode = userData.data.zipcode;
      this.user.firstname = userData.data.firstname;
      this.user.lastname = userData.data.lastname;
      this.states = data;
      const selectedState = this.states.find(
        state => state.id == userData.data.state
      );
      this.selectedState = selectedState;
      this.answers.state = this.selectedState;
      this.phone = userData.data.phoneno;
      this.user.phoneno = userData.data.phoneno;
      this.user.birthday = userData.data.birthday;
      // this.answers.optforpromotions = userData.data.optforpromotions;
      this.loading = false;
    } finally {
      this.loading = false;
    }
  },
  methods: {

    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    async getAddressData(addressData) {
      this.address = addressData;
      this.fromAutocomplete = true;
      if (addressData.street_number) {
        this.answers.address1 = this.removeAccents(
          `${addressData.street_number} ${addressData.route}`
        );
      } else {
        this.answers.address1 = this.removeAccents(addressData.route);
      }
      if (addressData.country == 'Puerto Rico') {
        // Manual state search override
        const selectedState = this.states.find(state => state.id == 'PR');
        this.selectedState = selectedState;
      } else {
        const selectedState = this.states.find(
          state => state.id == addressData.administrative_area_level_1
        );

        // console.log(addressData.administrative_area_level_1);
        this.selectedState = selectedState;
      }
      this.answers.city = addressData.locality
        ? this.removeAccents(addressData.locality)
        : '';
      this.answers.zipcode = addressData.postal_code
        ? addressData.postal_code
        : '';
    },
    onStreetAddressChange() {
      this.$refs.streetAddress.update(
        this.removeAccents(this.answers.address1)
      );
    },
    onStreetAddressInputChange() {
      this.answers.address1 = this.removeAccents(
        this.$refs.streetAddress.autocompleteText
      );
      console.log(this.answers.address1);
    },
    async submitForm(paymentMethod) {
      this.$emit('setButtonDisable', true);

      // Check if state is available
      const selectedState = this.states.find(
        state => state.id == this.selectedState.id
      );
      if (!selectedState.hasOwnProperty('supported')) {
        this.showUnavailableStateModal = true;
        this.$emit('setButtonDisable', false);
        return;
      }

      if (this.address2 && this.address2 !== '')
        this.answers['address2'] = this.address2;
      if (this.nonrx) {
        const userAnswer = {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          phoneno: this.phone,
        };
        try {
          await OnboardingService.updatePatientOnboarding(
            5.1,
            userAnswer
          );
        } catch (err) {
          this.showSideModal = false;
          const id = this.analyticsId
            ? this.analyticsId
            : 'Could not get customer ID';
          const email = this.body.email
            ? this.body.email
            : 'Could not get customer email';
          this.$store.dispatch('sendErrorLog', {
            step: 'Checkout - Basic Info',
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.$emit('setButtonDisable', false);
          this.$emit('showAlert', err);
          return;
        }
      }
      this.$emit('setButtonDisable', false);
      const answers = { ...this.answers };
      answers.phoneno = this.phone;

      answers.state = this.selectedState.id;
      const index = parseInt(this.selectedAddressSuggestion);
      if (this.showModal || (this.showSideModal && index === 0)) {
        answers.overriddenaddress = true;
      } else {
        this.suggestedAddresses = [
          {
            street: this.answers.address1,
            city: this.answers.city,
            state: this.answers.state,
            country: this.answers.country,
            zip: this.answers.zipcode,
          },
        ];
      }
      this.$store.commit('global/SET_MODAL_STATE', false);
      this.$emit('nextStep', answers, paymentMethod);
    },
    hideSideModal() {
      this.suggestedAddresses = [];
      this.elementTag(null, false);
    },
    elementTag(style, show) {
      this.showSideModal = show;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    parseState(stateText, shorten) {
      if (!stateText) {
        return;
      }
      if (shorten) {
        if (stateText.length > 2) {
          const foundState = this.states.find(
            state => state.name == stateText
          );
          return foundState ? foundState.id : '';
        } else {
          return stateText;
        }
      }
      if (stateText.length > 2) {
        return stateText;
      } else {
        const foundState = this.states.find(state => state.id == stateText);
        return foundState ? foundState.name : '';
      }
    },
    onUseSuggestedAddress() {
      const index = parseInt(this.selectedAddressSuggestion);
      this.$refs.streetAddress.update(this.suggestedAddresses[index]?.street);
      this.answers.address1 = this.suggestedAddresses[index]?.street;
      this.answers.city = this.suggestedAddresses[index]?.city;
      this.selectedState = this.states.find(
        state =>
          state.id ==
          this.parseState(this.suggestedAddresses[index]?.state, true)
      );
      this.answers.state = this.selectedState?.name;
      this.answers.zipcode = this.suggestedAddresses[index]?.zip;
      this.submitForm();
    },
    formatPhoneNumber(phoneNumber) {
      const cleaned = phoneNumber.replace(/^\+1\s*/, '');
      const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      return formatted;
    },
    onPaymentMethodSuccess(stripeEvent) {
      const isCountrySupported = ['US', 'PR'].includes(stripeEvent.shippingAddress.country)
      if (!isCountrySupported) {
        this.$emit('showAlert', 'Unsupported country');
      }
      this.answers.address1 = stripeEvent.shippingAddress.addressLine?.[0];
      this.answers.city = stripeEvent.shippingAddress.city;
      const selectedState = this.states.find(
        state => state.id == stripeEvent.shippingAddress.region
      );
      this.selectedState = selectedState;
      this.answers.state = this.selectedState;
      this.answers.country = stripeEvent.shippingAddress.country;
      this.answers.zipcode = stripeEvent.shippingAddress.postalCode;
      this.phone = this.formatPhoneNumber(stripeEvent.payerPhone);
      this.submitForm(stripeEvent.paymentMethod);
    },
  },
};
</script>

<style scoped>
.shipping-info > .row > .col > label {
  margin-bottom: 0px;
}

.shipping-info > .row > .col > input {
  padding: 5px;
}
</style>

<style lang="scss" scoped>
label {
  font-size: 11px;

  // @include media-breakpoint-up(xxl) {
  //   font-size: (11px * 2) !important
  // }
}

.form-control {
  padding-left: 0;
}
</style>
