<template>
  <b-container fluid>
    <!-- for Desktop Only Progress -->
    <OnboardingProgress
      :hide="hideBackButton"
      :progress="progress"
      @back="prev"
    />

    <b-row
      class="onboarding-wrapper"
      :style="{
        marginBottom: $_onboardingCtaFooter_marginBottom
      }"
    >
      <OnboardingHeader
        :hide="hideBackButton"
        step="2"
        :title="title"
        @back="prev"
      />
      <b-col
        cols="12"
        :lg="questionType == 'symptoms' ? 6 : 5"
        xl="4"
        xxl="4"
        class="mt-6 pt-3 onboarding-content"
      >
        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{ alert.message }}
        </b-alert>

        <b-row class="d-lg-none">
          <b-col>
            <h3 class="bold d-letter-spacing onboarding-title">
              Intake
            </h3>
          </b-col>
          <b-col>
            <h3
              class="bold d-letter-spacing onboarding-title text-muted text-right"
            >
              Onboarding Completion
            </h3>
          </b-col>
        </b-row>

        <b-progress
          :value="progress[1].value"
          :max="2.9"
          class="step-progress d-lg-none mb-5"
          variant="purple"
          height="5px"
        />

        <DiscountBanner
          :show="showCouponBanner"
          :couponid="coupon.couponid"
        />

        <Symptoms
          v-if="questionType == 'symptoms'"
          :selected-symptoms="symptoms"
          :userid="analyticsId"
          :disabled="buttonDisabled"
          :firstname="firstname"
          :lastname="lastname"
          :onboardingflow="onboardingflow"
          @submitSymptoms="submitSymptoms"
        />

        <OtherSymptoms
          v-if="questionType == 'othersymptom'"
          @submitAnswer="submitSymptoms"
        />

        <Single
          v-if="questionType == 'single' && questionBody.type !== 'modal'"
          ref="single"
          :class="{
            'treatment-type': step == 2.77,
            'fs-mask': isMasked,
          }"
          :body="questionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submitAnswer"
          @alert="showAlert"
        />

        <Nothing
          v-if="questionType == 'nothing'"
          :class="{ 'fs-mask': isMasked }"
          :body="questionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submitAnswer"
        />

        <Multiple
          v-if="questionType == 'multiple'"
          :class="{ 'fs-mask': isMasked }"
          :body="questionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submitAnswer"
          @alert="showAlert"
        />

        <TextQuestion
          v-if="questionType == 'text'"
          heading
          text-height="70px"
          :class="{ 'fs-mask': isMasked }"
          :body="questionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submitAnswer"
        />

        <Array
          v-if="questionType == 'array'"
          heading
          :class="{ 'fs-mask': isMasked }"
          :body="questionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submitAnswer"
        />

        <DynamicArray
          v-if="questionType == 'dynamicarray'"
          :class="{ 'fs-mask': isMasked }"
          :body="questionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submitAnswer"
        />

        <BMI
          v-if="questionType == 'bmi'"
          :bmi="BMI"
          :userid="analyticsId"
          :disabled="buttonDisabled"
          :onboardingflow="onboardingflow"
          :firstname="firstname"
          :lastname="lastname"
          @submitAnswer="submitBMI"
          @alert="showAlert"
        />

        <Modal
          v-if="questionType == 'single' && questionBody.type == 'modal'"
          :body="questionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submitAnswer"
        />

        <Nothing
          v-if="questionType == 'over60'"
          :body="questionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submit60"
        />

        <Single
          v-if="composite && secondQuestionType == 'single'"
          class="pt-4 follow-up-questions"
          :body="secondQuestionBody"
          :disabled="buttonDisabled"
          @submitAnswer="submitAnswer"
        />

        <TextQuestion
          v-if="composite && secondQuestionType == 'text'"
          class="pt-4 follow-up-questions"
          :body="secondQuestionBody"
          :composite="composite"
          :disabled="buttonDisabled"
          @alert="showAlert"
          @submitAnswer="submitAnswer"
        />

        <Array
          v-if="composite && secondQuestionType == 'array'"
          class="pt-4 follow-up-questions"
          :body="secondQuestionBody"
          :composite="composite"
          :disabled="buttonDisabled"
          @alert="showAlert"
          @submitAnswer="submitAnswer"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
        xl="4"
        xxl="4"
      >
        <!-- Don't remove this -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// imports services
import { OnboardingService } from '@/services/onboarding.service';

// constants
import constants from '@/constants';

// components
import Symptoms from '@/components/onboarding/questions/Symptoms';
import OtherSymptoms from '@/components/onboarding/questions/OtherSymptoms';
import BMI from '@/components/onboarding/questions/BMI';
import Single from '@/components/onboarding/questions/Single';
import Nothing from '@/components/onboarding/questions/Nothing';
import Multiple from '@/components/onboarding/questions/Multiple';
import TextQuestion from '@/components/onboarding/questions/TextQuestion';
import Array from '@/components/onboarding/questions/Array';
import DynamicArray from '@/components/onboarding/questions/DynamicArray';
import Modal from '@/components/onboarding/questions/Modal';
import OnboardingHeader from '@/components/partials/OnboardingHeader';
import OnboardingProgress from '@/components/partials/OnboardingProgress';
import DiscountBanner from '@/components/partials/DiscountBanner.vue';

// mixins
import analyticsMixin from '@/mixins/analytics';
import { onboardingCtaFooter } from '@/mixins/onboardingCtaFooter';

export default {
  name: 'HealthQuestions',
  components: {
    Symptoms,
    Single,
    Nothing,
    Multiple,
    TextQuestion,
    Array,
    DynamicArray,
    BMI,
    Modal,
    OtherSymptoms,
    OnboardingHeader,
    OnboardingProgress,
    DiscountBanner,
  },
  mixins: [analyticsMixin, onboardingCtaFooter],
  beforeRouteLeave(to, from, next) {
    if (this.step === 1.5 && !this.exiting) {
      next(false);
    } else if (this.excludePaths.includes(to.path) || this.exiting) {
      next();
    } else {
      this.prev();
      next(false);
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      alert: {
        show: false,
        type: '',
        message: '',
      },
      coupon: {
        couponid: '',
      },
      BMI: {
        height: {
          feet: '',
          inch: '',
        },
        weight: '',
      },
      userid: null,
      analyticsId: null,
      healthquestionstatus: null,
      symptoms: [],
      step: 0,
      questions: [],
      questionType: '',
      questionBody: {},
      // Primes the composite logic
      composite: false,
      // Second question data
      secondQuestionType: '',
      secondQuestionBody: {},
      // Displays the question
      compositeActivate: false,
      compositeAnswer: [],
      // ? This looks weird, but it's the format required by the API.
      answer: {
        answers: [],
      },
      buttonDisabled: false,
      exiting: false,

      email: '',
      state: '',
      firstname: '',
      lastname: '',
      phoneno: '',

      // Over 60 override
      age: 0,
      currenthormonetherapy: [],
      onboardingflow: 'flowA',

      // for new progress UI update on the header
      progress: [
        {
          title: 'Account Creation',
          value: 100,
          max: 100,
        },
        {
          title: 'Intake',
          value: 0,
          max: 1.7,
        },
        {
          title: 'Onboarding Completion',
          value: 0,
        },
      ],
      manaJs: '',
      onboardingSymptoms: [],
      excludePaths: ['/treatment-options', '/website'],
    };
  },
  computed: {
    showCouponBanner() {
      return this.step == 2.77;
    },
    isMasked() {
      const maskedQuestions = [
        2.1, 2.21, 2.21, 2.3, 2.4, 2.41, 2.42, 2.5, 2.51, 2.6, 2.61, 2.7, 2.71,
        2.72, 2.741, 2.742, 2.731, 2.732, 2.9,
      ];
      return maskedQuestions.includes(this.questionBody.id);
    },
    hideBackButton() {
      if (this.step > 1.5 || this.questionType == 'othersymptom') {
        return false;
      }
      return true;
    },
  },
  beforeDestroy() {
    const manaJsSuppress = document.getElementById('manaJsSuppress');
    document.head.removeChild(manaJsSuppress);
  },
  async created() {
    this.manaSuppression();

    this.$_analytics_page();
    await this.loadPatient()

    this.loadHealthQuestionStep(this.step);
  },
  methods: {
    showAlert(message, type = null) {
      if (!type) type = 'warning';
      this.alert = {
        show: true,
        type: 'warning',
        message: message,
      };
      window.scrollTo(0, 0);
    },
    async submitSymptoms(selected, hasOthers = false) {
      this.buttonDisabled = true;

      if (selected.length < 1) {
        this.showAlert('Please select at least one symptom.');
        this.buttonDisabled = false;
        return;
      }

      if (selected.includes('others')) {
        this.symptoms = selected;
        this.step = 1.51;
        this.questionType = 'othersymptom';
        return;
      }
      try {
        let params;

        this.onboardingSymptoms = selected;

        if (hasOthers) {
          this.symptoms.splice(this.symptoms.indexOf('others'), 1);
          params = {
            symptomid: this.symptoms,
            othersymptom: selected,
          };
        } else {
          params = {
            symptomid: selected,
          };
        }
        const data = await OnboardingService.updatePatientOnboarding(
          1.5,
          params
        );
        this.loadHealthQuestionStep(data.data.onboardingstep);
      } catch (err) {
        if (err.status == 401) {
          this.exiting = true;
          this.$router.push('/login');
        } else {
          this.buttonDisabled = false;
          const id = this.userid || 'Could not get customer ID';
          const email = this.email || 'Could not get patient email';
          await this.$store.dispatch('sendErrorLog', {
            step: 'Health Questions - Symptoms',
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.showAlert(err);
        }
      }
    },
    async fbqTrack(data) {
      // FB Pixel Tracking
      try {
        fbq('init', '348219289912097', {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: this.userid,
          extern_id: this.userid,
        });
        const pageViewBody = { userid: this.userid };
        OnboardingService.onboardingEvent('PageView', pageViewBody);
        fbq(
          'trackCustom',
          'QuestionnaireCompleted',
          {
            external_id: this.userid,
            extern_id: this.userid,
            state: this.state,
            ...(await this.$store.dispatch('getFBQParams')),
          },
          {
            eventID: data.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async submitBMI(answer) {
      this.buttonDisabled = true;
      try {
        const { data } = await OnboardingService.updatePatientOnboarding(
          3.1,
          answer
        );
        this.buttonDisabled = false;

        if (!this.BMI.weight) {
          this.$_analytics_track('Medical Questionnaire Completed', {
            customerId: this.analyticsId,
            onboardingflow: this.onboardingflow,
            phoneno: this.phoneno,
            email: this.email,
            messageID: data.eventid,
            state: this.state,
            firstname: this.firstname,
            lastname: this.lastname,
          })

          const onboardingSymptoms = {};
          for (const symptomindex in this.onboardingSymptoms.slice(0, 3)) {
            if (this.onboardingSymptoms[symptomindex]) {
              onboardingSymptoms[
                `onboardingSymptom${Number(symptomindex) + 1}`
              ] = this.onboardingSymptoms[symptomindex];
            }
          }
          window.analytics.identify(this.userid, {
            ...onboardingSymptoms,
            onboardingSymptoms: this.onboardingSymptoms,
          });

          if (data.eventid) {
            await this.fbqTrack(data);
          } else if (
            data.eventid &&
            process.env.VUE_APP_SERVER != 'production'
          ) {
            console.log('FB TRACKING QuestionnaireCompleted');
            console.log('FB TRACKING QuestionnaireCompleted: ' + data.eventid);
          }
        }
        this.$router.push('/treatment-options');
      } catch (err) {
        if (err.status == 401) {
          this.exiting = true;
          this.$router.push('/login');
        } else {
          const id = this.userid || 'Could not get customer ID';
          const email = this.email || 'Could not get patient email';
          await this.$store.dispatch('sendErrorLog', {
            step: 'Health Questions - BMI',
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.buttonDisabled = false;
          this.showAlert(err);
        }
      }
    },
    submit60() {
      window.location.href = constants.OFFBOARDING_URL;
    },
    resetQuestions() {
      this.answer = {
        answers: [],
      };
      this.questionType = '';
      this.questionBody = {};
      this.composite = false;
      this.compositeActivate = false;
      this.secondQuestionType = '';
      this.secondQuestionBody = {};
      this.compositeAnswer = [];
    },
    async loadPatient () {
      const data = await this.$store.dispatch('onboarding/getPatientOnboarding');

      this.email = data.email;
      this.state = data.state;
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.phoneno = data.phoneno;
      this.onboardingflow = data.onboardingflow;

      if (data.currenthomonetherapy) {
        this.currenthormonetherapy = data.currenthomonetherapy;
      }

      if (data.symptomid) {
        this.onboardingSymtoms = data.symptomid;
      }

      if (data.couponid) {
        this.coupon.couponid = data.couponid;
      }

      this.userid = data.id;
      this.analyticsId = data.usercustomid
        ? data.usercustomid
        : this.userid;

      this.age = data.age;
      this.step = parseFloat(data.onboardingstep);
      this.$set(this.progress[1], 'value', this.step - 1.5);

      this.healthquestionstatus = data.healthquestionstatus;

      // * Setting of data variables
      this.symptoms = data.symptomid || [];
      if (data.height) {
        this.BMI.height.feet = data.height.feet;
        this.BMI.height.inch = data.height.inch;
        this.BMI.weight = data.weight;
      }
    },
    async loadHealthQuestionStep(next) {
      this.buttonDisabled = false;
      this.alert.show = false;
      this.resetQuestions();

      try {
        this.step = next || this.step;
        this.$set(this.progress[1], 'value', this.step - 1.5);

        this.$_analytics_track('Onboarding Screen Viewed', {
          customerId: this.analyticsId,
          screen: this.step + '-A',
          onboardingflow: this.onboardingflow,
          firstname: this.firstname,
          lastname: this.lastname,
        });

        if (!this.isStepOverridden()) {
          const questionData = await OnboardingService.getHealthQuestion(
            next
          );

          this.step = questionData.data[0].id;
          this.questions = questionData.data;
          this.questionType = questionData.data[0].answertype;
          this.questionBody = questionData.data[0];
        }
      } catch (error) {
        if (error.status == 401) {
          this.exiting = true;
          this.$router.push('/login');
        } else if (!window.localStorage.getItem('accessToken')) {
          this.$router.push('/login');
        } else {
          const id = this.userid || 'Could not get customer ID';
          const step = this.step || 'Could not get health question step';
          const email = this.email || 'Could not get patient email';

          await this.$store.dispatch('sendErrorLog', {
            step: `Health Questions - ${step}`,
            cus_id: id,
            email: email,
            error_log: error,
          });
          this.showAlert(error);
        }
      } finally {
        this.alert.show = false;
      }
    },
    isStepOverridden() {
      // * Unique question overrides,
      if (this.step == 1.5) {
        this.questionType = 'symptoms';
        return true;
      } else if (this.step == 3.1) {
        this.questionType = 'bmi';
        return true;
      } else if (this.step == 3.2) {
        this.step = 3.1;
        this.questionType = 'bmi';
        return true;
      } else if (this.step >= 4) {
        this.step = 3.1;
        this.questionType = 'bmi';
        return true;
      } else {
        return false;
      }
    },
    async submitAnswer(
      questionid,
      answer,
      _body = null,
      answerBody = null,
      others = []
    ) {
      this.buttonDisabled = true;
      // * Overwrites any previous answers
      this.answer.answers = this.answer.answers.filter(obj => {
        return obj.questionid !== questionid;
      });

      // * Over 60 handling
      if (questionid == 2.71 || questionid == 2.72) {
        if (questionid == 2.71) {
          this.currenthormonetherapy = answer;
        } else {
          this.currenthormonetherapy = [];
        }
      }

      if (!!answerBody && answerBody.inline) {
        this.answer.answers = [];
        this.composite = true;
        const CompositeHealthRequest =
          await OnboardingService.getHealthQuestion(answerBody.next);
        this.secondQuestionType = CompositeHealthRequest.data[0].answertype;
        this.secondQuestionBody = CompositeHealthRequest.data[0];
        this.answer.answers.push({ questionid: questionid, answer: answer });
        this.buttonDisabled = false;
      } else {
        try {
          if (this.questionType == 'multiple' && others.length > 0) {
            this.answer.answers.push({
              questionid: questionid,
              answer: answer,
              others: others,
            });
          } else {
            this.answer.answers.push({
              questionid: questionid,
              answer: answer,
            });
          }
          const HealthRequest = await OnboardingService.updatePatientOnboarding(
            this.step,
            this.answer
          );

          if (HealthRequest.data.next == 0 || HealthRequest.data.next == 4) {
            await this.trackOnboardingReject();

            window.location.href = constants.OFFBOARDING_URL;
          } else {
            this.loadHealthQuestionStep(HealthRequest.data.next);
          }
        } catch (err) {
          if (err.status == 401) {
            this.exiting = true;
            this.$router.push('/login');
          } else {
            this.buttonDisabled = false;
            const id = this.userid || 'Could not get customer ID';
            const step = this.step || 'Could not get health question step';
            const email = this.email || 'Could not get patient email';
            await this.$store.dispatch('sendErrorLog', {
              step: `Health Questions - ${step}`,
              cus_id: id,
              email: email,
              error_log: err,
            });
            this.showAlert(err);
          }
        }
      }
    },
    async trackOnboardingReject() {
      let { data } = await OnboardingService.getPreviousHealthQuestion(
        this.step
      );

      data = data[0];

      const rejectBody = {
        externalid: this.userid,
        question: data.question,
      };

      let answer;
      if (data.next == this.step) {
        answer = data.answerlist[0].name;
      } else {
        answer = data.answerlist.filter(o => o.next == this.step);
      }

      if (data.answertype == 'multiple') {
        const answerTexts = [];
        for (const a of data.answerlist) {
          if (data.answer.includes(a.id)) {
            answerTexts.push(a.name);
          }
        }
        rejectBody.answer = answerTexts;
      } else if (data.answertype == 'single' && typeof answer == 'object') {
        rejectBody.answer = answer[0].name;
      } else {
        rejectBody.answer = answer;
      }

      await this.$_analytics_track(
        'Onboarding Reject',
        {
          ...rejectBody,
        },
      );
    },
    async prev() {
      this.buttonDisabled = false;
      this.resetQuestions();

      if (this.step == 1.51 || this.step == 2.1) {
        this.step = 1.5;
        this.questionType = 'symptoms';
        this.$set(this.progress[1], 'value', this.step - 1.5);
        return;
      } else {
        let questionData;
        try {
          questionData = await OnboardingService.getPreviousHealthQuestion(
            this.step
          );
        } catch (err) {
          if (err.status == 401) {
            this.exiting = true;
            this.$router.push('/login');
          } else {
            const id = this.userid || 'Could not get customer ID';
            const step = this.step || 'Could not get step';
            const email = this.email || 'Could not get patient email';
            await this.$store.dispatch('sendErrorLog', {
              step: `Health Questions - ${step}`,
              cus_id: id,
              email: email,
              error_log: err,
            });
            this.showAlert(err);
          }
        }

        this.step = questionData.data[0].id;
        this.questions = questionData.data;
        this.questionType = questionData.data[0].answertype;
        this.questionBody = questionData.data[0];
        this.alert.show = false;
        this.$_analytics_track('Onboarding Screen Viewed', {
          customerId: this.analyticsId,
          screen: this.step + '-A',
          onboardingflow: this.onboardingflow,
          firstname: this.firstname,
          lastname: this.lastname,
        });
        this.$set(this.progress[1], 'value', this.step - 1.5);
      }
    },

    manaSuppression() {
      const suppress = document.createElement('script');
      suppress.type = 'text/javascript';
      suppress.src =
        'https://jsx.s3.us-west-2.amazonaws.com/mana-suppression.js';
      suppress.async = 'true';
      suppress.setAttribute('id', 'manaJsSuppress');
      // store it to data
      this.manaJs = document.head.appendChild(suppress);
    },
  },
};
</script>
