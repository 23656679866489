<template>
  <b-container fluid>
    <!-- for Desktop Only Progress -->
    <OnboardingProgress
      :hide="hideBackButton"
      :progress="progress"
      @back="prev"
    />
    <b-row
      class="onboarding-wrapper"
      :style="{
        marginBottom: $_onboardingCtaFooter_marginBottom
      }"
    >
      <OnboardingHeader
        step="4"
        :hide="hideBackButton"
        :title="title"
        @back="prev"
      />
      <b-col
        cols="12"
        lg="5"
        xl="4"
        xxl="4"
        class="mt-6 pt-3 onboarding-content"
      >
        <b-row class="d-lg-none">
          <b-col>
            <h3 class="bold d-letter-spacing onboarding-title">
              Onboarding Completion
            </h3>
          </b-col>
          <b-col>
            <h3
              class="bold d-letter-spacing onboarding-title text-muted text-right"
            >
              <!-- Onboarding Completion -->
            </h3>
          </b-col>
        </b-row>
        <b-progress
          :value="mobileProgressDisplay"
          :max="100"
          class="step-progress d-lg-none mb-5"
          variant="purple"
          height="5px"
        />

        <b-row align-h="center">
          <b-col cols="12">
            <b-alert
              :show="alert.show"
              :variant="alert.type"
            >
              {{
                alert.message
              }}
            </b-alert>
            <VerificationInfo
              v-if="step == 'information' && userData.onboardingversion == 'V3'"
              :analytics-id="analyticsId"
              :state="state"
              :phoneno="userData.phoneno"
              :email="userData.email"
              :firstname="userData.firstname"
              :lastname="userData.lastname"
              :onboardingflow="onboardingflow"
              @setStep="setStep"
            />
            <PatientInformation
              v-if="step == 'information' && userData.onboardingversion != 'V3'"
              :nonrx="userData.nonrxmedicationonly"
              :onboarding-data="userData"
              :analytics-id="analyticsId"
              :onboardingflow="onboardingflow"
              @setStep="setStep"
              @showAlert="showAlert"
              @updateUserInfo="updateUserInfo"
            />
            <IDPhoto
              v-if="step == 'idphoto'"
              :onboardingflow="onboardingflow"
              :state="state"
              :analytics-id="analyticsId"
              @setStep="setStep"
              @showAlert="showAlert"
              @hideAlert="hideAlert"
            />
            <PersonalPhoto
              v-if="step == 'personalphoto'"
              :onboardingflow="onboardingflow"
              :state="state"
              :analytics-id="analyticsId"
              @showAlert="showAlert"
              @hideAlert="hideAlert"
              @setStep="setStep"
            />
            <Complete
              v-if="step == 'complete'"
              :body="userData"
              @exit="exiting = true"
            />
            <div
              v-if="0"
              class="d-block text-center mt-4 mb-4"
            >
              <div class="d-none d-lg-block mt-4"></div>
              <small><b-icon icon="lock-fill" /> 256-BIT TLS SECURITY</small>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        lg="3"
        xl="4"
        xxl="4"
      >
        <div class="d-none d-lg-block mt-6"></div>
        <div class="d-block d-lg-none mt-4"></div>
        <div class="tw-flex tw-justify-center">
          <OnboardingCertifiedSeal
            v-if="[
              'information',
              'idphoto',
              'personalphoto',
            ].includes(step)"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// components
import VerificationInfo from '@/components/onboarding/verification/VerificationInfo';
import PatientInformation from '@/components/onboarding/verification/PatientInformation';
import IDPhoto from '@/components/onboarding/verification/IDPhoto';
import PersonalPhoto from '@/components/onboarding/verification/PersonalPhoto';
import OnboardingHeader from '@/components/partials/OnboardingHeader';
import Complete from '@/components/onboarding/checkout/Complete';
import OnboardingProgress from '@/components/partials/OnboardingProgress';
import OnboardingCertifiedSeal from '@/components/onboarding/OnboardingCertifiedSeal';

// mixins
import { onboardingCtaFooter } from '@/mixins/onboardingCtaFooter';

export default {
  components: {
    VerificationInfo,
    PatientInformation,
    IDPhoto,
    PersonalPhoto,
    OnboardingHeader,
    Complete,
    OnboardingProgress,
    OnboardingCertifiedSeal
  },
  mixins: [onboardingCtaFooter],
  beforeRouteLeave(to, from, next) {
    // Check if there's a modal open
    if (this.$store.state.global.isModalOpen) {
      next(false);
      return;
    }

    if (
      (this.step == 'information' || to.path == '/checkout') &&
      this.onboardingflow != 'flowB'
    ) {
      next();
    } else if (
      this.onboardingflow == 'flowB' &&
      !this.exiting &&
      this.step == 'information'
    ) {
      next(false);
    } else if (this.exiting) {
      next();
    } else {
      this.prev();
      next(false);
    }
  },
  props: ['title'],
  data() {
    return {
      alert: {
        show: false,
        message: '',
      },
      step: '',
      analyticsId: null,
      state: '',
      userData: {},
      exiting: false,
      onboardingflow: 'flowA',

      // for new progress UI update on the header
      progress: [
        {
          title: 'Account Creation',
          value: 100,
          max: 100,
        },
        {
          title: 'Intake',
          value: 5,
          max: 5,
        },
        {
          title: 'Onboarding Complete',
          value: 2,
          max: 5,
        },
      ],
      mobileProgressDisplay: 70,
    };
  },
  computed: {
    hideBackButton() {
      if (
        (this.step !== 'information' || this.step !== 'complete') &&
        this.onboardingflow != 'flowB'
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
    step: {
      immediate: true,
      handler (step) {
        switch (step) {
          case 'information':
            this.mobileProgressDisplay =
            this.onboardingflow === 'flowA' ? 70 : 85;
            this.onboardingflow === 'flowA'
              ? this.$set(this.progress[2], 'value', 2)
              : this.$set(this.progress[2], 'value', 3);
            break;
          case 'idphoto':
            this.mobileProgressDisplay =
            this.onboardingflow === 'flowA' ? 75 : 90;
            this.onboardingflow === 'flowA'
              ? this.$set(this.progress[2], 'value', 2.4)
              : this.$set(this.progress[2], 'value', 3.5);
            break;
          case 'personalphoto':
            this.mobileProgressDisplay =
            this.onboardingflow === 'flowA' ? 80 : 95;
            this.onboardingflow === 'flowA'
              ? this.$set(this.progress[2], 'value', 2.8)
              : this.$set(this.progress[2], 'value', 4);
            break;
          case 'complete':
            this.mobileProgressDisplay = 100;
            this.$set(this.progress[2], 'value', 5);
            break;
        }
      }
    },
  },
  async mounted() {
    await this.loadOnboarding();
    this.loadAnalytics();
  },
  methods: {
    updateUserInfo(userinfo) {
      this.userData.firstname = userinfo.firstname;
      this.userData.lastname = userinfo.lastname;
      this.userData.phoneno = userinfo.phoneno.replace('+1', '');
    },
    showAlert(message) {
      this.alert = {
        show: true,
        type: 'warning',
        message: message,
      };
    },
    hideAlert() {
      this.alert.show = false;
    },
    setStep(step) {
      this.alert.show = false;
      this.step = step;
    },
    prev() {
      switch (this.step) {
        case 'information':
          if (this.onboardingflow == 'flowB') {
            break;
          } else {
            this.$router.push('/treatment-options').catch(() => {});
            break;
          }
        case 'idphoto':
          this.step = 'information';
          break;
        case 'personalphoto':
          this.step = 'idphoto';
          break;
      }
    },
    async loadOnboarding() {
      try {
        const data = await this.$store.dispatch('onboarding/getPatientOnboarding');
        this.userData = data;
        this.analyticsId = data.usercustomid ? data.usercustomid : data.id;
        this.onboardingflow = data.onboardingflow;
        this.state = data.state;
        if (data.onboardingstep == 5.2) {
          this.step = 'information';
        } else if (data.onboardingstep >= 5.3) {
          this.step = 'personalphoto';
        } else if (data.onboardingstep < 5) {
          window.location.href = '/';
        } else {
          this.step = 'information';
        }
      } catch (err) {
        this.exiting = true;
        const id = this.userid ? this.userid : 'Could not get customer ID';
        const email = this.userData.email
          ? this.userData.email
          : 'Could not get patient email';
        await this.$store.dispatch('sendErrorLog', {
          step: 'Identity Verification - Loading',
          cus_id: id,
          email: email,
          error_log: err,
        });
        this.$router.push('/login');
      }
    },
    loadAnalytics() {
      try {
        this.$_analytics_page();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
