import { AnalyticsService } from '@/services/analytics.service';

import {
  browserName,
  browserVersion,
  osVersion,
  osName
} from 'mobile-device-detect';

import { getDevice, getHardwareModel } from '@/utils/device';

export default {
  namespaced: true,
  state: {
    isAnchoredCtaTracked: false,
    isAccountCreationHsaFsaTracked: false,
    isCheckoutHsaFsaTracked: false,
  },
  mutations: {
    SET_STATE (state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async trackSegmentCRO (ctx, data) {
      await AnalyticsService.trackSegmentCRO(data);
    },

    async trackClientServerSegment ({ state, commit }, { title, event, trackState, clientOnly }) {
      if (state[trackState]) {
        return
      }

      if (!clientOnly) {
        AnalyticsService.trackSegmentCRO({
          event
        });
      }

      const [
        deviceDetailsForContext,
        deviceDetails,
        everflowDetails,
        voluumDetails,
      ] = await Promise.all([
        this.dispatch('getDeviceDetailsForContext'),
        this.dispatch('getDeviceDetails'),
        this.dispatch('getEverflowDetails'),
        this.dispatch('getVoluumDetails'),
      ]);

      window.analytics.track(
        title,
        {
          Name: event,
          device: getDevice(),
          hardwareModel: getHardwareModel(),
          os: osName,
          os_version: osVersion,
          browserVersion: browserVersion,
          browser: browserName,
          ...deviceDetailsForContext,
          ...voluumDetails,
        },
        {
          referrer: everflowDetails,
          context: deviceDetails,
        }
      );

      commit('SET_STATE', { [trackState]: true });
    }
  },
};