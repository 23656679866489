<template>
  <div class="personalPhoto">
    <h2 class="question-title with-subtext">
      {{ isAndroid11Webview ? "Send us" : "Upload" }} a photo of your face
    </h2>
    <p class="sub-text">
      Your medical provider is legally required to verify your identity by
      comparing your photo{{
        isAndroid11Webview
          ? "."
          : " with the ID you uploaded on the previous step."
      }}
    </p>
    <div v-if="!isMobileOrTablet">
      <div class="photo-holder fs-exclude">
        <div
          v-if="
            (picture || imagePreview) && uploadFileType !== 'application/pdf'
          "
          class="btn-crop"
          @click="openCropModal"
        >
          <b-icon icon="crop" />
        </div>
        <div
          class="photo"
          :style="{
            backgroundImage:
              isPhotoTaken || showPhoto || photoUrl
                ? ''
                : 'url(' + require('@/assets/images/photo.jpg') + ')',
          }"
        >
          <img
            v-if="isPhotoTaken"
            class="fs-exclude"
            :src="picture"
            alt=""
          >
          <img
            v-else-if="showPhoto"
            class="fs-exclude"
            :src="imagePreview"
          >
          <img
            v-else-if="photoUrl"
            class="fs-exclude"
            :src="photoUrl"
          >
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-2">
        <div class="text-center">
          <PersonalPhotoTroubleUpload @skip="next({ isSkipped: true })" />
        </div>

        <div class="tw-flex tw-justify-center">
          <OnboardingTLSSecurity />
        </div>
      </div>

      <OnboardingCTAContainer>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
          <div class="tw-flex tw-items-center tw-gap-2">
            <b-button
              pill
              block
              variant="info"
              class="photo-btn tw-m-0 slight-rounded"
              :disabled="saving || isLoadingImage"
            >
              <input
                id="photo"
                ref="photo"
                type="file"
                :accept="ACCEPTED_FILE_MIME_TYPES"
                class="hide-file-input"
                @change="photoUpload()"
              >
              <label
                for="photo"
                class="after:tw-m-0 tw-m-0"
              >
                {{
                  isLoadingImage
                    ? "Loading..."
                    : !isPhotoTaken && !showPhoto && !photoUrl
                      ? "Select Photo"
                      : "REUPLOAD"
                }}
              </label>
            </b-button>

            <b-button
              variant="outline-info"
              :disabled="saving"
              pill
              block
              class="tw-m-0 slight-rounded"
              @click="showCamera"
            >
              {{ isPhotoTaken ? "Retake" : "TAKE PHOTO" }}
            </b-button>
          </div>

          <div class="tw-flex">
            <b-button
              v-if="isPhotoTaken || showPhoto || photoUrl"
              class="mt-2 slight-rounded"
              :disabled="saving"
              variant="info"
              block
              pill
              @click="next"
            >
              {{ saving ? "Saving..." : "Confirm" }}
            </b-button>
          </div>
        </div>
      </OnboardingCTAContainer>
    </div>
    <div v-else>
      <MobileUpload
        class="fs-exclude"
        :is-android11-webview.sync="isAndroid11Webview"
        :existing-photo="photoUrl"
        background-type="personal"
        @submitAnswer="setMobilePhoto"
        @showAlert="showAlert"
        @hideAlert="hideAlert"
        @skip="next({ isSkipped: true })"
        @next="next"
      />
    </div>

    <modal
      id="takePhoto"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <video
        ref="camera"
        width="100%"
        height="325"
        autoplay
        style="background-color: #000"
      ></video>
      <canvas
        ref="canvas"
        class="photo-taken"
        :width="325"
        :height="250"
      ></canvas>

      <b-row class="mt-3">
        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="info"
            class="slight-rounded"
            @click="takePhoto"
          >
            Capture
          </b-button>
          <div class="mb-3 d-md-none"></div>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="outline-info"
            class="slight-rounded"
            @click="hideCameraModal"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </modal>

    <b-modal
      id="crop-personal-photo"
      size="md"
      class="fs-exclude"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <vue-croppie
        ref="croppieRefPersonalPhoto"
        :enable-orientation="false"
        :enable-resize="false"
        :boundary="{ width: 300, height: 300 }"
        :viewport="{ width: 300, height: 300 }"
      />
 
      <b-row class="mt-3">
        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="info"
            class="slight-rounded"
            @click="cropImage"
          >
            Crop
          </b-button>
          <div class="mb-3 d-md-none"></div>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="outline-info"
            class="slight-rounded"
            @click="closeCropModal"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="trouble"
      v-model="showSkipModal"
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo dark"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <p
          class="sub-text"
          style="color: inherit"
        >
          You can skip uploading your photo now but you will need to upload it
          later to verify your identity.
        </p>

        <b-row class="mb-3">
          <b-col
            cols="12"
            lg="12"
          >
            <b-button
              class="slight-rounded"
              variant="info"
              pill
              style="min-width: 100px; width: 100%"
              @click="closeModal"
            >
              Upload Now
            </b-button>
            <div class="separator"></div>
          </b-col>
          <b-col
            cols="12"
            lg="12"
          >
            <b-button
              class="modal-cancel-btn slight-rounded"
              variant="outline-info"
              pill
              style="min-width: 100px; width: 100%"
              @click="next({ isSkipped: true })"
            >
              Skip Uploading
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
// services
import { isMobile } from 'mobile-device-detect';
import { OnboardingService } from '@/services/onboarding.service';

// mixins
import mixins from '@/components/form/mixins';
import analyticsMixin from '@/mixins/analytics';

// components
import MobileUpload from '@/components/partials/MobileUpload';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';
import OnboardingTLSSecurity from '@/components/onboarding/OnboardingTLSSecurity'
import PersonalPhotoTroubleUpload from '@/components/onboarding/verification/PersonalPhotoTroubleUpload'

// assets
import pdfIcon from '@/assets/images/onboarding/pdf-icon.png';

// constants
import { ONBOARDING_STEP } from '@/constants/localStorage';
import { ACCEPTED_FILE_MIME_TYPES } from '@/constants/files';

// utils
import {
  finalizeFileForUpload,
  fileToDataUrl,
  isFileTypePdf,
} from '@/utils/file';
import { getRef } from '@corefront/utils/getRef';

export default {
  components: {
    MobileUpload,
    OnboardingCTAContainer,
    OnboardingTLSSecurity,
    PersonalPhotoTroubleUpload
  },
  mixins: [mixins, analyticsMixin],
  props: {
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    analyticsId: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ACCEPTED_FILE_MIME_TYPES,
      userid: null,
      currentonboardingstep: null,
      isPhotoTaken: false,
      showPhoto: false,
      photoUrl: '',
      saving: false,
      imagePreview: '',
      isLoadingImage: false,
      picture: '',
      orientation: 0,

      email: '',
      firstname: '',
      lastname: '',
      phoneno: '',

      photo: null,
      photoFileType: '',
      photoUploadStatus: '',
      showSkipModal: false,

      isAndroid11Webview: false,
    };
  },
  computed: {
    isMobileOrTablet() {
      // mobile-device-detect API; isMobile = mobile or tablet
      return isMobile;
    },
    uploadFileType() {
      return this.photo?.type || '';
    },
  },
  watch: {
    saving: {
      handler(val) {
        this.$store.commit('onboarding/SET_IS_IMAGE_UPLOAD_PROCESSING', val);
      },
      immediate: true,
    },
  },
  async mounted() {
    localStorage.removeItem(ONBOARDING_STEP);

    const photo = await getRef.call(this, 'photo')
    photo.value = null

    window.scrollTo(0, 0);
    await this.loadStepData();

    await this.$_analytics_track(
      'Onboarding Screen Viewed',
      {
        customerId: this.analyticsId,
        firstname: this.firstname,
        lastname: this.lastname,
        screen: '4.3-A',
        onboardingflow: this.onboardingflow,
      },
    );
  },
  methods: {
    openCropModal() {
      this.$bvModal.show('crop-personal-photo');
      this.$nextTick(() => {
        this.$refs.croppieRefPersonalPhoto.bind({
          url: this.picture || this.imagePreview,
        });
      });
    },
    closeCropModal() {
      this.$bvModal.hide('crop-personal-photo');
    },
    cropImage() {
      const options = {
        format: 'jpeg',
      };
      this.$refs.croppieRefPersonalPhoto.result(options, output => {
        this.imagePreview = output;
        this.picture = output;
        this.photo = this.dataURLtoFile(output, 'image');
        this.closeCropModal();
      });
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'trouble');
    },
    showAlert(msg) {
      this.$emit('showAlert', msg);
    },
    hideAlert() {
      this.$emit('hideAlert');
    },
    setMobilePhoto(photo) {
      this.picture = photo;
      this.imagePreview = photo;
      this.isPhotoTaken = true;
      this.next({ isSkipped: false });
    },
    async fbqTrack(data) {
      // FB Pixel Tracking
      try {
        fbq('init', '348219289912097', {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: this.userid,
          extern_id: this.userid,
        });

        const pageViewBody = { userid: this.userid };
        OnboardingService.onboardingEvent('PageView', pageViewBody);
        fbq(
          'trackCustom',
          'PhotoUploaded',
          {
            external_id: this.userid,
            extern_id: this.userid,
            state: this.state,
            ...(await this.$store.dispatch('getFBQParams')),
          },
          {
            eventID: data.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async next({ isSkipped = false }) {
      try {
        this.saving = true;

        if (this.imagePreview === '' && this.photoUrl !== '') {
          // Segment
          window.analytics.identify(this.analyticsId, {
            photouploaded: this.photoUploadStatus === 'UPLOADED',
          });
          // Multiflow redirection
          if (this.onboardingflow == 'flowB') {
            this.$emit('setStep', 'complete');
            return;
          } else {
            this.$emit('setOnboardingStep', 5.4);
            this.$router.push('/checkout');
            return;
          }
        }
        const mform = new FormData();
        let imageToUse;

        if (isSkipped) {
          imageToUse = 'SKIPPED';
        } else {
          if (this.isPhotoTaken === true && this.picture) {
            imageToUse = this.picture;
          } else if (this.isPhotoTaken === false && this.imagePreview) {
            if (this.uploadFileType === 'application/pdf') {
              imageToUse = this.picture;
            } else {
              imageToUse = this.imagePreview;
            }
          }

          if (imageToUse) {
            if (!(imageToUse instanceof File)) {
              imageToUse = this.dataURLtoFile(imageToUse, 'image');
            }
          }
        }

        mform.append('photo', imageToUse);
        if (this.orientation > 0)
          mform.append('rotationindex', this.orientation);
        let res;
        try {
          res = await OnboardingService.updatePatientOnboarding(5.3, mform, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } catch (err) {
          const id = this.userid ? this.userid : 'Could not get customer ID';
          const email = this.email
            ? this.email
            : 'Could not get customer email';
          this.$store.dispatch('sendErrorLog', {
            step: 'Identity Verification - Personal Photo Upload',
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.$emit('showAlert', err);
          this.saving = false;
          return;
        }

        if (isSkipped) {
          this.step = 5.3;
          this.saving = false;

          // Segment
          window.analytics.identify(this.analyticsId, {
            photouploaded: false,
          });

          if (this.photoUploadStatus !== 'SKIPPED') {
            // ID Upload skipped analytics
            await this.$_analytics_track(
              'Personal Photo Upload Skipped',
              {
                customerId: this.analyticsId,
                onboardingflow: this.onboardingflow,
                state: this.state,
                email: this.email,
                messageID: res.data.eventid,
                phoneno: this.phoneno,
                firstname: this.firstname,
                lastname: this.lastname,
              },
            );
          }
        } else {
          // Segment
          window.analytics.identify(this.analyticsId, {
            photouploaded: true,
          });

          if (this.photoUploadStatus !== 'UPLOADED') {
            // SEGMENT
            await this.$_analytics_track(
              'Personal Photo Uploaded',
              {
                customerId: this.analyticsId,
                onboardingflow: this.onboardingflow,
                state: this.state,
                email: this.email,
                messageID: res.data.eventid,
                phoneno: this.phoneno,
                firstname: this.firstname,
                lastname: this.lastname,
              },
            );
          }
        }

        if (res.data.eventid) {
          await this.fbqTrack(res.data);
        } else if (
          res.data.eventid &&
          process.env.VUE_APP_SERVER != 'production'
        ) {
          // alert('FB TRACKING PhotoUploaded: ' + res.data.eventid);
          console.log('FB TRACKING PhotoUploaded');
          console.log('FB TRACKING PhotoUploaded: ' + res.data.eventid);
        }

        if (res && res.data) {
          this.saving = false;
          // Multiflow redirection
          if (this.onboardingflow == 'flowB') {
            this.$emit('setStep', 'complete');
          } else {
            this.$emit('setOnboardingStep', 6);
            this.$router.push('/checkout');
          }
        }
      } catch (err) {
        if (err.status == 401) {
          this.$router.push('/login');
        } else {
          const id = this.userid ? this.userid : 'Could not get customer ID';
          const email = this.email
            ? this.email
            : 'Could not get customer email';
          this.$store.dispatch('sendErrorLog', {
            step: 'Identity Verification - Personal Photo',
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.$emit('showAlert', err);
        }
      } finally {
        this.saving = false;
      }
    },
    // camera functions
    showCamera() {
      this.$root.$emit('bv::show::modal', 'takePhoto');
      this.createCameraElement();
    },
    createCameraElement() {
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: 'user',
        },
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          if (error.name == 'NotAllowedError') {
            this.hideCameraModal();
            this.$emit(
              'showAlert',
              'Camera is not detected. Please turn on your camera and refresh your browser.'
            );
            alert(
              'Camera is not detected. Please turn on your camera and refresh your browser.'
            );
          }
        });
    },
    stopCameraStream() {
      if (this.$refs.camera?.srcObject) {
        const tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach(track => {
          track.stop();
        });
      }
    },
    hideCameraModal() {
      this.stopCameraStream();
      this.$root.$emit('bv::hide::modal', 'takePhoto');
    },
    takePhoto() {
      try {
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 325, 250);
        this.picture = canvas.toDataURL('image/png');
      } catch (err) {
        const id = this.userid ? this.userid : 'Could not get customer ID';
        const email = this.email ? this.email : 'Could not get customer email';
        this.$store.dispatch('sendErrorLog', {
          step: 'Identity Verification - Take Personal Photo',
          cus_id: id,
          email: email,
          error_log: err,
        });
        this.$emit('showAlert', err.message);
      }
      this.$nextTick(() => {
        this.$root.$emit('bv::hide::modal', 'takePhoto');
        this.stopCameraStream();
        this.isPhotoTaken = true;
      });
    },
    // select photo
    async photoUpload() {
      try {
        this.isLoadingImage = true;

        this.hideAlert();

        const fileToUpload = this.$refs.photo.files[0];

        if (!fileToUpload) {
          return;
        }

        const { error, success } = await finalizeFileForUpload(fileToUpload);

        if (error) {
          this.showAlert(error);
          return;
        }

        this.photo = success;

        const isPdf = isFileTypePdf(this.photo);
        const dataUrl = await fileToDataUrl(this.photo);

        this.isPhotoTaken = false;
        this.showPhoto = true;
        this.picture = dataUrl;
        this.imagePreview = isPdf ? pdfIcon : dataUrl;
      } catch (err) {
        const id = this.userid || 'Could not get customer ID';
        const email = this.email || 'Could not get customer email';

        this.$store.dispatch('sendErrorLog', {
          step: 'Identity Verification - Personal Photo Upload',
          cus_id: id,
          email: email,
          error_log: err,
        });

        this.showAlert(err);
      } finally {
        this.isLoadingImage = false;
      }
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    },
    async loadStepData() {
      this.$root.$on('bv::modal::hide', () => {
        this.stopCameraStream(); // trigger stopCameraStream to stop camera on running in background
      });

      const data = await this.$store.dispatch('onboarding/getPatientOnboarding');
      this.userid = data.id;

      this.email = data.email;
      this.firstname = data.firstname;
      this.lastname = data.lastname;
      this.phoneno = data.phoneno;

      this.currentonboardingstep = data.onboardingstep;
      this.photoUploadStatus = data.photoupload;
      if (data.photoURL) {
        this.photoFileType = data.photoFileType;
        if (this.photoFileType === 'application/pdf') {
          this.photoUrl = pdfIcon;
        } else {
          this.photoUrl = data.photoURL;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  margin-bottom: 10px;
}
.modal-cancel-btn.btn-outline-info {
  border-color: #cdfcb1;
  background-color: transparent;
  color: #cdfcb1;

  &:active {
    background-color: transparent;
    border-color: #cdfcb1;
    box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
  }
}

.photo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  position: relative;

  .btn-crop {
    padding: 0.75rem;
    background-color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #a783ff;
  }

  @include media-breakpoint-up(xxl) {
    height: 250px * $scale-font;
    margin-bottom: 20px * $scale-font;
  }

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-up(xxl) {
      width: 250px * $scale-font;
      height: 250px * $scale-font;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.photo-taken {
  position: absolute;
  left: -9999px;
}
</style>
