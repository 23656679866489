<template>
  <b-container
    v-show="showContent"
    fluid
  >
    <OnboardingProgress
      :hide="hideBackButton"
      :progress="progress"
      @back="prev"
    />
    <b-row
      class="onboarding-wrapper"
      :style="{
        marginBottom: $_onboardingCtaFooter_marginBottom
      }"
    >
      <OnboardingHeader
        :hide="hideBackButton"
        step="1"
        :section="step == 'email'"
        :title="title"
        @back="prev"
      />
      <b-col
        cols="12"
        lg="5"
        xl="4"
        xxl="4"
        class="mt-6 pt-3 onboarding-content"
      >
        <DiscountBanner
          v-if="!$_fsaHsaPromo_isActiveFsaHsaPromo"
          :show="coupon.show"
          :couponid="coupon.couponid"
        />

        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{ alert.message }}
          <span v-if="alert.showEmail">
            <br>
            <router-link
              to="/login"
              class="text-primary"
            ><u>Click here to sign in.</u></router-link>
          </span>
        </b-alert>
        <b-row class="d-lg-none">
          <b-col>
            <h3 class="bold d-letter-spacing onboarding-title">
              Account Creation
            </h3>
          </b-col>
          <b-col>
            <h3
              class="
                bold
                d-letter-spacing
                onboarding-title
                text-muted text-right
              "
            >
              Intake
            </h3>
          </b-col>
        </b-row>
        <b-progress
          :value="progress[0].value"
          :max="progress[0].max"
          class="step-progress d-lg-none mb-5"
          variant="purple"
          height="5px"
        />
        <!-- Set event handlers as necessary -->

        <State
          v-if="step == 'state'"
          :pre-answer="eligibilityParams.state"
          :onboardingflow="onboardingflow"
          @setParams="setParams"
          @setStateName="setStateName"
          @setStep="setStep"
          @showLoader="showLoader"
          @showAlert="showAlert"
        />

        <Email
          v-if="step == 'email'"
          :onboardingflow="onboardingflow"
          :eligibility-params="eligibilityParams"
          @setStep="setStep"
          @showAlert="showAlert"
          @showEmailAlert="showEmailAlert"
          @setId="setId"
          @setEmail="setEmail"
        />

        <Waitlist
          v-if="step == 'waitlist'"
          :state="eligibilityParams.state"
          :onboardingflow="onboardingflow"
          @setStep="setStep"
          @showAlert="showAlert"
          @exit="exit"
        />
        <Nothing
          v-if="step == 'thankyou'"
          :body="thankyouBody"
          show-fsa-badge
          @submitAnswer="exit"
        />
        <Birthday
          v-if="step == 'birthday'"
          :pre-answer="eligibilityParams.birthday"
          :onboardingflow="onboardingflow"
          :externalid="userId"
          @setParams="setParams"
          @setStep="setStep"
          @showLoader="showLoader"
          @showAlert="showAlert"
          @exit="exit"
        />

        <Nothing
          v-if="step == 'over60'"
          :body="over60Body"
          show-fsa-badge
          @submitAnswer="exit"
        />

        <Sex
          v-if="step == 'sex' && !registered"
          :onboardingflow="onboardingflow"
          :pre-answer="eligibilityParams.gender"
          :age="age"
          @setParams="setParams"
          @setStep="setStep"
          @showLoader="showLoader"
          @checkToken="checkAuthToken"
          @showAlert="showAlert"
        />

        <Sex
          v-if="step == 'sex' && registered"
          :pre-answer="eligibilityParams.gender"
          :onboardingflow="onboardingflow"
          :age="age"
          :eligibility-params="eligibilityParams"
          @setParams="setParams"
          @finish="finish"
          @setStep="setStep"
          @showLoader="showLoader"
          @checkToken="checkAuthToken"
          @showAlert="showAlert"
        />

        <Surgery
          v-if="step == 'surgery'"
          @setParams="setParams"
          @setStep="setStep"
          @showLoader="showLoader"
          @checkToken="checkAuthToken"
          @showAlert="showAlert"
        />

        <Nothing
          v-if="step == 'under35'"
          :body="under35Body"
          show-fsa-badge
          @submitAnswer="exit"
        />

        <Nothing
          v-if="step == 'male'"
          :body="maleBody"
          show-fsa-badge
          @submitAnswer="exit"
        />

        <Registration
          v-if="step == 'registration'"
          :id="userId"
          ref="Registration"
          :registration-params="registrationParams"
          :eligibility-params="eligibilityParams"
          :state-name="stateName"
          :email="email"
          @showAlert="showAlert"
          @finish="finish"
          @update-registration="updateRegistration"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
        xl="4"
        xxl="4"
      >
        <b-row
          align-v="stretch"
          style="height: 100%"
        >
          <b-col
            align-self="start"
            cols="12"
          >
            <div class="d-none d-lg-block mt-6"></div>
            <div class="d-block d-lg-none mt-4"></div>
            <div class="tw-flex tw-justify-center">
              <OnboardingCertifiedSeal v-if="step == 'registration'" />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        v-show="step == 'email'"
        cols="12"
      />
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment';

// services
import { AuthService } from '@/services/auth.service';
import { OnboardingService } from '@/services/onboarding.service';
import { UsersService } from '@/services/users.service';

// constants
import constants from '@/constants';

// components
import State from '@/components/onboarding/eligibility/State';
import Waitlist from '@/components/onboarding/eligibility/Waitlist';
import Email from '@/components/onboarding/eligibility/Email';
import Nothing from '@/components/onboarding/questions/Nothing';
import Birthday from '@/components/onboarding/eligibility/Birthday';
import Sex from '@/components/onboarding/eligibility/Sex';
import Surgery from '@/components/onboarding/eligibility/Surgery';
import Registration from '@/components/onboarding/eligibility/Registration';
import OnboardingHeader from '@/components/partials/OnboardingHeader';
import OnboardingProgress from '@/components/partials/OnboardingProgress';
import DiscountBanner from '@/components/partials/DiscountBanner.vue';
import OnboardingCertifiedSeal from '@/components/onboarding/OnboardingCertifiedSeal';

// Mixins
import analyticsMixin from '@/mixins/analytics';
import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';
import { onboardingCtaFooter } from '@/mixins/onboardingCtaFooter';

// utils
import $cookies from '@/utils/cookies';

const tyParams = {
  id: 'thankyou',
  title:
    'Thank you for visiting Winona. We will reach out to you as soon as we are in your state.',
};

const under35Params = {
  id: 'under35',
  title:
    'Women who are younger than 35 years old and have their ovaries do not qualify for the treatment.',
  description:
    'We invite you to check out some of our non-prescription products.',
};

const maleParams = {
  id: 'male',
  title:
    'We can only provide Bioidentical Treatments to individuals whose gender assignment at birth was female.',
  description:
    'We invite you to check out some of our non-prescription products.',
};

const over60Params = {
  id: 'over60',
  title:
    'Thank you for your interest in Winona. Unfortunately, women of age 60 and up are not eligible for treatment on this platform.',
  description:
    'We recommend you to consult with your doctor for treatment opportunities. In the meantime, we invite you to check out some of our non-prescription products.',
};

export default {
  components: {
    State,
    Email,
    Waitlist,
    Nothing,
    Birthday,
    Sex,
    Surgery,
    Registration,
    OnboardingHeader,
    OnboardingProgress,
    DiscountBanner,
    OnboardingCertifiedSeal
  },
  mixins: [analyticsMixin, fsaHsaPromoMixin, onboardingCtaFooter],
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.global.isModalOpen) {
      next(false);
      return;
    }
    next();
  },
  props: {
    title: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      alert: {
        show: false,
        message: '',
        showEmail: false,
        email: '',
      },
      step: 'state',
      thankyouBody: tyParams,
      maleBody: maleParams,
      under35Body: under35Params,
      over60Body: over60Params,
      eligibilityParams: {
        state: '',
        birthday: '',
        ovariansurgery: null,
        gender: null,
      },
      coupon: {
        show: false,
        id: '',
      },
      stateName: '',
      showContent: true,
      age: null,
      onboardingstep: null,
      userId: null,
      email: '',
      registered: false,
      referralToken: '',
      registrationParams: {
        email: '',
        firstname: '',
        lastname: '',
        phoneno: '',
      },

      // for new progress UI update on the header
      progress: [
        {
          title: 'Account Creation',
          value: 0,
          max: 5,
        },
        {
          title: 'Intake',
          value: 0,
          max: 5,
        },
        {
          title: 'Onboarding Complete',
          value: 0,
        },
      ],
      manaJs: '',
    };
  },
  computed: {
    onboardingflow() {
      const flow = $cookies.get('onboardingflow') || $cookies.get('flow');
      if (flow && (flow == 'flowB' || flow == 'b')) {
        return 'flowB';
      } else {
        return 'flowA';
      }
    },
    hideBackButton() {
      if (this.step != 'state' && this.step != 'birthday') {
        return false;
      }
      return true;
    },
  },
  watch: {
    step: {
      async handler () {
        if (this.step == 'registration') {
          this.$_analytics_track(
            'Onboarding Screen Viewed',
            {
              screen: '1.4-A',
              onboardingflow: this.onboardingflow,
            }
          );
        }

        if (this.step == 'male') {
          this.$_analytics_track('Onboarding Reject', {
            externalid: this.userId,
            question: 'What was the sex assigned to you at birth?',
            answer: 'Male',
            onboardingflow: this.onboardingflow,
          });
        }

        switch (this.step) {
          case 'state':
            this.$set(this.progress[0], 'value', 1);
            break;
          case 'email':
            this.$set(this.progress[0], 'value', 2);
            break;
          case 'birthday':
            this.$set(this.progress[0], 'value', 3);
            break;
          case 'sex':
            this.$set(this.progress[0], 'value', 4);
            break;
          case 'registration':
            this.$set(this.progress[0], 'value', 5);
            break;
        }
      },
      immediate: true
    },
    eligibilityParams: {
      handler() {
        const newDate = new Date(this.eligibilityParams.birthday);
        const ageDifMs = Date.now() - newDate.getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        this.age = Math.abs(ageDate.getUTCFullYear() - 1970);
      },
      deep: true,
    },
  },
  beforeDestroy() {
    const manaJs = document.getElementById('manaJs');
    document.head.removeChild(manaJs);
  },
  async mounted() {
    this.mana();
    this.loadOnboarding();
    this.addTrustpilot();

    // Referral code
    if ($cookies.get('referralToken') && !$cookies.get('promocodeExistingID')) {
      try {
        if (
          $cookies.get('referralToken') &&
          ($cookies.get('promocodeExistingID') || $cookies.get('promocode'))
        ) {
          $cookies.remove('promocode');
          $cookies.remove('promocodeExistingID');
        }
        const checkToken = await UsersService.checkReferralToken({
          referralcode: $cookies.get('referralToken'),
        });
        if (checkToken.message == 'success') {
          this.referralToken = $cookies.get('referralToken');
          this.showAlert(
            'Referral code detected! Finish your registration to receive $75 off your first treatment.',
            'success'
          );
        } else {
          this.showAlert(checkToken.message);
        }
      } catch (err) {
        if (err.message == 'Not a valid referral');
        this.showAlert('The referral code you provided is not valid.');
      }
    }

    if (this.$_fsaHsaPromo_isActiveFsaHsaPromo) {
      // * FSA HSA sessionPromo
      this.coupon.show = false;
      this.coupon.couponid = '';
      $cookies.remove('promocode')
    }
    else if (
      ($cookies.get('promocode') || this.$route.query.promocode) &&
      !$cookies.get('promocodeExistingID')
    ) {
      // Promo Code
      try {
        const promocode =
          $cookies.get('promocode') || this.$route.query.promocode;

        this.coupon.couponid = promocode;
        if (promocode != 'BFBLOSSOM40' && promocode != 'BFBLOSSOM40FIXED') {
          this.coupon.show = true;
        }
      } catch (err) {
        this.showAlert(err.message);
      }
    }

    // Flow B
    if (this.$route.query.flow) {
      const flow =
        this.$route.query.flow == 'b' || this.$route.query.flow == 'flowB'
          ? 'flowB'
          : '';
      $cookies.set('onboardingflow', flow);
    }

    // SEGMENT
    this.$_analytics_page();

    this.$_analytics_track(
      'Onboarding Started',
      {
        onboardingflow: this.onboardingflow,
      }
    );
    //INTEGROMAT
    const onboardingStartedBody = {
      referralcode: $cookies.get('referralToken')
        ? $cookies.get('referralToken')
        : null,
    };
    OnboardingService.onboardingEvent(
      'ONBOARDING_STARTED',
      onboardingStartedBody
    );

    // WIN-15171
    // Save timestamp as cookie
    const now = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    $cookies.set('onboardingStartedTimestamp', now);
  },
  methods: {
    setId(id) {
      this.userId = id;
    },
    setEmail(email) {
      this.email = email;
    },
    setStateName(name) {
      this.stateName = name;
    },
    async checkAuthToken() {
      const auth = await AuthService.getBearer();
      if (auth) {
        const data = await this.$store.dispatch('onboarding/getPatientOnboarding');
        if (data.onboardingstep >= 1.5) {
          this.finish();
        }
      }
    },
    showLoader(type) {
      this.showContent = false;
      this.$emit('showLoader', type);
      window.setTimeout(() => {
        this.showContent = true;
      }, 1500);
    },
    showAlert(message, type = null) {
      this.alert.show = false;
      if (!type) type = 'warning';
      this.alert = {
        show: true,
        type: type,
        message: message,
        showEmail: false,
        email: '',
      };
      window.scrollTo(0, 0);
    },
    showEmailAlert(message, email) {
      this.alert.show = false;
      this.alert = {
        show: true,
        type: 'warning',
        message: message,
        showEmail: true,
        email: email,
      };
      window.scrollTo(0, 0);
    },
    prev() {
      this.alert.show = false;
      switch (this.step) {
        case 'waitlist':
          this.step = 'state';
          break;
        case 'thankyou':
          this.step = 'waitlist';
          break;
        case 'email':
          this.step = 'state';
          break;
        case 'sex':
          this.step = 'birthday';
          break;
        case 'over60':
          this.step = 'birthday';
          break;
        case 'male':
          this.step = 'sex';
          break;
        case 'surgery':
          this.step = 'sex';
          break;
        case 'under35':
          this.step = 'surgery';
          break;
        case 'registration':
          if (this.age < 35 && this.sex == 'Female') {
            this.step == 'surgery';
          } else {
            this.step = 'sex';
          }
          break;
      }
    },
    setStep(step) {
      this.alert.show = false;
      if (this.coupon.show) {
        this.coupon.show = false;
      }
      this.step = step;
    },
    updateRegistration(key, value) {
      this.$set(this.registrationParams, key, value);
    },
    setParams(key, value) {
      this.$set(this.eligibilityParams, key, value);
    },
    exit() {
      console.log('offboarding', constants.OFFBOARDING_URL)
      this.$emit('setExiting', true);
      window.location.href = `${constants.OFFBOARDING_URL}`;
    },
    addTrustpilot() {
      const pilot = document.createElement('script');
      pilot.type = 'text/javascript';
      pilot.src =
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      pilot.async = 'true';
      document.head.appendChild(pilot);

      pilot.onload = function () {
        const trustbox = document.getElementById('trustbox');
        window.Trustpilot.loadFromElement(trustbox);
      };
    },
    mana() {
      const manajs = document.createElement('script');
      manajs.type = 'text/javascript';
      manajs.src = 'https://jsx.s3.us-west-2.amazonaws.com/mana.js';
      manajs.async = 'true';
      manajs.setAttribute('data-tag', 'onboarding');
      manajs.setAttribute('id', 'manaJs');
      // store it to data
      this.manaJs = document.head.appendChild(manajs);
    },
    async loadOnboarding() {
      try {
        if (window.localStorage.getItem('accessToken')) {
          const data = await this.$store.dispatch('onboarding/getPatientOnboarding');
          this.onboardingstep = data.onboardingstep;
          if (this.onboardingstep == 1) {
            this.step = 'birthday';
            this.setParams('state', data.state);
            this.stateName = data.statename;
            this.email = data.email;
          }
          this.userId = data.id;
          if (data.fromsite || data.email) {
            this.registered = true;
          }
        } else {
          throw 'Not logged in';
        }
      } catch (err) {
        return;
      }
    },
    async finish(eligibilitydata) {
      this.step = 'registration';
      // Wait for registration to finish loading
      await this.$nextTick();

      this.$refs.Registration.buttonDisabled = true;
      // Attach quiz results if there are any
      const healthmarker = $cookies.get('winona-quiz-healthmarker');
      const feellikeage = $cookies.get('winona-quiz-feellikeage');
      if (healthmarker) {
        this.setParams('healthmarker', healthmarker);
      }
      if (feellikeage) {
        this.setParams('feellikeage', feellikeage);
      }

      if (!this.eligibilityParams.ovariansurgery) {
        this.eligibilityParams.ovariansurgery = false;
      }

      try {
        const { data } = await OnboardingService.updatePatientOnboarding(
          1,
          eligibilitydata
        );

        if (data.eventid) {
          this.$_analytics_track(
            'Visit Started',
            {
              customerId: data.userid,
              messageID: data.eventid,
              firstname: eligibilitydata.firstname,
              lastname: eligibilitydata.lastname,
              state: this.eligibilityParams.state,
              email: this.email,
              phoneno: this.registrationParams.phoneno,
            }
          );

          await this.fbqTrack(data);
        } else if (data.eventid && process.env.VUE_APP_SERVER != 'production') {
          console.log({ ...(await this.$store.dispatch('getFBQParams')) });
          console.log('FB TRACKING VisitStarted');
          console.log('FB TRACKING VisitStarted: ' + data.eventid);
        }

        if (data.nonrxmedicationonly) {
          window.location.href = process.env.VUE_APP_WEBSITE_URL;
        } else {
          //this.$router.push('/health-questions')
          this.$emit('setExiting', true);
          this.$router.push('/health-questions');
        }
      } catch (err) {
        this.$refs.Registration.buttonDisabled = false;
        this.showAlert(err);
        //this.$refs.Registration.buttonDisabled = false;
      }
    },
    async fbqTrack(auth) {
      // FB Pixel Tracking
      try {
        fbq('init', '348219289912097', {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: auth.userid,
          extern_id: auth.userid,
        });
        const pageViewBody = { userid: auth.userid };
        OnboardingService.onboardingEvent('PageView', pageViewBody);
        fbq(
          'trackCustom',
          'VisitStarted',
          {
            external_id: auth.userid,
            extern_id: auth.userid,
            state: this.eligibilityParams.state,
            ...(await this.$store.dispatch('getFBQParams')),
          },
          {
            eventID: auth.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
/* /deep/.vs__dropdown-toggle:not(.custom-select) {
  padding-right: 0.75em;
} */

::v-deep .custom-input-style .vs__dropdown-toggle {
  margin-top: 10px;
  /* padding-left: 0.75em; */
  border: 0;
  background-color: rgba(242, 242, 242, 0.5);
}
</style>

<style lang="scss" scoped>
.custom-control-label {
  line-height: 1.1;
}

.certified-logo {
  @include media-breakpoint-up(xxl) {
    margin-top: 130px;
  }
}
</style>
