<template>
  <a
    href="javascript:void(0)"
    class="text-default text-underline d-inline-block"
    @click="showSkipModal = true"
  >
    Having Trouble Uploading Photo?

    <b-modal
      id="trouble"
      v-model="showSkipModal"
      hide-header
      hide-footer
      centered
      content-class="dark-bg"
      no-close-on-backdrop
      no-close-on-esc
      no-stacking
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo dark"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <p
          class="sub-text"
          style="color: inherit"
        >
          You can skip uploading your photo now but you will need to upload it
          later to verify your identity.
        </p>

        <div class="tw-flex tw-flex-col tw-gap-2">
          <b-button
            variant="info"
            pill
            style="min-width: 100px; width: 100%"
            class="slight-rounded"
            @click="closeModal"
          >
            Upload Now
          </b-button>

          <b-button
            class="modal-cancel-btn slight-rounded"
            variant="outline-info"
            pill
            style="min-width: 100px; width: 100%"
            @click="skip"
          >
            Skip Uploading
          </b-button>
        </div>
      </div>
    </b-modal>
  </a>
</template>

<script>
/**
import PersonalPhotoTroubleUpload from '@/components/onboarding/verification/PersonalPhotoTroubleUpload'
 */
export default {
  data() {
    return {
      showSkipModal: false
    }
  },

  methods: {
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'trouble');
    },
    skip () {
      this.showSkipModal = false
      this.$emit('skip')
    }
  },
}
</script>

<style lang="scss" scoped>

</style>