<template>
  <b-modal
    v-model="showDialog"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    no-stacking
    class="p-4"
  >
    <b-row
      class="mb-4"
      align-h="between"
    >
      <b-col class="text-right">
        <a
          href="javascript:;"
          @click="showDialog = false"
        >
          <b-icon
            icon="x-circle-fill"
            variant="primary"
            font-scale="1"
          />
        </a>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="3">
        <h4 class="heading">
          Download Order Receipt
        </h4>
      </b-col>
      <b-col>
        <p v-if="rowData">
          for {{ rowData.firstname }} {{ rowData.lastname }}
        </p>
        <b-form-select
          v-model="selectedYear"
          :options="yearOptions"
          class="mb-3"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="9"
        offset="3"
      >
        <b-button
          variant="primary"
          block
          pill
          @click="download(rowData, selectedYear)"
        >
          Download Order Receipt
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col
        cols="9"
        offset="3"
      >
        <b-button
          variant="outline-primary"
          block
          pill
          @click="showDialog = false"
        >
          Cancel
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { PatientService } from '@/services/patient.service';

import { downloadUrl } from '@/utils/downloadUrl';

export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {
          firstname: '',
          lastname: '',
        };
      },
    },
  },
  data() {
    return {
      showDialog: false,
      selectedYear: new Date().getFullYear(),
    };
  },
  computed: {
    yearOptions() {
      const currentYear = new Date().getFullYear();
      return this.range(2021, currentYear);
    },
  },
  methods: {
    range(start, end) {
      if (start === end) return [start];
      return [start, ...this.range(start + 1, end)];
    },
    async download(row, selectedYear) {
      try {
        const res = await PatientService.getFSAReceipt(row.id, selectedYear);
        const name = '';
        if (row.firstname) {
          name.concat(row.firstname);
        }
        if (row.firstname && row.lastname) {
          name.concat(' ');
        }
        if (row.lastname) {
          name.concat(row.lastname);
        }
        console.log(res);
        if (res.data) this.getFile(res.data, name);
      } catch (err) {
        // console.log(err.status);
        if (err.status == 404) {
          this.$emit(
            'show-toast',
            'There are no order receipts available for this year.'
          );
        } else {
          this.$emit('show-toast', err.message);
        }
      }
    },
    getFile(url, name) {
      const currentDate = new Date();
      const title = name
        ? `FSA - ${currentDate}`
        : `FSA - ${currentDate}${name}`;

      downloadUrl(url, title)
    },
  },
};
</script>

<style></style>
