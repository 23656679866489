<template>
  <div class="text-answer">
    <h4
      :class="[
        body.description ? 'mb-3' : '',
        heading ? 'question-title h2' : 'label-text',
      ]"
    >
      {{ body.question }}
    </h4>
    <p
      v-if="body.description"
      class="sub-text"
    >
      {{ body.description }}
    </p>
    <p
      v-if="body.header"
      class="sub-text"
    >
      {{ body.header }}
    </p>
    <b-form-input
      id="textarea"
      v-model="answer"
      :style="{ height: textHeight }"
      :placeholder="body.placeholder"
      :class="{ 'onboarding-textarea': isAshComputed, 'fs-mask': isSensitive }"
      rows="4"
      max-rows="6"
    />

    <OnboardingCTAContainer>
      <b-button
        variant="info"
        pill
        block
        :disabled="disabled"
        class="slight-rounded"
        @click="checkAnswer"
      >
        Continue
      </b-button>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';

export default {
  components: {
    OnboardingCTAContainer,
  },
  props: {
    body: {
      type: Object,
      default: () => {},
    },
    isAsh: {
      default: 'true',
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: Boolean,
      default: false,
    },
    textHeight: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      answer: '',
    };
  },
  computed: {
    isAshComputed() {
      return this.isAsh == 'false' ? false : true;
    },
    isSensitive() {
      return this.body.hasOwnProperty('sensitive') && this.body.sensitive;
    },
  },
  mounted() {
    if (this.body.hasOwnProperty('answer')) {
      this.answer = this.body.answer;
    }
  },
  methods: {
    checkAnswer() {
      if (
        this.answer.replace(/ /g, '') == '' &&
        (!this.body.hasOwnProperty('allowempty') ||
          this.body.allowempty == false)
      ) {
        this.$emit('alert', 'The text field cannot be empty.');
      } else {
        this.$emit('submitAnswer', this.body.id, this.answer, this.body);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-weight: normal !important;
}
</style>
