<template>
  <div class="tw-flex">
    <div class="tw-flex tw-my-7 tw-mx-7 md:tw-my-8 md:tw-mx-9 lg:tw-justify-between lg:tw-gap-8 lg:tw-w-[74rem] lg:tw-mx-auto lg:tw-mt-20">
      <section
        id="hero-image"
        class="tw-hidden lg:tw-block"
      >
        <b-img
          fluid
          :src="require('@/assets/images/referrals/refer-a-friend-hero.png')"
          alt="winona-hero-image"
          class="tw-w-[612px] tw-h-[598px] tw-bg-cover tw-bg-center tw-object-cover"
        />
      </section>
      <section
        id="refer-a-friend"
        class="lg:tw-mr-8"
      >
        <h3 class="heading tw-text-[2.5rem] tw-leading-[4rem] md:tw-text-6xl tw-flex-none tw-text-nowrap lg:tw-mt-1 tw-text-secondary-dark-gray">
          Refer a Friend
        </h3>
        <div>
          <div>
            <b-alert
              variant="warning"
              :show="alert.show"
              dismissible
            >
              {{
                alert.message
              }}
            </b-alert>
          </div>
        </div>
        <section
          id="referral-credits"
          class="tw-flex tw-justify-between tw-bg-off-white tw-px-5 tw-py-3 tw-rounded-xl tw-items-center md:tw-max-w-[29.5rem] md:tw-px-6 md:tw-py-6"
        >
          <div>
            <span class="tw-block tw-uppercase tw-max-w-[16ch] tw-text-deep-teal tw-font-atten-bold tw-tracking-widest tw-leading-[16px]">Your referral credits:</span>
            <div class="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-medium-gray tw-mt-1">
              <span class="block ">What is this</span>
              <span class="block tw-mt-[0.063rem]">
                <CoreTooltip
                  text="These credits are available to redeem on your next order."
                  placement="bottom"
                >
                  <IconQuestionMark />
                </CoreTooltip>  
              </span>
            </div>
          </div>
          <span class="heading tw-text-3xl tw-text-deep-teal">
            ${{ currentUser.referral_credit_balance || 0 }}
          </span>
        </section>
        <h2 class="tw-uppercase tw-text-lg tw-text-deep-teal tw-tracking-[0.25rem] tw-mt-4 tw-mb-0 md:tw-text-xl md:tw-mt-6 md:tw-mb-2 md:tw-text-nowrap">
          Your friends are our friends.
        </h2>
        <section class="md:tw-max-w-[29.5rem]">
          <p class="tw-text-secondary-dark-gray tw-leading-[1.75rem] tw-mb-0 tw-text-lg">
            Love your treatment? Spread the word! Share Winona with a friend and give them $75 off their first treatment plan. As a thank you, you'll receive a $100 credit for your first referral and $75 for each additional one.
          </p>
        </section>
        <div>
          <b-form-input
            v-model="email"
            placeholder="Enter your friend's email"
            class="tw-pl-0 tw-mt-1 md:tw-mt-4 md:tw-max-w-[29.5rem]"
          />
          <section
            id="cta-buttons"
            class="tw-flex-col tw-w-full tw-mt-6 !md:tw-flex md:tw-items-center md:tw-max-w-[29.5rem]"
          >
            <b-button
              variant="info"
              pill
              size="lg"
              class="tw-w-full tw-px-10 md:tw-w-fit"
              :disabled="isSharingEmail"
              @click="sendEmail"
            >
              <span v-if="isSharingEmail">
                <b-spinner
                  small
                  type="border"
                  label="Sharing..."
                />
                Sharing...
              </span>
              <span
                v-else
                class="tw-text-sm"
              >Share now</span>
            </b-button>
            <CopyToClipboard
              :text="referralLink"
              @copy="showCopyText"
            >
              <b-button
                variant="outline-info"
                class="tw-w-full tw-px-8 tw-mt-4 md:tw-w-fit md:tw-mt-0 md:tw-ml-3"
                pill
                size="lg"
                :disabled="!referralLink"
              >
                <span class="tw-text-sm">Copy my link</span>
              </b-button>
            </CopyToClipboard>
          </section>
          <div
            class="tw-text-2xl tw-flex tw-flex-none tw-items-center tw-mt-6 tw-pb-2 md:tw-pb-0 tw-gap-2 md:tw-max-w-[29.5rem]"
            :class="[
              alertType === 'success'? 'tw-text-lavender-purple': 'tw-text-red-500',
              alertText.includes('Link copied')? 'lg:tw-items-start': ''
            ]"
          >
            <span class="tw-w-fit">{{ alertText }}</span>
            <span
              v-if="alertType === 'success'"
              :class="alertText.includes('Link copied')? 'tw-block lg:tw-mt-1': ''"
            ><IconCheck /></span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { UsersService } from '@/services/users.service';
import { ReferralService } from '@/services/referral.service';
import LocalStorageService from '@/localStorage';

import IconQuestionMark from '@/components/icon/referrals/IconQuestionMark.vue';
import IconCheck from '@/components/icon/referrals/IconCheck.vue';
import CoreTooltip from '@corefront/components-v2/Core/CoreTooltip.vue';

import CopyToClipboard from 'vue-copy-to-clipboard'
import analyticsMixin from '@/mixins/analytics';

// libs
import { mapState } from 'vuex';

const localStorageService = LocalStorageService.getService();

export default {
  components: {
    IconQuestionMark,
    IconCheck,
    CoreTooltip,
    CopyToClipboard
  },
  mixins: [analyticsMixin],
  data() {
    return {
      referralLink: '',
      alertText: '',
      alertType: '',
      email: '',
      alert: {
        show: false,
        message: '',
      },
      isSharingEmail: false,
    };
  },
  computed: {
    ...mapState('user', ['currentUser']),
  },
  watch: {
    email(val) {
      if (val.length == 0) {
        this.alertText = '';
        this.alertType = '';
      }
    },
  },
  async mounted() {
    this.$_analytics_page();
    const { data } = await UsersService.getReferralToken();
    this.referralLink = `${process.env.VUE_APP_SITE_URL}/referral?refcode=${data.referralcode}`;
    if (this.$route.params.refcode) {
      if (this.$route.params.refcode == data.referralcode) {
        this.showAlert("You can't use your own referral link.");
      } else {
        this.showAlert(
          "You've already created an account, and can't use the link."
        );
      }
    }
  },
  methods: {
    showAlert(message) {
      this.alert.show = true;
      this.alert.message = message;
    },
    showCopyText() {
      this.alertType = 'success';
      this.alertText = 'Link copied successfully, please paste it and send it to your friend.';
    },
    testEmail(email) {
      const reg =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s`'|@"]+(\.[^<>()\[\]\\.,;:\s@`'"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return email == '' ? null : reg.test(email) ? true : false;
    },
    async sendEmail() {
      this.alertText = '';
      this.alertType = '';
      if (!this.email || !this.testEmail(this.email)) {
        this.alertText = 'This email seems to be wrong, please try again.';
        return;
      }
      let listOfEmails = localStorageService.getItem('referralsSent');
      // return;
      if (listOfEmails) {
        listOfEmails = listOfEmails.split(',');
        if (listOfEmails.includes(this.email)) {
          this.alertText = 'You have already shared with this friend before.';
          return;
        }
      }
      try {
        this.isSharingEmail = true;
        const { data } = await ReferralService.sendReferralEmail(
          this.referralLink,
          this.email
        );
        if (data) {
          this.alertType = 'success';
          this.alertText = 'Your referral link has been successfully sent.';
          if (!listOfEmails) {
            localStorageService.setItem('referralsSent', `${this.email},`);
          } else {
            listOfEmails.push(this.email);
            localStorageService.setItem('referralsSent', listOfEmails);
          }
        }
      } catch (err) {
        this.alertType = 'error';
        switch (err.message) {
          case 'User already exist':
          case 'User already started onboarding. Please copy the link and share to them instead':
          case 'User already exist as lead. Please copy link and share to them instead':
          case 'User already exist as lead':
            this.alertType = 'success';
            this.alertText = 'Your referral link has been successfully sent.';
            break;
          case 'You cannot refer yourself.':
            this.alertText = "You can't refer yourself!";
            break;
          default:
            this.alertText = err;
            break;
        }
      }
      finally {
        this.isSharingEmail = false;
      }
    },
  },
};
</script>

<style>
.referral-text {
  line-height: 1.5;
}
</style>
