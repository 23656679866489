<template>
  <div class="mx-3 pt-4">
    <OnboardingCertifiedSeal />
  </div>
</template>

<script>
import OnboardingCertifiedSeal from '@/components/onboarding/OnboardingCertifiedSeal.vue';

export default {
  components: {
    OnboardingCertifiedSeal
  },

  mounted () {
    this.$store.dispatch('cro/trackClientServerSegment', {
      title: 'Test Start',
      event: 'systemAnchoredCTA',
      trackState: 'isAnchoredCtaTracked',
      clientOnly: true
    })
  },
};
</script>

<style lang="scss" scoped>
</style>
