<template>
  <div
    id="onboarding"
    class="tw-flex tw-flex-col tw-justify-between"
  >
    <b-container
      fluid
      class="pl-4 pr-4"
    >
      <transition name="fade">
        <IntermediaryLoader
          v-if="intermediaryLoader && loaderType == 2"
          loader-type="2"
        />
        <IntermediaryLoader
          v-if="intermediaryLoader && loaderType == 3"
          loader-type="3"
        />
        <IntermediaryLoader
          v-if="intermediaryLoader && loaderType == 1"
          loader-type="1"
          :quote="intermediaryLoaderText"
          :image="require('@/assets/images/onboarding/loader.png')"
        />
        <IntermediaryLoader
          v-if="intermediaryLoader && loaderType == 4"
          loader-type="1"
          quote="With Winona, feeling better is a few steps away."
          :image="require('@/assets/images/onboarding/loader1.png')"
        />
        <IntermediaryLoader
          v-if="intermediaryLoader && loaderType == 5 && messageType == 'HIPAA'"
          loader-type="5"
          dark-bg
          @click="hideLoader"
        >
          <template #text>
            <p>
              Welcome to Winona! We are excited to get to help you on your
              menopause journey.
            </p>
            <p>
              Please start your brief online visit to confirm your interests,
              concerns, and goals. With this information, our medical team can
              send your personalized treatments directly from our Winona pharmacy
              to your home.
            </p>
            <p class="loader-highlight">
              All of your personal information is properly stored and protected by
              our HIPAA compliant medical information storage system.
            </p>
          </template>
        </IntermediaryLoader>
        <IntermediaryLoader
          v-if="
            intermediaryLoader && loaderType == 5 && messageType == 'doctorvisit'
          "
          loader-type="5"
          dark-bg
          @click="hideLoader"
        >
          <template #text>
            <p class="loader-highlight">
              Your Winona doctor visit is free and includes unlimited follow-ups.
              Assuming there are not any questions or concerns from you or the
              doctor, and the doctor feels the treatments selected are safe, the
              prescriptions will be filled. You will have the opportunity to
              message your doctor directly from the patient portal after
              onboarding is completed.
            </p>
            <p>
              Your card will not be charged at this time. However, by adding a
              payment method, you authorize Winona to charge your card upon a
              physician's prescription of treatment. If you have any questions,
              please message your doctor in the Patient Portal or reach out to the
              Patient Care Team.
            </p>
          </template>
        </IntermediaryLoader>
        <IntermediaryLoader
          v-if="intermediaryLoader && loaderType == 6"
          loader-type="6"
          neutral-bg
        />
        <IntermediaryLoader
          v-if="
            intermediaryLoader &&
              loaderType == 5 &&
              messageType == 'doctormatching'
          "
          message-type="doctormatching"
          loader-type="5"
          side-text="DOCTOR CONSULTATION"
          :userid="userid"
          light-bg
          @click="hideLoader"
        >
          <template #text>
            <!-- <b-img fluid-grow class="pr-3" :src="require('@/assets/images/doctor_chat.png')" /> -->
            <b-row
              align-v="center"
              class="mt-5"
            >
              <b-col cols="auto">
                <!-- <b-img src="@/assets/images/onboarding/doctor.png" rounded="circle" alt="Circle image"></b-img> -->
                <b-avatar
                  :src="require('@/assets/images/onboarding/doctor.png')"
                  size="7rem"
                />
              </b-col>
              <b-col>
                <h2 class="mb-2 loader-text">
                  Dr. Michael Green, OB/GYN
                </h2>
                <p
                  class="mb-0 loader-sub-text"
                  style="color: #333333"
                >
                  CA License #: G80363
                </p>
              </b-col>
            </b-row>
            <p class="mt-5 text-default">
              <span
                class="med-font d-block mb-3"
              >You've been matched with Dr. Michael Green, a board certified,
                licensed, OB/GYN in California with over 28 years of experience in
                women's healthcare.</span>
            </p>
            <p class="text-default">
              He will help you review your treatment options and choose your
              prescription if suitable — he'll be available for as many follow-ups
              as you need.
            </p>
          </template>
        </IntermediaryLoader>

        <div>
          <FsaHsaStoreBanner />
          <b-navbar
            v-if="!intermediaryLoader"
            toggleable="sm"
            fixed="top"
            :class="`tw-flex tw-shadow-none tw-z-[600] ${$_fsaHsaPromo_isActiveFsaHsaPromo? 'tw-mt-[60px] md:tw-mt-[86px]': ''}`"
          >
            <b-navbar-brand
              to="/website"
              class="c-logo relative mb-2"
            >
              <img
                src="@/assets/images/logo.svg"
                alt=""
              >
            </b-navbar-brand>
            <b-button
              v-if="$route.path != '/eligibility'"
              pill
              variant="light"
              class="logout ml-auto mr-3 pa-2 tw-flex"
              @click="showLogoutModal = true"
            >
              <img
                src="@/assets/images/logout.svg"
                class="mr-2"
              >Logout
            </b-button>
          </b-navbar>
        </div>
      </transition>

      <vue-page-transition
        id="onboarding-router-view"
        :class="`${$_fsaHsaPromo_isActiveFsaHsaPromo && 'tw-mt-20'}`"
      >
        <router-view
          :title="title"
          @showLoader="showLoader"
          @hideLoader="hideLoader"
          @setOnboardingStep="setOnboardingStep"
          @showExitModal="showRedirectModal = true"
          @setExiting="setExiting"
        />
      </vue-page-transition>

      <!-- Redirect Modal -->
      <b-modal
        v-model="showRedirectModal"
        size="md"
        hide-header
        hide-footer
        centered
        content-class="dark-bg"
        no-close-on-backdrop
        no-close-on-esc
        no-stacking
      >
        <b-row class="mb-6">
          <b-col cols="12">
            <div class="icon-logo dark"></div>
          </b-col>
        </b-row>
        <div class="modal-card">
          <h2
            class="mb-3 heading color-inherit"
            style="font-size: 26px; line-height: 32px"
          >
            Are you sure you want to quit the onboarding?
          </h2>
          <b-button
            variant="outline-info"
            pill
            block
            @click="window.location.href = process.env.VUE_APP_SITE_URL"
          >
            Yes, I'm Sure
          </b-button>
          <b-button
            variant="info"
            pill
            block
            @click="showRedirectModal = false"
          >
            Cancel
          </b-button>
        </div>
      </b-modal>

      <!-- Logout Modal -->
      <b-modal
        v-model="showLogoutModal"
        size="md"
        hide-header
        hide-footer
        centered
        content-class="dark-bg"
        no-close-on-backgdrop
        no-close-on-esc
        no-stacking
      >
        <b-row class="mb-6">
          <b-col cols="12">
            <div class="icon-logo dark"></div>
          </b-col>
        </b-row>
        <div class="modal-card">
          <h2
            class="mb-3 heading color-inherit"
            style="font-size: 26px; line-height: 32px"
          >
            Are you sure you want to log out?
          </h2>

          <b-button
            variant="outline-info"
            class="slight-rounded"
            pill
            block
            @click="logout"
          >
            Yes, I'm Sure
          </b-button>
          <b-button
            variant="info"
            class="slight-rounded"
            pill
            block
            @click="showLogoutModal = false"
          >
            Cancel
          </b-button>
        </div>
      </b-modal>
    </b-container>

    <portal-target
      name="onboarding-cta-footer"
    />
  </div>
</template>

<script>
// services
import { OnboardingService } from '@/services/onboarding.service';
import { AuthService } from '@/services/auth.service';

// mixins
import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';
import { onboardingCtaFooter } from '@/mixins/onboardingCtaFooter';

// components
import IntermediaryLoader from '../components/onboarding/loaders/IntermediaryLoader.vue';
import FsaHsaStoreBanner from '@/components/partials/FsaHsaStoreBanner.vue';

export default {
  components: {
    IntermediaryLoader,
    FsaHsaStoreBanner
  },
  mixins: [fsaHsaPromoMixin, onboardingCtaFooter],
  beforeRouteUpdate(to, from, next) {
    if (from.matched[0].name == 'onboarding' && to.name == 'onboarding') {
      next(false);
    } else {
      next();
    }
  },
  data() {
    return {
      userid: '',
      intermediaryLoader: false,
      loaderType: 1,
      intermediaryLoaderText:
        'As we age, our hormones decrease, and we have loads of symptoms related to those changes. Winona can help relieve those symptoms.',
      messageType: 'HIPAA',
      showRedirectModal: false,
      showLogoutModal: false,
      exiting: false,
    };
  },
  computed: {
    title: function () {
      return this.$route.meta.title;
    },
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventExit);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventExit);
  },
  async mounted () {
    this.$_onboardingCtaFooter_init()
    await this.$store.dispatch('setUTMS', this.$route.query);
    if (this.$route.query.flow && this.$route.query.flow == 'b') {
      $cookies.set('onboardingflow', 'flowB');
    }

    await this.fbqTrack();
  },
  methods: {
    async fbqTrack() {
      try {
        // FB Pixel Tracking
        fbq('init', '348219289912097');
        // fbq("track", "PageView");
        const pageViewBody = { userid: this.userid };
        OnboardingService.onboardingEvent('PageView', pageViewBody);
        fbq('trackCustom', 'OnboardingStarted', {
          ...(await this.$store.dispatch('getFBQParams')),
        });
      } catch (err) {
        console.log(err);
      }
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.documentElement;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    showLoader(loaderType, messageType = null) {
      if (loaderType) {
        this.loaderType = loaderType;
        if (messageType) {
          this.messageType = messageType;
          this.intermediaryLoader = true;
          this.toggleBodyClass('addClass', 'stopScroll');
        } else {
          this.intermediaryLoader = true;
          window.setTimeout(() => {
            this.intermediaryLoader = false;
            this.toggleBodyClass('removeClass', 'stopScroll');
          }, 5000);
        }
      }
    },
    hideLoader() {
      this.intermediaryLoader = false;
      this.toggleBodyClass('removeClass', 'stopScroll');
    },
    async logout() {
      this.$store.dispatch('socket/closeSocketConnection');
      this.$_fsaHsaPromo_resetBanner();
      await AuthService.logout();
    },
    // * Step override for prev() calls
    setOnboardingStep(step) {
      this.onboardingstep = step;
    },
    setExiting(val) {
      this.exiting = val;
    },
    preventExit(event) {
      if (this.exiting || this.$route.path != '/eligibility') return;
      event.preventDefault();
      event.returnValue = '';
    },
  },
};
</script>

<style scoped>
::v-deep .btn {
  min-width: 200px;
}

/* $primary */
::v-deep .heading {
  color: #1a6a72;
}
</style>

<style lang="scss" scoped>
.modal-card {
  .btn.btn-outline-info {
    border-color: #cdfcb1;
    background-color: transparent;
    color: #cdfcb1;

    &:active {
      background-color: transparent;
      border-color: #cdfcb1;
      box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
    }

    &:focus {
      box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
    }
  }
}

.getting-started {
  background-color: $body-bg;
}

.navbar {
  height: 80px;
  border: 0;
  background-color: $quaternary;

  @include media-breakpoint-down(md) {
    height: auto;
  }
  @include media-breakpoint-down(lg) {
    padding: 10px;
  }

  @include media-breakpoint-up(xxl) {
    height: 80px * $scale-font;
  }
}

.c-logo {
  @include media-breakpoint-down(md) {
    margin: 0;
    padding-left: 1rem;
  }
}

.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.logout {
  @include media-breakpoint-up(lg) {
    top: 0;
    padding: 7px 10px;
    font-size: 0.8rem;
    min-width: 40px !important;
    right: 2.5rem;
    img {
      height: 19px;
    }
  }

  @include media-breakpoint-up(xxl) {
    top: 0;
    margin-top: 0 !important;
    margin-right: 2rem !important;
    padding: 7px * $scale-font 10px * $scale-font;
    font-size: 0.8rem * $scale-font;
    right: 2.5rem * $scale-font;

    img {
      height: 19px * $scale-font;
    }
  }
  position: relative;
  min-width: 40px !important;
  background-color: #fbfff9;
  letter-spacing: 1px;
  text-transform: capitalize;
  box-shadow: 0px 4px 6px #d0e7c2;
  border-radius: 15px;
  font-weight: normal;
  top: -4px;
}

.loader-text {
  font-size: 1.2rem;

  @include media-breakpoint-up(xxl) {
    font-size: 1.2rem * $scale-font;
  }
}

.loader-sub-text {
  font-size: 1rem !important;

  @include media-breakpoint-up(xxl) {
    font-size: 1rem * $scale-font !important;
  }
}

</style>
