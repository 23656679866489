<template>
  <div
    class="round-badge tw-shadow-md tw-border-[#CDFCB1] tw-border-[5px] text-white d-flex flex-column justify-content-center align-items-center align-items-center"
    :class="[
      variant === 'purple' ? 'round-badge__purple align-self-end' : '',
    ]"
  >
    <span>Was $150, now</span>
    <span class="tw-text-6xl">
      {{ upsellPrice }}
    </span>
    <div
      class="text-center tw-mx-auto tw-font-hatton tw-mt-1 tw-text-sm"
    >
      Black Friday Sale
    </div>
  </div>
</template>

<script>
export default {
  name: 'BFCMBadge',
  props: {
    upsellPrice: {
      type: String,
      default: '$99',
    },
    variant: {
      type: String,
      default: 'green',
    },
  }
};
</script>

<style lang="scss" scoped>
.round-badge {
  @include media-breakpoint-up(lg) {
    height: 150px;
    width: 150px;
  }
  @include media-breakpoint-down(lg) {
    height: 75px;
    width: 75px;
    min-width: 75px;
  }

  background-color: $primary;
  display: block;
  position: relative;
  border-radius: 50%;
  text-align: center;

  &__purple {
    background-color: #2b2660;
  }

  -webkit-transform: rotate(6deg);
  -moz-transform: rotate(6deg);
  -o-transform: rotate(6deg);
  -ms-transform: rotate(6deg);
  transform: rotate(6deg);

  .badge-strike {
    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
      height: 20px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 0.75rem;
      height: 10px;
    }
  }

  .badge-price {
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
      height: 50px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 1.25rem;
      height: 27px;
    }
  }

  .badge-subtitle {
    @include media-breakpoint-up(lg) {
      font-size: 0.75rem;
      width: 70px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 0.5rem;
      width: 50px;
    }
  }
}
</style>