/**
import { onboardingCtaFooter } from '@/mixins/onboardingCtaFooter';
*/

// libs
import Vue from 'vue'

// utils
import { observeResize } from '@corefront/utils/observeResize'
import { prefixMethods } from '@corefront/utils/prefixMethods'
import { querySelectorAsync } from '@corefront/utils/querySelectorAsync'
import { prefixObservable } from '@corefront/utils/prefixObservable'

const PREFIX = '$_onboardingCtaFooter_'

const data = Vue.observable({
  mutationObserver: null,
  ctaFooterHeight: 0,
  fsaHsaBannerHeight: 0,
  ctaHeight: 0,
  onboardingCtaFooterObserver: null,
  promoBannerObserver: null,
})

async function observeCtaFooter () {
  if (data.onboardingCtaFooterObserver) {
    data.onboardingCtaFooterObserver()
  }

  data.ctaFooterHeight = 0
  data.onboardingCtaFooterObserver = await observeResize(['#onboarding-cta-footer'], els => {
    const [ctaFooter] = els
    data.ctaFooterHeight = ctaFooter.contentRect.height
  })
}

async function observePromoBanner () {
  if (data.promoBannerObserver) {
    data.promoBannerObserver()
  }

  data.fsaHsaBannerHeight = 0
  data.promoBannerObserver = await observeResize(['#fsa-has-store-banner'], els => {
    const [promoBanner] = els
    data.fsaHsaBannerHeight = promoBanner.contentRect.height
  })
}

export const onboardingCtaFooter = {
  computed: {
    ...prefixObservable(data, PREFIX),
    [`${PREFIX}marginBottom`]() {
      return `${data.ctaFooterHeight + data.fsaHsaBannerHeight}px`
    },
  },
  methods: prefixMethods({
    async init () {
      if (data.mutationObserver) {
        data.mutationObserver.disconnect();
      }

      const config = {
        childList: true,
        subtree: true
      };

      const callback = () => {
        console.log('callback')
        observeCtaFooter()
        observePromoBanner()
      };

      data.mutationObserver = new MutationObserver(callback);

      const onboardingRouterView = await querySelectorAsync('body')
      data.mutationObserver.observe(onboardingRouterView, config);
    }
  }, PREFIX),
}