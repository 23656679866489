<template>
  <div ref="chatbox">
    <div
      id="chat-headline"
      class="chat-headlines mx-auto"
    >
      <div style="max-width: 1150px">
        <div
          v-if="assignedAdmin"
          class="mb-0 d-flex"
        >
          <div class="text-right pr-3 d-flex align-items-center">
            <b-avatar style="background: #ece8f5; color: black">
              {{ assignedAdmin.charAt(0) }}
            </b-avatar>
          </div>
          <div>
            <b>{{ assignedAdmin }}</b>
            <br>
            <span class="text-muted">Winona Patient Services</span>
          </div>
        </div>
        <div
          v-else
          class="mb-0 d-flex align-items-center"
        >
          <div class="text-right pr-3 d-flex align-items-center">
            <b-avatar style="background: #ece8f5; color: black">
              W
            </b-avatar>
          </div>
          <div>
            <span class="text-muted">Winona Patient Services</span>
          </div>
        </div>
      </div>
    </div>
    <ul class="chat-holder list-unstyled mb-0">
      <li :class="['chat-box-container', imgUpload ? 'uploading-img' : '']">
        <div
          id="chat-box"
          :style="{
            height: threadHeight + 'px',
            overflow: 'auto',
          }"
          :class="['chat-box', mobileClass]"
          @scroll.passive="messageScroll"
        >
          <div class="chat-holder">
            <BaseTypingIndicator
              v-if="isRecipientTyping"
              class="mt-4"
            />

            <template>
              <div
                v-for="(messages, sentAt, index) in groupMessage"
                :key="sentAt"
                class="chat"
              >
                <div class="text-center">
                  {{ formattedTimestamp(sentAt) }}
                </div>

                <div
                  v-for="(chat, i) in messages"
                  :key="i"
                >
                  <div
                    v-if="chat.data.message || chat.data.attachments.length || chat.data?.articles"
                    :class="[
                      chat.data.sender.id == chat.data.patient.id
                        ? 'patient'
                        : 'doctor',
                      'chat-bubble',
                    ]"
                  >
                    <div
                      v-if="chat.data.message && chat.data.sender.id == chat.data.patient.id"
                      class="patient msg"
                    >
                      <p
                        class="mb-0 d-letter-spacing"
                      >
                        {{ chat.data.message }}
                      </p>
                    </div>

                    <div
                      v-if="chat.data.message && chat.data.sender.id != chat.data.patient.id || (chat.data.articles && chat.data.articles.length > 1)"
                      class="doctor msg"
                    >
                      <p
                        v-html-safe="chat.data.message"
                        :class="chat.data.articles ? 'mb-2' : ''"
                      >
                      </p>
                      <div
                        v-if="chat.data.articles"
                      >
                        <div
                          v-for="article in chat.data.articles"
                          :key="`${chat.data.id}-${article.id}`"
                          class="chat-article tw-border tw-border-solid tw-max-w-full tw-px-4 tw-py-3 tw-rounded-md mt-2"
                        >
                          <a
                            :href="article.url"
                            target="_blank"
                          >
                            <b>
                              {{ article.title }}
                            </b>
                            <div
                              v-html-remove="article.body.replaceAll('\r\n', ' ').replaceAll('\n', ' ').replaceAll('\r', ' ')"
                              class="tw-line-clamp-2"
                            ></div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <!-- Separate design for solo articles -->
                    <div
                      v-if="chat.data.articles && chat.data.articles.length === 1 && chat.data.message.length === 0"
                    >
                      <div
                        class="chat-article tw-border tw-border-solid tw-rounded-md tw-px-4 tw-py-3 tw-mt-3 tw-w-[60%]"
                      >
                        <a
                          :href="chat.data.articles[0].url"
                          target="_blank"
                        >
                          <b>
                            {{ chat.data.articles[0].title }}
                          </b>
                          <div
                            v-html-remove="chat.data.articles[0].body.replaceAll('\r\n', ' ').replaceAll('\n', ' ').replaceAll('\r', ' ')"
                            class="tw-line-clamp-2"
                          ></div>
                        </a>
                      </div>
                    </div>

                    <b-row
                      v-for="(group, groupIndex) in _chunk(
                        chat.data.attachments,
                        amountToGroupBy
                      )"
                      :key="groupIndex"
                      no-gutters
                      :class="[
                        chat.data.sender.id == chat.data.patient.id
                          ? 'patient'
                          : 'doctor',
                        'msg',
                        'p-0',
                      ]"
                      style="background-color: transparent"
                    >
                      <template v-for="(attach, i) in group">
                        <b-col
                          v-if="
                            attach.type.includes('image') &&
                              !['tiff', 'heic', 'svg', 'x-xbitmap'].some((type) =>
                                attach.type.includes(type)
                              )
                          "
                          :key="attach + i"
                        >
                          <b-img
                            thumbnail
                            fluid
                            :src="attach.url"
                          />
                        </b-col>
                        <template v-else>
                          <a
                            :key="attach + i"
                            :href="attach.url"
                            variant="link"
                            download
                            target="_blank"
                            class="p-2 d-block d-letter-spacing"
                          >
                            <b-icon
                              icon="arrow-down-circle-fill"
                              variant="primary"
                            />
                            {{ attach.filename }}
                          </a>
                        </template>
                      </template>
                    </b-row>
                  </div>
                  <div
                    :class="[
                      chat.data.sender.id != chat.data.patient.id
                        ? 'is-doctor'
                        : '',
                      'chat-img',
                    ]"
                  >
                    <div
                      v-if="chat.data.sender.id != chat.data.patient.id"
                      class="img-holder"
                    >
                      <img
                        v-if="chat.data.sender.avatarUrl"
                        :src="chat.data.sender.avatarUrl"
                        alt=""
                      >
                      <b-avatar
                        v-else
                        style="background: #ece8f5; color: black"
                      >
                        {{
                          chat.data.sender.firstName &&
                            chat.data.sender.firstName.charAt(0)
                        }}
                      </b-avatar>
                    </div>
                    <div class="doctor-info">
                      <h3
                        v-if="chat.data.sender.id != chat.data.patient.id"
                        class="mb-0 bold d-letter-spacing"
                        style="color: #333333"
                      >
                        {{ chat.data.sender.firstName }}
                        {{ chat.data.sender.lastName }}
                      </h3>
                      <small
                        class="text-muted d-letter-spacing"
                        style="font-size: 12px"
                      >{{
                        moment
                          .unix(chat.data.sentAt)
                          .format("MMM DD, YYYY hh:mm A")
                      }}</small>
                    </div>
                  </div>
                </div>
                <div
                  v-if="index === 0 && isUploading"
                  class="text-center"
                >
                  <b-spinner big />
                </div>
              </div>
            </template>
          </div>
        </div>

        <b-alert
          :show="alert.connection.show"
          variant="danger"
          class="text-center"
        >
          You are currently disconnected. Click
          <a
            href="javascript:;"
            class="text-light"
            @click="reconnectApollo"
          ><u><b>reconnect</b></u></a>
          to continue messaging.
        </b-alert>
      </li>
    </ul>
    <div
      v-if="conversation && conversation.isbanned && !isBanWithinOneMonth"
      id="message-input-area"
      class="message text-center"
    >
      <p class="mb-0">
        Your ability to message this doctor has been restricted due to a
        violation of our code of conduct.
      </p>
      <p class="mb-0">
        Please seek medical assistance from another healthcare provider.
      </p>
    </div>
    <div
      v-else
      id="message-input-area"
      ref="sendMessage"
      class="message"
    >
      <b-form @submit.prevent="sendMessage()">
        <b-container class="px-0">
          <div class="message-holder">
            <div class="textarea-box">
              <textarea
                ref="typeMessage"
                v-model="newMessage"
                class="chat-textbox"
                placeholder="Type a message"
                @blur="hideLabel()"
                @input="onType"
              ></textarea>
              <div>
                <a
                  href="#"
                  class="p-3 h-100"
                  @click="openUpload"
                >
                  <img
                    src="@/assets/images/paperclip_round.svg"
                    alt=""
                  >
                </a>
                <input
                  ref="fileInput"
                  type="file"
                  style="display: none"
                  multiple
                  @change="handleFilesUpload()"
                >
              </div>
            </div>
            <div class="button-holder d-flex align-items-center">
              <div class="d-none d-lg-flex">
                <b-button
                  id="send-button"
                  pill
                  block
                  type="submit"
                  variant="link"
                  :disabled="hasError || isSending || isMessageBodyEmpty"
                >
                  <b-spinner
                    v-if="isSending"
                    small
                  />
                  <img
                    v-else
                    src="@/assets/images/send.svg"
                    alt=""
                  >
                </b-button>
              </div>
              <b-tooltip
                v-if="!isMessageBodyEmpty && !isSending"
                target="send-button"
              >
                Send message
              </b-tooltip>
              <div class="d-lg-none d-flex">
                <b-button
                  id="send-button-1"
                  pill
                  block
                  type="submit"
                  variant="link"
                  :disabled="hasError || isSending || isMessageBodyEmpty"
                >
                  <b-spinner
                    v-if="isSending"
                    small
                  />
                  <img
                    v-else
                    src="@/assets/images/send.svg"
                    alt=""
                  >
                </b-button>
              </div>
              <b-tooltip
                v-if="!isMessageBodyEmpty && !isSending"
                target="send-button-1"
              >
                Send message
              </b-tooltip>
            </div>
          </div>
        </b-container>
      </b-form>
      <b-container
        v-if="imgUpload"
        ref="uploadImg"
        class="pre-upload"
      >
        <div
          v-for="(attachment, index) in attachments"
          :key="index"
          class="img-holder"
        >
          <b-icon
            icon="x-circle-fill"
            class="close-btn"
            @click="removeFile(index)"
          />
          <img
            v-if="viewImage(attachment)"
            :src="attachment.url"
            :alt="attachment.name"
          >
          <img
            v-else-if="!viewImage(attachment)"
            src="@/assets/images/pdf-logo.png"
            :alt="attachment.name"
          >
          <div v-else>
            <svg
              v-if="
                !attachment.type.includes('image') ||
                  attachment.type.includes('heic') ||
                  attachment.type.includes('tiff') ||
                  attachment.type.includes('x-xbitmap')
              "
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z"
              />
            </svg>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
// libs
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { mapGetters, mapState } from 'vuex';
import infiniteScroll from 'vue-infinite-scroll';
import gql from 'graphql-tag';
import _ from 'lodash';

// services
import { ChatService } from '@/services/chat.service.js';
import { AdminChatService } from '@/services/admin-chat.service.js';

// assets
import audio from '@/assets/notifications/pop.mp3';
const notificationsound = new Audio(audio);

// components
import BaseTypingIndicator from '@/components/base/BaseTypingIndicator.vue';

// constants
import { TYPING_INDICATOR } from '@/constants';
import { MAX_SIZE_MB, EXCLUDED_EXT } from '@/constants/files';

// utils
import {
  isFileSupported,
  isFileImage,
  transformFileForUpload,
} from '@/utils/file';
import { watchResizeObserver } from '@corefront/utils/watchResizeObserver';
import { getElementHeight } from '@corefront/utils/getElementHeight';

export default {
  components: {
    BaseTypingIndicator,
  },
  directives: {
    infiniteScroll,
  },
  props: {
    userid: {
      type: String,
      default: localStorage.getItem('userid'),
    },
    conversation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isRecipientTyping: false,
      recipientTypingTimout: null,
      followUpMessage: [],
      followUpType: 'treatment',
      followUpID: '',
      followUpAnswers: [],
      previousFollowUpID: '',
      firstAnswer: null,
      secondQuestionBody: {},
      composite: false,
      newMessage: '',
      isSending: false,
      messages: [],
      conversationId: '',
      nextPage: null,
      attachments: [],
      files: [],
      imgUpload: false,
      height: '',
      alert: {
        followup: {
          show: false,
          type: 'warning',
          message: '',
        },
        connection: {
          show: false,
          type: 'warning',
          message: '',
        },
      },
      hasError: false,
      loadingMessage: false,
      isIos: false,
      mobileClass: '',
      currentFollowupStep: 0,
      messageReceivedFn: null,
      assignedAdmin: null,

      amountToGroupBy: 1,
      isToastVisible: false,
      isUploading: false,

      threadHeight: 0,
    };
  },
  computed: {
    isMessageBodyEmpty() {
      if (this.newMessage) {
        return this.newMessage.trim() === '';
      } else {
        // Check if there's a file included during sending
        return !(this.files.length > 0);
      }
    },
    isBanWithinOneMonth() {
      if (!this.conversation.isbanned) return true;
      const current = moment();
      const provided = moment.unix(this.conversation.banned_date);
      const dayDiff = current.diff(provided, 'days');
      if (dayDiff <= 30) {
        return true;
      } else {
        return false;
      }
    },
    groupMessage() {
      const messages = this.messages.reduce((groupedMessages, chat) => {
        const timestampToSort =
          chat.timestamp || (chat.data && chat.data.sentAt);

        const sentAtFormatted = timestampToSort
          ? moment.unix(timestampToSort).format('MMM DD, YYYY hh:mm A')
          : moment().format('MMM DD, YYYY hh:mm A');

        if (!groupedMessages[sentAtFormatted]) {
          groupedMessages[sentAtFormatted] = [];
        }

        groupedMessages[sentAtFormatted].push(chat);

        groupedMessages[sentAtFormatted].sort((a, b) => {
          const timestampA = a.timestamp || (a.data && a.data.sentAt);
          const timestampB = b.timestamp || (b.data && b.data.sentAt);

          return timestampA - timestampB;
        });

        return groupedMessages;
      }, {});

      Object.keys(messages).forEach(key => {
        messages[key].sort((a, b) => a.timestamp - b.timestamp);
      });

      return messages;
    },
    ...mapState('adminChat', ['articles']),
    ...mapState('message', ['unreadAdminMessagesCount']),
    ...mapGetters('support', ['getMessage', 'getPrefix']),
    ...mapGetters('socket', {
      socket: 'getSocket',
    }),
    ...mapGetters('user', {
      user: 'getUser',
    }),
  },
  watch: {
    followUpMessage() {
      if (this.followUpMessage.length > 0) {
        this.followUpType = this.followUpMessage[0].answertype;
      }
    },
  },
  async mounted() {
    this.listMessage(this.userid);
    if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
      this.$apollo.subscriptions.message.stop();
      this.connectToSocket();
    }

    watchResizeObserver(
      ['#message-input-area', '#chat-headline', '#navbar'],
      entries => {
        const [msgInputAreaEntry, chatHeadlineEntry, navbarEntry] = entries;

        const chatHeadlineHeight = getElementHeight(chatHeadlineEntry.target);
        const navbarHeight = getElementHeight(navbarEntry.target);
        const msgInputAreaHeight = getElementHeight(msgInputAreaEntry.target);

        this.threadHeight =
          window.innerHeight -
          chatHeadlineHeight -
          navbarHeight -
          msgInputAreaHeight;
      }
    );

    this.matchHeight();
    const container = document.body;
    if (this.$route.name == 'messages') {
      container.classList.add('no-scroll');
    } else {
      container.classList.remove('no-scroll');
    }
    // /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
    const isIphone = /(iPhone)/i.test(navigator.platform);
    if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
      this.mobileClass = 'isAndroid';
      this.isIos = false;
    }
    if (isIphone) {
      this.mobileClass = 'isIos';
      this.isIos = true;
    }

    // WIN-16590
    const autofillMessage = this.getMessage;
    if (autofillMessage && autofillMessage.length > 0) {
      this.newMessage = `${this.getPrefix}${autofillMessage}`;
      this.$store.commit('support/CLEAR_MESSAGE');
      await this.sendMessage();
    }

    await ChatService.markAdminConversationAsRead(this.user.id);
    this.$store.dispatch('message/resetAdminMessagesCount');
    this.$bvToast.hide('notification');

    this.socket.emit('typing_activity:watch', {
      conversationIds: [this.userid],
    });

    this.socket.on(
      'typing_activity:typing',
      async ({ userId, conversationId }) => {
        if (this.userid === userId) {
          return;
        }

        if (!conversationId.includes(this.userid)) {
          return;
        }

        if (this.recipientTypingTimout) {
          clearTimeout(this.recipientTypingTimout);
        }

        this.isRecipientTyping = true;

        this.recipientTypingTimout = setTimeout(() => {
          this.isRecipientTyping = false;
        }, TYPING_INDICATOR.THROTTLE_TIME);
      }
    );
    await this.getAssignedAdmin();
  },
  destroyed() {
    if (this.messageReceivedFn) {
      this.socket.off('admin_chat:message', this.messageReceivedFn);
    }
  },
  methods: {
    _chunk: _.chunk,

    async getAssignedAdmin() {
      const { data } = await AdminChatService.getAssignedAdmin(this.userid);

      this.assignedAdmin = data?.assignee
        ? `${data.assignee.firstName} ${data.assignee.lastName}`
        : null;
    },
    onType: _.throttle(function () {
      this.socket.emit('typing_activity:typing', {
        conversationId: [this.userid],
      });
    }, TYPING_INDICATOR.THROTTLE_TIME),
    matchHeight() {
      const appHeight = window.innerHeight;
      const messageheight = this.$refs.sendMessage.clientHeight * 2 - 10;
      this.height = appHeight - messageheight;
    },
    openUpload() {
      this.$refs.fileInput.click();
    },
    async handleFilesUpload() {
      const uploadedFiles = this.$refs.fileInput.files;
      this.imgUpload = true;
      const maxFileSize = MAX_SIZE_MB;
      const excludedExtensions = EXCLUDED_EXT;

      const invalidFiles = [];
      const oversizedFiles = [];

      for (let i = 0; i < uploadedFiles.length; i++) {
        const fileSize = uploadedFiles[i].size;
        const fileSizeInMB = fileSize / (1024 * 1024);
        const formattedSize = fileSizeInMB.toFixed(1);
        const validSize = parseFloat(formattedSize) <= maxFileSize;

        if (
          !validSize ||
          !isFileSupported(uploadedFiles[i]) ||
          excludedExtensions.some(ext => uploadedFiles[i].name.endsWith(ext))
        ) {
          invalidFiles.push(uploadedFiles[i]);
        } else {
          const convertedFile = await transformFileForUpload(uploadedFiles[i]);
          this.files.push(convertedFile);
          convertedFile.url = URL.createObjectURL(convertedFile);
          this.attachments.push(convertedFile);
        }

        if (!validSize) {
          oversizedFiles.push(uploadedFiles[i]);
        }
      }

      const dataTransfer = new DataTransfer();

      this.files.forEach(file => {
        dataTransfer.items.add(file);
      });

      this.$refs.fileInput.files = dataTransfer.files;

      if (oversizedFiles.length > 0 && invalidFiles.length > 0) {
        this.$bvToast.toast(
          'Please upload files that are less than or equal to 29MB in size, and only TIFF, PNG, JPEG, JPG, TIF, HEIC, HEIF, and PDF are allowed.',
          {
            variant: 'danger',
            noCloseButton: true,
            autoHideDelay: 2100,
            toaster: 'b-toaster-bottom-center',
          }
        );
      } else if (invalidFiles.length > 0) {
        this.$bvToast.toast(
          'Some files are invalid. Only TIFF, PNG, JPEG, JPG, TIF, HEIC, HEIF, and PDF are allowed.',
          {
            variant: 'danger',
            noCloseButton: true,
            autoHideDelay: 2100,
            toaster: 'b-toaster-bottom-center',
          }
        );
      }
    },
    async setFollowUpMessage(messageid, step = 1) {
      this.composite = false;
      this.followUpID = messageid;
      try {
        this.secondQuestionBody = {};
        this.alert.followup.show = false;
        this.alert.followup.message = '';
        const followup = await ChatService.getFollowUpQuestion(messageid, step);
        // Answer followupquestion if recommendation engine has an empty answerlist
        if (
          ['recommendation', 'methodchange_recommendation'].includes(
            followup.data?.[0]?.answerlookup
          ) &&
          followup.data?.[0]?.answerlist.length === 0
        ) {
          this.answerFollowUpQuestion(
            followup.data?.[0]?.id,
            [],
            followup.data?.[0]
          );
          return;
        }

        if (followup.data) {
          const fmessage = this.messages.find(x => x.id === messageid);
          this.followUpMessage = followup.data;
          this.followUpMessage.actionstatus = fmessage.actionstatus;
          if (followup.data[0]?.answer) {
            this.firstAnswer = followup.data[0].answer;
          }
        }
      } finally {
        this.$refs.modal.$children[0].show();
      }
    },
    async answerFollowUpQuestion(
      id,
      answer,
      answerBody = null,
      moreQuestions,
      treatmentformanswer
    ) {
      try {
        const messageid = this.followUpID;
        // * Previous answer overwriting
        this.followUpAnswers = this.followUpAnswers.filter(o => {
          return o.questionid !== id;
        });
        if (id == this.followUpMessage[0].id) {
          this.firstAnswer = answer;
        }
        if (
          !!this.followUpMessage &&
          !!answerBody &&
          answerBody?.inline &&
          answerBody.inline
        ) {
          this.followUpAnswers = [];
          this.composite = true;
          this.previousFollowUpID = id;
          const CompositeFollowUpRequest =
            await ChatService.getFollowUpQuestion(messageid, answerBody.next);
          this.secondQuestionBody = CompositeFollowUpRequest.data[0];
          this.followUpAnswers.push({ questionid: id, answer: answer });
        } else {
          this.followUpAnswers.push({
            questionid: id,
            answer: answer,
            ...(moreQuestions && { others: [moreQuestions] }),
          });
          const rootID =
            this.followUpAnswers.length > 1 ? this.previousFollowUpID : id;
          await ChatService.answerFollowUpQuestion(messageid, rootID, {
            answers: this.followUpAnswers,
          })
            .then(res => {
              this.followUpAnswers = [];
              if (res.data.next == -1) {
                this.$refs.modal.$children[0].hide();
                return;
              } else {
                if (
                  answerBody?.type === 'treatmentform' &&
                  answerBody?.answerlist.length !== 0
                ) {
                  const treatmentFollowUpAnswer = [
                    {
                      questionid: 11,
                      answer: treatmentformanswer ? 'yes' : 'unsure',
                    },
                  ];
                  ChatService.answerFollowUpQuestion(messageid, 11, {
                    answers: treatmentFollowUpAnswer,
                  }).then(questionresponse => {
                    this.setFollowUpMessage(
                      this.followUpID,
                      questionresponse.data.next
                    );
                    this.currentFollowupStep = questionresponse.data.next;
                  });
                } else {
                  this.setFollowUpMessage(this.followUpID, res.data.next);
                  this.currentFollowupStep = res.data.next;
                }
              }
            })
            .catch(err => {
              this.showFollowUpAlert(err.message);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    removeFile(key) {
      this.attachments.splice(key, 1);
      this.files.splice(key, 1);
      if (this.attachments.length == 0) {
        this.attachments = [];
        this.files = [];
        this.imgUpload = false;
      }
    },
    async sendMessage() {
      try {
        if (!navigator.onLine) {
          if (this.isToastVisible) {
            return;
          }

          this.isToastVisible = true;

          this.$bvToast.toast('No internet connection. Message not sent.', {
            variant: 'warning',
            noCloseButton: true,
            toaster: 'b-toaster-bottom-center',
            autoHideDelay: 2000,
          });

          setTimeout(() => {
            this.isToastVisible = false;
            this.isUploading = false;
          }, 2000);

          return;
        }
        if (this.isMessageBodyEmpty) {
          if (!(this.files.length > 0)) {
            return;
          }
        }
        if (this.isSending === false) {
          this.isSending = true;
          const obj = {
            id: uuidv4(),
            conversationId: this.conversation.id,
            message: this.newMessage,
          };
          if (this.files.length > 0) {
            this.isUploading = true;
            obj.attachments = Object.keys(this.files).map(key => ({
              name: this.files[key].name,
              size: this.files[key].size,
              type: this.files[key].type,
              file: this.files[key],
            }));
            this.socket.emit('admin_chat:new_message', obj, res => {
              console.log(res);
            });
          } else {
            this.socket.emit('admin_chat:new_message', obj);
          }
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        if (navigator.onLine) {
          this.$store.commit('message/SET_UNREAD_ADMIN_MESSAGE_COUNT', 0);
          // this.$store.dispatch("message/getUnreadMessageCount");
          this.isSending = false;
          this.newMessage = '';
          this.attachments = [];
          this.files = [];
          this.imgUpload = false;
          this.$refs.fileInput.value = '';
        }
      }
    },
    async listMessage(userid) {
      try {
        this.nextPage = null;
        this.loadingMessage = true;
        const { data: messages } = await AdminChatService.getMessages(
          userid,
          this.nextPage
        );
        this.messages = messages.data.filter(o => o.type.includes('MESSAGE'));
        const articles = [...new Set(this.messages.filter(m => m.data.articles).map(o => o.data.articles).flat())];
        
        await this.$store.dispatch('adminChat/fetchArticlesById', { ids: articles });
        
        const articlesById = _.keyBy(this.articles, 'id');

        this.messages = this.messages.map(m => {
          if (m.data.articles) {
            m.data.articles = m.data.articles.map(id => {
              if (!articlesById[id]) {
                return { 
                  id, 
                  title: 'Article not found',
                  body: 'Sorry, this article may no longer be available',
                  bodyNoHtml: 'Sorry, this article may no longer be available', 
                };
              }
              
              return articlesById[id]
            });
          }
          return m;
        });

        this.nextPage = messages.nextPageCursor;
        if (this.unreadAdminMessagesCount > 0) {
          this.$store.dispatch('message/resetAdminMessagesCount');
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    async messageScroll(e) {
      const scroll =
        e.target.scrollTop + e.target.scrollHeight === e.target.clientHeight;
      const scrollwithallowance =
        e.target.scrollTop + e.target.scrollHeight < e.target.clientHeight + 10;
      if (scroll || scrollwithallowance) {
        if (this.nextPage && !this.loadingMessage) {
          this.loadingMessage = true;
          const { data: messages } = await ChatService.listMessage(
            this.conversationId,
            this.nextPage
          );
          for (let i = 0; i < messages.items.length; i++) {
            const message = messages.items[i];
            this.messages.push(message);
          }
          if (messages.nextpage) {
            this.nextPage = messages.nextpage;
          } else {
            this.nextPage = null;
          }
          this.loadingMessage = false;
        }
      }
    },
    reconnectApollo() {
      location.reload();
    },
    connectToSocket() {
      if (this.socket) {
        this.socket.on('message_delivery_confirmed', message => {
          console.log('Confirmed: ', message);
          this.onMessageReceived(message);
        });

        // Get the reference of the passed function so that we can disconnect it later
        this.messageReceivedFn = message => {
          this.onMessageReceived(message);
          console.log('Received message', message);
        };

        this.socket.on('admin_chat:message', this.messageReceivedFn);
      }
    },
    async onMessageReceived(message) {
      await ChatService.markAdminConversationAsRead(this.user.id);

      this.hasError = false;
      this.isUploading = false;
      const messageData = {
        data: {
          ...message,
          patient: { id: message.patientId },
        },
      };
      if (message.attachments) {
        messageData.data.attachments = message.attachments;
      }

      if (message.articles && message.articles.length > 0) {
        await this.$store.dispatch('adminChat/fetchArticlesById', { ids: message.articles });
        const articlesById = _.keyBy(this.articles, 'id');
        messageData.data.articles = message.articles.map(id => articlesById[id]);
      }

      this.messages.splice(0, 0, messageData);
      notificationsound.play();
      return this.messages;
    },
    formattedTimestamp(originalTimestamp) {
      return moment(originalTimestamp, 'MMM DD, YYYY hh:mm A')
        .format('MMMM DD, hh:mm A')
        .toUpperCase();
    },
    viewImage(file) {
      return isFileImage(file);
    },
  },
  apollo: {
    $subscribe: {
      message: {
        query: gql`
          subscription onMessageActivity($conversationid: String!) {
            onMessageActivity(conversationid: $conversationid) {
              id
              sentat
              conversationid
              type
              userid
              message
              action
              actionstatus
              custommessage
              attachment {
                filename
                id
                key
                size
                type
                url
              }
            }
          }
        `,
        variables() {
          return {
            conversationid: this.conversationId,
          };
        },
        result({ data }) {
          console.log('AppSync Event', data);
          this.hasError = false;
          const obj = data;
          if (obj.onMessageActivity.action === 'SENT') {
            const newmessage = obj.onMessageActivity;
            if (newmessage.userid !== this.userid) {
              notificationsound.play();
            }
            if (
              newmessage.type === 'prescription_confirmation' ||
              newmessage.type === 'prescription_followup'
            ) {
              if (newmessage.custommessage.constructor.name === 'String') {
                newmessage.custommessage = JSON.parse(newmessage.custommessage);
              }
            }
            this.messages.splice(0, 0, newmessage);
            return this.messages;
          } else if (obj.onMessageActivity.action === 'UPDATED') {
            const messageupdate = obj.onMessageActivity;
            const messagetoupdate = this.messages.find(
              x => x.id === messageupdate.id
            );
            if (messagetoupdate != undefined) {
              if (messagetoupdate.type === 'prescription_confirmation') {
                messagetoupdate.actionstatus = messageupdate.actionstatus;
                notificationsound.play();
              } else if (messagetoupdate.type === 'prescription_followup') {
                messagetoupdate.custommessage = JSON.parse(
                  messageupdate.custommessage
                );
                messagetoupdate.actionstatus = messageupdate.actionstatus;
                notificationsound.play();
              }
            }
          }
        },
        skip() {
          return false;
        },
        error(err) {
          if (err?.errors?.[0]?.message.includes('UnauthorizedException')) {
            this.alert.connection.show = true;
          }
          this.hasError = true;
          console.error('err', err);
        },
      },
    },
  },
};
</script>
<style>
.modal-content.cancellation-main {
  background-color: #1a6a72 !important;
}

.text-plum {
  color: #cdfcb1 !important;
}
</style>
<style scoped>
::v-deep .intercom-btn {
  border: 1px solid #19191c;
}
::v-deep body {
  overflow-y: hidden !important;
}
</style>
<style lang="scss" scoped>
.textarea-label {
  position: absolute;
  top: 0;
  left: 0;
  right: 40px;
  bottom: 0;
  z-index: 1;
  margin-bottom: 0;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
}

.intercom-button {
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.message-holder {
  display: flex;
  padding: 0 10px;
  max-width: 1006px;
  width: 100%;
  height: 52px;

  .textarea-box {
    display: flex;
    flex-grow: 1;
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 15px;

    div:first-child {
      position: relative;
      flex-grow: 1;
    }

    div:last-child {
      flex: none;
    }
  }

  .button-holder {
    padding-left: 10px;
  }
}

.chat-textbox {
  background-color: #f8f8f8;
  font-size: 14px !important;
  border: none;
  width: 100%;
  resize: none;
  margin-left: 10px;
  outline: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #828282;
  }
}
.chat-holder {
  display: flex;
}
.chat-headlines {
  height: 70px;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(md) {
    position: fixed;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1;
  }
  > div {
    margin: 0 auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
}
.new-prescription {
  background-color: #e9f5f3;
}
.products {
  display: flex;
  align-items: stretch;
  background-color: #f9f9f9;
  .product-img {
    background-color: #e8f2f1;
    width: 165px;
    max-width: 165px;
    height: 155px;
    flex: 1 0 165px;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .product-content {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 0;
    list-style-type: none;
    list-style: none;
  }
}

.chat-container,
.chat-box-container {
  transition: height 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.chat-box-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  .chat-box {
    flex-grow: 1;
    width: 100%;
  }
  .message {
    flex: none;
  }
}
.chat-box {
  display: flex;
  margin: 0 auto;
  padding: 1rem;
  overflow-y: scroll;
  flex-flow: column-reverse;
  background-color: #f9f9f9;

  .chat-holder {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-flow: column-reverse;
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .chat {
      margin: 0.75rem 0;
    }
  }

  @include media-breakpoint-down(md) {
    margin-top: 70px;
  }

  .chat-bubble {
    display: flex;
    padding: 0.5rem 0;
    // flex-flow: column;
    &.doctor {
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    &.patient {
      flex-flow: column;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .msg {
      flex: 0 auto;
      max-width: 60%;
      padding: 0.75rem 1rem;
      background-color: #e6e6e6;
      white-space: pre-line;
      word-break: break-word;
      border-radius: 8px;
      
      :deep(p) {
        display: block;
        font-size: 14px;
        line-height: 20px !important;

        a {
          text-decoration: underline;
        }
      }

      :deep(p):empty:before {
        content: " ";
        white-space: pre;
      }

      :deep(p:last-of-type) {
        margin-bottom: 0;
      }
      
      .img-thumbnail {
        border: 0;
        border-radius: 0;
      }
      &.patient {
        margin-left: 10px;
        margin-right: 0;
        background-color: #e3deff;
      }
    }

    .chat-article {
      flex: 0 auto;
      border-color: #e6e6e6;
      background-color: #fff;

      a {
        div {
          color: #000;
        }
        div:hover {
          color: #000;
        }
      }
    }
  }

  .chat-img {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &.is-doctor {
      justify-content: flex-start;
    }
    .img-holder {
      flex: 50px 0 0;
      width: 50px;
      border-radius: 50px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.pre-upload {
  display: flex;
  margin-top: 7px;
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;

  .img-holder {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border: 1px solid $border-color;
    .b-icon.bi {
      position: absolute;
      top: -7px;
      right: -7px;
      cursor: pointer;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  + .card-footer {
    border-top: 0;
  }
}
.message {
  padding: 15px 0;
  background-color: #e6e6e6;
  border-top: 1px solid #e0e0e0;
}
.msg-type {
  margin: 0 auto;
  width: 100%;
}

.pending-btn {
  background-color: #c7bdff;
  color: #2b2660;
  border-color: #c7bdff;
  @include media-breakpoint-down(sm) {
    min-width: none;
    width: 100%;
    &.decline {
      margin-top: 1rem;
    }
  }
}
</style>
