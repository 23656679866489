<template>
  <b-alert
    v-if="getFailedTransactionReason"
    show
    class="tw-px-3 tw-py-2 tw-bg-[#DA635A] tw-text-white tw-text-center tw-border-none tw-mb-0 tw-flex-inline"
  >
    <span class="tw-font-atten-medium tw-tracking-widest tw-text-md lg:tw-text-lg tw-align-middle">
      {{ alertMessage.header }} <span class="tw-font-atten">{{ alertMessage.message }}</span>
    </span>
    <div class="tw-inline-block">
      <b-button
        size="sm"
        pill
        class="tw-px-3 tw-py-1 lg:tw-px-5 lg:tw-py-2 tw-font-normal tw-font-atten-medium tw-bg-tangerine-dream tw-text-white tw-border-transparent tw-ml-2 tw-tracking-widest tw-text-xs lg:tw-text-sm"
        @click="$router.push({ name: 'billing-details' })"
      >
        Update Now
      </b-button>
    </div>
  </b-alert>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FailedPaymentAlert',
  computed: {
    ...mapGetters('user', ['getFailedTransactionReason']),
    alertMessage() {
      const failedTransactionReason = this.getFailedTransactionReason;
      switch (failedTransactionReason) {
        case 'insufficient_funds':
          return {
            header: 'FAILED PAYMENT NOTICE:',
            message: 'Your payment failed due to insufficient funds.',
          };
        case 'lost_card':
          return {
            header: 'FAILED PAYMENT NOTICE:',
            message: 'We have detected that your card has been reported lost. Please contact your card issuer and update your payment details.',
          };
        case 'invalid_expiry_month':
        case 'expired_card':
          return {
            header: 'IMPORTANT NOTICE:',
            message: 'We have detected that your card has expired.',
          };
        case 'generic_decline':
          return {
            header: 'FAILED PAYMENT NOTICE:',
            message: 'Your card has been declined. Update your payment info to continue treatment.',
          };
        default:
          return {
            header: 'FAILED PAYMENT NOTICE:',
            message: 'Your card has been declined. Update your payment info to continue treatment.',
          };
      }
    },
  },
};
</script>