<template>
  <b-container fluid>
    <!-- for Desktop Only Progress -->
    <OnboardingProgress
      :hide="hideBackButton"
      :progress="progress"
      @back="prev"
    />
    <b-row
      class="onboarding-wrapper"
      :style="{
        marginBottom: $_onboardingCtaFooter_marginBottom
      }"
    >
      <OnboardingHeader
        :hide="hideBackButton"
        :title="stepTitle"
        @back="prev"
      />
      <b-col
        cols="12"
        lg="5"
        xl="4"
        xxl="4"
        class="mt-6 pt-3 onboarding-content"
      >
        <!-- mobile progress -->
        <b-row class="d-lg-none">
          <b-col>
            <h3 class="bold d-letter-spacing onboarding-title">
              Onboarding Complete
            </h3>
          </b-col>
        </b-row>
        <b-progress
          :value="mobileProgressDisplay"
          :max="100"
          class="step-progress d-lg-none mb-5"
          variant="purple"
          height="5px"
        />

        <h3
          v-if="step !== 'complete'"
          class="
            d-lg-none
            mt-4
            pb-4
            mb-5
            bold
            d-letter-spacing
            onboarding-title
          "
        >
          <template v-if="step == 'summary'">
            Visit Summary
          </template>
          <template
            v-if="step == 'shipping-info'"
          >
            Shipping Information
          </template>
          <template v-if="step == 'billing'">
            Billing Information
          </template>
        </h3>
        <DiscountBanner
          :show="coupon.show"
          :couponid="coupon.couponid"
        />
        <OnboardingCheckoutFormaCodeDiscountBanner />

        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{
            alert.message
          }}
        </b-alert>
        <Summary
          v-if="step == 'summary'"
          ref="Summary"
          :analytics-id="analyticsId"
          :body="onboardingData"
          :button-disabled="buttonDisabled"
          :saving="buttonDisabled"
          :nonrx="nonrx"
          :selected-pharmacy="selectedPharmacy"
          :pharmacy="pharmacy"
          :onboardingflow="onboardingflow"
          @nextStep="submitPharmacy"
          @clearPharmacy="clearPharmacy"
          @reload-summary="getOnboarding"
          @goToStep="goToStep"
        />
        <!-- <Modal v-if="showM" :body="modalBody" @submitAnswer="submitPharmacy" /> -->
        <PharmacySelect
          v-if="step == 'pharmacy-select'"
          @alert="showAlert"
          @hideAlert="hideAlert"
          @submitAnswer="submitPharmacySelection"
        />
        <Shipping
          v-if="step == 'shipping-info'"
          ref="Shipping"
          :analytics-id="analyticsId"
          :button-disabled="buttonDisabled"
          :saving="buttonDisabled"
          :body="onboardingData"
          :nonrx="nonrx"
          :onboardingflow="onboardingflow"
          :total-price="total"
          @setButtonDisable="setButtonDisable"
          @nextStep="submitShipping"
          @showAlert="showAlert"
        />
        <template v-if="step == 'billing' || billingSkipped">
          <BillingDisplay
            ref="BillingDisplay"
            :body="onboardingData"
            :billing-skipped="billingSkipped"
            :nonrx="nonrx"
            :discount="discount"
            :onboardingflow="onboardingflow"
            :get-onboarding="getOnboarding"
            :analytics-id="analyticsId"
            @set-discount="setDiscount"
            @set-total="setTotal"
          />

          <OnboardingHsaFsaBadge class="tw-mb-[1.5rem]" />

          <BillingInput
            :body="onboardingData"
            :disabled="buttonDisabled"
            :nonrx="nonrx"
            :total-price="total"
            @showAlert="showAlert"
            @hideAlert="hideAlert"
            @nextStep="submitBilling"
            @update-onboarding="getOnboarding"
          />
        </template>
        <Complete
          v-if="step == 'complete'"
          :analytics-id="analyticsId"
          :nonrx="nonrx"
          :body="onboardingData"
          :total-price="total"
          :couponid="coupon.couponid"
        />
        <AltUpsell
          v-if="step == 'upsell'"
          :couponid="coupon.couponid"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
        xl="4"
        xxl="4"
      >
        <!-- Spacer -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// libs
import { v4 as uuidv4 } from 'uuid';
import _kebabCase from 'lodash/kebabCase'

// services
import { OnboardingService } from '@/services/onboarding.service';

// components
import Summary from '@/components/onboarding/checkout/Summary';
import Shipping from '@/components/onboarding/checkout/Shipping';
import BillingDisplay from '@/components/onboarding/checkout/BillingDisplay';
import BillingInput from '@/components/onboarding/checkout/BillingInput';
import Complete from '@/components/onboarding/checkout/Complete';
import PharmacySelect from '@/components/onboarding/checkout/PharmacySelect';
import OnboardingHeader from '@/components/partials/OnboardingHeader';
import AltUpsell from '@/components/onboarding/checkout/AltUpsell';
import OnboardingProgress from '@/components/partials/OnboardingProgress';
import DiscountBanner from '@/components/partials/DiscountBanner';
import OnboardingCheckoutFormaCodeDiscountBanner from '@/components/onboarding/checkout/FormaCodeDiscountBanner';
import OnboardingHsaFsaBadge from '@/components/onboarding/OnboardingHsaFsaBadge.vue';

// constants
import { ONBOARDING_STEP } from '@/constants/localStorage';

// mixins
import analyticsMixin from '@/mixins/analytics';
import { onboardingCtaFooter } from '@/mixins/onboardingCtaFooter';

export default {
  components: {
    Summary,
    Shipping,
    BillingDisplay,
    BillingInput,
    Complete,
    PharmacySelect,
    OnboardingHeader,
    AltUpsell,
    OnboardingProgress,
    DiscountBanner,
    OnboardingCheckoutFormaCodeDiscountBanner,
    OnboardingHsaFsaBadge,
  },
  mixins: [analyticsMixin, onboardingCtaFooter],
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.global.isModalOpen) {
      next(false);
      return;
    }

    if (
      this.step == 'summary' ||
      to.path == '/' ||
      to.path == '/home' ||
      this.exiting ||
      this.onboardingData.onboardingstep < 5.4
    ) {
      next();
    } else if (
      (this.step == 'complete' || this.step == 'upsell') &&
      to.path == '/identity-verification' &&
      this.onboardingflow == 'flowB'
    ) {
      next();
    } else if (this.step == 'complete' && to.path != '/home') {
      next(false);
    } else {
      this.prev();
      next(false);
    }
  },
  data() {
    return {
      alert: {
        show: false,
        message: '',
      },
      coupon: {
        show: false,
        couponid: '',
      },
      analyticsId: null,
      step: '',
      onboardingData: {},
      nonrx: false,
      selectedPharmacy: false,
      pharmacy: {},
      productIds: [],
      discount: 0,
      total: 0,
      exiting: false,
      buttonDisabled: null,
      onboardingflow: 'flowA',
      city: '',
      zipcode: '',
      address: '',
      revealedProducts: [],
      // for new progress UI update on the header
      progress: [
        {
          title: 'Account Creation',
          value: 100,
          max: 100,
        },
        {
          title: 'Intake',
          value: 5,
          max: 5,
        },
        {
          title: 'Onboarding Complete',
          value: 3,
          max: 5,
        },
      ],
      mobileProgressDisplay: 85,

      billingSkipped: false
    };
  },
  computed: {
    hideBackButton() {
      if (this.step !== 'complete') {
        return false;
      }
      return true;
    },
    stepTitle() {
      if (this.step !== 'complete') {
        if (this.step == 'summary') return 'Visit Summary';
        if (this.step == 'shipping-info') return 'Shipping Information';
        if (this.step == 'billing') return 'Billing Information';
      }
      return '';
    },
    showAverageValue() {
      return this.total == 0 || Math.sign(this.total) == -1
    },
  },
  watch: {
    step: {
      handler (step) {
        localStorage.setItem(ONBOARDING_STEP, step);

        switch (step) {
          case 'summary':
            this.mobileProgressDisplay = 85;
            this.coupon.show = true;
            this.onboardingflow === 'flowA' ? 85 : 70;
            this.onboardingflow === 'flowA'
              ? this.$set(this.progress[2], 'value', 3)
              : this.$set(this.progress[2], 'value', 2);
            break;
          case 'shipping-info':
            this.coupon.show = false;
            this.mobileProgressDisplay =
            this.onboardingflow === 'flowA' ? 90 : 75;
            this.onboardingflow === 'flowA'
              ? this.$set(this.progress[2], 'value', 3.5)
              : this.$set(this.progress[2], 'value', 2.4);
            break;
          case 'billing':
            if (this.coupon.couponid) {
              this.coupon.show = true;
            }
            this.mobileProgressDisplay =
            this.onboardingflow === 'flowA' ? 95 : 80;
            this.onboardingflow === 'flowA'
              ? this.$set(this.progress[2], 'value', 4)
              : this.$set(this.progress[2], 'value', 2.8);
            break;
          case 'complete':
            this.coupon.show = false;
            this.mobileProgressDisplay = 100;
            this.$set(this.progress[2], 'value', 5);
            break;
        }
      },
    },
  },
  async mounted() {
    this.$_analytics_page();

    this.revealedProducts = await this.$store.dispatch(
      'onboarding/getProductReveal'
    );
    this.$emit('showLoader', 5, 'doctorvisit');
    await this.getOnboarding();
  },
  methods: {
    setButtonDisable(val) {
      this.buttonDisabled = val;
    },
    showAlert(message, type = null) {
      if (!type) type = 'warning';
      this.alert = {
        show: true,
        type: 'warning',
        message: message,
      };
      window.scrollTo(0, 0);
    },
    hideAlert() {
      this.alert.show = false;
    },
    async getOnboarding() {
      try {
        this.step = localStorage.getItem(ONBOARDING_STEP) || '';

        this.onboardingData = await this.$store.dispatch(
          'onboarding/getPatientOnboarding'
        );

        if (this.onboardingData.couponid) {
          this.coupon.couponid = this.onboardingData.couponid;
        }

        if (this.onboardingData.onboardingstep < 5.4) {
          this.exiting = true;
          window.location.href = '/';
        }

        if (this.onboardingData.city) {
          this.city = this.onboardingData.city;
        }

        this.analyticsId = this.onboardingData.usercustomid
          ? this.onboardingData.usercustomid
          : this.onboardingData.id;

        // Multiflow
        this.onboardingflow = this.onboardingData.onboardingflow;

        this.nonrx =
          this.onboardingData?.nonrxmedicationonly !== undefined &&
          this.onboardingData.nonrxmedicationonly == true;

        this.total = 0;
        if (this.onboardingData?.desiredplan !== undefined) {
          for (const item of this.onboardingData.desiredplan) {
            this.total = this.total + Math.floor(item.price);
            this.productIds.push(item.productid);
          }
        }
        if (this.onboardingData?.desiredaddon !== undefined) {
          for (const item of this.onboardingData.desiredaddon) {
            this.total = this.total + Math.floor(item.price);
            this.productIds.push(item.productid);
          }
        }

        if (this.onboardingData?.referraldiscountamount !== undefined) {
          if (this.onboardingData.referraldiscountamount) {
            this.total -= this.onboardingData.referraldiscountamount;
          }
        }

        if (this.onboardingData?.couponid !== undefined) {
          this.discount = this.onboardingData.discountbyamount;
          if (this.discount) {
            this.total -= this.discount;
          }
        } else {
          this.discount = 0;
        }

        // Renders a component once data has been loaded in
        if (this.step == '') {
          this.step = 'summary';
        }
      } catch (err) {
        console.error(err);

        if (err.status == 401) {
          this.exiting = true;
          this.$router.push('/login');
        } else {
          this.showAlert(err);
        }
      }
    },

    prev() {
      this.alert.show = false;
      switch (this.step) {
        case 'summary':
          // Multiflow redirection
          if (this.onboardingflow == 'flowB') {
            this.$router.push('/treatment-options').catch(() => {});
          } else {
            this.$router.push('/identity-verification').catch(() => {});
          }
          break;
        case 'pharmacy-select':
          this.step = 'summary';
          break;
        case 'shipping-info':
          this.step = 'summary';
          break;
        case 'billing':
          this.step = 'shipping-info';
          break;
        case 'complete':
          this.step = 'billing';
          break;
      }
    },
    nextStep() {
      this.buttonDisabled = false;
      this.alert.show = false;
      switch (this.step) {
        case 'summary':
          this.step = 'shipping-info';
          break;
        case 'pharmacy-select':
          this.step = 'shipping-info';
          break;
        case 'shipping-info':
          this.step = 'billing';
          break;
        case 'billing': {
          // Multiflow redirection
          $cookies.remove('referralToken');

          const onboardingBlossomPromoCodes = [
            'BLACKFRIDAY2022',
            'CYBERMONDAY2022',
          ];

          if (
            $cookies.get('promocode') &&
            !onboardingBlossomPromoCodes.includes($cookies.get('promocode'))
          ) {
            $cookies.remove('promocode');
          }

          if (this.onboardingflow == 'flowB') {
            this.step = 'upsell';
          } else {
            this.step = 'complete';
          }
          break;
        }
      }
    },
    goToStep(step) {
      this.step = step;
    },
    async submitShipping(answers, paymentMethod) {
      this.buttonDisabled = true;
      try {
        this.alert.show = false;
        this.city = answers.city;
        this.zipcode = answers.zipcode;
        this.address = answers.address1;
        await OnboardingService.updatePatientOnboarding(
          6,
          answers
        );

        if (paymentMethod) {
          this.billingSkipped = true;
          const billingAnswer = {
            paymentmethod: paymentMethod.id,
            sameaddress: true
          }
          await this.submitBilling(billingAnswer, true)
          this.step = 'complete';
          this.billingSkipped = false;
          return
        }

        this.nextStep();
      } catch (err) {
        if (err.response.status == 401) {
          this.exiting = true;
          this.$router.push('/login');
        } else {
          this.buttonDisabled = false;
          if (
            err.response.data.message ==
            'Could not match zip code, city and state'
          ) {
            if (err.response?.data?.body?.addresses?.length > 0) {
              this.$refs.Shipping.showSideModal = true;
              this.$refs.Shipping.suggestedAddresses =
                this.$refs.Shipping.suggestedAddresses.concat(
                  err.response?.data?.body?.addresses
                );
            } else {
              this.$refs.Shipping.showModal = true;
            }
          } else {
            const id = this.onboardingData.id
              ? this.onboardingData.id
              : 'Could not get customer ID';
            const email = this.onboardingData.email
              ? this.onboardingData.email
              : 'Could not get customer email';
            this.$store.dispatch('sendErrorLog', {
              step: 'Checkout - Shipping',
              cus_id: id,
              email: email,
              error_log: err.response.data.message,
            });
            this.showAlert(err.response.data.message);
          }
        }
      } finally {
        this.buttonDisabled = false;
      }
    },
    async submitBilling(answer, isSkipBilling) {
      this.buttonDisabled = true;
      try {
        // Supplies message to turn on manual toggling
        this.$emit('showLoader', 6, 'lottie');

        await this.$_analytics_track(
          'Onboarding Screen Viewed',
          {
            customerId: this.analyticsId,
            screen: '5.4-A',
            onboardingflow: this.onboardingflow,
            state: this.onboardingData.state,
            firstname: this.onboardingData.firstname,
            lastname: this.onboardingData.lastname,
          },
        );

        // Checkout ID
        const checkoutID = uuidv4();
        answer.checkoutID = checkoutID;
        const data = await OnboardingService.updatePatientOnboarding(7, answer);

        const productids = [];
        let price = 0;
        if (
          this.onboardingData.desiredaddon &&
          this.onboardingData.desiredaddon.length > 0
        ) {
          for (const addon of this.onboardingData.desiredaddon) {
            price = price + Math.floor(addon.price);
            productids.push(addon.productid);
          }
        }

        if (
          this.onboardingData.desiredplan &&
          this.onboardingData.desiredplan.length > 0
        ) {
          for (const plan of this.onboardingData.desiredplan) {
            price = price + Math.floor(plan.price);
            productids.push(plan.productid);
          }
        }

        if (tatari) {
          // WIN-23503
          tatari.track('purchase', {
            orderId: data.data.eventid,
            total: this.showAverageValue ? 160 : this.total,
          });
          console.log('Tatari Track: Purchase', {
            orderId: data.data.eventid,
            total: this.showAverageValue ? 160 : this.total,
          });
        }

        console.log('SEGMENT', {
          messageId: data.data.eventid,
          productids: productids,
          subtotal: this.$refs.BillingDisplay.subtotal,
          platformfee: this.$refs.BillingDisplay.platformfee,
          taxamount: this.$refs.BillingDisplay.taxamount,
          grandtotal: Math.sign(this.total) == -1 ? 0 : this.total,
          customerId: this.analyticsId,
          value: this.showAverageValue ? 160 : this.total,
        });

        let products = {};
        if ('desiredplan' in this.onboardingData) {
          // WIN-53617
          products = {
            // this.revealedProducts
            // this.onboardingData.desiredplan
            products: this.onboardingData.desiredplan.map(plan => {
              const productObj = this.revealedProducts.find(product => plan.productid === product.id)

              return {
                name: productObj.name,
                product_id: productObj.id,
                category: 'core_products',
                sku: productObj.id,
                price: productObj.availablepricing.find(pricing => pricing.pricingid === plan.pricingdetail.pricingid).price,
                url: `${process.env.VUE_APP_WEBSITE_URL}/product/${_kebabCase(productObj.name)}`,
                image_url: productObj.image,
              }
            })
          }
        }

        await this.$_analytics_track(
          'Checkout Complete',
          {
            checkoutID: checkoutID,
            messageID: data.data.eventid,
            productids: productids,
            subtotal: this.$refs.BillingDisplay.subtotal,
            platformfee: this.$refs.BillingDisplay.platformfee,
            taxamount: this.$refs.BillingDisplay.taxamount,
            // WIN-24812
            grandtotal: Math.sign(this.total) == -1 ? 0 : this.total,
            revenue: this.showAverageValue ? 16900 : (Math.round(this.total * 100)),
            // WIN-29811
            total: this.showAverageValue ? 187 : this.total,
            value: this.showAverageValue ? 160 : this.total,
            customerId: this.analyticsId,
            onboardingflow: this.onboardingflow,
            email: this.onboardingData.email,
            phone: this.onboardingData.phoneno,
            state: this.onboardingData.state,
            zipcode: this.zipcode,
            address: this.address,
            firstname: this.onboardingData.firstname,
            lastname: this.onboardingData.lastname,
            couponCode: this.coupon.couponid ? this.coupon.couponid : null,
            ...products,
            currency: 'USD',
          },
        );

        // Reboot intercom to hide "Schedule a meeting" component
        window.Intercom('shutdown')
        window.Intercom('boot', {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          user_id: this.onboardingData.id
        });

        // GTM productObj
        const productObj = [];
        const productItems = [];
        if (this.onboardingData?.desiredplan !== undefined) {
          for (const item of this.onboardingData.desiredplan) {
            const toPush = {
              id: item.productid,
              name: item.name,
              price: item.price,
            };
            productObj.push(toPush);
            const itemObj = {
              item_name: item.name,
              item_id: item.productid,
              quantity: 1,
              price: item.price,
            }
            productItems.push(itemObj);
          }
        }
        // Logging for GTM testing purposes
        console.log('productObj', productObj);
        console.log('items property', productItems);
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
          productObj: productObj,
          items: productItems,
        });

        console.log('Purchase', {
          external_id: this.onboardingData.id,
          productID: productids,
          currency: 'USD',
          value: this.total,
        });
        if (data.data.eventid) {
          /*
           * Ensure that purchase events are above $0 as FB
           * doesn't like it when Purchase events have a value of 0.
           */

          if (this.total == 0 || !this.total) {
            this.total = 1;
          }
          await this.fbqTrack(
            'track',
            'Purchase',
            productids,
            this.total,
            data.data.eventid
          );
        } else if (
          data.data.eventid &&
          process.env.VUE_APP_SERVER != 'production'
        ) {
          // alert('FB TRACKING PURCHASE: ' + data.data.eventid);
          console.log('FB TRACKING PURCHASE');
          console.log('FB TRACKING PURCHASE: ' + data.data.eventid);
        }

        this.$emit('hideLoader');

        if (isSkipBilling) {
          return
        }

        this.nextStep();
      } catch (err) {
        this.$emit('hideLoader');

        await this.$_analytics_track(
          'Onboarding Screen Viewed',
          {
            customerId: this.analyticsId,
            screen: '5.3-A',
            onboardingflow: this.onboardingflow,
            firstname: this.onboardingData.firstname,
            lastname: this.onboardingData.lastname,
          },
        );
        console.error(err);

        const id = this.onboardingData.id
          ? this.onboardingData.id
          : 'Could not get customer ID';
        const email = this.onboardingData.email
          ? this.onboardingData.email
          : 'Could not get customer email';
        this.$store.dispatch('sendErrorLog', {
          step: 'Checkout - Billing Details',
          cus_id: id,
          email: email,
          error_log: err,
        });
        this.buttonDisabled = false;
        this.showAlert(err);
      }
    },
    async fbqTrack(type, eventName, productids, price, eventID) {
      try {
        // FB Pixel Tracking
        fbq('init', '348219289912097', {
          em: this.onboardingData.email, //Values will be hashed automatically by the pixel using SHA-256
          fn: this.onboardingData.firstname,
          ln: this.onboardingData.lastname,
          ph: this.onboardingData.phoneno,
          external_id: this.onboardingData.id,
          extern_id: this.onboardingData.id,
        });
        // fbq("track", "PageView");
        const pageViewBody = { userid: this.onboardingData.userid };
        OnboardingService.onboardingEvent('PageView', pageViewBody);
        fbq(
          type,
          eventName,
          {
            external_id: this.onboardingData.id,
            extern_id: this.onboardingData.id,
            productID: productids,
            currency: 'USD',
            value: price,
            state: this.onboardingData.state,
            ...(this.city && { city: this.city }),
            ...(await this.$store.dispatch('getFBQParams')),
          },
          {
            eventID: eventID,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async submitPharmacy() {
      this.buttonDisabled = true;
      console.log('InitiateCheckout', {
        external_id: this.onboardingData.id,
        productID: this.productIds,
        currency: 'USD',
        value: this.total,
      });
      try {
        const productids = [];
        let price = 0;
        if (
          this.onboardingData.desiredaddon &&
          this.onboardingData.desiredaddon.length > 0
        ) {
          for (const addon of this.onboardingData.desiredaddon) {
            price = price + Math.floor(addon.price);
            productids.push(addon.productid);
          }
        }

        if (
          this.onboardingData.desiredplan &&
          this.onboardingData.desiredplan.length > 0
        ) {
          for (const plan of this.onboardingData.desiredplan) {
            price = price + Math.floor(plan.price);
            productids.push(plan.productid);
          }
        }

        if (this.onboardingData.onboardingstep === 5.4) {
          try {
            const event_checkoutstarted =
              OnboardingService.triggerOnboardingEvents({
                eventname: 'CheckoutStarted',
                path: window.location.pathname,
              });

            if (event_checkoutstarted.data.status === 'new') {
              if (event_checkoutstarted.data.id) {
                await this.fbqTrack(
                  'trackCustom',
                  'InitiateCheckout',
                  productids,
                  price,
                  event_checkoutstarted.data.id
                );
              } else if (
                event_checkoutstarted.data.id &&
                process.env.VUE_APP_SERVER != 'production'
              ) {
                // alert('FB TRACKING InitiateCheckout: ' + event_checkoutstarted.data.id);
                console.log('FB TRACKING InitiateCheckout');
                console.log(
                  'FB TRACKING InitiateCheckout: ' +
                    event_checkoutstarted.data.id
                );
              }
            }
          } catch (err) {
            // Error handling for integromat event
          }
        }

        try {
          await OnboardingService.updatePatientOnboarding(
            5.4,
            {
              pharmacyid: '',
              agreedtorisks: true,
            }
          );
        } catch (err) {
          if (err.status == 401) {
            this.exiting = true;
            this.$router.push('/login');
          } else {
            this.buttonDisabled = false;
            this.showAlert(err);
          }
        }
        this.nextStep();
      } catch (err) {
        console.log({ err })
      } finally {
        this.buttonDisabled = false;
      }
    },
    async submitPharmacySelection(answer) {
      this.onboardingData.pharmacyid = answer.PharmacyId;
      this.pharmacy = answer;
      this.selectedPharmacy = true;
      this.step = 'summary';
    },
    clearPharmacy() {
      this.pharmacy = {};
      this.selectedPharmacy = false;
    },
    setTotal(total) {
      this.total = total;
    },
    setDiscount(discount) {
      this.discount = discount;
    },
  },
};
</script>
