<template>
  <div class="array-question">
    <h4
      :class="[
        body.header ? 'mb-3' : '',
        heading ? 'question-title h2' : 'label-text',
      ]"
    >
      {{ body.question }}
    </h4>
    <p class="sub-text">
      {{ body.header }}
    </p>
    <b-form-tags
      v-model="answer"
      add-on-change
      input-id="tags-basic"
      class="text-tag mb-3"
      :class="{ 'fs-mask': isSensitive }"
      tag-variant="info"
      add-button-variant="info"
      separator=","
      :placeholder="placeholder"
      :disabled="disabled"
      @focus.native="focus"
    />

    <OnboardingCTAContainer>
      <b-button
        pill
        block
        variant="info"
        :disabled="disabled"
        class="slight-rounded"
        @click="checkAnswer"
      >
        Continue
      </b-button>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';

export default {
  components: {
    OnboardingCTAContainer,
  },
  props: {
    body: {
      type: Object,
      default: () => {},
    },
    othersField: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      placeholder: 'Enter here...',
      answer: [],
    };
  },
  computed: {
    isSensitive() {
      return this.body.hasOwnProperty('sensitive') && this.body.sensitive;
    },
  },
  mounted() {
    if (this.body.hasOwnProperty('answer')) {
      this.answer = this.body.answer;
    } else if (this.othersField.length > 0) {
      this.answer = this.othersField;
    } else {
      this.answer = [];
    }

    if (this.body.hasOwnProperty('placeholder')) {
      this.placeholder = this.body.placeholder;
    }
  },
  methods: {
    focus() {
      event.target.querySelector('#tags-basic').focus();
    },
    checkAnswer() {
      if (
        this.answer.length == 0 &&
        (!this.body.hasOwnProperty('allowempty') || !this.body.allowempty)
      ) {
        this.$emit('alert', 'Please fill in all fields.');
      } else {
        this.$emit('submitAnswer', this.body.id, this.answer, this.body);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .b-form-tags-button {
  min-width: initial;
}

::v-deep .b-form-tag-content {
  font-size: 1.35em;
}
</style>

<style lang="scss" scoped>
.text-tag {
  background-color: #f8f8f8;

  &:focus {
    background-color: #f8f8f8;
  }
}

.heading {
  font-weight: normal !important;
}
</style>
