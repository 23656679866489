<template>
  <div class="eligibilitySurgery">
    <b-row>
      <b-col>
        <div class="mb-4">
          <h2 class="mb-3 heading text-primary">
            Have you undergone a surgery that removed your ovaries, and put you
            into menopause?
          </h2>
          <div class="single-answer">
            <w-checkbox
              id="overianSurgeryYes"
              v-model="surgery"
              type="radio"
              value="Yes"
              label="Yes"
              @change="submitAnswer"
            />
            <w-checkbox
              id="ovarianSurgeryNo"
              v-model="surgery"
              type="radio"
              value="No"
              label="No"
              @change="submitAnswer"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import analyticsMixin from '@/mixins/analytics';

export default {
  mixins: [analyticsMixin],
  data() {
    return {
      surgery: '',
    };
  },
  async mounted() {
    await this.$_analytics_track(
      'Onboarding Screen Viewed',
      {
        screen: '1.3.1.1-A',
      },
    );
  },
  methods: {
    submitAnswer() {
      if (this.surgery == 'Yes') {
        this.$emit('setParams', 'ovariansurgery', true);
        // this.$emit('showLoader', 4);
        this.$emit('setStep', 'registration');
      } else {
        this.$emit('setParams', 'ovariansurgery', false);
        this.$emit('setStep', 'under35');
      }
    },
  },
};
</script>

<style></style>
