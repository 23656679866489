<template>
  <small>
    <b-icon icon="lock-fill" /> 256-BIT TLS SECURITY
  </small>
</template>

<script>
/**
import OnboardingTLSSecurity from '@/components/onboarding/OnboardingTLSSecurity'
 */
export default {

}
</script>