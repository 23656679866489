import { ConfigurationService } from '@/services/configuration.service';

export default {
  namespaced: true,
  state: {
    config: {},
  },
  mutations: {
    SET_STATE(state, payload) {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    isUserSupportsAdminPatientChat(state, getter, rootState) {
      return state.config.admin_chat?.includes(
        rootState.user?.currentUser?.state
      );
    },
  },
  actions: {
    async get({ commit }) {
      // const response = await ConfigurationService.get();
      const response = {
        data: {
          formacode_used: {},
          admin_chat: ['IA', 'MI', 'NH', 'NC'],
          doctor_router: {
            enabled: false,
            byDoctorEnabled: false,
          },
        },
      };

      commit('SET_STATE', {
        state: 'config',
        value: response.data,
      });

      return response.data;
    },
  },
};
