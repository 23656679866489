<template>
  <div
    class="single-answer"
  >
    <h2 :class="['question-title', body.header ? 'with-subtext' : '']">
      {{ body.question }}
    </h2>

    <p
      v-if="body.header"
      class="sub-text group-mb"
    >
      {{ body.header }}
    </p>

    <p
      v-if="body.description"
      class="sub-text group-mb"
    >
      {{ body.description }}
    </p>

    <div class="tw-flex tw-flex-col">
      <SingleCheckbox
        v-for="(list, i) in body.answerlist"
        :key="i"
        v-model="answer"
        :item="list"
        :disabled="disabled"
        @input="e => changeAnswer(e, list)"
      />
    </div>

    <div
      v-if="_size(body.footerarray)"
      class="tw-mt-2"
    >
      <ul class="tw-p-0 tw-list-inside">
        <li
          v-for="(item, i) in body.footerarray"
          :key="`fruit-${i}`"
          class="tw-text-sm"
        >
          {{ item }}
        </li>
      </ul>
    </div>

    <OnboardingCTAContainer v-if="showContinue">
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
        <b-button
          :disabled="disabled"
          pill
          block
          variant="info"
          class="slight-rounded"
          @click="checkAnswer(answer)"
        >
          Continue
        </b-button>
      </div>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
import SingleCheckbox from './SingleCheckbox.vue';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';

import _size from 'lodash/size'
import _isNil from 'lodash/isNil'

export default {
  components: {
    SingleCheckbox,
    OnboardingCTAContainer
  },
  props: {
    body: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answer: null,
      showContinue: false,
    };
  },
  async created () {
    if (!_isNil(this.body.answer) ) {
      this.answer = this.body.answer;
      const selectedAnswer = this.body.answerlist.find(answer => answer.id == this.answer);

      if (selectedAnswer.inline) {
        this.showContinue = false;
        this.$emit(
          'submitAnswer',
          this.body.id,
          this.answer,
          this.body,
          selectedAnswer
        );
      } else {
        this.showContinue = true;
      }
    } else {
      if (this.body.preventautosubmit) {
        this.showContinue = true;
      }
    }
  },
  methods: {
    _size,
    changeAnswer (answer, answerBody) {
      if (answerBody.inline) {
        this.showContinue = false;
      }

      if (!this.showContinue) {
        this.checkAnswer(answer, answerBody);
      }
    },
    checkAnswer(answer, answerBody) {
      if (answer == null) {
        this.$emit('alert', 'Please select an answer below.');
      } else {
        this.$emit(
          'submitAnswer',
          this.body.id,
          this.answer,
          this.body,
          answerBody
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-holder {
  margin-right: -20px;
}
</style>
