<template>
  <div
    class="idPhoto"
  >
    <!-- ID modal -->
    <IdModal
      :show="showModal"
      header-class="id-modal"
      body-class="id-body-modal"
      @exit="hideModal()"
    >
      <template #header>
        <img
          :src="require('@/assets/images/id.png')"
          alt=""
        >
      </template>
      <template #body>
        <h3
          class="sub-text text-uppercase text-primary"
          style="letter-spacing: 0.16em"
        >
          Identity Verification
        </h3>

        <h2 class="heading h1 text-default mt-5 mb-2 initial-line-height">
          Why do you need a photo of my ID?
        </h2>
        <p class="sub-text">
          We use your ID photo to verify your identity, for example your name
          and date of birth. Telemedicine laws require us to verify this
          information before prescribing medication.
        </p>

        <h2 class="heading h1 text-default mt-4 mb-2 initial-line-height">
          Who sees this?
        </h2>
        <p class="sub-text">
          The photo of your ID will be stored securely and will only be shared
          with the patient support team and our identity verification platform.
        </p>

        <b-button
          pill
          block
          variant="info"
          class="mt-5 slight-rounded"
          @click="hideModal()"
        >
          I understand
        </b-button>
      </template>
    </IdModal>
    <div
      class="bg-overlay"
      @click="hideModal()"
    ></div>

    <h2 class="question-title with-subtext">
      {{ isAndroid11Webview ? "Send us" : "Upload" }} a photo of your ID
    </h2>
    <p class="sub-text mb-4">
      In order to legally prescribe medications, we need a valid
      government-issued ID that matches your name and date of birth.
      <a
        style="color: #828282"
        class="text-underline"
        @click="openModal()"
      >
        Why do you need this?
      </a>
    </p>
    <p class="sub-text mb-0">
      Acceptable Forms of ID
    </p>
    <b-row class="mb-3">
      <b-col
        cols="12"
        md="6"
        class="list-id"
      >
        <ul>
          <li>Driver License</li>
          <li>Passport</li>
          <li>Identification Card</li>
        </ul>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <ul>
          <li>Permanent Resident Card</li>
          <li>Consular Card</li>
        </ul>
      </b-col>
    </b-row>
    <div
      v-if="!isMobileOrTablet"
      class="fs-exclude"
    >
      <div class="photo-holder fs-mask">
        <div
          v-if="(picture || idPreview) && uploadFileType !== 'application/pdf'"
          class="btn-crop"
          @click="openCropModal"
        >
          <b-icon icon="crop" />
        </div>
        <div
          class="photo"
          :style="{
            backgroundImage:
              isPhotoTaken || showId || identificationURL
                ? ''
                : 'url(' + require('@/assets/images/id.png') + ')',
          }"
        >
          <img
            v-if="isPhotoTaken"
            :src="picture"
            class="fs-exclude"
            alt=""
          >
          <img
            v-else-if="showId"
            :src="idPreview"
            class="fs-exclude"
            style="object-fit: contain"
          >
          <img
            v-else-if="identificationURL"
            class="fs-exclude"
            :style="{
              width:
                identificationFileType === 'application/pdf' ? 'auto' : '100%',
            }"
            :src="identificationURL"
          >
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-2">
        <div v-if="identificationUploadStatus !== 'UPLOADED' && !isAndroid11Webview">
          <div class="text-center">
            <PersonalPhotoTroubleUpload @skip="submitAnswer({ isSkipped: true })" />
          </div>
        </div>

        <div class="tw-text-center">
          <OnboardingTLSSecurity />
        </div>
      </div>

      <OnboardingCTAContainer>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
          <div
            v-if="!isPhotoTaken && !showId && !identificationURL"
            class="tw-flex tw-items-center tw-gap-2"
          >
            <b-button
              block
              pill
              variant="info"
              class="photo-btn slight-rounded"
              :disabled="isLoadingImage"
            >
              <input
                id="identification"
                ref="identification"
                type="file"
                :accept="ACCEPTED_FILE_MIME_TYPES"
                class="hide-file-input"
                @change="idUpload()"
              >
              <label
                for="identification"
                class="mb-0"
              >
                {{ isLoadingImage ? "Loading..." : "SELECT PHOTO" }}
              </label>
            </b-button>

            <b-button
              variant="outline-info"
              :disabled="saving"
              pill
              block
              class="tw-m-0 slight-rounded"
              @click="showCamera"
            >
              {{ isPhotoTaken ? "RETAKE" : "TAKE PHOTO" }}
            </b-button>
          </div>

          <div
            v-else
            class="tw-flex tw-items-center tw-gap-2"
            e
          >
            <b-button
              pill
              block
              variant="outline-info"
              :disabled="saving || isLoadingImage"
              class="photo-btn tw-m-0 slight-rounded"
            >
              <input
                id="identification"
                ref="identification"
                type="file"
                :accept="ACCEPTED_FILE_MIME_TYPES"
                class="hide-file-input"
                @change="idUpload()"
              >
              <label
                for="identification"
                class="mb-0"
              >
                {{ isLoadingImage ? "Loading..." : "REUPLOAD" }}
              </label>
            </b-button>

            <b-button
              variant="outline-info"
              :disabled="saving || isLoadingImage"
              pill
              block
              class="tw-m-0 slight-rounded"
              @click="showCamera"
            >
              {{ isPhotoTaken ? "Retake" : "TAKE PHOTO" }}
            </b-button>
          </div>

          <div v-if="isPhotoTaken || showId || identificationURL">
            <b-button
              class="tw-m-0 slight-rounded"
              pill
              variant="info"
              :disabled="saving"
              block
              @click="submitAnswer({ isSkipped: false })"
            >
              {{ saving ? "Saving..." : "CONFIRM" }}
            </b-button>
          </div>
        </div>
      </OnboardingCTAContainer>
    </div>
    <div v-else>
      <MobileUpload
        class="fs-exclude"
        id-size
        :is-android11-webview.sync="isAndroid11Webview"
        :existing-photo="identificationURL"
        :existing-photo-file-type="identificationFileType"
        @submitAnswer="setMobilePhoto"
        @showAlert="showAlert"
        @hideAlert="hideAlert"
        @skip="submitAnswer({ isSkipped: true })"
      />
    </div>

    <IDPhotoEnsure />

    <modal
      id="takePhoto"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <video
        ref="camera"
        width="100%"
        height="325"
        autoplay
        style="background-color: #000"
      ></video>
      <canvas
        ref="canvas"
        class="photo-taken"
        :width="325"
        :height="250"
      ></canvas>
      <b-row class="mt-3">
        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="info"
            class="slight-rounded"
            @click="takePhoto"
          >
            Capture
          </b-button>
          <div class="mb-3 d-md-none"></div>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="outline-info"
            class="slight-rounded"
            @click="hideCameraModal"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </modal>

    <b-modal
      id="crop"
      class="fs-exclude"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <vue-croppie
        ref="croppieRef"
        :enable-orientation="false"
        :enable-resize="false"
        :boundary="{ width: 300, height: 200 }"
        :viewport="{ width: 300, height: 200 }"
      />
      <b-row class="mt-3">
        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="info"
            class="slight-rounded"
            @click="cropImage"
          >
            Crop
          </b-button>
          <div class="mb-3 d-md-none"></div>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="outline-info"
            class="slight-rounded"
            @click="closeCropModal"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
// services
import { isMobile } from 'mobile-device-detect';
import { OnboardingService } from '@/services/onboarding.service';

// components
import MobileUpload from '@/components/partials/MobileUpload';
import IdModal from '@/components/partials/SideModal';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';
import IDPhotoEnsure from '@/components/onboarding/verification/IDPhotoEnsure';
import OnboardingTLSSecurity from '@/components/onboarding/OnboardingTLSSecurity'
import PersonalPhotoTroubleUpload from '@/components/onboarding/verification/PersonalPhotoTroubleUpload'

// libs
import pdfIcon from '@/assets/images/onboarding/pdf-icon.png';

// constants
import { ACCEPTED_FILE_MIME_TYPES } from '@/constants/files';

// utils
import {
  finalizeFileForUpload,
  fileToDataUrl,
  isFileTypePdf,
} from '@/utils/file';
import { getRef } from '@corefront/utils/getRef';

// mixins
import analyticsMixin from '@/mixins/analytics';

export default {
  components: {
    MobileUpload,
    IdModal,
    OnboardingCTAContainer,
    OnboardingTLSSecurity,
    PersonalPhotoTroubleUpload,
    IDPhotoEnsure
  },
  mixins: [analyticsMixin],
  props: {
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    analyticsId: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ACCEPTED_FILE_MIME_TYPES,
      showModal: false,
      userid: null,
      currentonboardingstep: null,
      isPhotoTaken: false,
      showId: false,
      isLoadingImage: false,
      identificationURL: null,
      saving: false,
      idPreview: '',
      picture: '',
      orientation: 0,

      email: '',
      firstname: '',
      lastname: '',
      phoneno: '',

      identification: null,
      identificationFileType: '',
      identificationUploadStatus: '',

      isAndroid11Webview: false,
    };
  },
  computed: {
    isMobileOrTablet() {
      return isMobile;
    },
    uploadFileType() {
      return this.identification?.type || '';
    },
  },
  watch: {
    saving: {
      handler(val) {
        this.$store.commit('onboarding/SET_IS_IMAGE_UPLOAD_PROCESSING', val);
      },
      immediate: true,
    },
  },
  async mounted () {
    window.scrollTo(0, 0);

    const identification = await getRef.call(this, 'identification')
    identification.value = null

    const data = await this.$store.dispatch('onboarding/getPatientOnboarding');
    this.userid = data.id;
    this.email = data.email;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.phoneno = data.phoneno;

    this.$_analytics_track('Onboarding Screen Viewed', {
      customerId: this.analyticsId,
      screen: '4.2-A',
      firstname: this.firstname,
      lastname: this.lastname,
      onboardingflow: this.onboardingflow,
    })

    this.currentonboardingstep = data.onboardingstep;
    this.identificationUploadStatus = data.identificationupload;
    if (data.identificationURL) {
      this.identificationFileType = data.identificationFileType;
      if (this.identificationFileType === 'application/pdf') {
        this.identificationURL = pdfIcon;
      } else {
        this.identificationURL = data.identificationURL;
      }
    }
  },
  methods: {
    openModal() {
      this.elementTag('hidden', true);
    },
    hideModal() {
      this.elementTag(null, false);
    },
    elementTag(style, Boolean) {
      this.showModal = Boolean;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      // htmlTag.scrollTop = htmlTag.scrollHeight;
    },
    openCropModal() {
      this.$root.$emit('bv::show::modal', 'crop');
      this.$nextTick(() => {
        this.$refs.croppieRef.bind({
          url: this.idPreview,
        });
      });
    },
    closeCropModal() {
      this.$root.$emit('bv::hide::modal', 'crop');
    },
    cropImage() {
      const options = {
        format: 'jpeg',
      };
      this.$refs.croppieRef.result(options, output => {
        this.idPreview = output;
        this.picture = output;
        this.identification = this.dataURLtoFile(output, 'image');
        this.closeCropModal();
      });
    },
    setMobilePhoto(photo) {
      this.picture = photo;
      this.idPreview = photo;
      const reader = new FileReader();
      reader.addEventListener(
        'load',
        function () {
          this.isPhotoTaken = false;
          this.showId = true;
          this.idPreview = reader.result;
        }.bind(this),
        false
      );
      this.submitAnswer({ isSkipped: false });
    },
    showAlert(msg) {
      this.$emit('showAlert', msg);
    },
    hideAlert() {
      this.$emit('hideAlert');
    },
    showCamera() {
      this.$root.$emit('bv::show::modal', 'takePhoto');
      this.createCameraElement();
    },
    createCameraElement() {
      const constraints = (window.constraints = {
        audio: false,
        video: {
          facingMode: 'user',
        },
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream;
        })
        .catch(error => {
          if (error.name == 'NotAllowedError') {
            this.hideCameraModal();
            this.$emit(
              'showAlert',
              'Camera is not detected. Please turn on your camera and refresh your browser.'
            );
            alert(
              'Camera is not detected. Please turn on your camera and refresh your browser.'
            );
          }
        });
    },
    stopCameraStream() {
      if (this.$refs.camera?.srcObject) {
        const tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach(track => {
          track.stop();
        });
      }
    },
    hideCameraModal() {
      this.stopCameraStream();
      this.$root.$emit('bv::hide::modal', 'takePhoto');
    },
    takePhoto() {
      try {
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 325, 250);
        this.picture = canvas.toDataURL('image/png');
        this.idPreview = canvas.toDataURL('image/png');
      } catch (err) {
        const id = this.userid ? this.userid : 'Could not get customer ID';
        const email = this.email ? this.email : 'Could not get customer email';
        this.$store.dispatch('sendErrorLog', {
          step: 'Identity Verification - ID Take Photo',
          cus_id: id,
          email: email,
          error_log: err,
        });
        this.$emit('showAlert', err.message);
      }

      this.$nextTick(() => {
        this.$root.$emit('bv::hide::modal', 'takePhoto');
        this.stopCameraStream();
        this.isPhotoTaken = true;
      });
    },
    async idUpload() {
      try {
        this.isLoadingImage = true;

        this.hideAlert();

        const fileToUpload = this.$refs.identification.files[0];

        if (!fileToUpload) {
          return;
        }

        const { error, success } = await finalizeFileForUpload(fileToUpload);

        if (error) {
          console.log(error);
          this.showAlert(error);
          return;
        }

        this.identification = success;

        const isPdf = isFileTypePdf(this.identification);
        const dataUrl = await fileToDataUrl(this.identification);

        this.isPhotoTaken = false;
        this.showId = true;
        this.picture = dataUrl;
        this.idPreview = isPdf ? pdfIcon : dataUrl;
      } catch (err) {
        console.log(err);
        const id = this.userid ? this.userid : 'Could not get customer ID';
        const email = this.email ? this.email : 'Could not get customer email';
        this.$store.dispatch('sendErrorLog', {
          step: 'Identity Verification - ID Upload',
          cus_id: id,
          email: email,
          error_log: err,
        });
        this.showAlert(err);
      } finally {
        this.isLoadingImage = false;
      }
    },
    async fbqTrack(data) {
      try {
        // FB Pixel Tracking
        fbq('init', '348219289912097', {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: this.userid,
          extern_id: this.userid,
        });

        const pageViewBody = { userid: this.userid };
        OnboardingService.onboardingEvent('PageView', pageViewBody);

        fbq(
          'trackCustom',
          'IDUploaded',
          {
            external_id: this.userid,
            extern_id: this.userid,
            state: this.state,
            ...(await this.$store.dispatch('getFBQParams')),
          },
          {
            eventID: data.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async submitAnswer ({ isSkipped = false }) {
      try {
        this.saving = true;

        if (this.idPreview == '' && this.identificationURL != null) {
          // Segment
          window.analytics.identify(this.analyticsId, {
            iduploaded: this.identificationUploadStatus === 'UPLOADED',
          });

          this.saving = false;
          this.$emit('setStep', 'personalphoto');
          return;
        }

        const mform = new FormData();
        let idFront;

        if (isSkipped) {
          idFront = 'SKIPPED';
        } else {
          if (this.isPhotoTaken === true && this.picture) {
            idFront = this.picture;
          } else if (this.isPhotoTaken === false && this.idPreview) {
            if (this.uploadFileType === 'application/pdf') {
              idFront = this.picture;
            } else {
              idFront = this.idPreview;
            }
          }

          if (idFront) {
            if (!(idFront instanceof File)) {
              idFront = this.dataURLtoFile(idFront, 'image');
            }
          }
        }

        mform.append('identification', idFront);
        if (this.orientation > 0)
          mform.append('rotationindex', this.orientation);

        let res;
        try {
          res = await OnboardingService.updatePatientOnboarding(5.2, mform, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } catch (err) {
          console.log(err);
          this.$emit('showAlert', err);
          this.saving = false;
          return;
        }
        if (isSkipped) {
          // Segment
          window.analytics.identify(this.analyticsId, {
            iduploaded: false,
          });
          if (this.identificationUploadStatus !== 'SKIPPED') {
            // ID Upload skipped analytics
            await this.$_analytics_track(
              'ID Upload Skipped',
              {
                customerId: this.analyticsId,
                onboaringflow: this.onboardingflow,
                messageID: res.data.eventid,
                phoneno: this.phoneno,
                state: this.state,
                email: this.email,
                firstname: this.firstname,
                lastname: this.lastname,
              },
            );
          }
        } else {
          // Segment
          window.analytics.identify(this.analyticsId, {
            iduploaded: true,
          });
          if (this.identificationUploadStatus !== 'UPLOADED') {
            // SEGMENT
            await this.$_analytics_track(
              'ID Uploaded',
              {
                customerId: this.analyticsId,
                onboardingflow: this.onboardingflow,
                messageID: res.data.eventid,
                phoneno: this.phoneno,
                state: this.state,
                email: this.email,
                firstname: this.firstname,
                lastname: this.lastname,
              },
            );
          }
        }
        if (res.data.eventid) {
          await this.fbqTrack(res.data);
        } else if (
          res.data.eventid &&
          process.env.VUE_APP_SERVER != 'production'
        ) {
          console.log('FB TRACKING IDUploaded');
          console.log('FB TRACKING IDUploaded: ' + res.data.eventid);
        }
        this.$emit('setStep', 'personalphoto');
        this.saving = false;
      } catch (err) {
        if (err.status == 401) {
          this.$router.push('/login');
        } else {
          const id = this.userid ? this.userid : 'Could not get customer ID';
          const email = this.email
            ? this.email
            : 'Could not get customer email';
          this.$store.dispatch('sendErrorLog', {
            step: 'Identity Verification - ID Submission',
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.$emit('showAlert', err);
        }
      }
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    },
  },
};
</script>

<style lang="scss">
.id-modal {
  background-color: #f6f6f6;
  height: 50vh;

  @include media-breakpoint-down(xs) {
    height: 30vh;
  }

  img {
    height: 50%;
  }
}

.id-body-modal {
  overflow: auto;
  overflow-x: hidden;
}
</style>

<style lang="scss" scoped>
.separator {
  margin-bottom: 10px;
}
.modal-cancel-btn.btn-outline-info {
  border-color: #cdfcb1;
  background-color: transparent;
  color: #cdfcb1;

  &:active {
    background-color: transparent;
    border-color: #cdfcb1;
    box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba(#cdfcb1, 0.5);
  }
}

.photo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  position: relative;

  .btn-crop {
    padding: 0.75rem;
    background-color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #a783ff;
  }

  @include media-breakpoint-up(xxl) {
    height: 250px * $scale-font;
    margin-bottom: 20px * $scale-font;
  }

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include media-breakpoint-up(xxl) {
      width: 300px * $scale-font;
      height: 200px * $scale-font;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.photo-taken {
  position: absolute;
  left: -9999px;
}

.sub-text {
  a {
    cursor: pointer;
  }
}

.list-id {
  margin-bottom: -15px;
}
</style>
