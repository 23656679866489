/**
import { ... } from "@/utils/file";
 */

// libs
import Heic2Any from 'heic2any';
import Compressor from 'compressorjs';
import Tiff from 'tiff.js';

// constants
import { FILES } from '@/constants';

export function getFileExtension(file) {
  return file.name.split('.').pop().toLowerCase();
}

export function isBase64(str) {
  const base64Regex =
    // eslint-disable-next-line no-useless-escape
    /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

  return base64Regex.test(str);
}

export function isFile(file) {
  return file instanceof File;
}

export function isFileTypeHeic(file) {
  return ['heic', 'heif'].includes(getFileExtension(file));
}

export function isFileTypePdf(file) {
  return getFileExtension(file) === 'pdf';
}

export function isFileTypeTif(file) {
  return ['tif', 'tiff'].includes(getFileExtension(file));
}

export function isFileTypeJpeg(file) {
  return ['jpeg', 'jpg'].includes(getFileExtension(file));
}

export function isFileTypePng(file) {
  return getFileExtension(file) === 'png';
}

export function getGenericFileType(file) {
  if (isFileTypePdf(file)) {
    return FILES.GENERIC_FILE_TYPES.PDF;
  }

  if (isFileTypeJpeg(file)) {
    return FILES.GENERIC_FILE_TYPES.JPG;
  }

  if (isFileTypePng(file)) {
    return FILES.GENERIC_FILE_TYPES.PNG;
  }

  if (isFileTypeHeic(file)) {
    return FILES.GENERIC_FILE_TYPES.HEIC;
  }

  if (isFileTypeTif(file)) {
    return FILES.GENERIC_FILE_TYPES.TIF;
  }

  return null;
}

export function createImage(base64DataUrl) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = base64DataUrl;
    image.onload = () => resolve(image);
    image.onerror = err => reject(err);
  });
}

export function getImageFileDimensions (file) {
  return new Promise((resolve, reject) => {
    if (!isFileImage(file)) {
      resolve({
        width: 0,
        height: 0,
      })
    }

    const img = new Image();

    img.src = URL.createObjectURL(file);

    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = reject;
  });
}

/**
 * Should only allow jpeg/jpg/png
 */
export function isImageSupported(file) {
  return isFileTypePng(file) || isFileTypeJpeg(file);
}

/**
 * Should only allow pdf, heic, tif, jpeg/jpg/png
 * Heic and tif should be converted to jpeg later
 */
export function isFileSupported(file) {
  if (isFileTypeHeic(file)) {
    return true;
  }

  if (isFileTypeTif(file)) {
    return true;
  }

  if (isImageSupported(file)) {
    return true;
  }

  const extension = getFileExtension(file);

  return ['pdf'].includes(extension);
}

export function isFileSizeValid(file) {
  return file.size < FILES.MAX_SIZE_BYTES;
}

export async function heicToJpegFile(file) {
  const blob = await fileToBlob(file);

  const jpgBlob = await Heic2Any({
    blob: blob,
    toType: 'image/jpeg',
    quality: FILES.DEFAULT_IMAGE_QUALITY,
  });

  return blobToFile(jpgBlob, {
    name: file.name.replace(/heic$/gi, 'jpeg'),
  });
}

export async function tifToJpegFile(file) {
  const buffer = await fileToBuffer(file);
  const tiff = new Tiff({
    buffer: buffer,
  });

  const canvas = tiff.toCanvas();
  const base64DataUrl = canvas.toDataURL('image/jpeg');
  const blob = base64toBlob(base64DataUrl);

  const filenameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
  const newFilename = `${filenameWithoutExtension}.jpeg`;

  return blobToFile(blob, {
    name: newFilename,
  });
}

/**
 * Converts file to base64 data url.
 * Automatically resolves heic and tif files to jpeg
 */
export function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = reject;
  });
}

/**
 * Prepares file for upload.
 * Converts heic and tif files to jpeg
 * otherwise returns the file as is
 */
export async function transformFileForUpload(file) {
  if (isFileTypeHeic(file)) {
    return heicToJpegFile(file);
  }

  if (isFileTypeTif(file)) {
    return tifToJpegFile(file);
  }

  return file;
}

/**
 * Converts file to buffer
 */
export function fileToBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = reject;
  });
}

export function fileToBlob(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type,
      });

      resolve(blob);
    };
    reader.readAsArrayBuffer(file);
  });
}

export function blobToFile(blob, _options) {
  const options = Object.assign(
    {
      name: new Date().getTime(),
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    },
    _options
  );

  return new File([blob], options.name, options);
}

/**
 * Converts base64 data url to blob
 */
export function base64toBlob(base64DataUrl) {
  const arr = base64DataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
}

/**
 * Converts base64 data url to file
 */
export function base64toFile(dataurl, _options) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1;
  }

  const options = Object.assign(
    {
      name: new Date().getTime(),
      type: mime,
      lastModified: new Date().getTime(),
    },
    _options
  );

  return new File([u8arr], options.name, options);
}

/**
 * Compress files. Files accepted are limited to pdf and
 * supported images only.
 *
 * @returns {Promise<File>}
 */
export async function compressFile(file, options = {}) {
  return new Promise((resolve, reject) => {
    /**
     * Allow compression only to pdf and supported images
     */
    if (isFileTypePdf(file)) {
      return resolve(file);
    }

    if (!isImageSupported(file)) {
      return resolve(file);
    }

    new Compressor(file, {
      convertSize: FILES.MAX_SIZE_BYTES,
      convertTypes: ['image/jpeg', 'image/jpg', 'image/png'],
      success: result => {
        const compressedFile = new File([result], options.name || file.name, {
          type: result.type,
          lastModified: new Date(),
        });

        resolve(compressedFile);
      },
      error: reject,
    });
  });
}

export async function finalizeFileForUpload(file, options = {}) {
  if (!file) {
    return {
      error: 'No file found!',
    };
  }

  if (!isFileSupported(file)) {
    return {
      error: 'Please upload an image or a PDF file!',
    };
  }

  const preparedFile = await transformFileForUpload(file);

  const compressedFile = await compressFile(preparedFile, options);

  /**
   * Check file size after compression
   */
  if (!isFileSizeValid(compressedFile)) {
    return {
      error: `File too big (> ${FILES.MAX_SIZE_MB}MB)`,
    };
  }

  return {
    success: compressedFile,
  };
}

export function isFileImage(file) {
  return (
    isFileTypeJpeg(file) ||
    isFileTypePng(file) ||
    isFileTypeTif(file) ||
    isFileTypeHeic(file)
  );
}
