var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"tw-border-2 tw-border-solid tw-relative hover:tw-border-lavender-purple tw-py-3 tw-px-5 tw-rounded-lg tw-flex tw-items-start tw-overflow-hidden tw-cursor-pointer",class:[
    _vm.model === _vm.item.id ? 'tw-border-lavender-purple' : 'tw-border-silver-sand',
    _vm.disabled ? 'tw-opacity-80 tw-pointer-events-none' : ''
  ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"tw-mr-5 tw-flex-shrink-0",attrs:{"type":"radio","name":"foo"},domProps:{"value":_vm.item.id,"checked":_vm._q(_vm.model,_vm.item.id)},on:{"change":function($event){_vm.model=_vm.item.id}}}),_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-2 tw-select-none"},[_c('span',{staticClass:"tw-text-base tw-font-atten-medium"},[_vm._v(_vm._s(_vm.item.name))]),(_vm.item.description)?_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"tw-font-thin tw-text-sm tw-leading-none tw-m-0",class:{
          'tw-line-clamp-2': !_vm.isDescriptionVisible
        }},[_vm._v(" "+_vm._s(_vm.item.description)+" ")]),_c('span',{staticClass:"tw-inline-block tw-font-thin tw-text-sm tw-text-ocean-depths tw-underline tw-cursor-pointer",on:{"click":function($event){$event.preventDefault();_vm.isDescriptionVisible = !_vm.isDescriptionVisible}}},[_vm._v(" "+_vm._s(_vm.isDescriptionVisible ? 'Less' : 'More')+" Details ")])]):_vm._e(),(_vm.item.pricingstatement)?_c('p',{staticClass:"tw-leading-none tw-font-atten-bold tw-m-0"},[_vm._v(_vm._s(_vm.item.pricingstatement))]):_vm._e()]),_c('div',{staticClass:"tw-w-[50px] tw-flex-shrink-0 tw-bg-cover tw-bg-no-repeat",class:[
      _vm.item.image ? 'tw-h-[70px]' : ''
    ],style:({
      backgroundImage: `url(${_vm.item.image})`
    })}),(_vm.item.mostpopular || _vm.item.bestdeal)?_c('div',{staticClass:"tw-absolute tw-text-[10px] tw-bg-tangerine-dream tw-text-white tw-py-1 tw-px-3 tw-right-0 tw-uppercase"},[_vm._v(" "+_vm._s(_vm.item.mostpopular ? 'Most Popular' : _vm.item.bestdeal ? 'Best Deal' : '')+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }