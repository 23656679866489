<template>
  <b-row
    align-v="center"
    align-h="center"
    :class="[
      name == 'login'
        ? 'login'
        : name == 'signup'
          ? 'signup'
          : name == 'forgot-password'
            ? 'forgot-password'
            : name == 'new-password'
              ? 'new-password'
              : name == 'invitation-code'
                ? 'invite-code'
                : '',
    ]"
  >
    <b-col
      cols="12"
      class="bg-form"
      align-self="stretch"
    >
      <b-form
        v-if="name == 'cart'"
        @submit.prevent="login()"
      >
        <b-row
          style="height: 85vh"
          align-v="stretch"
        >
          <b-col cols="12">
            <h1 :class="['display-4 mb-3 heading', textCenter]">
              {{ title }}
            </h1>
            <p :class="['text-muted', textCenter]">
              {{ subTitle }}
            </p>
          </b-col>

          <b-col cols="12">
            <b-alert
              :show="loginAlert.show"
              :variant="loginAlert.type"
            >
              {{
                loginAlert.message
              }}
            </b-alert>

            <div
              role="group"
              class="mb-4"
            >
              <label for="email">Email Address:</label>
              <b-form-input
                id="email"
                v-model="email"
                class="pl-0 fs-mask"
                type="email"
                :state="emailState"
                autocomplete="off"
                required
              />
              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback id="input-live-feedback">
                The email must have '@email.com'
              </b-form-invalid-feedback>
            </div>
            <div
              role="group"
              class="mb-4"
            >
              <b-row align-v="center">
                <label
                  for="password"
                  class="col mb-0"
                >Password:</label>
                <div class="col-auto">
                  <slot name="password">
                    <router-link
                      v-slot="{ navigate }"
                      tabindex="-1"
                      to="/forgot-password"
                      custom
                    >
                      <a
                        href="javascript:void(0)"
                        class="form-text small d-letter-spacing mb-0 fs-mask"
                        @click="navigate"
                      >
                        Forgot password?</a>
                    </router-link>
                  </slot>
                  <!-- Help text -->
                </div>
              </b-row>
              <b-form-input
                id="password"
                v-model="password"
                class="pl-0 fs-mask"
                type="password"
                :state="passwordState"
                autocomplete="off"
                required
              />
              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback id="input-live-feedback">
                Min. 8 characters
                <!-- Min. 8 characters with at least one capital letter, a number and a
                special character -->
              </b-form-invalid-feedback>
            </div>

            <slot name="register">
              <small class="text-muted d-letter-spacing">
                Don't have an account yet?
                <router-link
                  v-slot="{ navigate }"
                  to="/eligibility"
                  tabindex="-1"
                  custom
                >
                  <a
                    href="javascript:void(0)"
                    @click="navigate"
                  >Get started</a>
                </router-link>
              </small>
            </slot>
          </b-col>

          <b-col cols="12">
            <slot name="button">
              <b-button
                v-if="!dataloading"
                type="submit"
                pill
                block
                variant="primary"
                size="lg"
                class="mt-3"
              >
                Continue
              </b-button>
              <b-button
                v-else
                disabled
                pill
                block
                variant="primary"
                size="lg"
                class="mb-3"
              >
                Logging in
              </b-button>
            </slot>
          </b-col>
        </b-row>
      </b-form>

      <b-form
        v-if="name == 'login'"
        @submit.prevent="login()"
      >
        <h1 class="display-3 mb-3 heading initial-line-height">
          {{ title }}
        </h1>
        <p class="sub-text">
          {{ subTitle }}
        </p>
        <b-alert
          :show="loginAlert.show"
          :variant="loginAlert.type"
        >
          {{
            loginAlert.message
          }}
        </b-alert>

        <div
          role="group"
          class="mb-4 mt-5"
        >
          <label
            for="email"
            class="input-label"
          >Email Address:*</label>
          <b-form-input
            id="email"
            v-model="email"
            class="pl-0 fs-mask"
            type="email"
            :state="emailState"
            autocomplete="off"
            required
          />
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>
        <div
          role="group"
          class="mb-4"
        >
          <div class="row">
            <label
              for="password"
              class="col input-label"
            >Password:*</label>
            <div class="col-auto">
              <router-link
                v-slot="{ navigate }"
                tabindex="-1"
                to="/forgot-password"
                custom
              >
                <a
                  href="javascript:void(0)"
                  class="text-primary form-text input-label"
                  @click="navigate"
                >
                  Forgot password?
                </a>
              </router-link>
            </div>
          </div>
          <b-form-input
            id="password"
            v-model="password"
            class="pl-0 fs-mask"
            type="password"
            :state="passwordState"
            autocomplete="off"
            required
          />
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Min. 8 characters
            <!-- Min. 8 characters with at least one capital letter, a number and a
            special character -->
          </b-form-invalid-feedback>
        </div>

        <b-button
          v-if="!dataloading"
          type="submit"
          pill
          variant="info"
          size="lg"
          class="mb-3"
          style="min-width: 250px"
        >
          Continue
        </b-button>
        <b-button
          v-else
          style="min-width: 250px"
          pill
          disabled
          variant="info"
          size="lg"
          class="mb-3"
        >
          Logging in
        </b-button>
        <p class="mb-0 sub-text">
          Don't have an account?
          <router-link
            v-slot="{ navigate }"
            to="/eligibility"
            tabindex="-1"
            custom
          >
            <a
              href="javascript:void(0)"
              class="text-primary"
              @click="navigate"
            >
              Get Started
            </a>
          </router-link>
        </p>
      </b-form>

      <b-form
        v-if="name == 'signup'"
        @submit.prevent="signUp()"
      >
        <h1 :class="['mb-3 heading', textCenter]">
          {{ title }}
        </h1>
        <p :class="['text-muted sub-text', textCenter]">
          {{ subTitle }}
        </p>
        <b-alert
          :show="loginAlert.show"
          :variant="loginAlert.type"
        >
          {{
            loginAlert.message
          }}
        </b-alert>
        <b-row class="mt-4">
          <b-col cols="6">
            <div
              role="group"
              class="mb-4"
            >
              <label for="firstName">First Name:</label>
              <b-form-input
                id="firstName"
                v-model="firstName"
                class="pl-0 fs-mask"
                type="text"
                autocomplete="off"
                required
              />
            </div>
          </b-col>

          <b-col cols="6">
            <div
              role="group"
              class="mb-4"
            >
              <label for="lastName">Last Name:</label>
              <b-form-input
                id="lastName"
                v-model="lastName"
                class="pl-0 fs-mask"
                type="text"
                autocomplete="off"
                required
              />
            </div>
          </b-col>
        </b-row>

        <div
          role="group"
          class="mb-4"
        >
          <label for="email">Email Address:</label>
          <b-form-input
            id="email"
            v-model="email"
            class="pl-0 fs-mask"
            type="email"
            :state="emailState"
            autocomplete="off"
            required
          />
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>

        <div
          role="group"
          class="mb-4"
        >
          <label for="password">Password:</label>
          <b-form-input
            id="password"
            v-model="password"
            class="pl-0 fs-mask"
            type="password"
            :state="passwordState"
            required
            autocomplete="off"
          />
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Min. 8 characters
            <!-- Min. 8 characters with at least one capital letter, a number and a
            special character -->
          </b-form-invalid-feedback>
        </div>

        <!-- <div role="group" class="mb-4">
          <label for="cpassword">Confirm Password:</label>
          <b-form-input
            id="cpassword"
            class="pl-0"
            v-model="cpassword"
            type="password"
            :state="cpasswordState"
            required
          ></b-form-input>

          <b-form-invalid-feedback id="input-live-feedback">
            Your password does not match
          </b-form-invalid-feedback>
        </div> -->

        <b-form-checkbox
          id="terms"
          v-model="iAgree"
          name="terms"
        >
          I have read and agree to these
          <a
            :href="termLink"
            @click.prevent="showDialog(termLink)"
          >Terms &amp; Conditions</a>,
          <a
            :href="privacyLink"
            @click.prevent="showDialog(privacyLink)"
          >Privacy Policy</a>, and
          <a
            :href="teleLink"
            @click.prevent="showDialog(teleLink)"
          >Telehealth Consent</a>
        </b-form-checkbox>

        <slot name="login">
          <small class="text-muted d-letter-spacing">
            Already have an account
            <router-link
              v-slot="{ navigate }"
              to="/login"
              tabindex="-1"
              custom
            >
              <a
                href="javascript:void(0)"
                @click="navigate"
              >Log In</a>
            </router-link>
          </small>
        </slot>

        <b-button
          v-if="!dataloading"
          pill
          block
          type="submit"
          variant="info"
          size="lg"
          class="mt-3 mb-3"
        >
          Register
        </b-button>
        <b-button
          v-else
          disabled
          pill
          block
          variant="info"
          size="lg"
          class="mt-3 mb-3"
        >
          Processing
        </b-button>
      </b-form>

      <b-form
        v-if="name == 'forgot-password'"
        class="mt-4"
        @submit.prevent="forgotPassword()"
      >
        <h1
          v-if="resetButton == true"
          class="display-3 mb-3 heading initial-line-height"
        >
          It’s on the way
        </h1>
        <h1
          v-else
          class="display-3 mb-3 heading initial-line-height"
        >
          {{ title }}
        </h1>
        <p
          v-if="resetButton == true"
          class="mb-6 sub-text"
        >
          Please check your inbox and follow the instructions to reset your
          password.
        </p>
        <p
          v-else
          class="mb-6 sub-text"
        >
          {{ subTitle }}
        </p>
        <b-alert
          :show="loginAlert.show"
          :variant="loginAlert.type"
        >
          {{
            loginAlert.message
          }}
        </b-alert>

        <div
          v-if="resetButton == false"
          role="group"
          class="mb-4"
        >
          <label
            for="email"
            class="input-label"
          >Email Address:</label>

          <b-form-input
            id="email"
            v-model="email"
            class="pl-0 fs-mask"
            type="email"
            :state="emailState"
            required
          />

          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>

        <!-- <b-button
          v-if="resetButton == true"
          pill
          to="/login"
          variant="info"
          size="lg"
          class="mb-3"
          style="min-width: 250px"
          >
            <span class="color-white">Login</span>
          </b-button
        > -->
        <b-button
          v-if="!resetButton"
          pill
          type="submit"
          variant="info"
          size="lg"
          class="mb-3"
          style="min-width: 250px"
        >
          Send
        </b-button>

        <p
          v-if="resetButton == true"
          class="sub-text"
        >
          Didn’t receive the link?
          <a
            href="#"
            tabindex="-1"
            class="text-primary"
            @click="resetButton = false"
          >Resend Link</a>
        </p>
        <p
          v-else
          class="sub-text"
        >
          <slot name="goback">
            Do you remember your password?
            <router-link
              v-slot="{ navigate }"
              to="/login"
              tabindex="-1"
              custom
            >
              <a
                href="javascript:void(0)"
                class="text-primary"
                @click="navigate"
              >
                Try logging in
              </a>
            </router-link>
          </slot>
        </p>
      </b-form>

      <b-form
        v-if="name == 'new-password'"
        class="mt-5"
        @submit.prevent="newPassword()"
      >
        <template v-if="changeButton == true">
          <h1 class="display-3 mb-3 heading initial-line-height">
            Your password has been changed
          </h1>
          <p class="sub-text">
            You can now use your new password to log in to your account.
          </p>
        </template>
        <template v-else>
          <h1 class="display-3 mb-3 heading initial-line-height">
            {{ title }}
          </h1>
          <p class="sub-text">
            {{ subTitle }}
          </p>
        </template>
        <b-alert
          :show="loginAlert.show"
          :variant="loginAlert.type"
        >
          {{
            loginAlert.message
          }}
        </b-alert>

        <template v-if="changeButton == false">
          <div
            role="group"
            class="mb-4"
          >
            <label
              for="password"
              class="input-label"
            >New Password:</label>
            <b-form-input
              id="password"
              v-model="password"
              class="pl-0 fs-mask"
              type="password"
              :state="passwordState"
              required
            />
            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Min. 8 characters
              <!-- Min. 8 characters with at least one capital letter, a number and a
              special character -->
            </b-form-invalid-feedback>
          </div>

          <div
            role="group"
            class="mb-4"
          >
            <label
              for="cpassword"
              class="input-label"
            >Confirm Password:</label>
            <b-form-input
              id="cpassword"
              v-model="cpassword"
              class="pl-0 fs-mask"
              type="password"
              :state="cpasswordState"
              required
            />
            <!-- This will only be shown if the preceding input has an invalid state -->
            <b-form-invalid-feedback id="input-live-feedback">
              Your password does not match
            </b-form-invalid-feedback>
          </div>
        </template>

        <b-button
          v-if="changeButton == true"
          style="min-width: 250px"
          pill
          to="/login"
          variant="info"
          size="lg"
          class="mb-3"
        >
          Login
        </b-button>
        <b-button
          v-else
          style="min-width: 250px"
          pill
          type="submit"
          variant="info"
          size="lg"
          class="mb-3"
        >
          Change Password
        </b-button>
        <p
          v-if="!changeButton"
          class="sub-text"
        >
          <slot name="goback">
            Do you remember your password?
            <router-link
              v-slot="{ navigate }"
              to="/login"
              tabindex="-1"
              custom
            >
              <a
                href="javascript:void(0)"
                class="text-primary"
                @click="navigate"
              >Try logging in</a>
            </router-link>
          </slot>
        </p>
      </b-form>

      <b-form
        v-if="name == 'invitation-code'"
        @submit.prevent="invitationCode()"
      >
        <h1 class="display-3 mb-3 initial-line-height">
          {{ title }}
        </h1>
        <p class="text-muted sub-text">
          {{ subTitle }}
        </p>

        <div
          role="group"
          class="mb-4"
        >
          <label for="code">Sign-up code:</label>
          <b-form-input
            id="code"
            v-model="code"
            class="pl-0"
            type="text"
            required
          />
        </div>

        <b-button
          block
          type="submit"
          variant="info"
          size="lg"
          class="mb-3"
        >
          Continue
        </b-button>

        <small class="text-muted">
          Already have an account
          <router-link
            v-slot="{ navigate }"
            to="/login"
            tabindex="-1"
            custom
          >
            <a
              href="javascript:void(0)"
              @click="navigate"
            >Log In</a>
          </router-link>
        </small>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import { io } from 'socket.io-client';
import { AuthService } from '@/services/auth.service';
import { UsersService } from '@/services/users.service';
import { OnboardingService } from '@/services/onboarding.service';
import formValidation from '../form/mixins';
import VueJwtDecode from 'vue-jwt-decode';
import LocalStorageService from '../../localStorage/index';
const localStorageService = LocalStorageService.getService();
import audio from '@/assets/notifications/pop.mp3';
const notificationsound = new Audio(audio);
import doctorNotificationAudioFile from '@/assets/notifications/pristine.mp3';
const doctorNotification = new Audio(doctorNotificationAudioFile);

import $cookies from '@/utils/cookies';

import analyticsMixin from '@/mixins/analytics';
import VueSecureHTML from 'vue-html-secure';

import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';

export default {
  mixins: [formValidation, analyticsMixin, fsaHsaPromoMixin],
  props: {
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    textCenter: {
      type: String,
    },
  },
  data() {
    return {
      // Links
      termLink: `${process.env.VUE_APP_WEBSITE_URL}/terms-conditions/`,
      privacyLink: `${process.env.VUE_APP_WEBSITE_URL}/privacy-policy/`,
      teleLink: `${process.env.VUE_APP_WEBSITE_URL}/telehealth-informed-consent/`,

      dataloading: false,
      firstName: '',
      lastName: '',

      iAgree: false,

      code: '',
      email: '',
      password: '',
      cpassword: '',
      resetButton: false,
      changeButton: false,
      error: '',
      alert: {
        // show: false,
        age: 0,
        type: 'info',
        message: '',
      },
      loginAlert: {
        show: false,
        type: 'warning',
        message: '',
      },
    };
  },
  async mounted() {
    await this.$store.dispatch('setUTMS', this.$route.query);

    this.$_analytics_page();

    if (this.$route.name == 'login' && this.$route.query.promocode) {
      try {
        await OnboardingService.checkCoupon(
          this.$route.query.promocode
        );
        $cookies.set('promocode', this.$route.query.promocode);
      } catch (err) {
        this.showAlert(err);
      }
    }

    if (window.localStorage.getItem('token')) {
      return this.$router.push({ name: 'home' });
    }

    if (this.$route.name == 'login' && this.$route.query.expired == 'true') {
      this.showAlert('Your session has expired. Please log in again.');
    }

    // this.checkInvitationCode();
  },
  methods: {
    removeHTML(str) {
      return VueSecureHTML.removeHTML(str.replace(/<\/p>/g, ' </p>'));
    },
    showDialog(contentLink) {
      const params = {
        content: contentLink,
      };
      if (window.parent) {
        window.parent.postMessage(params, process.env.WEBSITE_URL);
      }
    },
    alertMessage(type, message, age) {
      if (!age) age < 35;
      this.alert = {
        age: age,
        type: type,
        message: message,
      };
    },
    showAlert(message, type = null) {
      if (!type) type = 'warning';
      this.loginAlert = {
        show: true,
        type: 'warning',
        message: message,
      };
      window.scrollTo(0, 0);
    },
    async login() {
      this.loginAlert.show = false;
      this.dataloading = true;
      try {
        const { data: auth } = await AuthService.login({
          email: this.email,
          password: this.password,
        });
        this.error = '';

        this.$_fsaHsaPromo_forceHideBanner();
        window.analytics.identify(auth.userid, {
          email: this.email,
          customerId: auth.userid,
        });

        if (tatari) {
          console.log('Tatari Identify: ', auth.userid);
          tatari.identify(`$${[auth.userid]}`);
        }

        // ! usercustomid is not yet accessible from this location
        await this.$_analytics_track(
          'Login',
          {
            customerId: auth.userid,
          },
        );

        // * FSA HSA sessionPromo
        if(this.$_fsaHsaPromo_isActiveFsaHsaPromo) {
          const couponResult = await OnboardingService.checkCoupon(
            this.$_fsaHsaPromo_sessionPromoValue
          );

          const data = await this.$store.dispatch('onboarding/getPatientOnboarding');
          if (data.onboardingstatus !== 'DONE' && couponResult?.data?.id) {
            await OnboardingService.applyCoupon({
              couponid: this.$_fsaHsaPromo_sessionPromoValue,
            });
            $cookies.remove('promocode')
          }
        }
        else if ($cookies.get('promocode')) {
          if (
            $cookies.get('promocode') == 'BFBLOSSOM40' ||
            $cookies.get('promocode') == 'BFBLOSSOM40FIXED'
          ) {
            // TODO Optimize
            // Do nothing
            // Only apply coupon if it's not for Blossom
            // Hands off the code removal to the onboarding router if the user is still onboarding
          } else {
            try {
              const data = await this.$store.dispatch('onboarding/getPatientOnboarding');
              if (data.onboardingstatus !== 'DONE') {
                await OnboardingService.applyCoupon({
                  couponid: $cookies.get('promocode'),
                });
                $cookies.remove('promocode');
              }
            } catch (err) {
              this.showAlert(err);
            }
          }
        }

        if (this.name !== 'cart') {
          // FS.identify(auth.userid);
          if (auth.onboardingstatus === 'PENDING') {
            this.$router.push({ name: 'onboarding' }).catch(() => {});
          } else if (this.$route.query.redirectTo) {
            this.connectToSocket();
            if (this.$route.query.redirectTo == 'cream_modal') {
              this.$router.push({ name: 'home', query: { upsell: 'cream' } }).catch(() => {});
            } else {
              this.$router.push(this.$route.query.redirectTo).catch(() => {});
            }
          } else {
            this.connectToSocket();
            this.$router.push({ name: 'home' });
          }
        } else {
          window.localStorage.setItem('cartLogin', 1);
          window.location.reload();
        }
        this.dataloading = false;
      } catch (error) {
        this.dataloading = false;
        if (error.message) {
          this.showAlert(error.message);
        }
      }
    },

    connectToSocket() {
      if (localStorageService.getItem('accessToken')) {
        const socket = io(process.env.VUE_APP_WEBSOCKET_URL, {
          auth: {
            token: localStorage.getItem('accessToken'),
          },
          transports: ['websocket', 'polling'],
        });
        this.$store.dispatch('socket/connectToSocket', socket);
        socket.on('connect', () => {
          console.log('Connected to server');
        });
        socket.on('connect_error', err => {
          console.log(err);
          socket.io.opts.transports = ['polling', 'websocket'];
        });

        socket.on('token_expired', async err => {
          console.log(err);
          this.$store.dispatch('socket/closeSocketConnection');
          await AuthService.logout();
        });

        socket.on('request_error', err => {
          console.log(err);
        });

        socket.on('notification', notification => {
          const h = this.$createElement;
          const vNodesMessage = h(
            'div',
            {
              class: ['d-flex', 'p-4'],
              style: 'background: #F4F2FF;',
            },
            [
              h('div', { class: ['ml-3'] }, [
                h('p', { class: ['mb-1'] }, [
                  h('strong', { style: 'font-size: 1rem' }, notification.title),
                ]),
                h(
                  'p',
                  { class: ['mb-0', 'text-muted'], style: 'font-size: 1rem' },
                  notification.description
                ),
              ]),
            ]
          );
          this.$bvToast.hide('system-notification');
          this.$bvToast.toast([vNodesMessage], {
            visible: true,
            id: 'system-notification',
          });
          notificationsound.play();
        });

        socket.on('message', message => {
          if (
            this.$route.name !== 'message-doctor' &&
            message.sender.id !== message.patientId
          ) {
            this.$store.dispatch('message/incrementDoctorMessagesCount');
            doctorNotification.play();
          }
        });

        socket.on('admin_chat:message', message => {
          console.log('Form Listener: Received message')
          if (
            this.$route.name !== 'message-admin' &&
            message.sender.id !== message.patientId
          ) {
            this.$store.dispatch('message/incrementAdminMessagesCount');
            const h = this.$createElement;
            const vNodesMessage = h(
              'div',
              {
                class: ['d-flex', 'p-4', 'position-relative'],
                style:
                  'background: #F4F2FF; cursor: pointer; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); border-radius: 0.3rem;',
                on: {
                  click: () => {
                    this.$router.push('/messages/admin');
                    this.$bvToast.hide('notification');
                  },
                },
              },
              [
                h(
                  'b-avatar',
                  { style: 'background: #ece8f5; color: black' },
                  message.sender?.firstName?.charAt(0)
                ),
                h('div', { class: ['ml-3'] }, [
                  h('p', { class: ['mb-1'] }, [
                    h(
                      'strong',
                      { style: 'font-size: 1rem' },
                      `New message from ${message.sender.firstName} ${message.sender.lastName}`
                    ),
                  ]),
                  h(
                    'p',
                    { class: ['mb-0', 'text-muted'], style: 'font-size: 1rem' },
                    message.message.length <= 85
                      ? this.removeHTML(message.message)
                      : `${this.removeHTML(message.message).substring(0, 85 - 3)}...`
                  ),
                ]),
              ]
            );
            this.$bvToast.hide('notification');
            this.$bvToast.toast([vNodesMessage], {
              visible: true,
              noCloseButton: true,
              noAutoHide: true,
              id: 'notification',
            });
            notificationsound.play();
          }
        });
      }
    },

    async signUp() {
      this.loginAlert.show = false;
      this.dataloading = true;
      try {
        if (this.iAgree) {
          if (!this.firstName && !this.lastName) {
            // from get started
            if (this.password !== this.cpassword) {
              return this.alertMessage('warning', 'Password did not match');
            }
            const data = {
              email: this.email,
              password: this.password,
              role: 'PATIENT',
            };

            const { data: auth } = await AuthService.signup(data);
            AuthService.setBearer(auth.idToken);

            this.$router.push('/health-questions').catch(() => {});
          } else {
            // from website

            const { data: auth } = await AuthService.signupNonRx({
              firstname: this.firstName,
              lastname: this.lastName,
              email: this.email,
              password: this.password,
            });

            AuthService.setBearer(auth.idToken);
            this.cart = '';
            window.localStorage.setItem('cartLogin', 1);
            window.location.reload();
          }
        } else {
          this.showAlert(
            'Please state if you agree to our Terms & Conditions, Privacy Policy, and Telehealth Consent'
          );
        }
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      } finally {
        this.dataloading = false;
      }
    },

    invitationCode() {
      window.localStorage.setItem('code', this.code);
      this.$router.push('/signup').catch(() => {});
    },

    async forgotPassword() {
      this.loginAlert.show = false;
      try {
        await UsersService.forgotPassword(this.email);
        this.resetButton = true;
      } catch (error) {
        return this.showAlert(error.message);
      }
    },

    async newPassword() {
      this.loginAlert.show = false;
      try {
        // this.changeButton = true
        if (this.password !== this.cpassword) {
          return this.showAlert('Password did not match');
        }

        const jwttoken = this.$route.query.v;
        const _code = this.$route.query.c;
        const decoded = VueJwtDecode.decode(jwttoken);

        const confirmpassword = {
          email: decoded.username,
          code: _code,
          password: this.password,
        };
        //console.log('confirmpassword', confirmpassword)
        await UsersService.confirmPassword(confirmpassword);
        this.changeButton = true;
      } catch (error) {
        if (error.message) {
          return this.showAlert(error.message);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  text-transform: uppercase;
}

.color-white {
  color: #fff !important;
}
</style>
