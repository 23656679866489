<template>
  <b-container
    v-show="!isFetchingOnboardingStatus"
    class="p-0"
    fluid
  >
    <top-bar
      :id="id"
      :username="name"
      :profile-pic="profileUrl"
      class="topbar tw-block"
    >
      <template #top>
        <portal-target name="bfcm" />
      </template>
      <template #bottom>
        <FailedPaymentAlert />
      </template>
    </top-bar>
    <b-container
      fluid
      :class="[$route.name == 'messages' ? 'p-0' : 'pl-0 pr-0']"
      style="overflow: hidden"
      :style="{ marginTop: `${topBarHeight}px` }"
    >
      <vue-page-transition>
        <router-view />
      </vue-page-transition>
    </b-container>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import TopBar from '@/components/partials/Navbar';
import FailedPaymentAlert from '@/components/dashboard/FailedPaymentAlert';
import { OnboardingService } from '@/services/onboarding.service';
import LocalStorageService from '@/localStorage';
const localStorageService = LocalStorageService.getService();

export default {
  name: 'Home',
  components: {
    TopBar,
    FailedPaymentAlert
    // Sidebar
  },
  data() {
    return {
      name: '',
      id: '',
      profileUrl: '',
      isFetchingOnboardingStatus: false,
      intervalFunction: null,

      topBarHeight: 0,
      resizeObserver: null,
    };
  },
  computed: {
    ...mapGetters('user', ['getFailedTransactionReason']),
  },
  watch: {
    $route(to, from) {
      if (from.name == 'messages' && to.name != 'messages') {
        window.Intercom('update', {
          hide_default_launcher: false,
        });
      }
    },
  },
  async mounted() {
    if (!localStorageService.getItem('token') && this.$route.path != '/login') {
      return this.pushRouteTo(`/login?redirectTo=${this.$route.path}`);
    }

    if (window.localStorage.getItem('token')) {
      try {
        this.createResizeObserver();
        this.isFetchingOnboardingStatus = true;
        const data = await this.$store.dispatch('onboarding/getPatientOnboarding');
      
        // * Share a Sale handling
        if (data.hasOwnProperty('shareasaleclickid')) {
          if ($cookies.get('sas_m_awin')) {
            if (data.shareasaleclickid != $cookies.get('sas_m_awin')) {
              await OnboardingService.sendSASClickId(
                $cookies.get('sas_m_awin').clickId
              );
              $cookies.remove('sas_m_awin');
            }
          }
        } else {
          if ($cookies.get('sas_m_awin')) {
            await OnboardingService.sendSASClickId(
              $cookies.get('sas_m_awin').clickId
            );
            $cookies.remove('sas_m_awin');
          }
        }

        if (data.onboardingstatus === 'PENDING') {
          return this.$router.push({ name: 'onboarding' }).catch(() => {});
        }
        this.isFetchingOnboardingStatus = false;
      } catch (err) {
        if (err.message == 'Invalid token') {
          localStorageService.clearToken();
          this.$router.push('/login');
        }
      }
    }

    await this.getUser();
    this.getOnboardingStatus();
    this.intervalFunction = setInterval(async () => {
      await this.fetchUnreadMessageCount();
    }, 300000);
    this.fetchUnreadMessageCount();
  },
  beforeDestroy() {
    this.destroyResizeObserver();
  },
  methods: {
    createResizeObserver() {
      this.resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          const style = getComputedStyle(entry.target);
          const paddingTop = parseFloat(style.paddingTop);
          const paddingBottom = parseFloat(style.paddingBottom);
          this.topBarHeight = entry.contentRect.height + paddingTop + paddingBottom;
        }
      });
      this.resizeObserver.observe(document.querySelector('.topbar'));
    },
    destroyResizeObserver() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      }
    },
    async getUser() {
      try {
        const data = await this.$store.dispatch('user/getUser');

        if (data.id) {
          this.id = data.id;
        }
        if (data.firstname && data.lastname) {
          this.name = data.firstname + ' ' + data.lastname;
        } else {
          this.name = data.email;
        }
        this.profileUrl = data.profileUrl;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchUnreadMessageCount() {
      await this.$store.dispatch('message/getUnreadMessageCount');
      await this.$store.dispatch('message/getAdminUnreadMessageCount');
    },
    async getOnboardingStatus() {
      try {
        const { data } = await OnboardingService.getPatientOnboarding();
        if (
          data.hasOwnProperty('hasorder') &&
          data.hasorder &&
          data.onboardingstatus == 'PENDING'
        ) {
          return;
        }
        if (
          data.hasOwnProperty('birthday') &&
          data.onboardingstatus == 'PENDING' &&
          !data.hasOwnProperty('nonrxmedicationonly')
        ) {
          this.pushRouteTo('/');
        }
      } catch (err) {
        if (err.message == 'Invalid token') {
          localStorageService.clearToken();
          this.$router.push('/login');
        }
      }
    },
    pushRouteTo(route) {
      // if sending path:
      if (this.$route.path != route) {
        this.$router.push(route);
      }
    },
  },
};
</script>