<template>
  <b-card
    class="upsell-card"
    :style="{
      'background-image': imageWithOverlay,
    }"
  >
    <div
      class="d-flex flex-column flex-lg-row"
      style="height: 100%"
    >
      <div class="ml-3 my-5 mr-3 ml-lg-5 mr-lg-0">
        <div class="d-flex justify-content-between d-lg-block">
          <div class="d-inline d-lg-block">
            <h1
              style="font-family: 'hatton-medium'"
              class="tw-w-[200px] sm:tw-w-full"
            >
              <span v-html="upsellDisplayData[variant].title"></span>
            </h1>
            <p
              v-if="upsellItem.metadata.bfcm === 'true'"
              class="tw-mb-1"
            >
              {{ upsellPrice }} - limited time only
            </p>
            <p
              v-else
              class="tw-mb-1"
            >
              <s
                v-if="upsellItem.firstorder || upsellItem.upsellcode === 'BLOSSOM'"
                class="tw-text-[#777]"
              >{{
                upsellItem.metadata.strikethroughprice
              }}</s>
              {{ upsellPrice }}
              {{ upsellItem.metadata.upsellpriceunit }}
            </p>
            <p
              v-if="upsellItem.upsellcode === 'CREAM'"
              class="tw-text-[#1a6a72]"
            >
              <template v-if="upsellItem.metadata.bfcm === 'true'">
                Auto-refilled at normal price of {{ upsellItem.metadata.strikethroughprice }}
              </template>
              <template v-else-if="upsellItem.firstorder">
                Auto-refilled, subsequent orders at full price of {{ upsellItem.metadata.strikethroughprice }}
              </template>
              <template v-else>
                Auto-refilled
              </template>
            </p>
            <p
              v-else
              class="tw-text-[#1a6a72]"
            >
              One time purchase. Subsequent orders at full price of {{ upsellItem.metadata.strikethroughprice }}
            </p>
          </div>

          <!-- Badge - Mobile -->
          <div
            v-if="upsellItem.metadata.bfcm === 'true'"
            class="m-4 d-flex d-lg-none"
          >
            <BFCMBadge
              :upsell-price="upsellPrice"
              :variant="variant"
              class="tw-scale-75 -tw-top-12 -tw-right-8 tw-size-[180px] tw-rotate-[20deg] tw-absolute tw-z-20"
            />
          </div>
          <div
            v-else
            class="round-badge d-flex flex-column justify-content-center align-items-center d-lg-none text-white"
            :class="[variant === 'purple' ? 'round-badge__purple' : '']"
          >
            <s
              v-if="upsellItem.firstorder || upsellItem.upsellcode === 'BLOSSOM'"
              class="tw-text-[#777] badge-strike"
            >{{
              upsellItem.metadata.strikethroughprice
            }}</s>
            <span class="badge-price">
              {{ upsellPrice }}
            </span>
            <span class="text-center badge-subtitle">
              {{ upsellItem.metadata.upsellpriceunit }}
            </span>
          </div>
        </div>
        <p class="description">
          {{ upsellItem.description.text }}
        </p>

        <b-button
          variant="info"
          size="lg"
          pill
          class="px-5 my-3 d-none d-sm-block tw-font-light slight-rounded"
          :disabled="processing"
          @click="$emit('order', upsellItem)"
        >
          {{ buttonText }}
        </b-button>
        <b-button
          variant="info"
          size="lg"
          block
          pill
          class="px-5 my-3 d-block d-sm-none tw-font-light slight-rounded"
          :disabled="processing"
          @click="$emit('order', upsellItem)"
        >
          {{ buttonText }}
        </b-button>
        <a
          :href="learnMoreLink"
          class="upsell-link text-uppercase align-middle text-underline"
          target="_blank"
        >
          Learn more about <span>{{ upsellDisplayData[variant].name }}</span>
          <span class="next-arrow-purple align-middle"></span>
        </a>
      </div>
      <div
        v-if="upsellItem.metadata.bfcm === 'true'"
        class="m-4 d-none d-lg-flex"
      >
        <BFCMBadge
          class="tw-size-[180px] tw-rotate-[20deg] tw-absolute -tw-top-7 -tw-right-7 tw-z-20"
          :upsell-price="upsellPrice"
          :variant="variant"
        />
      </div>
      <div
        v-else
        class="m-4 d-none d-lg-flex"
      >
        <!-- Badge - Desktop -->
        <div
          class="round-badge text-white d-flex flex-column justify-content-center align-items-center align-items-center"
          :class="[
            variant === 'purple' ? 'round-badge__purple align-self-end' : '',
          ]"
        >
          <s
            v-if="upsellItem.firstorder || upsellItem.upsellcode === 'BLOSSOM'"
            class="tw-text-[#777] badge-strike"
          >{{
            upsellItem.metadata.strikethroughprice
          }}</s>
          <span class="badge-price mt-2">
            {{ upsellPrice }}
          </span>
          <div
            class="text-center badge-subtitle"
            style="margin: 0 auto; "
          >
            {{ upsellItem.metadata.upsellpriceunit }}
          </div>
        </div>
      </div>
      <div
        class="pt-8 pb-6 mobile-image"
        :style="{
          'margin-top': 'auto',
          'background-image': imageWithOverlayMobile,
        }"
      ></div>
    </div>
  </b-card>
</template>

<script>
// services
import { PrescriptionService } from '@/services/prescription.service';

// assets
import creamImage from '@/assets/images/web_Winona_r1_03F_83231.svg';
import blossomImage from '@/assets/images/web_winona_r2_32817_1.svg';
import BFCMBadge from '@/components/dashboard/bfcm/BFCMBadge.vue';

export default {
  name: 'UpsellDisplayCard',
  components: {
    BFCMBadge
  },
  props: {
    processing: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'green',
    },
    upsellItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      upsellDisplayData: {
        green: {
          name: 'The Cream',
          title: 'Rx Estriol Cream <br> with Tretinoin',
        },
        purple: {
          name: 'Blossom',
          title: 'Rx Arousal Cream (Blossom)',
        },
      },
    };
  },
  computed: {
    imageWithOverlay() {
      const url = this.getBackgroundImage();
      const overlay = '-webkit-linear-gradient(left, rgba(247, 247, 247, 0.9) 45%, rgba(247, 247, 247, 0.0) 70%), ';

      return overlay + 'url(' + url + ')';
    },
    imageWithOverlayMobile() {
      const url = this.getBackgroundImage();
      const overlay = '-webkit-linear-gradient(top, rgba(247, 247, 247, 1.0) 0%,rgba(247, 247, 247, 0.0) 20%), ';
      return overlay + 'url(' + url + ')';
    },
    upsellPrice() {
      if (this.upsellItem.firstorder) {
        return this.upsellItem.metadata.firsttime_upsellprice || this.upsellItem.metadata.upsellprice;
      }

      return this.upsellItem.metadata.upsellprice;
    },
    buttonText() {
      if (this.upsellItem.metadata.bfcm === 'true') {
        return 'Get Deal';
      }
      return this.upsellItem.firstorder ? 'Order now' : 'Order again';
    },
    learnMoreLink() {
      if (this.upsellItem.metadata.bfcm === 'true') {
        return process.env.VUE_APP_SERVER === 'production' ? 'https://bywinona.com/product/estriol-cream-with-tretinoin' : 'https://staging-nuxt3.bywinona.com/product/estriol-cream-with-tretinoin'
      }

      return this.upsellItem.metadata.new_url;
    },
  },
  methods: {
    getBackgroundImage() {
      if (this.variant === 'purple') {
        return blossomImage;
      } else {
        return creamImage;
      }
    }
  },
};
</script>

<style lang="scss">
.upsell-card {
  text-align: left;
  height: 100%;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-color: rgb(247, 247, 247);

  @include media-breakpoint-up(md) {
    background-position: 100% 50%;
  }

  @include media-breakpoint-down(md) {
    background-image: none !important;
  }

  .card-body {
    padding: 0 !important;
  }

  .mobile-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  h1 {
    font-size: 2rem;
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
    }
    margin-bottom: 1rem;
  }

  .body {
    padding: 1rem;
    @include media-breakpoint-up(lg) {
      padding: 3rem 0 3rem 4rem;
    }
  }

  .description {
    font-size: 1.25rem;
    margin-top: 1rem;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
  }

  .upsell-link {
    font-family: "Atten New";
    font-weight: 400;
    font-size: 1rem;
    @include media-breakpoint-down(lg) {
      font-size: 0.75rem;
    }
    letter-spacing: 2px;
  }

  .round-badge {
    @include media-breakpoint-up(lg) {
      height: 150px;
      width: 150px;
    }
    @include media-breakpoint-down(lg) {
      height: 75px;
      width: 75px;
      min-width: 75px;
    }

    background-color: $primary;
    display: block;
    position: relative;
    border-radius: 50%;
    text-align: center;

    &__purple {
      background-color: #2b2660;
    }

    -webkit-transform: rotate(6deg);
    -moz-transform: rotate(6deg);
    -o-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
    transform: rotate(6deg);

    .badge-strike {
      @include media-breakpoint-up(lg) {
        font-size: 1.75rem;
        height: 20px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 0.75rem;
        height: 10px;
      }
    }

    .badge-price {
      @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
        height: 50px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 1.25rem;
        height: 27px;
      }
    }

    .badge-subtitle {
      @include media-breakpoint-up(lg) {
        font-size: 0.75rem;
        width: 70px;
      }
      @include media-breakpoint-down(lg) {
        font-size: 0.5rem;
        width: 50px;
      }
    }
  }
}
</style>
