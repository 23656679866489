<template>
  <div class="eligibility-sex">
    <h2 class="question-title with-subtext">
      What was the sex assigned to you at birth?
    </h2>
    <p class="sub-text group-mb">
      Your sex assignment helps us to determine if you are eligible for
      treatment.
    </p>
    <w-checkbox
      id="sex-female"
      v-model="sex"
      class="fs-exclude"
      type="radio"
      value="Female"
      label="Female"
      @change="submitAnswer"
    />
    <w-checkbox
      id="sex-male"
      v-model="sex"
      class="fs-exclude"
      type="radio"
      value="Male"
      label="Male"
      @change="submitAnswer"
    />

    <div v-if="sex">
      <img
        src="@/assets/images/cro/hsa-fsa-eligible-badge.png"
        alt=""
        class="tw-h-[18px] tw-mx-auto"
      >
    </div>

    <OnboardingCTAContainer>
      <div
        v-if="sex"
        class="tw-w-full tw-flex tw-flex-col tw-gap-2"
      >
        <b-button
          class="slight-rounded"
          pill
          variant="info"
          @click="submitAnswer"
        >
          Continue
        </b-button>
      </div>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
// components
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer.vue';
import analyticsMixin from '@/mixins/analytics';

export default {
  components: {
    OnboardingCTAContainer,
  },
  mixins: [analyticsMixin],
  props: ['age', 'onboardingflow', 'preAnswer'],
  data() {
    return {
      sex: '',
    };
  },
  async mounted() {
    if (this.preAnswer) {
      this.sex = this.preAnswer;
    }

    await this.$_analytics_track(
      'Onboarding Screen Viewed',
      {
        screen: '1.3-A',
        onboardingflow: this.onboardingflow,
      },
    );
  },
  methods: {
    async submitAnswer() {
      this.$emit('setParams', 'gender', this.sex);
      if (this.sex == 'Male') {
        this.$emit('setStep', 'male');

        await this.$_analytics_track(
          'Onboarding Screen Viewed',
          {
            screen: '1.3.2-A',
            onboardingflow: this.onboardingflow,
          },
        );
      } else if (this.sex == 'Female' && this.age < 35) {
        this.$emit('setStep', 'surgery');
        await this.$_analytics_track(
          'Onboarding Screen Viewed',
          {
            screen: '1.3.1-A',
            onboardingflow: this.onboardingflow,
          },
        );
      } else {
        // this.$emit('showLoader', 4);
        this.$emit('checkToken');
        this.$emit('setStep', 'registration');
      }
    },
  },
};
</script>

<style></style>
