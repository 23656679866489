<template>
  <b-navbar
    id="navbar"
    toggleable="sm"
    fixed="top"
    class="tw-p-0 tw-border-none"
  >
    <slot name="top"></slot>
    <b-container
      fluid
      class="navigator tw-px-2 tw-py-3"
    >
      <b-navbar-brand to="/">
        <img
          src="@/assets/images/logo_black.svg"
          alt="Winona logo"
        >
      </b-navbar-brand>
      <b-navbar-nav
        class="d-none d-sm-none d-md-flex tw-pl-[40px]"
      >
        <b-nav-item
          v-for="(list, i) in navList"
          :key="i + '3'"
          :to="list.link"
          exact
          exact-active-class="active"
          class="px-3"
        >
          <span class="d-flex align-items-center">
            <span class="d-flex justify-space-between align-items-center min-h-[1.125rem]">
              <span class="d-block">{{ list.name }}</span>
              <b-badge
                v-if="list.badge"
                pill
                class="ml-2 tw-bg-tangerine-dream text-white tw-px-2 tw-py-1"
              >
                {{ list.badge }}
              </b-badge>
            </span>
          </span>
        </b-nav-item>
      </b-navbar-nav>
      <div class="navbar-user ml-center">
        <notification />
        <b-dropdown
          variant="link"
          right
          no-caret
          :class="['user-dropdown']"
        >
          <template #button-content>
            <span
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              class="dropdown-toggle !tw-p-0"
            >
              <div class="position-relative">
                <b-avatar
                  variant="primary"
                  :text="username && username.charAt(0)"
                  class="tw-mr-3"
                />
                <b-badge
                  v-if="totalUnreadMessages > 0"
                  pill
                  class="position-absolute d-md-none d-flex justify-content-center align-items-center tw-bg-tangerine-dream tw-text-white tw-leading-[11px] tw-right-[2px]"
                >
                  {{ totalUnreadMessages }}
                </b-badge>
              </div>
              <h4 class="mb-0 pr-2 text-uppercase d-none d-sm-none d-md-none d-lg-flex fs-mask">
                {{ username }}
              </h4>
              <b-icon
                icon="caret-down-fill"
                font-scale="0.75"
              />
            </span>
          </template>
          <b-dropdown-item
            v-for="(list, i) in navList"
            :key="i + '3'"
            class="d-md-none"
            :to="list.link"
            exact
            exact-active-class="active"
          >
            <span class="d-flex align-items-center">
              <span class="tw-leading-[14px]">{{ list.name }}</span>
              <b-badge
                v-if="list.badge"
                pill
                class="ml-2 tw-bg-tangerine-dream tw-text-white tw-leading-[11px]"
              >
                {{ list.badge }}
              </b-badge>
            </span>
          </b-dropdown-item>
          <b-dropdown-divider class="d-md-none" />
          <b-dropdown-item
            v-for="(a, i) in accountList"
            :key="i + '1'"
            :to="a.link"
            exact
            exact-active-class="active"
          >
            {{ a.name }}
          </b-dropdown-item>
          <b-dropdown-divider class="d-md-none" />
          <b-dropdown-item
            exact
            exact-active-class="active"
            :to="{ name: 'insurance-documents' }"
          >
            Insurance Documents
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="logout">
            Logout
          </b-dropdown-item>
          <HSAReceiptDialog
            :id="id"
            ref="HSAReceiptDialog"
            :name="username"
          />
        </b-dropdown>
      </div>
    </b-container>
    <slot name="bottom"></slot>
  </b-navbar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Notification from '@/components/partials/Notification';
import { AuthService } from '@/services/auth.service';
import HSAReceiptDialog from '@/components/dashboard/HSAReceiptDialog';
export default {
  components: {
    Notification,
    HSAReceiptDialog,
  },
  props: {
    username: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      accountList: [
        {
          name: 'Your Account',
          link: '/your-account',
        },
        {
          name: 'Medical Information',
          link: '/medical-information',
        },
      ],
    };
  },

  computed: {
    ...mapState('message', ['unreadMessagesCount', 'unreadAdminMessagesCount']),
    ...mapGetters('configuration', ['isUserSupportsAdminPatientChat']),
    totalUnreadMessages() {
      return (
        (Number(this.unreadMessagesCount) || 0) +
        (Number(this.unreadAdminMessagesCount) || 0)
      );
    },
    navList() {
      return [
        {
          name: 'Home',
          link: '/',
        },
        {
          name: 'Treatment',
          link: '/treatment',
        },
        {
          name: 'Messages',
          link: this.isUserSupportsAdminPatientChat
            ? '/messages'
            : '/messages/doctor',
          badge: this.totalUnreadMessages,
        },
        {
          name: 'Support',
          link: '/support',
        },
        {
          name: 'Share',
          link: '/referral',
        },
      ];
    },
  },
  methods: {
    async logout() {
      try {
        this.$store.dispatch('socket/closeSocketConnection');
        await AuthService.logout();
      } catch (error) {
        console.log('error', error.message);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .nav-link.dropdown-toggle,
::v-deep .btn-link.dropdown-toggle {
  padding: 0;
}

::v-deep .btn-link.dropdown-toggle {
  color: inherit;
  text-align: left;
}

::v-deep .btn-link.dropdown-toggle .text-uppercase {
  font-weight: 700;
  letter-spacing: 1.5px;
  font-size: inherit;
}
</style>

<style lang="scss" scoped>
.navbar {
  @include media-breakpoint-up(xxl) {
    padding: 1.75rem 1rem !important;
  }
}

@media (max-width: 320px) {
  .navbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.navbar-nav .nav-link.active:before {
  @include media-breakpoint-up(xxl) {
    bottom: calc(-1.75rem - 1px) !important;
  }
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link {
  color: $text-color;
}

.dark-bg {
  box-shadow: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.dark-bg .navbar-nav .nav-link:hover {
  opacity: 0.85;
}

.dark-bg .navbar-nav .nav-link:hover,
.dark-bg .navbar-nav .nav-link.active,
.dark-bg .navbar-nav .nav-link {
  color: $secondary;
}

.dark-bg .navbar-nav .nav-link.active:before {
  border-color: $secondary;
}

.is-support {
  .badge-primary {
    background-color: $secondary;
    color: $primary;
  }
}

@media (min-width: 767px) {
  .navigator {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .navbar-expand-sm .navbar-collapse {
    flex: 1 0 0;
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .navbar-user.ml-auto {
    margin-left: inherit !important;
  }

  .navigator {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.nav-link {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  font-size: 14px;
  text-decoration: none !important;

  @include media-breakpoint-up(xxl) {
    font-size: 14px * $scale-font;
  }
}

.dropdown-toggle {
  display: flex;
  align-items: center;

  &::after {
    margin-left: 15px;
  }
}
</style>
