// modiles
import Vue from 'vue';
import Vuex from 'vuex';
import axiosRetry from 'axios-retry';
import destr from 'destr'
import {
  browserName,
  browserVersion,
  deviceDetect,
} from 'mobile-device-detect';
import DeviceDetector from 'device-detector-js';

// axios
import axios from '@/axios';

// modules
import modules from './modules';

// localstorage
import LocalStorageService from '@/localStorage';

// utils
import $cookies from '@/utils/cookies';

// constants
import { COOKIES } from '@/constants';

// services
const localStorageService = LocalStorageService.getService();

Vue.use(Vuex);

axiosRetry(axios, {
  retries: 1,
  shouldResetTimeout: true,
  retryCondition: () => true,
  retryDelay: retryCount => {
    return retryCount * 3000;
  },
});

export default new Vuex.Store({
  state: {
    website: process.env.VUE_APP_WEBSITE_URL,
    errorlog: process.env.VUE_APP_ERROR_LOG_URL,
  },
  mutations: {},
  actions: {
    async getEverflowDetails() {
      if (typeof EF != 'undefined') {
        const transactionId = EF.getAdvertiserTransactionId(1);
        if (EF && transactionId) {
          return {
            type: 'everflow',
            id: transactionId,
          };
        } else {
          return {};
        }
      }
    },
    async getImpactId() {
      const impactId = Vue.$cookies.get('irclickid');
      if (impactId) {
        return {
          type: 'impactRadius',
          id: impactId,
        }
      } else {
        return {};
      }
    },
    async sendErrorLog(state, data) {
      if (data.error_log.status == 400) {
        return
      }

      const errorDetails =
        data.error_log.status == 500
          ? `Server error -- Log ID: ${data.error_log.logid}`
          : data.error_log.message;

      const cusId = data?.cus_id ? data.cus_id : 'No ID (Unregistered)';
      const email = data?.email ? data.email : 'No Email (Unregistered)';

      const currentDate = new Date();

      const eventObject = {
        event: 'ONBOARDING_ERROR',
        created_at: currentDate,
        referring_step: data.step,
        customerid: cusId,
        email: email,
        error_log: errorDetails,
        environment: process.env.VUE_APP_SERVER,
      };

      if (process.env.VUE_APP_SERVER == 'local') {
        eventObject.environment = 'STAGING';
      }
      const requestOptions = {
        method: 'post',
        url: process.env.VUE_APP_ERROR_LOG_URL,
        data: eventObject,
      };

      if (typeof errorDetails == 'String') {
        eventObject.error_log = {
          id: 'clientside_error',
          message: errorDetails,
        };
      }

      // * Comment this out before deploying-- This is for testing only
      console.log(eventObject);

      try {
        await axios.request(requestOptions);
      } catch (err) {
        console.error(err);
      }
    },
    async getFBQParams() {
      const fbParams = {};
      try {
        const fbp = await $cookies.get('_fbp');
        const fbc = await $cookies.get('_fbc');
        if (fbp) {
          fbParams.fbp = fbp;
        }
        if (fbc) {
          fbParams.fbc = fbc;
        }
      } catch (err) {
        console.log(err)
      }
      return fbParams;
    },
    async getVoluumDetails() {
      // Set to store function in case more details are needed/set to a separate property
      const clickid = $cookies.get('taboolaClickID');
      const ret = {};
      if (clickid) {
        ret.clickID = clickid.taboolaClickID;
      }
      return ret;
    },
    async getDeviceDetails() {
      try {
        const detect = deviceDetect();
        let device = 'Desktop';
        if (detect.isMobileOnly != undefined && detect.isMobileOnly === true) {
          device = 'Mobile';
        } else if (detect.isTablet != undefined && detect.isTablet === true) {
          device = 'Tablet';
        } else if (detect.isMobile != undefined && detect.isMobile === true) {
          device = 'Mobile';
        } else if (detect.isSmartTV != undefined && detect.isSmartTV === true) {
          device = 'SmartTV';
        } else if (
          detect.isWearable != undefined &&
          detect.isWearable === true
        ) {
          device = 'Wearable';
        }

        const deviceDetector = new DeviceDetector();
        const detectedDevice = deviceDetector.parse(navigator.userAgent);

        const hardwareModel = `${detectedDevice.device.brand} ${detectedDevice.device.model}`;

        let campaign = undefined;
        const winonaUtms = destr($cookies.get('winona_utms')) || undefined;

        if (
          winonaUtms != undefined &&
          winonaUtms.constructor.name === 'Object'
        ) {
          const utmName = winonaUtms.utm_name || winonaUtms.utm_campaign || undefined;
          const utmSource = winonaUtms.utm_source || undefined;
          const utmMedium = winonaUtms.utm_medium || undefined;
          const utmCampaign = winonaUtms.utm_campaign || undefined;
          const utmTerm = winonaUtms.utm_term || undefined;
          const utmContent = winonaUtms.utm_content || undefined;

          if (
            utmName != undefined ||
            utmSource != undefined ||
            utmMedium != undefined ||
            utmCampaign != undefined ||
            utmTerm != undefined ||
            utmContent != undefined
          ) {
            campaign = {
              name: utmName,
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              term: utmTerm,
              content: utmContent,
            };
          }
        }

        let firsttouchcampaign = undefined;
        const winonaFirstTouchUtms = destr($cookies.get('winona_firsttouch_utms')) || undefined;

        if (
          winonaFirstTouchUtms != undefined &&
          winonaFirstTouchUtms.constructor.name === 'Object'
        ) {
          const utmName = winonaFirstTouchUtms.utm_name || undefined;
          const utmSource = winonaFirstTouchUtms.utm_source || undefined;
          const utmMedium = winonaFirstTouchUtms.utm_medium || undefined;
          const utmCampaign = winonaFirstTouchUtms.utm_campaign || undefined;
          const utmTerm = winonaFirstTouchUtms.utm_term || undefined;
          const utmContent = winonaFirstTouchUtms.utm_content || undefined;

          if (
            utmName != undefined ||
            utmSource != undefined ||
            utmMedium != undefined ||
            utmCampaign != undefined ||
            utmTerm != undefined ||
            utmContent != undefined
          ) {
            firsttouchcampaign = {
              name: utmName,
              source: utmSource,
              medium: utmMedium,
              campaign: utmCampaign,
              term: utmTerm,
              content: utmContent,
            };
          }
        }

        const ret = {
          browser: browserName,
          browserVersion: browserVersion,
          os: detect.osName != undefined ? detect.osName : detect.os,
          os_version: detect.osVersion,
          device: device,
          campaign: campaign,
          firsttouchcampaign: firsttouchcampaign,
        };

        if (device != 'Desktop') {
          ret.hardwareModel = hardwareModel;
        }

        return ret;
      } catch (err) {
        console.log(err);
      }
    },

    async getDeviceDetailsForContext() {
      try {
        const detect = deviceDetect();
        let device = 'Desktop';
        if (detect.isMobileOnly != undefined && detect.isMobileOnly === true) {
          device = 'Mobile';
        } else if (detect.isTablet != undefined && detect.isTablet === true) {
          device = 'Tablet';
        } else if (detect.isMobile != undefined && detect.isMobile === true) {
          device = 'Mobile';
        } else if (detect.isSmartTV != undefined && detect.isSmartTV === true) {
          device = 'SmartTV';
        } else if (
          detect.isWearable != undefined &&
          detect.isWearable === true
        ) {
          device = 'Wearable';
        }

        const ret = {
          browserVersion: browserVersion,
          browser: browserName,
          device: device,
        };

        const winonaUtms = destr($cookies.get('winona_utms')) || undefined;
        let winonaFirstTouchUtms = destr($cookies.get('winona_firsttouch_utms')) || undefined;

        if (winonaUtms === undefined || winonaFirstTouchUtms === undefined) {
          const nobackendutm = $cookies.get('nobackendutm') || undefined;
          const nobackendfirsttouchutm = $cookies.get('nobackendfirsttouchutm') || undefined;

          if (
            nobackendutm === undefined ||
            nobackendfirsttouchutm === undefined
          ) {
            const sessionutm = await this.dispatch('getSessionUTM');
            const firsttouch = await this.dispatch('getFirstTouchUTM');

            const parsedutm = {};
            if (sessionutm['utm-content']) {
              parsedutm.utm_content = sessionutm['utm-content'];
            }
            if (sessionutm['utm-campaign']) {
              parsedutm.utm_campaign = sessionutm['utm-campaign'];
            }
            if (sessionutm['utm-source']) {
              parsedutm.utm_source = sessionutm['utm-source'];
            }
            if (sessionutm['utm-medium']) {
              parsedutm.utm_medium = sessionutm['utm-medium'];
            }
            if (sessionutm['utm-term']) {
              parsedutm.utm_term = sessionutm['utm-term'];
            }

            const parsedftutm = {};
            if (firsttouch['utm-content']) {
              parsedftutm.utm_content = firsttouch['utm-content'];
            }
            if (firsttouch['utm-campaign']) {
              parsedftutm.utm_campaign = firsttouch['utm-campaign'];
            }
            if (firsttouch['utm-source']) {
              parsedftutm.utm_source = firsttouch['utm-source'];
            }
            if (firsttouch['utm-medium']) {
              parsedftutm.utm_medium = firsttouch['utm-medium'];
            }
            if (firsttouch['utm-term']) {
              parsedftutm.utm_term = firsttouch['utm-term'];
            }

            if (
              Object.keys(parsedutm).length > 0 ||
              Object.keys(parsedftutm).length > 0
            ) {
              const strCookie = JSON.stringify(parsedutm);
              $cookies.set('winona_utms', strCookie);

              const strCookieFirstTouch = JSON.stringify(parsedftutm);
              $cookies.set('winona_firsttouch_utms', strCookieFirstTouch);
              winonaFirstTouchUtms = parsedftutm;
            } else {
              $cookies.set('nobackendutm', true, '1h');
              $cookies.set('nobackendfirsttouchutm', true, '1h');

              if (
                winonaFirstTouchUtms === undefined &&
                winonaUtms != undefined
              ) {
                winonaFirstTouchUtms = winonaUtms;
                const strCookieFirstTouch = JSON.stringify(winonaUtms);
                $cookies.set('winona_firsttouch_utms', strCookieFirstTouch);
              }
            }
          }
        }

        if (
          winonaUtms != undefined &&
          winonaUtms.constructor.name === 'Object'
        ) {
          const utmSource = winonaUtms.utm_source || undefined;
          const utmMedium = winonaUtms.utm_medium || undefined;
          const utmCampaign = winonaUtms.utm_campaign || undefined;
          const utmTerm = winonaUtms.utm_term || undefined;
          const utmContent = winonaUtms.utm_content || undefined;
          let campaignId = Number(winonaUtms.utm_id) || undefined;

          if (campaignId && isNaN(campaignId)) {
            campaignId = undefined;
          }

          if (utmSource != undefined) {
            ret['sessionUTMSource'] = utmSource;
          }
          if (utmMedium != undefined) {
            ret['sessionUTMMedium'] = utmMedium;
          }
          if (utmCampaign != undefined) {
            ret['sessionUTMCampaign'] = utmCampaign;
          }
          if (utmTerm != undefined) {
            ret['sessionUTMTerm'] = utmTerm;
          }
          if (utmContent != undefined) {
            ret['sessionUTMContent'] = utmContent;
          }
          if (campaignId != undefined) {
            ret['campaignId'] = campaignId;
          }
        }

        if (
          winonaFirstTouchUtms != undefined &&
          winonaFirstTouchUtms.constructor.name === 'Object'
        ) {
          const utmSource = winonaFirstTouchUtms.utm_source || undefined;
          const utmMedium = winonaFirstTouchUtms.utm_medium || undefined;
          const utmCampaign = winonaFirstTouchUtms.utm_campaign || undefined;
          const utmTerm = winonaFirstTouchUtms.utm_term || undefined;
          const utmContent = winonaFirstTouchUtms.utm_content || undefined;

          if (utmSource != undefined) {
            ret['firsttouchUTMSource'] = utmSource;
          }
          if (utmMedium != undefined) {
            ret['firsttouchUTMMedium'] = utmMedium;
          }
          if (utmCampaign != undefined) {
            ret['firsttouchUTMCampaign'] = utmCampaign;
          }
          if (utmTerm != undefined) {
            ret['firsttouchUTMTerm'] = utmTerm;
          }
          if (utmContent != undefined) {
            ret['firsttouchUTMContent'] = utmContent;
          }
        }

        ret.os = detect.osName != undefined ? detect.osName : detect.os;
        ret.os_version = detect.osVersion;

        const deviceDetector = new DeviceDetector();
        const detectedDevice = deviceDetector.parse(navigator.userAgent);

        const hardwareModel = `${detectedDevice.device.brand} ${detectedDevice.device.model}`;
        if (device != 'Desktop') {
          ret.hardwareModel = hardwareModel;
        }

        return ret;
      } catch (err) {
        console.log(err);
      }
    },

    async setUTMS(context, query) {
      const cookie = {};

      if (query.utm_source != undefined) {
        cookie.utm_source = query.utm_source;
      }

      if (query.utm_medium != undefined) {
        cookie.utm_medium = query.utm_medium;
      }

      if (query.utm_campaign != undefined) {
        cookie.utm_campaign = query.utm_campaign;
      }

      if (query.utm_term != undefined) {
        cookie.utm_term = query.utm_term;
      }

      if (query.utm_content != undefined) {
        cookie.utm_content = query.utm_content;
      }

      if (query.campaignId != undefined) {
        cookie.utm_id = query.campaignId;
      }

      if (query.campaignID != undefined) {
        cookie.utm_id = query.campaignID;
      }

      if (
        query[COOKIES.SHOW_PROMO_FORM.KEY] === COOKIES.SHOW_PROMO_FORM.VALUE
      ) {
        $cookies.set(
          COOKIES.SHOW_PROMO_FORM.COOKIE_NAME,
          true,
          COOKIES.SHOW_PROMO_FORM.COOKIE_DURATION
        );
      }

      if (Object.keys(cookie).length > 0) {
        const strCookies = JSON.stringify(cookie);

        $cookies.set('winona_utms', strCookies, '3d', '/', '.bywinona.com');

        // Check if firsttouch UTMs exist
        const firsttouch = $cookies.get('winona_firsttouch_utms');
        if (!firsttouch) {
          $cookies.set(
            'winona_firsttouch_utms',
            strCookies,
            '3d',
            '/',
            '.bywinona.com'
          );
        }
      }
    },

    // login to WINONA
    login(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'post',
          url: 'signin',
          data: data,
          headers: {
            'Content-Type': 'application/json',
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            localStorageService.setToken(response.data.body.idToken);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    getUser(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: 'user',
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            // console.log(response.data)
            // if (response?.data?.message && response.data.message !== "success") return reject(response.data)
            localStorageService.setuserID(response.data.body.id);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    // first step signup for new user
    signup(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'post',
          url: 'signup',
          data: data,
          headers: {
            'Content-Type': 'application/json',
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            localStorageService.setToken(response.data.body.idToken);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error.response.data);
            console.log(error);
            return reject(error);
          });
      });
    },

    // check status for wizard form
    getBoardingStatus(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: 'onboarding',
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            // console.log(response.data)
            // if (response?.data?.message && response.data.message !== "success") return reject(response.data)
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },
    // fill up basic information
    // update user
    updateUser(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'put',
          url: 'user',
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
          validateStatus: () => {
            return true; // I'm always returning true, you may want to do it depending on the status received
          },
        };
        axios(options)
          .then(function (response) {
            // console.log(response.data.body)
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    // upload avatar
    uploadImage(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'post',
          url: 'user/avatar',
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
            'Content-Type': 'multipart/form-data',
          },
          validateStatus: () => {
            return true; // I'm always returning true, you may want to do it depending on the status received
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    // shipping details
    addShippingDetails(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'post',
          url: 'shipping',
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
          validateStatus: () => {
            return true; // I'm always returning true, you may want to do it depending on the status received
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            console.log(error);
            return reject(error);
          });
      });
    },

    getShippingDetails(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: 'shipping',
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    // credit card details
    addCardDetails(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'post',
          url: 'payment',
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            console.log(error);
            return reject(error);
          });
      });
    },

    getCardDetails(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: 'payment',
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            console.log(error);
            return reject(error);
          });
      });
    },

    // end flow when user get boring
    logout() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            'signout',
            {
              /* body */
            },
            {
              headers: {
                Authorization: `Bearer ${localStorageService.getAccessToken()}`,
              },
            }
          )
          .then(function (response) {
            localStorageService.clearToken();
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    //JAMES
    async getConversation() {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: 'conversation',
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    async getConversationMember(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: `conversation/${data.id}/member`,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    async getConversationMessage(context, data) {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: `conversation/${data.id}/message?nextpage=${data.nextPage}`,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    async sendMessage(context, data) {
      return new Promise((resolve, reject) => {
        const conversationid = data.id;
        delete data['id'];
        const options = {
          method: 'post',
          url: `conversation/${conversationid}/message`,
          data: data,
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function (error) {
            if (error?.response?.data?.message)
              return reject(error?.response?.data);
            return reject(error);
          });
      });
    },

    //SESSION UTM
    async getSessionUTM() {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: 'session/utm',
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function () {
            return resolve({});
          });
      });
    },
    //FIRST TOUCH UTM
    async getFirstTouchUTM() {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'get',
          url: 'firsttouch/utm',
          headers: {
            Authorization: `Bearer ${localStorageService.getAccessToken()}`,
          },
        };
        axios(options)
          .then(function (response) {
            if (response?.data?.message && response.data.message !== 'success')
              return reject(response.data);
            return resolve(response.data.body);
          })
          .catch(function () {
            return resolve({});
          });
      });
    },
  },
  modules,
});
