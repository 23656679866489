import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import { mask } from 'vue-the-mask'
import VuePageTransition from 'vue-page-transition';
import Affix from 'vue-affix';
import lineClamp from 'vue-line-clamp';
import vSelect from 'vue-select';
import VueApollo from 'vue-apollo';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import VueResizeObserver from 'vue-resize-observer';
// Ninetailed imports
import NinetailedPlugin from '@ninetailed/experience.js-vue';
import DatadogLogger from './services/datadog.service';

import { NinetailedSegmentPlugin } from '@ninetailed/experience.js-plugin-segment';

import { AuthService } from './services/auth.service';
import '@bywinona/corefront/dist/style.css';
import './tailwind.css';

import VueSecureHTML from 'vue-html-secure';

Vue.use(VueSecureHTML);

// import VueCookieAcceptDecline from "vue-cookie-accept-decline";
// Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

// Global Component
Vue.component('Pageloader', () =>
  import('./components/onboarding/loaders/IntermediaryLoader')
);

Vue.component('Modal', () => import('./components/partials/Modal.vue'));

Vue.component('WCheckbox', () =>
  import('./components/partials/RadioAndCheckbox.vue')
);

// Ninetailed
Vue.use(NinetailedPlugin, {
  clientId: process.env.VUE_APP_NINETAILED_ID,
  environment:
    process.env.VUE_APP_SERVER === 'production' ? 'main' : 'development',
});

// plugins: [
//   NinetailedSegmentPlugin({
//     writeKey: process.env.VUE_APP_SEGMENT_WRITEKEY,
//     actionTemplate: `Ninetailed Test Started`,
//     audiencePropertyTemplate: `{{ audience.name || "baseline" }}`,
//     componentPropertyTemplate: `{{ component.slug || component.id }}`,
//   }),
// ],

// Vue.component('r-products',
//   () => import('./components/partials/RelatedProducts.vue')
// )

Vue.component('VIcon', () => import('./icon/icon.vue'));

// Vue.component('plan',
//   () => import('./components/partials/ChoosePlan.vue')
// )

import VueMask from 'v-mask';
Vue.use(VueMask);

import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';

Vue.use(VueCroppie);

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

Vue.component('VSelect', vSelect);

Vue.use(VuePageTransition);
Vue.use(Affix);
Vue.use(lineClamp);
Vue.use(VueResizeObserver);

import flatPickr from 'vue-flatpickr-component';
import {
  BootstrapVue,
  IconsPlugin,
  TooltipPlugin,
  PopoverPlugin,
  LayoutPlugin,
} from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/scss/theme.scss';
import 'flatpickr/dist/flatpickr.min.css';
import moment from 'moment';
import VueCookies from 'vue-cookies';
// import '@/assets/js/chart.js'
Vue.use(LayoutPlugin, { breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] });

Vue.config.productionTip = false;
Vue.use(flatPickr);

// Vue.directive('mask', mask);

// Install BootstrapVue
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(require('vue-shortkey'));
Vue.use(IconsPlugin);
Vue.use(TooltipPlugin);
Vue.use(PopoverPlugin);

Vue.use(VueApollo);

// Global
Vue.prototype.moment = moment;
Vue.prototype.$ddlogger = new DatadogLogger();

Vue.directive('height', {
  inserted: function (el, binding) {
    const onResizeCallback = binding.value;
    window.addEventListener('resize', () => {
      const height = document.documentElement.clientHeight;
      onResizeCallback({ height });
    });
  },
});

let token = localStorage.getItem('accessToken');

Object.defineProperty(Vue.prototype, '$token', {
  get() {
    return token;
  },
  set(value) {
    token = value;
  },
});

const config = {
  url: process.env.VUE_APP_CHAT_URL,
  region: 'us-west-2',
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: process.env.VUE_APP_CHAT_API_KEY,
  },
};

const options = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
};

const appSyncClient = new AWSAppSyncClient(config, options);

const apolloProvider = new VueApollo({
  defaultClient: appSyncClient,
  async errorHandler(error) {
    try {
      if (error?.errors?.[0]?.message.includes('UnauthorizedException')) {
        // await AuthService.logout();
      }
    } catch (e) {
      console.log('error', e.message);
    }
  },
});
Vue.use(VueCookies);

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app');
