<template>
  <b-row
    align-h="center"
    class="pt-6"
  >
    <b-col
      v-if="dataloaded"
      cols="12"
      md="9"
    >
      <h1 class="mb-6 heading link text-center">
        My Subscription
      </h1>
      <b-alert
        :show="alert.show"
        :variant="alert.type"
      >
        {{
          alert.message
        }}
      </b-alert>
      <b-card class="default-bg">
        <b-row class="pt-3 pb-3 pl-4 pr-4">
          <b-col
            cols="12"
            class="mb-4 tw-flex tw-flex-wrap tw-justify-between tw-items-baseline"
          >
            <div>
              <h3
                class="label-header text-default mb-2"
                style="font-size: 24px"
              >
                {{ prescription.medicinename }}
              </h3>
              <p
                v-if="prescription.dosage && prescription.duration"
                class="mb-4"
              >
                {{ prescription.dosage }} / {{ prescription.frequency }} ({{
                  prescription.duration
                }})
              </p>
              <p
                v-else
                class="mb-4"
              >
                {{ prescription.unit }}
              </p>
            </div>
            <div
              v-if="prescription.prescriptionstatus === 'PAUSED'"
              class="tw-border tw-border-warning tw-text-warning tw-px-3 tw-py-1 tw-rounded-lg tw-uppercase tw-font-atten-medium tw-font-medium"
            >
              {{ prescription.resumecollection ? `Paused until ${formatDateForDisplay(prescription.resumecollection)}` : 'Paused' }}
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
          /><!-- spacer -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- Treatment Details -->
            <small class="link">Treatment Details</small>
            <p
              v-if="prescription.dosage && prescription.duration"
              class="mb-4 bold"
              style="letter-spacing: 0.01em"
            >
              {{ prescription.dosage }} / {{ prescription.frequency }} ({{
                prescription.duration
              }})
            </p>
            <p
              v-else
              class="mb-4 bold"
              style="letter-spacing: 0.01em"
            >
              {{ prescription.unit }}
            </p>

            <!-- <h3 class="mb-2 mt-2">
              {{ prescription.unit }} {{ prescription.medicinename }}
              {{ prescription.dosage }}
            </h3> -->
            <!-- <p class="mb-4">
              {{ prescription.dosage }} {{ prescription.frequency }} ({{
                prescription.duration
              }})
            </p> -->

            <!-- Next Order -->
            <small class="link">Next Order Date</small>
            <p
              v-if="prescription.prescriptionstatus === 'PAUSED'"
              class="mb-2 mt-2 bold"
            >
              {{ prescription.resumecollection ? `Paused until ${formatDateForDisplay(prescription.resumecollection)}` : 'This treatment is paused.' }}
            </p>
            <p
              v-else-if="prescription.transactiontype === 'one_time'"
              class="mb-2 mt-2 bold"
            >
              -
            </p>
            <p
              v-else
              class="mb-2 mt-2 bold"
              style="letter-spacing: 0.01em"
            >
              {{ getScheduledDate(prescription) }}
            </p>

            <br>

            <!-- Shipping Address -->
            <small
              v-if="prescription.prescriptionstatus != 'SENT'"
              class="link"
            >Shipping Address</small>
            <small
              v-else
              class="link"
            >Prescription sent to</small>

            <p
              v-if="prescription.prescriptionstatus != 'SENT'"
              class="mb-4 mt-2 bold"
              style="letter-spacing: 0.01em"
            >
              {{ prescription.address }}
            </p>
            <p
              v-if="prescription.prescriptionstatus == 'SENT'"
              class="mb-4 mt-2 bold"
              style="letter-spacing: 0.01em"
            >
              {{
                `${prescription.sentto_pharmacy.name}, ${prescription.sentto_pharmacy.address1} ${prescription.sentto_pharmacy.address2} ${prescription.sentto_pharmacy.city}, ${prescription.sentto_pharmacy.state} ${prescription.sentto_pharmacy.zipcode}`
              }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-list-group flush>
              <b-list-group-item
                v-if="prescription.prescriptionstatus != 'SENT' && prescription.prescriptionstatus != 'PAUSED'"
                v-b-modal.track
                class="
                  py-2
                  d-flex
                  justify-content-between
                  align-items-center
                  bold
                "
              >
                Track shipment <span class="next-arrow"></span>
              </b-list-group-item>
              <!-- <b-list-group-item class="py-2 d-flex bold justify-content-between align-items-center" v-b-modal.shippingDate>Change shipping date <span class="next-arrow" ></span></b-list-group-item> -->
              <!-- <b-list-group-item
                v-if="prescription.prescriptionstatus !== 'CANCELLED'"
                class=" py-2 d-flex justify-content-between align-items-center bold"
                v-b-modal.getShipment
                >Get shipment now<span class="next-arrow"></span
              ></b-list-group-item> -->
              <!-- <b-list-group-item
                v-if="prescription.prescriptionstatus !== 'CANCELLED'"
                class="py-2 d-flex justify-content-between align-items-center bold"
                v-b-modal.skipShipment
                >Skip next shipment <span class="next-arrow"></span
              ></b-list-group-item> -->
              <!-- <b-list-group-item
                class="
                  py-2
                  d-flex
                  justify-content-between
                  align-items-center
                  bold
                "
                v-if="
                  prescription.prescriptionstatus != 'CANCELLED' &&
                  prescription.prescriptionstatus != 'SENT'
                "
                v-b-modal.cancelSubscription
                >Cancel subscription <span class="next-arrow"></span
              ></b-list-group-item> -->
            </b-list-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <!-- get shipment modal -->
    <modal
      id="getShipment"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Get your shipment now?
        </h2>
        <p class="mb-3">
          If you request to get your shipment now, we will update your shipping
          date frequency based on the date we sent out your order.
        </p>
        <b-row class="text-center">
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              :disabled="processing"
              variant="outline-info"
              @click="toggleModal('getShipment')"
            >
              {{ processing ? "Processing" : "Confirm" }}
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              variant="info"
              @click="$bvModal.hide('getShipment')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- skip shipment -->
    <modal
      id="skipShipment"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Skip next shipment?
        </h2>
        <p class="mb-3">
          If you skip your next shipment, your next order will process on
          <b>
            {{
              prescription.prescriptionstatus != "CANCELLED"
                ? moment
                  .unix(prescription.nextshipmentdate)
                  .format("MMM DD, YYYY")
                : ""
            }}
          </b>
        </p>
        <b-row class="text-center">
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              :disabled="processing"
              variant="outline-info"
              @click="toggleModal('skipShipment')"
            >
              {{ processing ? "Processing" : "Confirm" }}
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              variant="info"
              @click="$bvModal.hide('skipShipment')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- shipping date -->
    <modal
      id="shippingDate"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <h2 class="mb-3 text-center heading link">
        Select a date
      </h2>
      <div
        style="width: 310px; margin: 0 auto"
        class="pb-4"
      >
        <flat-pickr
          v-model="shippingDate"
          class="form-control pl-0"
          placeholder="MM-DD-YYYY"
          :config="config"
        />
      </div>
      <b-row
        class="text-center"
        align-h="center"
        style="width: 310px; margin: 0 auto"
      >
        <b-col
          cols="6"
          class="mb-3"
        >
          <b-button
            block
            pill
            variant="outline-info"
            @click="toggleModal('shippingDate')"
          >
            Update
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            block
            pill
            variant="info"
            @click="$bvModal.hide('shippingDate')"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </modal>

    <!-- reorder -->
    <modal
      id="reorder"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>

      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Reorder this prescription?
        </h2>
        <p class="mb-3">
          If you wish to continue this treatment you can reorder this product
          and your care provider will review and approve if qualified.
        </p>
        <b-row
          class="text-center"
          align-h="center"
          no-gutters
        >
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="toggleModal('reorder')"
            >
              Confirm
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              variant="info"
              @click="$bvModal.hide('reorder')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- track -->
    <modal
      id="track"
      ref="modal"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div
        class="modal-header intake-header"
        style="padding: 0; border: 0"
      >
        <b-container fluid>
          <b-row>
            <b-col cols="6">
              <h2 class="mt-5 heading text-primary">
                Track your shipment
              </h2>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <b-button
                size="sm"
                variant="link"
                @click="$bvModal.hide('track')"
              >
                <b-icon
                  icon="x-circle-fill"
                  variant="primary"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- name of patient -->
      <div
        v-for="item in shipment"
        :key="item.id"
        class="modal-card mt-5"
      >
        <h2 class="mb-4 bold">
          {{ prescription.medicinename }} -
          {{ moment.unix(item.shippingdate).format("MMM DD, YYYY") }}
        </h2>
        <b-row>
          <b-col cols="12">
            <template v-if="item.trackingno && item.shipmentstatus === 'Complete'">
              <small
                class="d-block text-muted d-letter-spacing"
              >Tracking Number</small>
              <!-- <p>{{ item.trackingno }}</p> -->
              {{ item.trackingno }}
            </template>
            <template v-else>
              <small
                class="d-block text-muted d-letter-spacing"
              >Shipping Date</small>
              <p>{{ moment.unix(item.shippingdate).format("MMM DD, YYYY") }}</p>
            </template>
          </b-col>
          <!-- <b-col cols="12" md="6">
            <small class="d-block text-muted d-letter-spacing">Status</small>
            <p>
              {{
                item.trackingstatus
                  ? item.trackingstatus
                  : item.shippingstatus
                  ? item.shippingstatus
                  : ""
              }}
            </p>
          </b-col> -->
          <b-col
            v-if="item.invoiceno"
            cols="12"
            md="6"
          >
            <small
              class="d-block text-muted d-letter-spacing"
            >Invoice Number</small>
            <p>{{ item.invoiceno }}</p>
          </b-col>

          <b-col
            v-if="item.expecteddate"
            cols="12"
            md="6"
          >
            <small
              class="d-block text-muted d-letter-spacing"
            >Expected Date</small>
            <p>{{ moment.unix(item.expecteddate).format("MMM DD, YYYY") }}</p>
          </b-col>
          <b-col
            v-if="item.shippedvia"
            cols="12"
            md="6"
          >
            <small
              class="d-block text-muted d-letter-spacing"
            >Shipped via</small>
            <p>{{ item.shippedvia }}</p>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- cancel subscriptions -->
    <modal
      id="cancelSubscription"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>

      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Are you sure you want to cancel your order?
        </h2>
        <p class="mb-3">
          If you cancel your subscription, your treatment will be stopped
          immediately. Canceling doesn't issue a refund.
        </p>
        <b-row
          class="text-center"
          align-h="center"
          no-gutters
        >
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              :disabled="processing"
              variant="outline-info"
              @click="toggleModal('cancelSubscription')"
            >
              {{ processing ? "Processing" : "Confirm" }}
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              variant="info"
              @click="$bvModal.hide('cancelSubscription')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>
  </b-row>
</template>

<script>
import { PrescriptionService } from '@/services/prescription.service';
import { TransactionService } from '@/services/transaction.service';
import { UsersService } from '@/services/users.service';
import LocalStorageService from '@/localStorage';
import moment from 'moment';
const localStorageService = LocalStorageService.getService();
import analyticsMixin from '@/mixins/analytics';

export default {
  name: 'Subscription',
  data() {
    return {
      alert: {
        show: false,
        type: 'warning',
        message: '',
      },
      data: [],
      dataloaded: false,
      processing: false,
      prescription: {},
      shipment: {},
      shippingDate: '',
      config: {
        allowInput: true,
        minDate: 'today',
        dateFormat: 'm-d-Y',
        inline: true,
      },
      max: 100,
    };
  },
  async mounted() {
    window.analytics.page();
    await this.getUser();
    const prescriptionId = this.$route.params.id;
    const patientId = this.data.id;

    await this.getPrescriptionorder(patientId, prescriptionId);
    await this.getShipment(prescriptionId);
  },
  methods: {
    showAlert(message, type = null) {
      if (!type) type = 'warning';
      this.alert = {
        show: true,
        type: 'warning',
        message: message,
      };
      window.scrollTo(0, 0);
    },
    displayNextOrderDate(prescription) {
      if (prescription.transactiontype === 'one_time') {
        return false;
      }

      const refills =
        prescription.refills -
        1 -
        (prescription.paidrefills == 0 ? 0 : prescription.paidrefills - 1);
      return (
        prescription.prescriptionstatus != 'SENT' &&
        prescription.prescriptionstatus != 'CANCELLED' &&
        refills > 0
      );
    },
    async toggleModal(id) {
      this.alert.show = false;
      let res;
      try {
        this.processing = true;
        if (id === 'getShipment') {
          res = await TransactionService.getNextShipment(this.$route.params.id);
          if (res && res.data) {
            this.prescription = res.data;
            // console.log("getShipment ", res.data);
          }
        } else if (id === 'skipShipment') {
          res = await TransactionService.skipPrescriptionOrder(
            this.$route.params.id
          );
          if (res && res.data) {
            this.prescription = res.data;
            // console.log("skipShipment ", res.data);
          }
        } else if (id === 'cancelSubscription') {
          res = await TransactionService.cancelPrescriptionOrder(
            this.$route.params.id
          );
          if (res && res.data) {
            // console.log("cancelSubscription ", res.data);
            this.$router.push('/treatment');
          }
        }
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      } finally {
        this.processing = false;
        await this.getShipment(this.$route.params.id);
        this.$root.$emit('bv::hide::modal', id);
      }
    }, // User
    async getUser() {
      this.alert.show = false;
      try {
        const res = await UsersService.getCurrent();
        if (res && res.data) {
          const data = res.data;
          this.data = data;
        }
      } catch (error) {
        if (error.message) {
          console.log('Error', error.message);
        }
      }
    },
    async getPrescriptionorder(patientId, prescriptionId) {
      this.alert.show = false;
      try {
        const res = await PrescriptionService.getPrescriptionorder(
          prescriptionId
        );
        if (res && res.data) {
          // const { items } = res.data;
          this.prescription = res.data;
        }
      } catch (error) {
        if (error.message) {
          console.log('Error', error.message);
        }
      }
    },
    async getShipment(prescriptionId) {
      try {
        const res = await UsersService.getShipment(prescriptionId);
        if (res && res.data) {
          // const { items } = res.data;
          this.shipment = res.data;
          this.dataloaded = true;
        }
      } catch (error) {
        if (error.message) {
          console.log('Error', error.message);
        }
      }
    },
    getScheduledDate(prescription) {
      let d;
      if (prescription.nextshipmentdate) {
        d = moment.unix(prescription.nextshipmentdate).format('MMM DD, YYYY');
      } else if (prescription.scheduleddatetimestamp) {
        d = moment
          .unix(prescription.scheduleddatetimestamp)
          .format('MMM DD, YYYY');
      } else if (prescription.timestampsubscriptionstartdate) {
        d = moment
          .unix(prescription.timestampsubscriptionstartdate)
          .format('MMM DD, YYYY');
      }
      return d;
    },
    formatDateForDisplay(date) {
      if (!date) {
        return '';
      }
      return this.moment(date * 1000).format('MM/DD/YYYY');
    },
  },
};
</script>

<style scoped>
/deep/.modal-header.intake-header {
  border-bottom: 0;
  align-items: center;
  padding: 1rem;
}
</style>

<style lang="scss" scoped>
.list-group-item {
  border: 0;
}
</style>
