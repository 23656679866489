<template>
  <b-row
    align-h="center"
    class="pt-6"
  >
    <b-col
      v-if="dataloaded"
      cols="12"
      md="9"
    >
      <h1 class="mb-6 heading link text-center">
        My Order
      </h1>
      <b-alert
        :show="alert.show"
        :variant="alert.type"
      >
        {{
          alert.message
        }}
      </b-alert>
      <b-card class="default-bg">
        <b-row class="p-3">
          <b-col
            cols="12"
            class="mb-4"
          >
            <h3
              class="bold"
              style="font-size: 24px"
            >
              {{ prescription.medicinename }}
            </h3>
          </b-col>
          <b-col
            cols="12"
            md="4"
          />
          <b-col
            cols="12"
            md="4"
          >
            <!-- Treatment Details -->
            <small class="link">Treatment Details</small>
            <!-- <h3 class="mb-2 mt-2">
              {{ prescription.unit }} {{ prescription.medicinename }}
              {{ prescription.dosage }}
            </h3>
            <p class="mb-4">
              {{ prescription.dosage }} {{ prescription.frequency }} ({{
                prescription.duration
              }})
            </p> -->
            <p
              v-if="prescription.dosage && prescription.duration"
              class="mb-4 bold"
              style="letter-spacing: 0.01em"
            >
              {{ prescription.dosage }} / {{ prescription.frequency }} ({{
                prescription.duration
              }})
            </p>
            <p
              v-else
              class="mb-4 bold"
              style="letter-spacing: 0.01em"
            >
              {{ prescription.unit }}
            </p>

            <!-- Next Order -->
            <small
              v-if="
                prescription.transactiontype !== 'one_time' &&
                  prescription.prescriptionstatus != 'CANCELLED' &&
                  shipment.filter(
                    (x) =>
                      x.trackingstatus === 'IN-TRANSIT' ||
                      x.shippingstatus === 'PAID'
                  ).length != shipment.length
              "
              class="link"
            >Next Order Date</small>
            <p
              v-for="item in shipment.filter(
                (x) => x.shippingstatus === 'PENDING'
              )"
              :key="item.id"
              class="mb-2 mt-2 bold"
              style="letter-spacing: 0.01em"
            >
              {{
                prescription.transactiontype != "one_time" &&
                  prescription.prescriptionstatus != "CANCELLED"
                  ? moment.unix(item.shippingdate).format("MMM DD, YYYY")
                  : ""
              }}
            </p>
            <br>

            <!-- Shipping Address -->
            <small
              v-if="prescription.prescriptionstatus != 'SENT'"
              class="link"
            >Shipping Address</small>
            <small
              v-else
              class="link"
            >Prescription sent to</small>

            <p
              v-if="prescription.prescriptionstatus != 'SENT'"
              class="mb-4 mt-2 bold"
              style="letter-spacing: 0.01em"
            >
              {{ prescription.address }}
            </p>
            <p
              v-if="prescription.prescriptionstatus == 'SENT'"
              class="mb-4 mt-2 bold"
              style="letter-spacing: 0.01em"
            >
              {{
                `${prescription.sentto_pharmacy.name}, ${prescription.sentto_pharmacy.address1} ${prescription.sentto_pharmacy.address2} ${prescription.sentto_pharmacy.city}, ${prescription.sentto_pharmacy.state} ${prescription.sentto_pharmacy.zipcode}`
              }}
            </p>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-list-group flush>
              <b-list-group-item
                v-if="prescription.prescriptionstatus != 'SENT'"
                v-b-modal.reorder
                class="d-flex justify-content-between align-items-center bold"
              >
                Reorder treatment <span class="next-arrow"></span>
              </b-list-group-item>
              <b-list-group-item
                v-if="prescription.prescriptionstatus != 'SENT'"
                v-b-modal.track
                class="d-flex justify-content-between align-items-center bold"
              >
                Track shipment <span class="next-arrow"></span>
              </b-list-group-item>
              <!-- <b-list-group-item class="d-flex justify-content-between align-items-center" v-b-modal.shippingDate>Change shipping date <span class="next-arrow" ></span></b-list-group-item> -->
              <!-- <b-list-group-item
                v-if="
                  prescription.prescriptionstatus != 'CANCELLED' &&
                  shipment.filter((x) => x.paymentstatus === 'GOOD').length !=
                    shipment.length
                "
                class="d-flex justify-content-between align-items-center"
                v-b-modal.getShipment
                >Get shipment now<span class="next-arrow"></span
              ></b-list-group-item> -->
              <!-- <b-list-group-item
                v-if="
                  shipment.filter((x) => x.paymentstatus === 'GOOD').length >
                    0 &&
                  prescription.prescriptionstatus != 'CANCELLED' &&
                  shipment.filter((x) => x.paymentstatus === 'GOOD').length !=
                    shipment.length
                "
                class="d-flex justify-content-between align-items-center"
                v-b-modal.skipShipment
                >Skip next shipment <span class="next-arrow"></span
              ></b-list-group-item> -->
              <!-- <b-list-group-item
                class="d-flex justify-content-between align-items-center"
                v-if="
                  prescription.prescriptionstatus != 'CANCELLED' &&
                  shipment.filter((x) => x.trackingstatus === 'IN-TRANSIT')
                    .length != shipment.length
                "
                v-b-modal.cancelSubscription
                >Cancel order <span class="next-arrow"></span
              ></b-list-group-item> -->
            </b-list-group>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <!-- get shipment modal -->
    <modal
      id="getShipment"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Get your shipment now?
        </h2>
        <p class="mb-3">
          If you request to get your shipment now, we will update your shipping
          date frequency based on the date we sent out your order.
        </p>
        <b-row class="text-center">
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              :disabled="processing"
              variant="outline-info"
              @click="toggleModal('getShipment')"
            >
              {{ processing ? "Processing" : "Confirm" }}
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              variant="info"
              @click="$bvModal.hide('getShipment')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- skip shipment -->
    <modal
      id="skipShipment"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>
      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Skip next shipment?
        </h2>
        <p class="mb-3">
          If you skip your next shipment, your next order will process on
          <b>
            {{
              prescription.prescriptionstatus != "CANCELLED"
                ? moment
                  .unix(prescription.nextshipmentdate)
                  .format("MMM DD, YYYY")
                : ""
            }}
          </b>
        </p>
        <b-row class="text-center">
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              :disabled="processing"
              variant="outline-info"
              @click="toggleModal('skipShipment')"
            >
              {{ processing ? "Processing" : "Confirm" }}
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              variant="info"
              @click="$bvModal.hide('skipShipment')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- shipping date -->
    <modal
      id="shippingDate"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <h2 class="mb-3 text-center heading link">
        Select a date
      </h2>
      <div
        style="width: 310px; margin: 0 auto"
        class="pb-4"
      >
        <flat-pickr
          v-model="shippingDate"
          class="form-control pl-0"
          placeholder="MM-DD-YYYY"
          :config="config"
        />
      </div>
      <b-row
        class="text-center"
        align-h="center"
        style="width: 310px; margin: 0 auto"
      >
        <b-col
          cols="6"
          class="mb-3"
        >
          <b-button
            block
            pill
            variant="outline-info"
            @click="toggleModal('shippingDate')"
          >
            Update
          </b-button>
        </b-col>
        <b-col cols="6">
          <b-button
            block
            pill
            variant="info"
            @click="$bvModal.hide('shippingDate')"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </modal>

    <!-- reorder -->
    <modal
      id="reorder"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>

      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Reorder this prescription?
        </h2>
        <p class="mb-3">
          If you wish to continue this treatment you can reorder this product
          and your care provider will review and approve if qualified.
        </p>
        <b-row
          class="text-center"
          align-h="center"
          no-gutters
        >
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="toggleModal('reorder')"
            >
              Confirm
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              variant="info"
              @click="$bvModal.hide('reorder')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- track -->
    <modal
      id="track"
      ref="modal"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div
        class="modal-header intake-header"
        style="padding: 0; border: 0"
      >
        <b-container fluid>
          <b-row>
            <b-col cols="6">
              <h2 class="mt-5 heading text-primary">
                Track your shipment
              </h2>
            </b-col>
            <b-col
              cols="6"
              class="text-right"
            >
              <b-button
                size="sm"
                variant="link"
                @click="$bvModal.hide('track')"
              >
                <b-icon
                  icon="x-circle-fill"
                  variant="primary"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <!-- name of patient -->
      <div
        v-for="item in shipment"
        :key="item.id"
        class="modal-card mt-5"
      >
        <h2 class="mb-4 bold">
          {{ prescription.medicinename }} -
          {{ moment.unix(item.shippingdate).format("MMM DD, YYYY") }}
        </h2>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <template v-if="item.trackingno">
              <small
                class="d-block text-muted d-letter-spacing"
              >Tracking Number</small>
              <!-- <p>{{ item.trackingno }}</p> -->
              <a
                target="_blank"
                :href="`https://www.stamps.com/shipstatus/?confirmation=${item.trackingno}`"
              >
                {{ item.trackingno }}
              </a>
            </template>
            <template v-else>
              <small
                class="d-block text-muted d-letter-spacing"
              >Shipping Date</small>
              <p>{{ moment.unix(item.shippingdate).format("MMM DD, YYYY") }}</p>
            </template>
          </b-col>
          <!-- <b-col cols="12" md="6">
            <small class="d-block text-muted d-letter-spacing">Status</small>
            <p>
              {{
                item.trackingstatus
                  ? item.trackingstatus
                  : item.shippingstatus
                  ? item.shippingstatus
                  : ""
              }}
            </p>
          </b-col> -->
          <b-col
            v-if="item.invoiceno"
            cols="12"
            md="6"
          >
            <small
              class="d-block text-muted d-letter-spacing"
            >Invoice Number</small>
            <p>{{ item.invoiceno }}</p>
          </b-col>

          <b-col
            v-if="item.expecteddate"
            cols="12"
            md="6"
          >
            <small
              class="d-block text-muted d-letter-spacing"
            >Expected Date</small>
            <p>{{ moment.unix(item.expecteddate).format("MMM DD, YYYY") }}</p>
          </b-col>
          <b-col
            v-if="item.shippedvia"
            cols="12"
            md="6"
          >
            <small
              class="d-block text-muted d-letter-spacing"
            >Shipped via</small>
            <p>{{ item.shippedvia }}</p>
          </b-col>
        </b-row>
      </div>
    </modal>

    <!-- cancel subscriptions -->
    <modal
      id="cancelSubscription"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>

      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Are you sure you want to cancel your order?
        </h2>
        <p class="mb-3">
          If you cancel your subscription, your treatment will be stopped
          immediately. Canceling doesn't issue a refund.
        </p>
        <b-row
          class="text-center"
          align-h="center"
          no-gutters
        >
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              :disabled="processing"
              variant="outline-info"
              @click="toggleModal('cancelSubscription')"
            >
              {{ processing ? "Processing" : "Confirm" }}
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              :disabled="processing"
              variant="info"
              @click="$bvModal.hide('cancelSubscription')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>
  </b-row>
</template>

<script>
import { PrescriptionService } from '@/services/prescription.service';
import { TransactionService } from '@/services/transaction.service';
import { UsersService } from '@/services/users.service';
import LocalStorageService from '@/localStorage';
const localStorageService = LocalStorageService.getService();
import analyticsMixin from '@/mixins/analytics';
export default {
  name: 'Order',
  mixins: [analyticsMixin],
  data() {
    return {
      alert: {
        show: false,
        type: 'warning',
        message: '',
      },
      data: [],
      dataloaded: false,
      processing: false,
      prescription: {},
      shipment: {},
      shippingDate: '',
      config: {
        minDate: 'today',
        dateFormat: 'm-d-Y',
        inline: true,
      },
      max: 100,
    };
  },
  async mounted() {
    this.$_analytics_page();
    await this.getUser();
    const prescriptionId = this.$route.params.id;
    const patientId = this.data.id;

    await this.getPrescriptionorder(patientId, prescriptionId);
    await this.getShipment(prescriptionId);
  },
  methods: {
    showAlert(message, type = null) {
      if (!type) type = 'warning';
      this.alert = {
        show: true,
        type: 'warning',
        message: message,
      };
      window.scrollTo(0, 0);
    },
    async toggleModal(id) {
      this.alert.show = false;
      let res;
      try {
        this.processing = true;
        if (id === 'getShipment') {
          res = await TransactionService.getNextShipment(this.$route.params.id);
          if (res && res.data) {
            // console.log("getShipment ", res.data);
          }
        } else if (id === 'skipShipment') {
          res = await TransactionService.skipPrescriptionOrder(
            this.$route.params.id
          );
          if (res && res.data) {
            // console.log("skipShipment ", res.data);
          }
        } else if (id === 'cancelSubscription') {
          res = await TransactionService.cancelPrescriptionOrder(
            this.$route.params.id
          );
          if (res && res.data) {
            // console.log("cancelSubscription ", res.data);
            this.$router.push('/treatment');
          }
        } else if (id === 'reorder') {
          res = await TransactionService.reorderPrescriptionOrder(
            this.$route.params.id
          );
          if (res && res.data) {
            // console.log("reorderPrescription ", res.data);
          }
        }
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      } finally {
        this.processing = false;
        await this.getShipment(this.$route.params.id);
        this.$root.$emit('bv::hide::modal', id);
      }
    }, // User
    async getUser() {
      this.alert.show = false;
      try {
        const res = await UsersService.getCurrent();
        if (res && res.data) {
          const data = res.data;
          // if (data.onboardingstatus === "PENDING") {
          //   localStorageService.setItem("onboardingstep", data.onboardingstep);
          //   if (data.onboardingstep === 1) {
          //     this.$router.push("/getting-started");
          //   } else if (data.onboardingstep >= 2 && data.onboardingstep < 3) {
          //     this.$router.push("/health-questions");
          //   } else if (data.onboardingstep >= 3 && data.onboardingstep < 4) {
          //     this.$router.push("/treatment-options");
          //   } else if (data.onboardingstep >= 3 && data.onboardingstep < 4) {
          //     this.$router.push("/treatment-addons");
          //   } else if (data.onboardingstep >= 3 && data.onboardingstep < 4) {
          //     this.$router.push("/identity-verification");
          //   } else if (data.onboardingstep >= 3 && data.onboardingstep < 4) {
          //     this.$router.push("/checkout");
          //   } else {
          //     this.$router.push("/");
          //   }
          // }
          this.data = data;
        }
      } catch (error) {
        if (error.message) {
          console.log('Error', error.message);
        }
      }
    },
    async getPrescriptionorder(patientId, prescriptionId) {
      this.alert.show = false;
      try {
        const res = await PrescriptionService.getPrescriptionorder(
          prescriptionId
        );
        if (res && res.data) {
          // const { items } = res.data;
          this.prescription = res.data;
        }
      } catch (error) {
        if (error.message) {
          console.log('Error', error.message);
        }
      }
    },
    async getShipment(prescriptionId) {
      try {
        const res = await UsersService.getShipment(prescriptionId);
        if (res && res.data) {
          // const { items } = res.data;
          this.shipment = res.data;
          this.dataloaded = true;
        }
      } catch (error) {
        if (error.message) {
          console.log('Error', error.message);
        }
      }
    },
  },
};
</script>

<style scoped>
/deep/.modal-header.intake-header {
  border-bottom: 0;
  align-items: center;
  padding: 1rem;
}
</style>

<style lang="scss" scoped>
.list-group-item {
  border: 0;
}
</style>
