acka
<template>
  <b-row
    align-h="center"
    class="pt-6 mr-4 ml-4"
  >
    <b-col
      cols="12"
      md="9"
      lg="8"
      xl="8"
      xxl="6"
    >
      <b-alert
        v-if="
          data.id != undefined &&
            !hasSkippedUpload &&
            (data.verificationstatus === undefined ||
              (data.verificationstatus != undefined &&
                data.verificationstatus === 'FAILED'))
        "
        variant="danger"
        show
        class="mb-5"
      >
        Important. You can’t edit your <u>Account Details</u> and
        <u>Identity Verification</u> once our doctor has verified your account
      </b-alert>
      <b-alert
        v-if="showDiscountMessage"
        style="background: #1a6a72; color: #cdfcb1; border-color: #1a6a72"
        show
        class="mb-5"
      >
        Thank you! A 20% discount will be applied to your next shipment
      </b-alert>
      <!-- account details -->
      <b-card
        v-if="editGroup"
        class="mb-5"
        no-body
      >
        <b-row>
          <b-col
            cols="12"
            class="static-margin"
          >
            <b-row align-v="center">
              <b-col>
                <h2 class="mb-0 bold text-primary">
                  Account Details
                </h2>
              </b-col>
              <b-col
                v-if="
                  data.id != undefined &&
                    (data.verificationstatus === undefined ||
                      (data.verificationstatus != undefined &&
                        data.verificationstatus === 'FAILED'))
                "
                cols="auto"
              >
                <b-button
                  v-if="verificationEditUnlocked"
                  variant="link"
                  class="btn-mini text-underline"
                  @click="editAcountDetails = true"
                >
                  Edit
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-form-input
              class="fs-mask"
              type="text"
              :value="firstName"
              placeholder="First Name"
              readonly
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-form-input
              class="fs-mask"
              type="text"
              :value="lastName"
              placeholder="Last Name"
              readonly
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-form-input
              class="fs-mask"
              type="text"
              :value="birthday"
              placeholder="Birthday"
              readonly
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-form-input
              class="fs-mask"
              type="text"
              :value="phoneNo"
              :formatter="formatPhoneNumber"
              placeholder="Phone No."
              readonly
            />
          </b-col>
        </b-row>
      </b-card>
      <!-- shipping information -->
      <b-card
        v-if="editGroup"
        class="mb-5"
        no-body
      >
        <b-row>
          <b-col
            cols="12"
            class="static-margin"
          >
            <b-row align-v="center">
              <b-col>
                <h2 class="mb-0 bold text-primary">
                  Shipping Address
                </h2>
              </b-col>
              <b-col cols="auto">
                <b-button
                  class="btn-mini text-underline"
                  variant="link"
                  @click="editShippingInfo = true"
                >
                  Edit
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-row>
              <b-col
                cols="12"
                class="static-margin"
              >
                <b-form-input
                  type="text"
                  :value="address1"
                  placeholder="Address 1"
                  readonly
                />
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="static-margin"
              >
                <b-form-input
                  type="text"
                  :value="city"
                  placeholder="City"
                  readonly
                />
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="static-margin"
              >
                <b-form-input
                  type="text"
                  :value="statename"
                  placeholder="State"
                  readonly
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-4"
          >
            <b-row>
              <b-col
                cols="12"
                class="static-margin"
              >
                <b-form-input
                  type="text"
                  :value="address2"
                  placeholder="Address 2"
                  readonly
                />
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="static-margin"
              >
                <b-form-input
                  type="text"
                  :value="country"
                  placeholder="Country"
                  readonly
                />
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="static-margin"
              >
                <b-form-input
                  type="text"
                  :value="zipCode"
                  placeholder="Zip Code"
                  readonly
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>

      <!-- billing information -->
      <b-card
        v-if="editGroup"
        class="mb-5"
        no-body
      >
        <b-row>
          <b-col
            cols="12"
            class="static-margin"
          >
            <b-row align-v="center">
              <b-col>
                <h2 class="mb-0 bold text-primary">
                  Billing Address
                </h2>
              </b-col>
              <b-col cols="auto">
                <b-button
                  v-if="sameAddress === 'No'"
                  class="btn-mini text-underline"
                  variant="link"
                  @click="editBillingInfo = true"
                >
                  Edit
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              label="Same as shipping address"
              @change.native="updateBillingInfo"
            >
              <b-form-radio
                v-model="sameAddress"
                class="shipping-radio"
                :aria-describedby="ariaDescribedby"
                name="shipping-adds"
                value="Yes"
              >
                Yes
              </b-form-radio>
              <b-form-radio
                v-model="sameAddress"
                class="shipping-radio"
                :aria-describedby="ariaDescribedby"
                name="shipping-adds"
                value="No"
              >
                No
              </b-form-radio>
            </b-form-group>
          </b-col>
          <template v-if="sameAddress === 'No'">
            <b-col
              cols="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="static-margin"
                >
                  <b-form-input
                    type="text"
                    :value="billingAddress1"
                    placeholder="Address 1"
                    readonly
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="static-margin"
                >
                  <b-form-input
                    type="text"
                    :value="billingCity"
                    placeholder="City"
                    readonly
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="static-margin"
                >
                  <b-form-input
                    type="text"
                    :value="billingStateName"
                    placeholder="State"
                    readonly
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-4"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="static-margin"
                >
                  <b-form-input
                    type="text"
                    :value="billingAddress2"
                    placeholder="Address 2"
                    readonly
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="static-margin"
                >
                  <b-form-input
                    type="text"
                    :value="country"
                    placeholder="Country"
                    readonly
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                  class="static-margin"
                >
                  <b-form-input
                    type="text"
                    :value="billingZipCode"
                    placeholder="Zip Code"
                    readonly
                  />
                </b-col>
              </b-row>
            </b-col>
          </template>
        </b-row>
      </b-card>

      <!-- billing details -->
      <b-card
        v-if="editGroup"
        class="mb-5"
        no-body
      >
        <b-row>
          <b-col
            cols="12"
            class="static-margin"
          >
            <b-row align-v="center">
              <b-col>
                <h2 class="mb-0 bold text-primary">
                  Billing Details
                  <small
                    class="text-muted text-uppercase"
                  ><b-icon icon="lock" />Secure</small>
                </h2>
              </b-col>
              <b-col cols="auto">
                <b-button
                  variant="link"
                  class="btn-mini text-underline"
                  @click="updateBilling"
                >
                  Edit
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text>
                  <img
                    width="40px"
                    style="margin: 0 5px"
                    :src="brand"
                    alt=""
                  >
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                type="text"
                class="fs-mask"
                :value="lastFour"
                placeholder="####"
                readonly
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="static-margin"
          >
            <b-form-input
              class="fs-mask"
              type="text"
              :value="expiry"
              placeholder="MM/YY"
              readonly
            />
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="static-margin"
          >
            <b-form-input
              class="fs-mask"
              type="text"
              :value="cvv"
              placeholder="CVV"
              readonly
            />
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col>
            <b-button
              variant="link"
              @click="getHSAReceipt"
            >
              Download HSA Receipt
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <!-- security settings -->
      <b-card
        v-if="editGroup"
        class="mb-5"
        no-body
      >
        <b-row>
          <b-col
            cols="12"
            class="static-margin"
          >
            <b-row align-v="center">
              <b-col>
                <h2 class="mb-0 bold text-primary">
                  Security Settings
                </h2>
              </b-col>
              <b-col cols="auto">
                <b-button
                  variant="link"
                  class="btn-mini text-underline"
                  @click="editSecurity = true"
                >
                  Edit
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-form-input
              class="fs-mask"
              type="text"
              :value="email"
              placeholder="Email"
              readonly
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-form-input
              class="fs-mask"
              type="password"
              value="password"
              placeholder="Password"
              readonly
            />
          </b-col>
        </b-row>
      </b-card>
      <!-- Indetity verification -->
      <b-card
        v-if="
          editGroup &&
            onBoardingStatus == 'DONE' &&
            !data.hasOwnProperty('nonrxmedicationonly')
        "
        class="mb-5"
        no-body
      >
        <b-row>
          <b-col
            cols="12"
            class="static-margin"
          >
            <b-row align-v="center">
              <b-col>
                <h2 class="mb-0 bold text-primary">
                  Identity Verification
                </h2>
              </b-col>
              <b-col
                v-if="
                  data.id != undefined &&
                    (data.verificationstatus === undefined ||
                      (data.verificationstatus != undefined &&
                        data.verificationstatus === 'FAILED'))
                "
                cols="auto"
              >
                <b-button
                  v-if="verificationEditUnlocked"
                  variant="link"
                  class="btn-mini text-underline"
                  @click="editPhoto = true"
                >
                  Edit
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <small class="text-muted mb-3">Face Photo</small>
            <div class="photo-holder fs-exclude">
              <div
                class="photo"
                :style="{
                  backgroundImage:
                    isPhotoTaken || showPhoto
                      ? ''
                      : 'url(' + require('@/assets/images/photo.jpg') + ')',
                }"
              >
                <img
                  v-if="isPhotoTaken"
                  :src="picture"
                >
                <img
                  v-else-if="showPhoto"
                  :src="imagePreview"
                >
              </div>
            </div>
            <div
              v-if="photoVerificationFileType === 'application/pdf'"
              class="text-center"
            >
              <b-button
                variant="outline-info"
                :href="photoURL"
                target="_blank"
                pill
                style="min-width: 200px"
              >
                Open PDF
              </b-button>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <small class="text-muted mb-3">Identity Card</small>
            <div class="photo-holder fs-exclude">
              <div
                class="photo"
                :style="{
                  backgroundImage: !showId
                    ? 'url(' + require('@/assets/images/id.png') + ')'
                    : '',
                  'background-size': 'contain',
                  'background-color': 'transparent',
                }"
              >
                <img
                  v-if="showId"
                  :src="idPreview"
                  style="object-fit: contain"
                >
              </div>
            </div>
            <div
              v-if="idVerificationFileType === 'application/pdf'"
              class="text-center"
            >
              <b-button
                variant="outline-info"
                :href="identificationURL"
                target="_blank"
                pill
                style="min-width: 200px"
              >
                Open PDF
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <!-- pharmacy details -->
      <b-card
        v-if="editGroup && !data.hasOwnProperty('nonrxmedicationonly')"
        class="mb-5"
        no-body
      >
        <b-row>
          <b-col
            cols="12"
            class="static-margin"
          >
            <b-row align-v="start">
              <b-col>
                <h2 class="mb-3 bold text-primary">
                  Pharmacy Information
                </h2>
                <p class="small-text">
                  If you would like to change your pharmacy, please email our
                  support team at
                  <a href="mailto:help@bywinona.com">help@bywinona.com</a>
                </p>
              </b-col>
              <b-col cols="auto">
                <b-button
                  v-if="canEditPharmacy"
                  variant="link"
                  class="btn-mini text-underline"
                  @click="showEditPharmacy"
                >
                  Edit
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-form-input
              type="text"
              :value="pharmacyName"
              placeholder="Pharmacy Name"
              readonly
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="static-margin"
          >
            <b-form-input
              type="text"
              :value="pharmacyAddress"
              placeholder="Pharmacy Address"
              readonly
            />
          </b-col>
        </b-row>
      </b-card>

      <b-row
        v-if="hasPrescription && editGroup"
        class="mb-4"
      >
        <b-col cols="12">
          <h2 class="mb-3 bold text-primary">
            Cancel Subscription
          </h2>
        </b-col>
        <b-col class="mt-2">
          <b-button
            v-if="!fullycancelled"
            pill
            variant="outline-primary"
            @click="$router.push('/cancel-subscription')"
          >
            cancel subscription
          </b-button>
          <p v-else>
            No active subscription available
          </p>
        </b-col>
      </b-row>

      <!-- edit account details -->
      <template v-if="editAcountDetails">
        <h2 class="mb-6 bold text-primary">
          Account Details
        </h2>
        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{
            alert.message
          }}
        </b-alert>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label for="firstName">First Name:</label>
              <b-form-input
                id="firstName"
                v-model="firstName"
                class="pl-0 fs-mask"
                type="text"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label
                class="d-letter-spacing"
                for="lastName"
              >Last Name:</label>
              <b-form-input
                id="lastName"
                v-model="lastName"
                class="pl-0 fs-mask"
                type="text"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label
                class="d-letter-spacing"
                for="birthday"
              >Date of Birth:</label>
              <b-form-input
                id="birthday"
                v-model="birthday"
                v-mask="'##/##/####'"
                class="pl-0 fs-mask"
                type="text"
                placeholder="MM/DD/YYYY"
                autocomplete="off"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label
                class="d-letter-spacing"
                for="phoneNumber"
              >Phone Number:</label>
              <b-form-input
                id="phoneNumber"
                v-model="phoneNo"
                class="pl-0 fs-mask"
                :formatter="formatPhoneNumber"
                type="text"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="resetFields('user')"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="info"
              @click="updateUser"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </template>

      <!-- edit pharmacy details -->
      <template v-if="editPharmacy">
        <h2 class="mb-4 bold text-primary">
          Search your pharmacy
        </h2>
        <p>
          If you pick a pharmacy outside of the Winona Pharmacy Network, and
          qualify for a prescription, we will not be able to guarantee the price
          of the medication and provide free monthly delivery.
        </p>
        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{
            alert.message
          }}
        </b-alert>
        <b-row>
          <b-col cols="4">
            <div
              role="group"
              class="mb-3"
            >
              <label
                class="d-letter-spacing"
                for="pharmacyName"
              >Pharmacy:</label>
              <b-form-input
                id="pharmacyName"
                v-model="pharmacyName"
                class="pl-0"
                type="text"
                placeholder="Pharmacy Name"
              />
            </div>
          </b-col>
          <b-col cols="4">
            <div
              role="group"
              class="mb-3"
            >
              <label
                class="d-letter-spacing"
                for="pharmacyState"
              >State:</label>
              <v-select
                id="pharmacyState"
                v-model="pharmacyState"
                append-to-body
                :calculate-position="withPopper"
                label="text"
                :options="stateoptions"
                :reduce="(stateoptions) => stateoptions.value"
              >
                <template #open-indicator="{ attributes }">
                  <b-icon
                    v-bind="attributes"
                    icon="chevron-down"
                  />
                </template>
              </v-select>
            </div>
          </b-col>
          <b-col cols="4">
            <div
              role="group"
              class="mb-3"
            >
              <label
                class="d-letter-spacing"
                for="pharmacyCity"
              >City:</label>
              <b-form-input
                v-model="pharmacyCity"
                name="city"
                placeholder="City"
              />
            </div>
          </b-col>
          <b-col class="mb-3">
            <b-button
              variant="info"
              pill
              class="my-3"
              @click="search"
            >
              Search for a Pharmacy
            </b-button>
          </b-col>
          <b-col
            cols="12"
            style="max-height: 250px; overflow: auto"
          >
            <div id="pharmItems">
              <w-checkbox
                v-for="list in searchedPharmacies"
                :id="list.PharmacyId.toString()"
                :key="list.PharmacyId"
                v-model="selectedPharmacy"
                type="radio"
                :value="list"
                :label="list.StoreName + ' - ' + list.City"
                :description="`${list.Address1} ${list.Address2} ${list.City} ${list.State} ${list.ZipCode}`"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-3">
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="resetFields('user')"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="info"
              @click="updatePharmacy"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </template>

      <!-- edit shipping information -->
      <div v-show="editShippingInfo">
        <h2 class="mb-6 bold text-primary">
          Shipping Address
        </h2>
        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{
            alert.message
          }}
        </b-alert>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="address"
                  >Street Address:</label>
                  <vue-google-autocomplete
                    id="map1"
                    ref="streetAddressShipping"
                    classname="form-control"
                    placeholder=" "
                    :country="['us', 'pr']"
                    class="fs-mask onboarding-input"
                    @placechanged="getShippingAddressData"
                    @inputChange="onStreetAddressShippingInputChange"
                    @change="onStreetAddressShippingChange"
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="address2"
                  >Apartment/Suite:</label>
                  <b-form-input
                    id="address2"
                    v-model="address2"
                    class="pl-0"
                    type="text"
                    :formatter="removeAccents"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="state"
                  >State:</label>
                  <v-select
                    v-model="state"
                    append-to-body
                    :calculate-position="withPopper"
                    label="text"
                    :disabled="true"
                    :options="stateoptions"
                    :clearable="false"
                    :reduce="(stateoptions) => stateoptions.value"
                  >
                    <template #open-indicator="{ attributes }">
                      <b-icon
                        v-bind="attributes"
                        icon="chevron-down"
                      />
                    </template>
                  </v-select>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="city"
                  >City:</label>
                  <b-form-input
                    id="city"
                    v-model="city"
                    class="pl-0"
                    type="text"
                    :formatter="removeAccents"
                  />
                </div>
              </b-col>

              <b-col
                cols="12"
                md="3"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="zipcode"
                  >Zip Code:</label>
                  <b-form-input
                    id="zipcode"
                    v-model="zipCode"
                    class="pl-0"
                    type="text"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-3">
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="resetFields('shipping')"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="info"
              @click="updateShipping"
            >
              Update
            </b-button>
          </b-col>
        </b-row>

        <p class="text-center pt-3">
          <i>
            If you wish to update your shipping state, kindly contact Winona's
            Patient Care Team to have the state updated.
          </i>
        </p>
      </div>

      <!-- edit billing information -->
      <div v-show="editBillingInfo">
        <h2 class="mb-6 bold text-primary">
          Billing Address
        </h2>
        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{
            alert.message
          }}
        </b-alert>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="billingAddress1"
                  >Street Address:</label>
                  <vue-google-autocomplete
                    id="map2"
                    ref="streetAddressBilling"
                    classname="form-control"
                    placeholder=" "
                    country="us"
                    class="fs-mask onboarding-input"
                    @placechanged="getBillingAddressData"
                    @inputChange="onStreetAddressBillingInputChange"
                    @change="onStreetAddressBillingChange"
                  />
                </div>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="billingAddress2"
                  >Apartment/Suite:</label>
                  <b-form-input
                    id="billingAddress2"
                    v-model="billingAddress2"
                    class="pl-0"
                    type="text"
                    :formatter="removeAccents"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="billingState"
                  >State:</label>
                  <v-select
                    v-model="billingState"
                    append-to-body
                    :calculate-position="withPopper"
                    label="text"
                    :options="stateoptions"
                    :clearable="false"
                    :reduce="(stateoptions) => stateoptions.value"
                  >
                    <template #open-indicator="{ attributes }">
                      <b-icon
                        v-bind="attributes"
                        icon="chevron-down"
                      />
                    </template>
                  </v-select>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="billingCity"
                  >City:</label>
                  <b-form-input
                    id="billingCity"
                    v-model="billingCity"
                    class="pl-0"
                    type="text"
                    :formatter="removeAccents"
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <div
                  role="group"
                  class="mb-3"
                >
                  <label
                    class="d-letter-spacing"
                    for="billingZipCode"
                  >Zip Code:</label>
                  <b-form-input
                    id="billingZipCode"
                    v-model="billingZipCode"
                    class="pl-0"
                    type="text"
                  />
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-3">
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="resetFields('billing')"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="info"
              @click="updateBillingInfo"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </div>

      <!-- edit credit card -->
      <template v-if="editCreditCard">
        <div class="tw-mb-6 tw-flex tw-justify-between">
          <div>
            <h2 class="mb-0 bold text-primary">
              Billing Details
            </h2>
            <small class="text-muted">
              <b-icon icon="lock-fill" /> 256-BIT TLS SECURITY
            </small>
          </div>
          <div class="tw-flex">
            <img
              class="mr-2 tw-h-5"
              src="@/assets/images/payment-methods/visa.svg"
              height="20"
              alt="Visa"
            >
            <img
              class="mr-2 tw-h-5"
              src="@/assets/images/payment-methods/mastercard.svg"
              height="20"
              alt="Mastercard"
            >
            <img
              class="mr-2 tw-h-5"
              src="@/assets/images/payment-methods/discover.svg"
              height="20"
              alt="Discover"
            >
            <img
              class="mr-2 tw-h-5"
              src="@/assets/images/payment-methods/amex.svg"
              height="20"
              alt="American Express"
            >
            <img
              class="tw-h-5"
              src="@/assets/images/payment-methods/jcb.svg"
              height="20"
              alt="JCB"
            >
          </div>
        </div>
        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{
            alert.message
          }}
        </b-alert>
        <b-row>
          <b-col cols="12">
            <p
              class="text-primary"
              style="letter-spacing: 0px; margin-bottom: 0.5rem"
            >
              Credit or debit card
            </p>
            <div
              role="group"
              class="mb-3"
            >
              <div
                ref="card"
                style="background-color: #f8fafc; padding: 0.5rem"
              ></div>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          />
        </b-row>
        <b-row class="mt-3 mb-3">
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="resetFields('card')"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="info"
              :disabled="disableCardUpdate"
              @click="updateCard"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </template>

      <!-- edit security -->
      <template v-if="editSecurity">
        <h2 class="mb-6 bold text-primary">
          Security Settings
        </h2>
        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{
            alert.message
          }}
        </b-alert>
        <b-row>
          <b-col cols="12">
            <p class="bold">
              Change Email
            </p>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label
                for="email"
                class="d-letter-spacing"
              >Current Email Address:</label>
              <b-form-input
                id="email"
                v-model="email"
                class="pl-0 fs-mask"
                type="text"
                readonly
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label
                for="nemail"
                class="d-letter-spacing"
              >New Email Address:</label>
              <b-form-input
                id="nemail"
                v-model="newEmail"
                autocomplete="off"
                class="pl-0 fs-mask"
                type="text"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label
                for="password"
                class="d-letter-spacing"
              >Current Password:</label>
              <b-form-input
                id="password"
                v-model="password"
                autocomplete="off"
                class="pl-0 fs-mask"
                type="password"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-3">
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="resetFields('security')"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="info"
              @click="updateSecurity"
            >
              Update
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col cols="12">
            <p class="bold">
              Change Password
            </p>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label
                for="password"
                class="d-letter-spacing"
              >Current Password:</label>
              <b-form-input
                id="currentpassword"
                v-model="currentPassword"
                class="pl-0 fs-mask"
                type="password"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div
              role="group"
              class="mb-3"
            >
              <label
                for="cpassword"
                class="d-letter-spacing"
              >New Password:</label>
              <b-form-input
                id="cpassword"
                v-model="confirmPassword"
                class="pl-0 fs-mask"
                type="password"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <p>Forgot your password? <a href="#">Reset It</a></p>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-4">
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="resetFields('security')"
            >
              Cancel
            </b-button>
          </b-col>
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="info"
              @click="updatePassword"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </template>

      <!-- edit photo -->
      <template v-if="editPhoto">
        <b-row
          v-if="!isMobile"
          class="mb-4"
        >
          <b-col
            cols="12"
            class="mb-4"
          >
            <b-row align-v="center">
              <b-col cols="12">
                <h2 class="mb-6 bold text-primary">
                  Identity Verification
                </h2>
                <p>
                  In order to safely prescribe medications, we need a valid
                  government-issued ID that matches the name and date of birth
                  you entered while completing the patient questionnaire.
                </p>
                <b-alert
                  :show="alert.show"
                  :variant="alert.type"
                >
                  {{ alert.message }}
                </b-alert>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-if="!isMobile"
            cols="12"
            md="6"
          >
            <small class="text-muted mb-3">Face Photo</small>
            <div class="photo-holder fs-exclude">
              <div
                class="photo"
                :style="{
                  backgroundImage:
                    isPhotoTaken || showPhoto
                      ? ''
                      : 'url(' + require('@/assets/images/photo.jpg') + ')',
                }"
              >
                <img
                  v-if="isPhotoTaken"
                  :src="picture"
                >
                <img
                  v-else-if="showPhoto"
                  :src="imagePreview"
                  style="object-fit: cover"
                >
              </div>
            </div>
            <b-row>
              <b-col cols="6">
                <b-button
                  variant="link"
                  class="photo-btn"
                  block
                  @click="showCamera"
                >
                  <label style="font-size: inherit">{{
                    isPhotoTaken ? "Retake" : "Take Photo"
                  }}</label>
                </b-button>
              </b-col>
              <b-col cols="6">
                <b-button
                  block
                  variant="link"
                  class="photo-btn"
                  :disabled="isProcessingPhoto"
                >
                  <input
                    id="photo"
                    ref="photo"
                    type="file"
                    :accept="ACCEPTED_FILE_MIME_TYPES"
                    class="hide-file-input"
                    @change="photoUpload()"
                  >
                  <label
                    for="photo"
                    class="mb-0"
                  >
                    {{ isProcessingPhoto ? "Loading..." : "Choose Photo" }}
                  </label>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-if="!isMobile"
            cols="12"
            md="6"
          >
            <small class="text-muted mb-3">Identity Card</small>
            <div class="photo-holder">
              <div
                class="photo fs-exclude"
                :style="{
                  backgroundImage: showId
                    ? ''
                    : 'url(' + require('@/assets/images/id.png') + ')',
                  'background-size': 'contain',
                  'background-size': 'contain',
                  'background-color': 'transparent',
                }"
              >
                <img
                  v-if="showId"
                  :src="idPreview"
                  class="fs-exclude"
                  style="object-fit: contain"
                >
              </div>
            </div>
            <b-row>
              <b-col cols="12">
                <b-button
                  block
                  variant="link"
                  class="photo-btn"
                  :disabled="isProcessingId"
                >
                  <input
                    id="identification"
                    ref="identification"
                    type="file"
                    :accept="ACCEPTED_FILE_MIME_TYPES"
                    class="hide-file-input"
                    @change="idUpload()"
                  >
                  <label
                    for="identification"
                    class="mb-0"
                  >
                    {{
                      isProcessingId
                        ? "Loading..."
                        : showId
                          ? "Choose Again"
                          : "Change Photo"
                    }}
                  </label>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Mobile View -->
        <b-row
          v-else
          align-h="center"
          class="mb-4"
        >
          <DashboardIdentityVerification
            :personal-photo="imagePreview"
            :id-photo="idPreview"
            class="fs-exclude"
            @setPhotoMobile="setPhotoMobile"
          />
        </b-row>

        <b-row class="mt-3 mb-4">
          <b-col
            cols="12"
            md="6"
          />
          <b-col
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="resetFields('photo')"
            >
              {{ isAndroid11WebView ? "Back" : "Cancel" }}
            </b-button>
          </b-col>
          <b-col
            v-if="!isAndroid11WebView"
            cols="6"
            md="3"
          >
            <b-button
              pill
              block
              variant="info"
              :disabled="uploadingPhoto"
              @click="updatePhoto"
            >
              <b-spinner
                v-if="uploadingPhoto"
                small
              />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-col>

    <!-- modal for taking photos -->
    <modal
      id="takePhoto"
      size="md"
      centered
      hide-header
      hide-footer
      class="fs-mask"
    >
      <video
        ref="camera"
        width="100%"
        height="325"
        autoplay
      ></video>
      <canvas
        ref="canvas"
        class="photo-taken"
        :width="325"
        :height="250"
      ></canvas>
      <b-button
        variant="outline-info"
        style="margin: 15px auto; display: block; min-width: 200px"
        @click="takePhoto"
      >
        Capture
      </b-button>
    </modal>

    <!-- modal for cancel account -->
    <modal
      id="cancelAccount"
      ref="modal"
      size="md"
      centered
      hide-header
      hide-footer
    >
      <b-row class="mb-6">
        <b-col cols="12">
          <div class="icon-logo"></div>
        </b-col>
      </b-row>

      <div class="modal-card">
        <h2 class="mb-3 heading text-primary">
          Are you sure you want to delete your account?
        </h2>
        <p class="mb-3">
          Et dolor, ut sed facilisi ornare justo amet. Sed at lorem sed
          dignissim eros. Est morbi sit laoreet accumsan neque, non urna amet
          dolor. Quisque vitae id egestas vel. Nunc neque condi.
        </p>
        <b-row
          class="text-center"
          align-h="center"
          no-gutters
        >
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              pill
              block
              variant="outline-info"
              @click="toggleModal('cancelAccount')"
            >
              Confirm
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-button
              pill
              block
              variant="info"
              @click="$bvModal.hide('cancelAccount')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </modal>
    <loading
      :active.sync="uploadingPhoto"
      :can-cancel="false"
      color="#1a6a72"
    />
    <loading
      :active.sync="showLoader"
      :can-cancel="false"
      color="#1a6a72"
    />
  </b-row>
</template>
<script>
// eslint-disable-next-line
var stripeKey = '<%= clientStripeKey %>';
</script>
<script>
// libs
import { isAndroid, osVersion, osName } from "mobile-device-detect";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

// services
import { StripeService } from "@/services/stripe.service";
import { UsersService } from "@/services/users.service";
import { PatientService } from "@/services/patient.service";
import { ShippingService } from "@/services/shipping.service";
import { LookUpService } from "@/services/lookup.service";
import { AuthService } from "@/services/auth.service";
import { DosespotService } from "@/services/dosespot.service";

// localstorage
import LocalStorageService from "@/localStorage";
const localStorageService = LocalStorageService.getService();

// assets
import popper from "@/assets/js/popper.js";
import pdfIcon from "@/assets/images/onboarding/pdf-icon.png";

// mixins
import mixins from "@/components/form/mixins";

// components
import DashboardIdentityVerification from "@/components/dashboard/DashboardIdentityVerification";

// constants
import { ACCEPTED_FILE_MIME_TYPES } from "@/constants/files";

import analyticsMixin from '@/mixins/analytics';

// utils
import { isValidEmail } from "@/utils/string";
import {
  finalizeFileForUpload,
  fileToDataUrl,
  isFileTypePdf,
} from "@/utils/file";

export default {
  name: "profile",
  mixins: [popper, mixins, analyticsMixin],
  components: {
    DashboardIdentityVerification,
    Loading,
    VueGoogleAutocomplete,
  },
  data() {
    return {
      alert: {
        show: false,
        type: "warning",
        message: "",
      },
      data: {},
      id: "",
      ACCEPTED_FILE_MIME_TYPES,

      // Custom user ID from ebook for Segment
      analyticsId: "",

      verificationEditUnlocked: true,

      showLoader: false,

      // Subscription
      breakTime: "30",
      hasPrescription: false,
      fullycancelled: true,

      //Account details
      firstName: "",
      lastName: "",
      birthday: "",
      phoneNo: "",
      email: "",
      customerId: "",

      // pharmacy details
      pharmacyName: "",
      pharmacyState: "",
      pharmacyCity: "",
      pharmacyAddress: "",
      selectedPharmacy: {},
      searchedPharmacies: [],
      canEditPharmacy: false,

      //Shipping details
      shippingId: "",
      address1: "",
      address2: "",
      state: "",
      statename: "",
      city: "",
      country: "",
      zipCode: "",
      setAddress: false,
      stateoptions: [],

      //Security settings
      newEmail: "",
      password: "",
      currentPassword: "",
      confirmPassword: "",

      isProcessingPhoto: false,
      isProcessingId: false,
      //Identification settings
      identificationURL: "",
      identificationBackURL: "",
      photoURL: "",
      uploadingPhoto: false,
      isPhotoUpload: false,
      isIdUpload: false,
      isIdBackUpload: false,
      isPhotoTaken: false,
      showPhoto: false,
      showId: false,
      showBackId: false,
      imagePreview: "",
      idPreview: "",
      idPreviewBack: "",
      picture: "",
      personalOrientation: 0,
      idOrientation: 0,
      photo: null,
      idCard: null,
      identification: null,
      photoVerificationFileType: "",
      idVerificationFileType: "",
      isPhotoComingFromMobileComponent: false,

      //Billing settings
      stripe: undefined,
      card: undefined,
      lastFour: "**** ****** " + "0000",
      expiryMonth: "**",
      expiryYear: "**",
      cvv: "***",
      brand: undefined,
      expiry: undefined,
      setCredit: undefined,
      onBoardingStatus: "",
      visa: require("@/assets/images/payment-methods/visa.svg"),
      mastercard: require("@/assets/images/payment-methods/mastercard.svg"),

      sameAddress: undefined,
      notSame: false,
      billingAddress1: "",
      billingAddress2: "",
      billingState: "",
      billingStateName: "",
      billingCity: "",
      billingZipCode: "",
      billingStateoptions: [],
      billingPhone: "",

      idupload: null,
      photoupload: null,

      editAcountDetails: false,
      editShippingInfo: false,
      editBillingInfo: false,
      editCreditCard: false,
      editSecurity: false,
      editPharmacy: false,
      editPhoto: false,
      selected: null,
      crediCard: [
        {
          name: "visa",
        },
        {
          name: "mastercard",
        },
        {
          name: "discover",
        },
        {
          name: "amex",
        },
        {
          name: "jcb",
        },
      ],
      disableCardUpdate: false,
      attr: {
        dateFormat: "m-d-Y",
        allowInput: true,
        minDate: this.moment().subtract(100, "year").format("MM-DD-YYYY"),
        maxDate: this.moment().subtract(18, "year").format("MM-DD-YYYY"),
      },

      showDiscountMessage: false,
    };
  },
  watch: {
    editCreditCard() {
      // WIN-8551
      if (this.editCreditCard && this.disableCardUpdate) {
        this.showAlert(
          "Your credit card details are currently being updated. Please wait for the card to finish updating and refresh this page before requesting again."
        );
      }
      if (!this.editCreditCard) {
        history.pushState({}, null, "/account-settings");
      }
    },
    editShippingInfo(val) {
      if (val) {
        this.$refs.streetAddressShipping.update(this.address1);
      } else {
        history.pushState({}, null, "/account-settings");
      }
    },
    editBillingInfo(val) {
      if (val) {
        this.$refs.streetAddressBilling.update(this.billingAddress1);
      } else {
        history.pushState({}, null, "/account-settings");
      }
    },
    editPhoto(val) {
      console.log("editPhoto", val);
      if (!val) {
        history.pushState({}, null, "/account-settings");
      }
    },
  },
  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    async getShippingAddressData(addressData, placeResultData, id) {
      if (addressData.street_number) {
        this.address1 = `${addressData.street_number} ${addressData.route}`;
      } else {
        this.address1 = addressData.route;
      }
      this.city = addressData.locality ? addressData.locality : "";
      this.zipCode = addressData.postal_code ? addressData.postal_code : "";
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    async getBillingAddressData(addressData, placeResultData, id) {
      if (addressData.street_number) {
        this.billingAddress1 = `${addressData.street_number} ${addressData.route}`;
      } else {
        this.billingAddress1 = addressData.route;
      }
      this.billingCity = addressData.locality ? addressData.locality : "";
      this.billingZipCode = addressData.postal_code
        ? addressData.postal_code
        : "";
    },
    async getHSAReceipt() {
      const res = await PatientService.getHSAReceipt(this.data.id);
      let name = `${this.data.firstname} ${this.data.lastname}`;
      if (res.data) this.download(res.data, name);
    },
    download(url, name) {
      let link = document.createElement("a");
      link.href = url;
      let currentDate = new Date();
      if (name) name = " " + name;
      link.download = `HSA - ${currentDate}${name}`;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    setPhotoMobile(type, imageData) {
      this.isPhotoComingFromMobileComponent = true;
      if (type == "personal") {
        this.picture = imageData.photo;
        this.photo = imageData.file;
        this.personalOrientation = imageData.orientation;
        this.isPhotoTaken = imageData.isPhotoTaken;
        this.isPhotoUpload = true;
      } else {
        this.idPreview = imageData.photo;
        this.idCard = imageData.file;
        this.identification = imageData.file;
        this.idOrientation = imageData.orientation;
        this.isIdUpload = true;
      }
    },
    async search() {
      try {
        this.$emit("hideAlert");
        let { data } = await DosespotService.searchDosespotPharmacy(
          this.pharmacyName,
          this.pharmacyState,
          this.pharmacyCity
        );
        this.searchedPharmacies = data;
        if (this.searchedPharmacies.length == 0) {
          this.$emit(
            "alert",
            "There are 0 search results. Please review your search terms."
          );
        }
      } catch (err) {
        if (err.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile",
            error_log: {
              message: err,
            },
          });
        }
        this.$emit("alert", err);
      }
    },
    showEditPharmacy() {
      this.editPharmacy = true;
      this.pharmacyName = "";
      this.pharmacyState = "";
      this.pharmacyCity = "";
      this.searchedPharmacies = [];
    },
    showAlert(message, type = null) {
      this.alert = {
        show: true,
        type: "warning",
        message: message,
      };
    },
    formatDateForDisplay(date) {
      if (!date) {
        return "";
      }
      return this.moment(date).format("MM/DD/YYYY");
    },
    formatDate(date) {
      const formattedDate = this.moment(date).format("YYYY-MM-DD");
      const dateDiff = this.moment().diff(formattedDate, "years");
      if (dateDiff >= 100 || dateDiff < 18) {
        throw "Please input a valid date in MM/DD/YYYY.";
      } else {
        return formattedDate;
      }
    },
    toggleModal(id) {
      this.$root.$emit("bv::hide::modal", id);
    },
    save() {
      this.editAcountDetails = false;
      this.editShippingInfo = false;
      this.editBillingInfo = false;
      this.editCreditCard = false;
      this.editSecurity = false;
      this.editPharmacy = false;
      this.editPhoto = false;
    },
    async resetFields(val) {
      this.alert.show = false;

      if (val == "user") {
        await this.getUser();
        this.editAcountDetails = false;
        this.editPharmacy = false;
      } else if (val == "shipping") {
        await this.getShipping();
        this.editShippingInfo = false;
      } else if (val == "billing") {
        // await this.getUser();
        this.setBillingAddress();
        this.editBillingInfo = false;
      } else if (val == "card") {
        await this.getCardDetails();
        this.editCreditCard = false;
      } else if (val == "security") {
        this.editSecurity = false;
      } else if (val == "photo") {
        await this.getVerification();
        this.editPhoto = false;
      }
    },

    resetPhoto() {
      this.editPhoto = false;
    },

    // camera functions
    showCamera() {
      this.$root.$emit("bv::show::modal", "takePhoto");
      this.createCameraElement();
    },
    createCameraElement() {
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.$emit(
            "showAlert",
            "Camera is not detected. Please turn on your camera and refresh your browser."
          );
          alert(
            "Camera is not detected. Please turn on your camera and refresh your browser."
          );
        });
    },
    stopCameraStream() {
      if (this.$refs.camera?.srcObject) {
        let tracks = this.$refs.camera.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    },
    // Take photo
    takePhoto() {
      this.isPhotoUpload = true;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 325, 250);
      this.picture = canvas.toDataURL("image/png");
      this.$nextTick(() => {
        this.$root.$emit("bv::hide::modal", "takePhoto");
        this.stopCameraStream();
        this.isPhotoTaken = true;
      });
    },
    // Select photo
    async photoUpload() {
      try {
        this.isProcessingPhoto = true;
        this.isPhotoUpload = false;
        this.alert.show = false;
        const fileToUpload = this.$refs.photo.files[0];

        if (!fileToUpload) {
          return;
        }

        const { error, success } = await finalizeFileForUpload(fileToUpload);

        if (error) {
          this.showAlert(error);
          return;
        }

        this.photo = success;

        const isPdf = isFileTypePdf(this.photo);
        const dataUrl = await fileToDataUrl(this.photo);

        this.imagePreview = isPdf ? pdfIcon : dataUrl;
        this.isPhotoTaken = false;
        this.showPhoto = true;
        this.isPhotoUpload = true;
        this.picture = dataUrl;
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        this.isProcessingPhoto = false;
      }
    },

    // Upload photo
    async idUpload() {
      try {
        this.isProcessingId = true;
        this.isIdUpload = false;
        this.alert.show = false;
        const fileToUpload = this.$refs.identification.files[0];

        if (!fileToUpload) {
          return;
        }

        const { error, success } = await finalizeFileForUpload(fileToUpload);

        if (error) {
          this.showAlert(error);
          return;
        }

        this.identification = success;

        const isPdf = isFileTypePdf(this.identification);
        const dataUrl = await fileToDataUrl(this.identification);

        this.isIdUpload = true;
        this.showId = true;
        this.idCard = dataUrl;
        this.idPreview = isPdf ? pdfIcon : dataUrl;
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        this.isProcessingId = false;
      }
    },

    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(",");
      console.log(arr);
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1);
        n -= 1; // to make eslint happy
      }
      return new File([u8arr], filename, { type: mime });
    },

    async updateBilling() {
      this.editCreditCard = true;
      // this.stripe = await Stripe(this.pk);
      this.stripe = await Stripe(process.env.VUE_APP_STRIPE_CLIENT_KEYS);
      this.card = this.stripe.elements().create("card", {
        hidePostalCode: true,
        placeholder: "Card Number haha",
        style: {
          base: {
            fontSize: "16px",
            color: "#495057",
          },
        },
      });
      this.card.mount(this.$refs.card);
    },

    async updateCard() {
      this.alert.show = false;
      this.disableCardUpdate = true;
      let _s = this;
      const { token, error } = await _s.stripe.createToken(_s.card);

      if (error) {
        this.showAlert(error.message);
        const errorElement = this.$refs.cardErrors;
        if (errorElement) {
          errorElement.textContent = error.message;
        }
        this.disableCardUpdate = false;
        return;
      } else if (error && typeof error.message == "undefined") {
        this.disableCardUpdate = false;
        return;
      } else {
        if (token && Object.keys(token).length > 0) {
          try {
            const res = await StripeService.updateSource({ source: token.id });
            if (res && res.data) {
              const { data } = res.data.sources;
              this.lastFour = "**** ****** " + data[0].last4;
              if (data[0].brand === "Visa") {
                this.brand = this.visa;
              } else if (data[0].brand === "MasterCard") {
                this.brand = this.mastercard;
              }
              this.expiryYear = data[0].exp_year;
              this.expiryMonth = data[0].exp_month;
              this.expiry = this.expiryMonth + " / " + this.expiryYear;
            }
          } catch (error) {
            if (error.status == 500) {
              this.$store.dispatch("sendErrorLog", {
                step: "Profile",
                error_log: {
                  message: error,
                },
              });
            }
            this.showAlert(error);
            this.disableCardUpdate = false;
            return;
          }
        } else {
          if (error && Object.keys(error).length > 0) {
            this.showAlert(error.message);
            this.disableCardUpdate = false;
            return;
          } else {
            this.showAlert("An error occurs. Please try again.");
            this.disableCardUpdate = false;
            return;
          }
        }
      }

      this.getUser();
      this.editCreditCard = false;
    },
    async updateSecurity() {
      this.alert.show = false;
      try {
        let newEmail = this.newEmail;
        let currentPassword = this.password;

        if (!isValidEmail(newEmail)) {
          this.showAlert("Please enter a valid email address.");
          return;
        }

        let data = {
          email: newEmail,
          password: currentPassword,
        };
        this.email = newEmail;

        await UsersService.updateUserEmail(data);

        const { data: auth } = await AuthService.login({
          email: newEmail,
          password: currentPassword,
        });
        this.password = undefined;
        this.newEmail = undefined;
        this.editSecurity = false;
      } catch (error) {
        if (error.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile - Security",
            error_log: {
              message: error,
            },
          });
        }
        if (error.message) {
          this.showAlert(error.message);
        }
      }
    },
    async updatePassword() {
      this.alert.show = false;
      try {
        let data = {
          email: this.email,
          password: this.currentPassword,
          newpassword: this.confirmPassword,
        };
        await UsersService.changePassword(data);
        this.password = "";
        this.newEmail = "";
        this.currentPassword = "";
        this.confirmPassword = "";
        this.editSecurity = false;
      } catch (error) {
        if (error.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile - Password",
            error_log: {
              message: error,
            },
          });
        }
        if (error.message) {
          this.showAlert(error.message);
        }
      }
    },

    async updatePhoto() {
      this.uploadingPhoto = true;
      try {
        if (this.isPhotoUpload) {
          let imageToUse;
          if (this.isPhotoComingFromMobileComponent) {
            imageToUse = this.dataURLtoFile(this.picture, "image");
          } else {
            if (this.photoFileType === "application/pdf") {
              imageToUse = this.dataURLtoFile(this.picture, "image");
            } else {
              if (this.isPhotoTaken) {
                imageToUse = this.dataURLtoFile(this.picture, "image");
              } else {
                imageToUse = this.dataURLtoFile(this.imagePreview, "image");
              }
            }
          }

          var photoForm = new FormData();
          photoForm.append("photo", imageToUse);
          if (this.personalOrientation > 0)
            photoForm.append("rotationindex", this.personalOrientation);
          await UsersService.uploadPhoto(photoForm);
        }

        if (this.isIdUpload) {
          let idFront;
          if (this.isPhotoComingFromMobileComponent) {
            idFront = this.dataURLtoFile(this.idPreview, "image");
          } else {
            if (this.idFileType === "application/pdf") {
              idFront = this.dataURLtoFile(this.idCard, "image");
            } else {
              idFront = this.dataURLtoFile(this.idPreview, "image");
            }
          }

          var idForm = new FormData();
          idForm.append("identification", idFront);
          if (this.idOrientation)
            idForm.append("rotationindex", this.idOrientation);
          await UsersService.uploadIdentification(idForm);
        }

        this.isPhotoUpload = false;
        this.isIdUpload = false;
        this.isIdBackUpload = false;
        this.editPhoto = false;
        this.personalOrientation = 0;
        this.idOrientation = 0;
        this.photo = null;
        this.idCard = null;
        this.identification = null;
        this.photoVerificationFileType = "";
        this.idVerificationFileType = "";
        this.resetFields("photo");
        this.uploadingPhoto = false;
      } catch (error) {
        if (error.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile - ID Upload",
            error_log: {
              message: error,
            },
          });
        }
        this.uploadingPhoto = false;
        this.showAlert(error.message);
        return;
      }
    },

    // Patients
    async getVerification() {
      try {
        let res = await PatientService.getVerification();
        if (res && res.data) {
          let data = res.data;

          if (data.photoURL) {
            this.photoVerificationFileType = data.photoFileType;
            this.photoURL = data.photoURL;
            this.showPhoto = true;
            if (data.photoFileType === "application/pdf") {
              this.imagePreview = pdfIcon;
            } else {
              this.imagePreview = data.photoURL;
            }
          }

          if (data.identificationURL) {
            this.idVerificationFileType = data.identificationFileType;
            this.identificationURL = data.identificationURL;
            this.showId = true;
            if (data.identificationFileType === "application/pdf") {
              this.idPreview = pdfIcon;
            } else {
              this.idPreview = data.identificationURL;
            }
          }

          if (data.identificationbackURL) {
            this.identificationBackURL = data.identificationbackURL;
            this.idPreviewBack = data.identificationbackURL;
            this.showBackId = true;
          }
        }
      } catch (error) {
        if (error.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile - ID Upload",
            error_log: {
              message: error,
            },
          });
        }
        if (error.message) {
          console.log("Error", error.message);
        }
      }
    },

    // Shipping
    async getShipping() {
      this.alert.show = false;
      try {
        let res = await ShippingService.listShipping();
        if (res && res.data.length > 0) {
          let data = res.data[0];

          this.shippingId = data.id;
          this.address1 = data.address1;
          this.address2 = data.address2;
          this.state = data.state;
          this.statename =
            data.statename != undefined ? data.statename : data.state;
          this.zipCode = data.zipcode;
          this.city = data.city;
          this.country = data.country;
        } else {
          this.shippingId = "";
          this.address1 = "";
          this.address2 = "";
          this.state = "";
          this.zipCode = "";
          this.city = "";
          this.country = "";
        }
      } catch (error) {
        if (error.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile - Shipping",
            error_log: {
              message: error,
            },
          });
        }
        if (error.message) {
          console.log("Error", error.message);
        }
      }
    },
    setBillingAddress() {
      const data = this.data;
      if (data.hasOwnProperty("billingaddress")) {
        this.billingAddress1 = data.billingaddress.address1;
        this.billingAddress2 = data.billingaddress.address2;
        this.billingState = data.billingaddress.state;
        let filteredState = this.stateoptions.filter(
          (item) => item.value == data.billingaddress.state
        );
        this.billingStateName =
          filteredState.length > 0 ? filteredState[0].text : "";
        this.billingCity = data.billingaddress.city;
        this.billingZipCode = data.billingaddress.zipcode;
        this.billingPhone = data.billingaddress.phoneno;
        this.sameAddress =
          data.billingaddress.sameaddress === true ? "Yes" : "No";
      }
    },
    async updateShipping() {
      this.alert.show = false;
      let id = this.shippingId;
      let data = {
        address1: this.address1,
        address2: this.address2,
        country: this.country,
        zipcode: this.zipCode,
        country: "US",
        city: this.city,
        state: this.state,
      };
      try {
        data = this.clean(data);
        let res = await ShippingService.updateShipping(id, data);
        this.editShippingInfo = false;
      } catch (error) {
        if (error.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile - Shipping",
            error_log: {
              message: error,
            },
          });
        }
        if (error.status == 404) {
          try {
            let res = await ShippingService.addShipping(data);
            this.country = "US";
            this.editShippingInfo = false;
          } catch (err) {
            if (err.status == 500) {
              this.$store.dispatch("sendErrorLog", {
                step: "Profile - Shipping",
                error_log: {
                  message: err,
                },
              });
            }
            this.showAlert(err.message);
          }
        } else {
          this.showAlert(error.message);
          return;
        }
      }
    },

    async updateBillingInfo() {
      this.alert.show = false;

      let billingAddressData = {
        sameaddress: this.sameAddress === "Yes" ? true : false,
      };

      // Convert zipcodes to string
      if (typeof this.billingZipCode == "Number") {
        this.billingZipCode = this.billingZipCode.toString();
      }
      if (!this.billingAddress2) {
        this.billingAddress2 = "";
      }

      let newStr;
      if (this.billingPhone) {
        newStr = this.billingPhone.substring(1);
      }
      if (this.sameAddress === "No") {
        billingAddressData.billingaddress = {
          address1: this.billingAddress1,
          address2: this.billingAddress2,
          zipcode: this.billingZipCode,
          country: "US",
          city: this.billingCity,
          state: this.billingState,
        };
        if (this.billingPhone) {
          billingAddressData.billingaddress.phoneno = newStr.substring(1);
        }
      }
      try {
        console.log(billingAddressData);
        const res = await UsersService.addBillingAddress(billingAddressData);
        console.log("res", res);
        this.editBillingInfo = false;
      } catch (error) {
        if (error.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile - Billing",
            error_log: {
              message: error,
            },
          });
        }
        if (error.status == 404) {
          try {
            // let res = await ShippingService.addShipping(data);
            this.country = "US";
            this.editBillingInfo = false;
          } catch (err) {
            this.showAlert(err.message);
          }
        } else {
          this.showAlert(error.message);
          return;
        }
      }
    },

    //Stripe
    async getCardDetails() {
      this.alert.show = false;
      try {
        let res = await StripeService.getSource();
        if (res && res.data) {
          let data = res.data[0];

          if (data) {
            this.lastFour = "**** ****** " + data.last4;
            if (data.brand === "Visa") {
              this.brand = this.visa;
            } else if (data.brand === "MasterCard") {
              this.brand = this.mastercard;
            }

            this.expiryYear = data.exp_year;
            this.expiryMonth = data.exp_month;
            this.expiry = this.expiryMonth + " / " + this.expiryYear;
          }
        }
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      }
    },

    async getPrescription() {
      try {
        let response = await UsersService.getPrescriptionorder();

        const hasPrescriptions = response.data.length > 0;

        if (!hasPrescriptions) {
          return;
        }

        this.hasPrescription = true;
        this.fullycancelled = this.data.fullycancelled
          ? this.data.fullycancelled
          : false;
      } catch (error) {
        console.log("Error", error.message);
      }
    },

    // User
    async getUser() {
      this.alert.show = false;
      try {
        let res = await UsersService.getCurrent();
        console.log("user", res);
        if (res && res.data) {
          let data = res.data;
          this.data = data;
          this.id = data.id;

          // Segment ebook
          this.analyticsId = data.usercustomid ? data.usercustomid : this.id;

          //Account Details
          this.firstName = data.firstname;
          this.lastName = data.lastname;
          this.birthday = data.birthday
            ? this.formatDateForDisplay(data.birthday)
            : "";
          this.phoneNo = this.formatPhoneNumber(data.phoneno);
          this.email = data.email;
          this.name = data.firstname + " " + data.lastname;
          this.profileUrl = data.profileUrl;
          this.customerId = data.customerid;
          this.password = "";
          this.newEmail = "";
          this.onBoardingStatus = data.onboardingstatus;
          this.verificationEditUnlocked = data.verificationstatus != "OK";
          if (data.hasOwnProperty("identificationupload")) {
            this.idupload = data.identificationupload;
            this.photoupload = data.photoupload;
          }

          this.setBillingAddress();

          this.disableCardUpdate = data.hasOwnProperty("updatingcreditcard");

          if (data.pharmacy) {
            let fullAddress = `${data.pharmacy.address1} ${data.pharmacy.address2} ${data.pharmacy.city} ${data.pharmacy.state} ${data.pharmacy.zipcode}`;
            this.pharmacyName = data.pharmacy ? data.pharmacy.name : "";
            this.pharmacyAddress = fullAddress ? fullAddress : "";
          }
        }
      } catch (error) {
        if (error.message) {
          console.log("Error", error.message);
        }
      }
    },

    async updateUser() {
      this.alert.show = false;
      try {
        let data = {
          firstname: this.firstName,
          lastname: this.lastName,
          birthday: this.birthday ? this.formatDate(this.birthday) : "",
          phoneno: this.phoneNo,
        };

        let res = await UsersService.updateUser(data);
        this.editAcountDetails = false;
      } catch (error) {
        if (erorr.status == 500) {
          this.$store.dispatch("sendErrorLog", {
            step: "Profile - User",
            error_log: {
              message: error,
            },
          });
        }
        if (error.message) {
          this.showAlert(error.message);
        } else {
          this.showAlert(error);
        }
      }
    },
    async updatePharmacy() {
      this.alert.show = false;
      let pharmacyid = this.selectedPharmacy.PharmacyId;
      try {
        let data = {
          pharmacyid: JSON.stringify(pharmacyid),
        };
        let res = await UsersService.updatePharmacy(data);
        this.pharmacyName = this.selectedPharmacy.StoreName;
        this.pharmacyAddress = `${this.selectedPharmacy.Address1} ${this.selectedPharmacy.Address2} ${this.selectedPharmacy.City} ${this.selectedPharmacy.State} ${this.selectedPharmacy.ZipCode}`;
        this.editPharmacy = false;
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      }
    },
    clean(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] == ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
    onStreetAddressShippingChange(val) {
      this.$refs.streetAddressShipping.update(
        this.removeAccents(this.address1)
      );
    },
    onStreetAddressShippingInputChange(val) {
      this.address1 = this.removeAccents(
        this.$refs.streetAddressShipping.autocompleteText
      );
    },
    onStreetAddressBillingChange(val) {
      this.$refs.streetAddressBilling.update(
        this.removeAccents(this.billingAddress1)
      );
    },
    onStreetAddressBillingInputChange(val) {
      this.billingAddress1 = this.removeAccents(
        this.$refs.streetAddressBilling.autocompleteText
      );
    },
  },
  computed: {
    editGroup() {
      return (
        !this.editAcountDetails &&
        !this.editShippingInfo &&
        !this.editBillingInfo &&
        !this.editCreditCard &&
        !this.editSecurity &&
        !this.editPharmacy &&
        !this.editPhoto
      );
    },
    idFileType() {
      return this.identification?.type || "";
    },
    photoFileType() {
      return this.photo?.type || "";
    },
    hasSkippedUpload() {
      return this.idupload == "SKIPPED" || this.photoupload == "SKIPPED";
    },
    isAndroidWebView() {
      if (isAndroid) {
        // Instagram WebView
        if (navigator.userAgent.match(/instagram/i)) {
          return true;
        }

        // Facebook WebView
        let ua = navigator.userAgent || navigator.vendor || window.opera;
        if (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1) {
          return true;
        }

        return false;
      } else {
        return false;
      }
    },
    isAndroid11WebView() {
      return (
        this.isAndroidWebView && osName === "Android" && osVersion === "11"
      );
    },
  },
  async mounted() {
    this.$_analytics_page();
    this.showLoader = true;
    try {
      this.$root.$on("bv::modal::hide", () => {
        this.stopCameraStream(); // trigger stopCameraStream to stop camera on running in background
      });
      if (this.stateoptions.length === 0) {
        const stateData = await LookUpService.getLookUp("state");
        //this.states = stateData.data;
        stateData.data.forEach((data) => {
          //if (data.supported == true)
          this.stateoptions.push({ value: data.id, text: data.name });
        });
      }
      if (!localStorageService.getItem("token")) {
        return this.$router.push("/login");
      }

      if (this.$route.query.discount) {
        this.showDiscountMessage = true;
      }

      await this.getUser();
      this.getShipping();
      this.getVerification();
      this.getCardDetails();
      this.getPrescription();

      // WIN-8551
      if (this.$route.name == "billing-details") {
        this.showLoader = true;
        this.updateBilling();
        // WIN-8678
      } else if (this.$route.name == "billing-address") {
        this.showLoader = true;
        this.editBillingInfo = true;
      } else if (this.$route.name == "shipping-address") {
        this.showLoader = true;
        await this.getShipping();
        this.editShippingInfo = true;
        console.log("editshippinginfo", this.editShippingInfo);
      } else if (this.$route.name == "account-identity-verification") {
        this.showLoader = true;
        this.editPhoto = true;
        if (!this.verificationEditUnlocked) {
          this.editPhoto = false;
          history.pushState({}, null, "/account-settings");
        }
      } else if (this.$route.name == "update-email") {
        this.showLoader = true;
        this.editSecurity = true;
      }
      await this.$nextTick();
      this.showLoader = false;
    } catch {
    } finally {
      this.showLoader = false;
    }
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 11px;
  color: #828282;
}

.text-primary {
  letter-spacing: 0.75px;
}

.form-control[readonly] {
  border: 0;
}

.input-group > .input-group-prepend > .input-group-text {
  border: 0;
  background-color: #f2f2f2;
}

.card {
  background-color: transparent;
  border: 0;
}

.photo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #edf5fb;

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 325px;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
