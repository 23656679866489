import { isAndroid, osVersion, osName, deviceDetect } from 'mobile-device-detect';
import DeviceDetector from 'device-detector-js';

export const isAndroidWebView = (() => {
  if (!isAndroid) {
    return false;
  }

  // Instagram WebView
  if (navigator.userAgent.match(/instagram/i)) {
    return true;
  }

  // Facebook WebView
  const ua = navigator.userAgent || navigator.vendor || window.opera;
  if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
    return true;
  }

  return false;
})();

export const isAndroid11WebView =
  isAndroidWebView && osName === 'Android' && osVersion === '11';

export const getDevice = () => {
  const detect = deviceDetect();

  if (detect.isMobileOnly === true) {
    return 'Mobile';
  }

  if (detect.isTablet === true) {
    return 'Tablet';
  }

  if (detect.isMobile === true) {
    return 'Mobile';
  }

  if (detect.isSmartTV === true) {
    return 'SmartTV';
  }

  if (detect.isWearable && detect.isWearable === true) {
    return 'Wearable';
  }

  return 'Desktop'
}

export const getHardwareModel = () => {
  const deviceDetector = new DeviceDetector();
  const detectedDevice = deviceDetector.parse(navigator.userAgent);

  return `${detectedDevice.device.brand} ${detectedDevice.device.model}`.trim();
}