<template>
  <div class="tw-bg-pastel-mint tw-py-[14px] tw-px-[20px] tw-rounded-lg tw-flex tw-gap-2 tw-items-center">
    <IconCardCheck class="tw-text-black tw-shrink-0" />

    <div>
      <h4 class="tw-text-[12px] tw-font-bold tw-tracking-wider">
        HSA/FSA-eligible
      </h4>
      <p class="tw-text-[11px]">
        Enter the card details as you would a normal credit card. Any forms you require can be found in the portal after
      </p>
    </div>
  </div>
</template>

<script>
/**
import OnboardingHsaFsaBadge from '@/components/onboarding/OnboardingHsaFsaBadge'
 */

//  components
import IconCardCheck from '@/components/icon/IconCardCheck';

// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

export default {
  components: {
    IconCardCheck,
  },

  computed: {
    ...mapState('cro', ['isHsaFsaBadgeTracked']),
  },

  mounted () {
    if (this.isHsaFsaBadgeTracked) {
      return
    }

    this.$store.commit('cro/SET_STATE', {
      isHsaFsaBadgeTracked: true,
    });
  },
}
</script>

<style lang="scss" scoped>
h4, p {
  margin: 0;
  padding: 0;
}
</style>