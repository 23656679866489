<template>
  <b-container fluid>
    <!-- for Desktop Only Progress -->
    <OnboardingProgress
      :progress="progress"
      @back="prev"
    />
    <b-row
      class="onboarding-wrapper"
      :style="{
        marginBottom: $_onboardingCtaFooter_marginBottom
      }"
    >
      <OnboardingHeader
        step="3"
        :title="title"
        @back="prev"
      />
      <b-col
        cols="12"
        lg="5"
        xl="5"
        xxl="4"
        class="mt-6 pt-3 onboarding-content"
      >
        <b-alert
          :show="alert.show"
          :variant="alert.type"
        >
          {{
            alert.message
          }}
        </b-alert>
        <!-- <h6 class="d-lg-none mt-3 mb-2 step">Step 3 of 4</h6> -->

        <!-- mobile progress -->
        <b-row class="d-lg-none">
          <b-col>
            <h3 class="bold d-letter-spacing onboarding-title">
              Onboarding Completion
            </h3>
          </b-col>
          <b-col>
            <h3
              class="
                bold
                d-letter-spacing
                onboarding-title
                text-muted text-right
              "
            >
              <!-- Onboarding Complete -->
            </h3>
          </b-col>
        </b-row>
        <b-progress
          :value="60"
          :max="100"
          class="step-progress d-lg-none mb-5"
          variant="purple"
          height="5px"
        />

        <ProductReveal
          v-if="step == 'productreveal'"
          ref="productReveal"
          :body="revealedProducts"
          :product-revealed="productRevealed"
          :selectedproduct="desiredplan"
          :user-id="userid"
          :analytics-id="analyticsId"
          :button-disabled="buttonDisabled"
          :email="email"
          :state="state"
          :phoneno="phoneno"
          :firstname="firstname"
          :lastname="lastname"
          :onboardingflow="onboardingflow"
          :onboarding-data="onboardingData"
          @submitAnswer="submitAnswer"
          @exit="exit"
        />
        <Single
          v-if="step == 'preferredshipment'"
          class="shipping-price"
          :body="shippinginfo"
          @submitAnswer="submitAnswer"
        />
        <PreferredTreatment
          v-if="step == 'preferredtreatment'"
          ref="PreferredTreatment"
          :saving="treatmentformsaving"
          @submitAnswer="submitAnswer"
          @alert="showAlert"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
        xl="3"
        xxl="4"
      />
    </b-row>
  </b-container>
</template>

<script>
// libs
import _kebabCase from 'lodash/kebabCase'

// services
import { OnboardingService } from '@/services/onboarding.service';
import { LookUpService } from '@/services/lookup.service';
import { ProductService } from '@/services/product.service';

import ProductReveal from '@/components/onboarding/treatments/ProductReveal';
import Single from '@/components/onboarding/questions/Single';
import PreferredTreatment from '@/components/onboarding/treatments/PreferredTreatment';
import OnboardingHeader from '@/components/partials/OnboardingHeader';
import OnboardingProgress from '@/components/partials/OnboardingProgress';

// mixins
import analyticsMixin from '@/mixins/analytics';
import { onboardingCtaFooter } from '@/mixins/onboardingCtaFooter';

export default {
  components: {
    ProductReveal,
    Single,
    PreferredTreatment,
    OnboardingHeader,
    OnboardingProgress,
  },
  mixins: [analyticsMixin, onboardingCtaFooter],
  beforeRouteLeave(to, from, next) {
    if (
      this.step == 'productreveal' ||
      to.path == '/identity-verification' ||
      this.exiting
    ) {
      next();
    } else {
      this.prev();
      next(false);
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userid: null,
      analyticsId: null,
      treatmentformsaving: false,
      currentonboardingstep: null,
      alert: {
        show: false,
        type: '',
        message: '',
      },
      onboardingData: {},
      step: 'productreveal',
      revealedProducts: [],
      shippinginfo: {
        question: 'Choose your shipping & billing frequency',
        header:
          'Bundle your treatments to save money — this can be changed at any time.',
        id: 'preferredShipment',
        answerlist: [],
      },
      agreedtomedication: null,
      letdoctorchoose: null,
      preferredshipment: null,
      desiredplan: [],
      productRevealed: false,
      exiting: false,

      email: '',
      firstname: '',
      lastname: '',
      phoneno: '',
      state: '',
      buttonDisabled: false,
      onboardingflow: 'flowA',

      // for new progress UI update on the header
      progress: [
        {
          title: 'Account Creation',
          value: 100,
          max: 100,
        },
        {
          title: 'Intake',
          value: 5,
          max: 5,
        },
        {
          title: 'Onboarding Complete',
          value: 1,
          max: 5,
        },
      ],
    };
  },
  watch: {
    step: {
      immediate: true,
      handler () {
        switch (this.step) {
          case 'productreveal':
            this.$set(this.progress[2], 'value', 1);
            break;
          case 'preferredshipment':
            this.$set(this.progress[2], 'value', 1.2);
            break;
          case 'preferredtreatment':
            this.$set(this.progress[2], 'value', 1.2);
            break;
        }
      }
    }
  },
  async mounted() {
    try {
      this.$_analytics_page();
      const onboardingdata = await OnboardingService.getPatientOnboarding();

      this.$store.commit('onboarding/SET_STATE', {
        state: 'onboardingPatient',
        value: onboardingdata.data,
      })

      this.onboardingData = onboardingdata.data;
      this.email = onboardingdata.data.email;
      this.firstname = onboardingdata.data.firstname;
      this.lastname = onboardingdata.data.lastname;
      this.phoneno = onboardingdata.data.phoneno;
      this.state = onboardingdata.data.state;

      this.onboardingflow = onboardingdata.data.onboardingflow;

      this.userid = onboardingdata.data.id;
      this.analyticsId = onboardingdata.data.usercustomid
        ? onboardingdata.data.usercustomid
        : onboardingdata.data.id;
      this.currentonboardingstep = onboardingdata.data.onboardingstep;

      // Redirect back if not done yet
      if (this.currentonboardingstep < 3) {
        this.exiting = true;
        window.location.href = '/';
      }

      this.desiredplan = [];

      this.revealedProducts = await this.$store.dispatch(
        'onboarding/getProductReveal'
      );

      if (onboardingdata.data.agreedtomedication === undefined) {
        this.desiredplan = [];
        for (const record of this.revealedProducts) {
          this.desiredplan.push(record.id);
        }
      }

      const shipmentData = await LookUpService.getLookUp('preferredshipment');
      this.shippinginfo.answerlist = shipmentData.data;
      this.productRevealed = true;
    } catch (err) {
      if (err.status == 401) {
        this.exiting = true;
        this.$router.push('/login');
      } else {
        const id = this.userid ? this.userid : 'Could not get customer ID';
        const email = this.email ? this.email : 'Could not get patient email';
        await this.$store.dispatch('sendErrorLog', {
          step: 'Treatment Options - Loading',
          cus_id: id,
          email: email,
          error_log: err,
        });
      }
    }
  },
  methods: {
    // Answer handling and redirection
    async submitAnswer(params, answer = null) {
      this.alert.show = false;
      this.buttonDisabled = true;
      switch (this.step) {
        case 'productreveal':
          this.agreedtomedication = params[0].answer;
          this.desiredplan = params[1].answer;

          if (
            this.agreedtomedication === true &&
            this.desiredplan.length === 0 &&
            this.revealedProducts.length > 0
          ) {
            this.showAlert(
              'Please select at least one from our product recommendation.'
            );
            this.buttonDisabled = false;
            break;
          }

          // Redirects to specific steps
          await this.$_analytics_track(
            'Onboarding Screen Viewed',
            {
              customerId: this.analyticsId,
              screen: '3.3-A',
              onboardingflow: this.onboardingflow,
              firstname: this.firstname,
              lastname: this.lastname
            },
          );
          //**************************************** JAMES UPDATE ****************************************//
          if (
            this.agreedtomedication === true &&
            this.revealedProducts.length > 0
          ) {
            //****** CUSTOM PARSING OF TEXT DESCRIPTION ON SHIPMENT DEPENDING ON THE SELECTED PRODUCT ******//
            for (const shipment of this.shippinginfo.answerlist) {
              let totalamount = 0;
              for (const revealprod of this.revealedProducts.filter(x =>
                this.desiredplan.includes(x.id)
              )) {
                for (const price of revealprod.availablepricing.filter(
                  x => x.subscriptiontype === shipment.subscriptiontype
                )) {
                  totalamount += price.price;
                }

                // IF THERE IS NO MONTHLY PRICING AVAILABLE, USE THE QUARTERLY THEN DIVIDE IT BY 3
                if (
                  revealprod.availablepricing.filter(
                    x => x.subscriptiontype === 'Monthly'
                  ).length === 0 &&
                  shipment.subscriptiontype === 'Monthly'
                ) {
                  const quarterlyprice = revealprod.availablepricing.find(
                    x => x.subscriptiontype === 'Quarterly'
                  );
                  if (quarterlyprice != undefined) {
                    totalamount += quarterlyprice.price / 3;
                  }
                }

                // IF THERE IS NO QUARTERLY PRICING AVAILABLE, USE THE MONTHLY THEN MULTIPLY IT BY 3
                if (
                  revealprod.availablepricing.filter(
                    x => x.subscriptiontype === 'Quarterly'
                  ).length === 0 &&
                  shipment.subscriptiontype === 'Quarterly'
                ) {
                  const monthlyprice = revealprod.availablepricing.find(
                    x => x.subscriptiontype === 'Monthly'
                  );
                  if (monthlyprice != undefined) {
                    totalamount += monthlyprice.price * 3;
                  }
                }
              }

              if (shipment.subscriptiontype === 'Quarterly') {
                shipment.description = `Receive a 3 month supply for $${totalamount.toFixed(
                  2
                )} every 3 months, cancel anytime.`;
                shipment.discountedprice = `$${(totalamount / 3).toFixed(
                  2
                )}/mo`;
                shipment.totalpricepermonth = Number(
                  (totalamount / 3).toFixed(2)
                );
              } else if (shipment.subscriptiontype === 'Monthly') {
                shipment.description =
                  'Receive your treatment every month, cancel anytime. ';
                shipment.regularprice = `$${totalamount.toFixed(2)}/mo`;
                shipment.totalprice = Number(totalamount.toFixed(2));
              }
            }

            //FINALIZE
            const monthyshipment = this.shippinginfo.answerlist.find(
              x => x.subscriptiontype === 'Monthly'
            );
            for (const shipment of this.shippinginfo.answerlist.filter(
              x => x.subscriptiontype != 'Monthly'
            )) {
              if (monthyshipment.totalprice != shipment.totalpricepermonth) {
                shipment.regularprice = `$${monthyshipment.totalprice}`;
              } else {
                delete shipment.discountedprice;
                shipment.regularprice = `$${shipment.totalpricepermonth}/mo`;
              }
            }
          } else {
            for (const shipment of this.shippinginfo.answerlist) {
              shipment.regularprice = '';
              shipment.description = '';
              delete shipment.discountedprice;
            }
          }
          //**********************************************************************************************//

          if (params[0].answer) {
            this.buttonDisabled = false;
            this.submitToOnboarding();
          } else {
            this.buttonDisabled = false;
            this.step = 'preferredtreatment';
          }
          break;
        case 'letDoctorChoose':
          this.letdoctorchoose = params.answer;
          break;
        case 'preferredtreatment':
          if (params.doctorPref) {
            this.letdoctorchoose = true;
            this.step = 'preferredshipment';
          } else {
            this.treatmentformsaving = true;

            const previousreveal = this.revealedProducts;
            await OnboardingService.updateTreatmentForm({
              treatmentform: answer,
            });

            const reveal = await this.$store.dispatch(
              'onboarding/getProductReveal'
            );

            const newproductsfromreveal = [];
            for (const revrecord of reveal) {
              if (
                previousreveal.filter(x => x.id === revrecord.id).length === 0
              ) {
                newproductsfromreveal.push(revrecord.id);
              }
            }

            this.revealedProducts = reveal;
            this.desiredplan = this.desiredplan.concat(newproductsfromreveal);

            this.letdoctorchoose = false;
            this.treatmentformsaving = false;
            this.buttonDisabled = false;
            this.step = 'productreveal';
          }

          break;
        case 'preferredshipment':
          this.preferredshipment = answer;
          this.submitToOnboarding();
          break;
      }
    },
    async fbqTrack(data) {
      try {
        // FB Pixel Tracking
        fbq('init', '348219289912097', {
          em: this.email,
          fn: this.firstname,
          ln: this.lastname,
          ph: this.phoneno,
          external_id: this.userid,
          extern_id: this.userid,
        });
        fbq(
          'trackCustom',
          'TreatmentChosen',
          {
            external_id: this.userid,
            extern_id: this.userid,
            treatment: this.selectedproduct,
            state: this.state,
            ...(await this.$store.dispatch('getFBQParams')),
          },
          {
            eventID: data.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    // * Separate method to prepare params
    async submitToOnboarding() {
      this.buttonDisabled = true;
      let params;
      if (this.agreedtomedication) {
        params = {
          agreedtomedication: this.agreedtomedication,
        };
      } else if (this.letdoctorchoose) {
        params = {
          agreedtomedication: this.agreedtomedication,
          letdoctorchoose: this.letdoctorchoose,
          preferredshipment: this.preferredshipment,
        };
      } else {
        params = {
          agreedtomedication: this.agreedtomedication,
          letdoctorchoose: this.letdoctorchoose,
          desiredplan: this.desiredplan,
        };
      }

      if (this.desiredplan.length > 0 && !this.letdoctorchoose) {
        params.desiredplan = this.desiredplan;
      }

      this.$emit('setOnboardingStep', 3.2);
      try {
        const data = await OnboardingService.updatePatientOnboarding(
          3.2,
          params
        );

        const { data: nonrxProducts } = await ProductService.listProductNonRX();
        const nonrxIds = nonrxProducts.map(product => product.id);

        const selectedAddons = [];
        for (const product of this.desiredplan) {
          if (nonrxIds.indexOf(product) > -1) selectedAddons.push(product);
        }

        const addonsToSubmit = [];
        for (const addon of selectedAddons) {
          addonsToSubmit.push({ productid: addon });
        }
        await OnboardingService.updatePatientOnboarding(4, {
          desiredaddon: addonsToSubmit,
        });

        const products = {
          // WIN-53617
          products: this.desiredplan.map(plan => {
            const productObj = this.revealedProducts.find(product => plan.productid === product.id)
            return {
              name: productObj.name,
              product_id: productObj.id,
              category: 'core_products',
              sku: productObj.id,
              price: productObj.availablepricing.find(pricing => pricing.pricingid === plan.pricingid).price,
              url: `${process.env.VUE_APP_WEBSITE_URL}/product/${_kebabCase(productObj.name)}`,
              image_url: productObj.image
            }
          }),
        }

        if (this.currentonboardingstep === 3.2) {
          await this.$_analytics_track('Treatment Option Chosen',
            {
              treatment: JSON.parse(JSON.stringify(this.desiredplan)),
              customerId: this.analyticsId,
              onboardingflow: this.onboardingflow,
              phoneno: this.phoneno,
              state: this.state,
              email: this.email,
              messageID: data.data.eventid,
              firstname: this.firstname,
              lastname: this.lastname,
              ...products,
              currency: 'USD',
            },)

          // WIN-14971
          // WIN-20853 - Remove Northbeam from Onboarding
          // window.Northbeam.fireCustomGoal("Treatment Chosen", {});
          // console.log("Northbeam: Treatment Chosen");

          // GTM: Mariwin
          // window.dataLayer = window.dataLayer || [];
          // window.dataLayer.push({
          //   event: "Treatment Option Chosen",
          //   customerID: this.userid,
          //   treatment: this.desiredplan,
          // });

          if (data.data.eventid) {
            await this.fbqTrack(data.data);
          } else if (
            data.data.eventid &&
            process.env.VUE_APP_SERVER != 'production'
          ) {
            //alert('FB TRACKING TreatmentChosen: '+ data.data.eventid);
            console.log('FB TRACKING TreatmentChosen');
            console.log('FB TRACKING TreatmentChosen: ' + data.data.eventid);
          }
        }

        this.$router.push('/identity-verification').catch(() => {});
      } catch (err) {
        if (err.status == 401) {
          this.exiting = true;
          this.$router.push('/login');
        } else {
          this.buttonDisabled = false;
          const id = this.userid ? this.userid : 'Could not get customer ID';
          const email = this.email ? this.email : 'Could not get patient email';
          await this.$store.dispatch('sendErrorLog', {
            step: 'Treatment Options',
            cus_id: id,
            email: email,
            error_log: err,
          });
          this.showAlert(err);
        }
      }
      this.buttonDisabled = false;
    },
    prev() {
      this.alert.show = false;
      switch (this.step) {
        case 'productreveal':
          this.$emit('setOnboardingStep', 3.1);
          this.$router.push('/health-questions').catch(() => {});
          break;
        case 'preferredshipment':
          // Tests if the user answered no
          this.step = this.agreedtomedication
            ? 'productreveal'
            : 'preferredtreatment';
          break;
        case 'preferredtreatment':
          this.step = 'productreveal';
          break;
      }
    },
    exit() {
      this.exiting = true;
      window.location.href = process.env.VUE_APP_WEBSITE_URL;
    },
    showAlert(message, type = null) {
      if (!type) type = 'warning';
      this.alert = {
        show: true,
        type: 'warning',
        message: message,
      };
      window.scrollTo(0, 0);
    },
  },
};
</script>
