<template>
  <b-form @submit.prevent="personalDetails()">
    <b-row
      align-h="center"
      align-v="stretch"
      style="min-height: calc(100vh - 180px)"
    >
      <b-col
        cols="12"
        xxl="6"
        align-self="center"
      >
        <b-row align-h="center">
          <b-col
            cols="12"
            style="height: 150px"
            class="mb-6"
          >
            <b-row
              align-v="end"
              style="height: 100%"
              class="pl-5 pb-3"
            >
              <b-col
                cols="12"
              >
                <h1
                  class="mb-3 heading text-primary"
                  style="font-size: 46px; line-height: 100%"
                >
                  Contact Us
                </h1>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            align-self="start"
          >
            <b-row class="pl-5 pr-5">
              <b-col
                cols="12"
                md="4"
              >
                <h2 class="heading text-primary mb-4">
                  Get In Touch
                </h2>
                <address style="font-size: 20px">
                  E: hello@bywinona.com<br>
                  <!-- <a href="tel:(844) 929-1586">(844) 929-1586</a><br> -->
                  T: (844) 929-1586 <br>
                  <!-- 1013 Centre Road, Suite 403-A, <br>
                  Wilmington Delaware, 19805 -->
                </address>
                <a
                  href="https://www.facebook.com/winonalife"
                  target="_blank"
                  class="mt-2 mr-2"
                ><img
                  src="@/assets/images/facebook.svg"
                  alt="facebook"
                ></a>
                <a
                  href="https://twitter.com/wearewinona"
                  target="_blank"
                  class="mt-2 mr-2"
                ><img
                  src="@/assets/images/twitter.svg"
                  alt="twitter"
                ></a>
                <a
                  href="https://www.instagram.com/winonalife/"
                  target="_blank"
                  class="mt-2 mr-2"
                ><img
                  src="@/assets/images/instagram.svg"
                  alt="instagram"
                ></a>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <b-alert
                  :show="alert.show"
                  :variant="alert.type"
                >
                  {{
                    alert.message
                  }}
                </b-alert>
                <b-row>
                  <b-col cols="12">
                    <div class="mt-5 d-md-none"></div>
                    <h2
                      for="subject"
                      class="heading text-primary"
                    >
                      Submit an inquiry:
                    </h2>
                    <div
                      role="group"
                      class="mb-3"
                    >
                      <v-select
                        v-model="selected"
                        append-to-body
                        :calculate-position="withPopper"
                        label="text"
                        :options="options"
                        :reduce="(options) => options.text"
                      >
                        <template #open-indicator="{ attributes }">
                          <b-icon
                            v-bind="attributes"
                            icon="chevron-down"
                          />
                        </template>
                      </v-select>
                      <!-- <b-form-select id="subject" class="pl-0" v-model="selected" :options="options"></b-form-select> -->
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div
                      role="group"
                      class="mb-3"
                    >
                      <label for="input-live">Email Address:</label>
                      <b-form-input
                        id="email"
                        v-model="email"
                        class="pl-0 fs-mask"
                        type="email"
                        autocomplete="off"
                        :state="emailState"
                        readonly
                      />
                      <b-form-invalid-feedback id="input-live-feedback">
                        The email must have '@email.com'
                      </b-form-invalid-feedback>
                    </div>
                  </b-col>
                  <b-col
                    v-if="name"
                    cols="12"
                    md="6"
                  >
                    <div
                      role="group"
                      class="mb-3"
                    >
                      <label for="name">Your Name: </label>
                      <b-form-input
                        id="name"
                        v-model="name"
                        class="pl-0 fs-mask"
                        type="text"
                        autocomplete="off"
                        readonly
                      />
                    </div>
                  </b-col>

                  <b-col
                    v-if="phone"
                    cols="12"
                    :md="name ? 6 : 12"
                  >
                    <div
                      role="group"
                      class="mb-3"
                    >
                      <label for="phone">Phone Number:</label>
                      <b-form-input
                        id="phone"
                        v-model="phone"
                        class="pl-0 fs-mask"
                        type="text"
                        autocomplete="off"
                        readonly
                        required
                      />
                    </div>
                  </b-col>

                  <b-col cols="12">
                    <div
                      role="group"
                      class="mb-4"
                    >
                      <label for="textarea">Message:</label>
                      <b-form-textarea
                        id="textarea"
                        v-model="message"
                        class="pl-0"
                        rows="3"
                        max-rows="6"
                        style="overflow: auto"
                        autocomplete="off"
                        required
                        @focus="toggleBodyClass('addClass', 'stopScroll')"
                        @blur="toggleBodyClass('removeClass', 'stopScroll')"
                      />
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    class="text-right mb-3"
                    style="padding-bottom: 8rem"
                  >
                    <b-button
                      pill
                      size="lg"
                      type="submit"
                      variant="info"
                      style="min-width: 200px"
                    >
                      Submit
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { ContactService } from '@/services/contact.service';
import { UsersService } from '@/services/users.service';
import popper from '@/assets/js/popper.js';
import formValidation from '../form/mixins';
import analyticsMixin from '@/mixins/analytics';
import { mapGetters } from 'vuex';

export default {
  name: 'ContactUs',
  mixins: [formValidation, popper, analyticsMixin],
  data() {
    return {
      data: [],
      alert: {
        show: false,
        type: 'warning',
        message: '',
      },
      phone: '',
      name: '',
      email: '',
      message: '',
      selected: '',
      options: [
        { value: 'subject1', text: 'How It Works' },
        { value: 'subject2', text: 'Manage Account' },
        // { value: "subject3", text: "Medical Question" },
        { value: 'subject4', text: 'Orders & Shipping' },
        { value: 'subject5', text: 'Products' },
        { value: 'subject6', text: 'Cancel Subscription' },
        { value: 'subject7', text: 'Others' },
      ],
      lastPos: '0',
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  async mounted() {
    this.$_analytics_page();
    await this.getUser();
    let lockScroll = false;
    let lastPos = 0;
    const textArea = document.querySelector('#textarea');
    this.$el.offsetParent.classList.add('scrollthis');
    textArea.addEventListener('focus', e => {
      lockScroll = true;
      lastPos = this.$el.offsetParent.scrollTop;
    });

    textArea.addEventListener('blur', e => {
      lockScroll = false;
    });

    textArea.addEventListener(
      'scroll',
      e => {
        if (lockScroll) {
          e.target.scrollTop = lastPos;
        }
      },
      true
    );
  },
  methods: {
    showAlert(message, type = null) {
      this.alert = {
        show: true,
        type: 'warning',
        message: message,
      };
    },
    async personalDetails() {
      this.alert.show = false;
      if (!this.name) {
        this.showAlert('Name is required');
        return;
      }
      if (!this.email) {
        this.showAlert('Invalid email address');
        return;
      }
      if (!this.message) {
        this.showAlert('Email message body is blank');
        return;
      }
      const data = {
        subject: this.selected,
        body: this.message,
      };
      try {
        const res = await ContactService.sendEmail(data);
        this.$router.push('/');
      } catch (error) {
        if (error.message) {
          this.showAlert(error.message);
        }
      }
    }, // User
    async getUser() {
      this.alert.show = false;
      try {
        const res = await UsersService.getCurrent();
        if (res && res.data) {
          const data = res.data;
          this.data = data;
          this.email = data.email;
          this.phone = data.phoneno;
          if (data.firstname && data.lastname) {
            this.name = data.firstname + ' ' + data.lastname;
          } else {
            this.name = '';
          }
        }
      } catch (error) {
        if (error.message) {
          console.log('Error', error.message);
        }
      }
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
};
</script>

<style scoped>
/deep/.vs__dropdown-toggle {
  border-width: 2px;
  border-color: #1a6a72;
  font-size: 20px;
}

/deep/.form-control {
  border-color: #1a6a72 !important;
  color: #19191c !important;
  font-size: 18px !important;
}
</style>

<style lang="scss" scoped>
.navbar {
  height: 100px;
  background-color: #d8cdef;
}

label {
  font-size: 14px;
  color: #1a6a72;
}
</style>
