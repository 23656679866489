<template>
  <div class="eligibilityWaitlist">
    <h2 class="mb-3 question-title">
      At the moment, we don't offer treatment in your state
    </h2>
    <p>
      Our goal is to open up in your state soon. In the meantime, would you be
      interested in joining our waitlist?
    </p>
    <w-checkbox
      id="yes"
      v-model="waitlist"
      type="radio"
      label="Yes"
      value="true"
      @change="textbox = true"
    />
    <w-checkbox
      id="no"
      v-model="waitlist"
      type="radio"
      label="No"
      value="false"
      @change="submitAnswer"
    />
    <div v-if="textbox">
      <label for="email">What is your email address?</label>
      <b-form-input
        v-model="email"
        class="fs-mask"
        name="email"
        placeholder="Email"
      />

      <OnboardingCTAContainer>
        <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
          <b-button
            pill
            variant="info"
            class="px-5 mt-4 slight-rounded"
            @click="submitAnswer"
          >
            Continue
          </b-button>
        </div>
      </OnboardingCTAContainer>
    </div>
  </div>
</template>

<script>
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer.vue';

import { OnboardingService } from '@/services/onboarding.service';

import analyticsMixin from '@/mixins/analytics';

export default {
  components: {
    OnboardingCTAContainer,
  },
  mixins: [analyticsMixin],
  props: {
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    state: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      waitlist: null,
      textbox: false,
      email: '',
    };
  },
  async mounted() {
    await this.$_analytics_track(
      'Onboarding Screen Viewed',
      {
        // customerId: <Customer ID.>,
        screen: '1.1.1-A',
        onboardingflow: this.onboardingflow,
      },
    );
  },
  methods: {
    async submitAnswer() {
      if (this.waitlist == 'true' && this.email == '') {
        this.$emit('showAlert', 'Please enter in your email.');
        return;
      }

      if (this.waitlist == 'true') {
        // send email data
        const body = { email: this.email, state: this.state };
        console.log(body);
        await OnboardingService.collectEmail(body);

        this.$emit('setStep', 'thankyou');

        await this.$_analytics_track(
          'Onboarding Screen Viewed',
          {
            screen: '1.1.1.1-A',
          },
        );
      } else {
        this.$emit('exit');
      }
    },
  },
};
</script>
