<template>
  <div
    class="checkout-summary"
  >
    <OnboardingModal
      v-if="showModal"
      size="lg"
      :body="modalBody"
      @submitAnswer="openPharmSelect"
    />

    <SideModal
      :show="showEditModal"
      :show-close="true"
      body-class="terms-body"
      @exit="showEditModal = false"
    >
      <template #body>
        <h2 class="question-title with-subtext">
          Edit your prescribed treatments
        </h2>
        <h3 class="sub-text mb-6">
          Change of mind? Here are the recommended treatments:
        </h3>
        <EditCheckout
          :desired-plan="desiredPlan"
          :product-reveal="productreveal"
          :treatment-form="treatmentform"
          @close-edit="showEditModal = false"
          @update-checkout="
            $emit('reload-summary');
            showEditModal = false;
          "
          @get-product-reveal="getProductReveal"
        />
      </template>
    </SideModal>
    <div
      class="bg-overlay"
      @click="showEditModal = false"
    ></div>

    <SideModal
      :show="riskModal"
      header-class="terms-title"
      body-class="terms-body"
      @exit="hideRiskModal()"
    >
      <template #header>
        <h1 class="h2 mb-0 heading text-primary">
          Safety Information
        </h1>
      </template>
      <template #body>
        <Risk />
        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              class="slight-rounded"
              pill
              variant="info"
              style="min-width: 250px"
              @click="agreeTerms()"
            >
              I Agree
            </b-button>
          </b-col>
        </b-row>
      </template>
    </SideModal>
    <div
      class="bg-overlay"
      @click="hideRiskModal()"
    ></div>

    <div
      v-if="
        !nonrx && body.hasOwnProperty('desiredplan') && desiredPlan.length > 0
      "
    >
      <h2 class="question-title with-subtext">
        Your visit summary
      </h2>
      <h3 class="sub-text mb-5">
        Almost done! Here’s your personalized treatment plan for the doctor to
        review. Our board certified doctors are here to determine the right
        treatment plan for you. In the next step, you’ll add billing information
        to your account and then a Winona doctor will review your medical
        history and address any questions.
      </h3>

      <div class="d-flex justify-content-between">
        <h3
          class="sub-text mb-4"
          style="margin-top: 10px"
        >
          Prescription Treatment
        </h3>
        <span
          class="sub-text mb-4"
          style="margin-top: 10px"
        >
          <a
            href="javascript:void(0)"
            :disabled="loading"
            style="color: #1a6a72"
            @click="showEditModal = true"
          >
            <u>EDIT</u>
          </a>
        </span>
      </div>
      <b-card
        v-for="med in desiredPlan.filter(
          (x) =>
            x.type === undefined || (x.type != undefined && x.type === 'RX')
        )"
        :key="med.id"
        no-body
        style="background-color: rgba(242, 242, 242, 0.5)"
        class="py-2"
      >
        <b-row
          no-gutters
          align-v="stretch"
        >
          <b-col
            :cols="med.image ? '8' : '12'"
            class="d-flex flex-column justify-content-between pl-4 pr-2 h-100"
            align-self="center"
          >
            <div class="mb-4">
              <h3 class="mb-2 med-font">
                {{ med.name }}
              </h3>
              <p>{{ med.pricingdetail.nickname }}</p>
            </div>
            <p class="med-font mb-0">
              <!-- med.pricingdetail.hidebillingfrequency -->
              <span
                v-if="med.pricingdetail.hasOwnProperty('hidebillingfrequency')"
              >
                ${{ med.price }}
              </span>
              <span v-else>
                {{
                  formatPricing(
                    med.pricingdetail.type,
                    med.price,
                    med.pricingdetail.recurring
                  )
                }}
              </span>
            </p>
          </b-col>
          <!-- this is for image placeholder  -->
          <b-col
            v-if="med.image"
            cols="4"
            class="p-0"
          >
            <div class="img-holder">
              <img
                :src="med.image"
                :alt="med.name"
              >
            </div>
          </b-col>
        </b-row>
      </b-card>
      <p
        class="mb-5 pb-3"
        style="margin-top: -10px"
      >
        You won't be charged for or shipped this prescription product unless a
        doctor prescribes it to you.
      </p>
    </div>

    <h3 class="mt-5 sub-text med-font">
      Added Benefits
    </h3>
    <b-row>
      <b-col
        cols="3"
        md="auto"
      >
        <img src="@/assets/images/onboarding/shipping.svg">
      </b-col>
      <b-col
        cols="9"
        class="pt-3 pr-0"
      >
        <h4 class="med-font">
          Free Shipping
        </h4>
        <p class="mb-0">
          We offer fast shipping so you have your medication when you need it.
        </p>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col
        cols="3"
        md="auto"
      >
        <img src="@/assets/images/onboarding/support.svg">
      </b-col>
      <b-col
        cols="9"
        class="pt-3 pr-0"
      >
        <h4 class="med-font">
          Continued Support
        </h4>
        <p class="mb-0">
          As a Winona patient, you will receive continuous, personalized care
          from our healthcare team who are specially trained to treat your
          symptoms.
        </p>
      </b-col>
    </b-row>

    <div class="mt-5">
      <b-form-checkbox
        id="risk"
        v-model="risk"
        name="checkbox-2"
        class="tw-text-sm"
      >
        <div class="tw-mt-0.5">
          <span>I have read and understand the </span>
          <a
            href="javascript:void(0)"
            class="text-underline"
            @click="showRiskModal()"
          >Safety Information</a>
          associated with these treatments.
        </div>
      </b-form-checkbox>
    </div>

    <OnboardingCTAContainer>
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
        <div>
          <b-button
            pill
            block
            variant="info"
            class="slight-rounded"
            :disabled="buttonDisabled"
            @click="confirmContinue"
          >
            {{ loading ? "Loading" : !saving ? "Continue" : "Saving" }}
          </b-button>
        </div>
      </div>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
// libs
import _kebabCase from 'lodash/kebabCase'

// components
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';
import Risk from '@/components/partials/Risk';
import SideModal from '@/components/partials/SideModal';
import EditCheckout from '@/components/onboarding/checkout/EditCheckout';
import OnboardingModal from '@/components/onboarding/questions/Modal';

// mixins
import analyticsMixin from '@/mixins/analytics';

export default {
  components: {
    OnboardingCTAContainer,
    OnboardingModal,
    Risk,
    SideModal,
    EditCheckout,
  },
  mixins: [analyticsMixin],
  props: {
    body: {
      type: Object,
      default: () => ({}),
    },
    nonrx: {
      type: Boolean,
      default: false,
    },
    pharmacy: {
      type: Object,
      default: () => ({}),
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    onboardingflow: {
      type: String,
      default: 'flowA',
    },
    analyticsId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      desiredAddon: [],
      desiredPlan: [],
      productreveal: [],
      loading: false,
      showModal: false,
      riskModal: false,
      treatmentform: '',
      risk: this.pharmacy.Risk,
      showEditModal: false,
      modalBody: {
        title: 'Need an alternate pharmacy?',
        description:
          'If you pick a pharmacy outside of the Winona Pharmacy Network and qualify for a prescription, you will be charged $50 for the platform fee and we will not be able to guarantee the price of the medication and provide free monthly delivery.',
        answerlist: [
          {
            id: 'winonaPharm',
            name: 'No, pick Winona Pharmacy',
          },
          {
            id: 'other',
            name: 'Yes, I\'m sure',
          },
        ],
      },
    };
  },
  watch: {
    body() {
      this.desiredPlan = [];
      this.loading = true;
      this.desiredAddon = this.body.desiredaddon;
      this.risk = this.body.agreedtorisks;
      if ('desiredplan' in this.body) {
        for (const med of this.body.desiredplan) {
          const found = this.body.desiredaddon.filter(
            item => item.productid === med.productid
          );

          if (!found.length) {
            this.desiredPlan.push(med);
          }
        }
      }
      this.loading = false;
    },
    showEditModal() {
      const htmlTag = document.documentElement;
      if (this.showEditModal) {
        htmlTag.style.overflow = 'hidden';
      } else {
        htmlTag.style.overflow = null;
      }
    },
  },
  async mounted() {
    try {
      await this.getProductReveal();
      await this.$_analytics_track(
        'Onboarding Screen Viewed',
        {
          customerId: this.analyticsId,
          screen: '5.1-A',
          firstname: this.body.firstname,
          lastname: this.body.lastname,
          onboardingflow: this.onboardingflow,
        },
      );
      this.loading = true;
      this.desiredAddon = this.body.desiredaddon;
      this.risk = this.body.agreedtorisks;
      if ('desiredplan' in this.body) {
        for (const med of this.body.desiredplan) {
          const found = this.body.desiredaddon.filter(
            item => item.productid === med.productid
          );

          if (!found.length) {
            this.desiredPlan.push(med);
          }
        }
      }

      this.treatmentform = this.body.treatmentform;
      this.loading = false;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async confirmContinue() {
      if (this.body.onboardingstep == 5.4) {
        // WIN-53617
        let products = {};
        if ('desiredplan' in this.body) {
          products = {
            products: this.body.desiredplan.map(plan => {
              const productObj = this.productreveal.find(product => plan.productid === product.id)
              return {
                name: productObj.name,
                product_id: productObj.id,
                category: 'core_products',
                sku: productObj.id,
                price: productObj.availablepricing.find(pricing => pricing.pricingid === plan.pricingdetail.pricingid).price,
                url: `${process.env.VUE_APP_WEBSITE_URL}/product/${_kebabCase(productObj.name)}`,
                image_url: productObj.image,
              }
            }),
          }
        }

        await this.$_analytics_track('Checkout Started', {
          customerId: this.analyticsId,
          onboardingflow: this.onboardingflow,
          email: this.body.email,
          phoneno: this.body.phoneno,
          state: this.body.state,
          firstname: this.body.firstname,
          lastname: this.body.lastname,
          ...products,
          currency: 'USD',
        });

        // GTM productObj
        const productObj = [];
        for (const item of this.desiredPlan) {
          const toPush = {
            id: item.productid,
            name: item.name,
            price: item.price,
          }
          productObj.push(toPush);
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          productObj: productObj
        });
      }

      if (this.risk) {
        this.$emit('nextStep');
      } else {
        this.elementTag('hidden', true);
      }
    },
    async getProductReveal() {
      this.productreveal = await this.$store.dispatch(
        'onboarding/getProductReveal'
      );
    },
    openPharmSelect(_questionid = null, answer = null) {
      if (answer == 'winonaPharm') {
        this.$emit('clearPharmacy');
        return;
      }
      this.$emit('goToStep', 'pharmacy-select');
    },
    formatPricing(type, price, recurring) {
      if (type == 'one_time') {
        return `One time payment of $${price}`;
      } else if (recurring.interval == 'day') {
        const isMoreThanOneMonth = recurring.interval_count / 30 > 1;
        const plural = isMoreThanOneMonth ? 's' : '';
        const interval = isMoreThanOneMonth
          ? Math.ceil(recurring.interval_count / 30) + ' '
          : '';
        return `$${price}/${interval}month${plural}`;
      } else {
        return `$${price}/${recurring.interval_count}${recurring.interval}(s)`;
      }
    },
    agreeTerms() {
      this.risk = true;
      this.elementTag(null, false);
    },
    showRiskModal() {
      this.elementTag('hidden', true);
    },
    hideRiskModal() {
      this.elementTag(null, false);
    },
    elementTag(style, Boolean) {
      this.riskModal = Boolean;
      const htmlTag = document.documentElement;
      htmlTag.style.overflow = style;
      htmlTag.scrollTop = htmlTag.scrollHeight;
    },
  },
};
</script>

<style scoped>
a.disabled {
  pointer-events: none;
}

::v-deep .modal-header {
  border-bottom: 0;
  align-items: center;
}

::v-deep .modal-footer {
  border-top: 0;
  align-items: center;
}

::v-deep .terms-modal .modal-header {
  padding-left: 10px;
  padding-right: 10px;
  font-family: "hattonmedium";
  letter-spacing: initial;
}
</style>

<style lang="scss" scoped>
.changePharmacy {
  color: #828282;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $info;
  }
}
.selectPharmacy {
  color: #19191c;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: lighten(#19191c, 25%);
  }
}

.img-holder {
  width: 100% !important;
  height: 140px !important;
  text-align: right;
  margin-right: 0 !important;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
