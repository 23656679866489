<template>
  <b-row
    :class="`d-none d-lg-none d-lg-flex py-4 progress-header mt-xxl-6 ${$_fsaHsaPromo_isActiveFsaHsaPromo? '!tw-top-[10rem]': ''}`"
    align-v="center"
  >
    <b-col
      cols="1"
      class="pl-5"
    >
      <b-button
        v-if="!hide"
        class="back-btn"
        size="lg"
        variant="link"
        style="min-width: inherit"
        @click="backButton"
      >
        <img
          src="@/assets/images/small_back_arrow.svg"
        >
      </b-button>
    </b-col>
    <b-col
      cols="11"
      class="pr-7"
    >
      <b-row>
        <b-col
          v-for="(data, i) in progress"
          :key="data + i"
          cols="12"
          md="4"
          :class="data.value !== 0 ? '' : 'progress-disable'"
        >
          <b-progress
            :value="data.value"
            :max="data.max"
            class="mb-1 step-progress"
            variant="purple"
            height="8px"
          />
          <span> {{ data.title }} </span>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';

export default {
  mixins: [fsaHsaPromoMixin],
  props: {
    step: {
      type: String,
    },
    hide: {
      type: Boolean,
    },
    hideStep: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    backButton(event) {
      this.$emit('back', event);
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped>
.progress-header {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 100;
  background-color: #fff;

  @include media-breakpoint-down(md) {
    top: 80px;
  }
}

.progress-disable {
  opacity: 0.5;
}

.back-btn {
  @include media-breakpoint-up(xxl) {
    img {
      width: 25px * $scale-font;
      height: 13px * $scale-font;
    }
  }
}
</style>
