<template>
  <b-container fluid>
    <!-- for Desktop Only Progress -->
    <OnboardingProgress
      :hide="hideBackButton"
      :progress="progress"
      @back="prev"
    />
    <b-row class="onboarding-wrapper">
      <OnboardingHeader
        :hide="hideBackButton"
        step="3"
        :title="title"
        @back="prev"
      />
      <b-col
        cols="12"
        lg="5"
        xxl="4"
        class="mt-6 pt-3 onboarding-content"
      >
        <!-- <h6 class="d-lg-none mt-3 mb-2 step">Step 3 of 4</h6> -->
        <!-- mobile progress -->
        <b-row class="d-lg-none">
          <b-col>
            <h3 class="bold d-letter-spacing onboarding-title">
              Intake
            </h3>
          </b-col>
          <b-col>
            <h3
              class="
                bold
                d-letter-spacing
                onboarding-title
                text-muted text-right
              "
            >
              Onboarding Complete
            </h3>
          </b-col>
        </b-row>
        <b-progress
          :value="50"
          class="step-progress d-lg-none mb-5"
          variant="purple"
          height="5px"
        />
        <Addons
          v-if="step == 'selectAddons'"
          :body="addonData"
          :product-reveal-data="productRevealData"
          :chosen-addons="
            onboardingData.hasOwnProperty('desiredaddon')
              ? onboardingData.desiredaddon
              : []
          "
          :nonrx="user.isNonRx"
          :disabled="buttonDisabled"
          @submitAddons="submitAddons"
        />
        <Pricing
          v-if="step == 'selectPricing'"
          :body="selectedAddons"
          :disabled="buttonDisabled"
          @submitPricing="submitPricing"
        />
      </b-col>
      <b-col
        cols="12"
        lg="3"
        xxl="4"
      />
    </b-row>
  </b-container>
</template>

<script>
// TODO add alert
import { ProductService } from '@/services/product.service';
import { OnboardingService } from '@/services/onboarding.service';
import Addons from '@/components/onboarding/treatments/Addons';
import Pricing from '@/components/onboarding/treatments/Pricing';
import OnboardingHeader from '@/components/partials/OnboardingHeader';
import OnboardingProgress from '@/components/partials/OnboardingProgress';
import analyticsMixin from '@/mixins/analytics';

export default {
  components: {
    Addons,
    Pricing,
    OnboardingHeader,
    OnboardingProgress,
  },
  mixins: [analyticsMixin],
  props: ['title'],
  data() {
    return {
      step: '',
      buttonDisabled: false,
      addonData: {},
      selectedAddons: [],
      onboardingData: {},
      user: {
        isNonRx: false,
        isUnregistered: false,
      },
      productRevealData: [],

      // for new progress UI update on the header
      progress: [
        {
          title: 'Account Creation',
          value: 100,
        },
        {
          title: 'Intake',
          value: 5,
        },
        {
          title: 'Onboarding Complete',
          value: 0,
        },
      ],
    };
  },
  computed: {
    hideBackButton() {
      if (step !== 'productreveal') {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    this.$_analytics_page();
    await this.getAddons();
    await this.getUserOnboarding();
    if (!this.user.isNonRx) await this.getRecommendations();
    this.step = 'selectAddons';
  },
  methods: {
    async getAddons() {
      try {
        const data = await ProductService.listProductNonRX();
        this.addonData = data;
      } catch (err) {
        if (err.status == 401) {
          this.$router.push('/login');
        } else {
          this.showAlert(err);
        }
      }
    },
    async getUserOnboarding() {
      let data;
      try {
        data = await OnboardingService.getPatientOnboarding();
      } catch (err) {
        if (err.status == 401) {
          this.$router.push('/login');
        } else {
          this.showAlert(err);
        }
      }

      this.onboardingData = data.data;
      this.user.isNonRx = !!this.onboardingData.nonrxmedicationonly;
      this.user.notFromEligibility = this.onboardingData.healthquestionstatus;
    },
    async submitAddons(selected) {
      this.buttonDisabled = true;
      this.selectedAddons = selected;
      if (this.selectedAddons.length == 0) {
        try {
          const data = await OnboardingService.updatePatientOnboarding(4, {
            desiredaddon: [],
          });
          this.$router.push('/identity-verification');
        } catch (err) {
          if (err.status == 401) {
            this.$router.push('/login');
          } else {
            this.buttonDisabled = false;
            this.showAlert(err);
          }
        }
      } else {
        this.buttonDisabled = false;
        this.step = 'selectPricing';
      }
    },
    submitPricing(selected) {
      this.buttonDisabled = true;
      const formattedAnswer = {
        desiredaddon: selected,
      };
      this.$emit('setOnboardingStep', 4);
      this.$emit('nextOnboardingStep', formattedAnswer);
    },
    prev() {
      switch (this.step) {
        case 'selectAddons':
          if (this.user.isNonRx) {
            // this.$emit('setOnboardingStep', this.getLastHealthQuestion())
            this.$router.push('/health-questions');
            if (!this.user.notFromEligibility) {
              this.$router.push('/eligibility');
            }
          } else {
            this.$emit('setOnboardingStep', 3.2);
            this.$router.push('/treatment-options');
          }
          break;
        case 'selectPricing':
          this.step = 'selectAddons';
          break;
      }
    },
    async getLastHealthQuestion() {
      const data = await OnboardingService.getPreviousHealthQuestion(4);
      return data.data[0].id;
    },
    async getRecommendations() {
      this.productRevealData = await this.$store.dispatch(
        'onboarding/getProductReveal'
      );
    },
  },
};
</script>
