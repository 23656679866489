import qs from 'qs';

import { Http } from './http.init';
import { ResponseWrapper, ErrorWrapper } from './util';
import VueCookies from 'vue-cookies';

export class BaseService {
  static get entity() {
    throw new Error('entity getter not defined');
  }
  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static request(
    status = { auth: false, trackFB: false, noRetry: false },
    version
  ) {
    return new Http(status, version);
  }

  static responseWrapper(...rest) {
    return new ResponseWrapper(...rest);
  }

  static errorWrapper(...rest) {
    return new ErrorWrapper(...rest);
  }

  static querystring(obj) {
    return qs.stringify(obj, {
      encode: false,
    });
  }

  static async getUTMS () {
    const [cookieUTMS, cookieFirstTouchUTMS] = await Promise.all([
      this.getCookieUTMS(),
      this.getCookieFirstTouchUTMS()
    ]);

    return {
      ...cookieUTMS,
      ...cookieFirstTouchUTMS
    }
  }

  static async getCookieUTMS () {
    let campaign = undefined;
    const winonaUtms = VueCookies.get('winona_utms') || undefined;

    if (winonaUtms != undefined && winonaUtms.constructor.name === 'Object') {
      const utmName = winonaUtms.utm_name || winonaUtms.utm_campaign || undefined;
      const utmSource = winonaUtms.utm_source || undefined;
      const utmMedium = winonaUtms.utm_medium || undefined;
      const utmCampaign = winonaUtms.utm_campaign || undefined;
      const utmTerm = winonaUtms.utm_term || undefined;
      const utmContent = winonaUtms.utm_content || undefined;
      const utmId = winonaUtms.utm_id || undefined;

      if (
        utmSource != undefined ||
        utmName != undefined ||
        utmMedium != undefined ||
        utmCampaign != undefined ||
        utmTerm != undefined ||
        utmContent != undefined ||
        utmId != undefined
      ) {
        campaign = {};
      }

      if (utmName != undefined) {
        campaign['utm-name'] = utmName;
      }
      if (utmSource != undefined) {
        campaign['utm-source'] = utmSource;
      }
      if (utmMedium != undefined) {
        campaign['utm-medium'] = utmMedium;
      }
      if (utmCampaign != undefined) {
        campaign['utm-campaign'] = utmCampaign;
      }
      if (utmTerm != undefined) {
        campaign['utm-term'] = utmTerm;
      }
      if (utmContent != undefined) {
        campaign['utm-content'] = utmContent;
      }
      if (utmId != undefined) {
        campaign['utm-campaignId'] = utmId;
      }

      // Converts objects to strings
      for (const utmkey in campaign) {
        if (typeof campaign[utmkey] == 'Object') {
          campaign[utmkey] = JSON.stringify(campaign[utmkey]);
          // if utm is a string, convert to ISO 8859-1 friendly format
        } else if (typeof campaign[utmkey] == 'string') {
          campaign[utmkey] = encodeURIComponent(campaign[utmkey]);
        }
      }
    }
    return campaign;
  }

  static async getCookieFirstTouchUTMS () {
    let campaign = undefined;
    const winonaFirstTouchUtms = VueCookies.get('winona_firsttouch_utms') || undefined;

    if (winonaFirstTouchUtms != undefined && winonaFirstTouchUtms.constructor.name === 'Object') {
      const utmName = winonaFirstTouchUtms.utm_name || undefined;
      const utmSource = winonaFirstTouchUtms.utm_source || undefined;
      const utmMedium = winonaFirstTouchUtms.utm_medium || undefined;
      const utmCampaign = winonaFirstTouchUtms.utm_campaign || undefined;
      const utmTerm = winonaFirstTouchUtms.utm_term || undefined;
      const utmContent = winonaFirstTouchUtms.utm_content || undefined;
      const utmId = winonaFirstTouchUtms.utm_id || undefined;

      if (
        utmSource != undefined ||
        utmName != undefined ||
        utmMedium != undefined ||
        utmCampaign != undefined ||
        utmTerm != undefined ||
        utmContent != undefined ||
        utmId != undefined
      ) {
        campaign = {};
      }

      if (utmName != undefined) {
        campaign['firsttouch-utm-name'] = utmName;
      }
      if (utmSource != undefined) {
        campaign['firsttouch-utm-source'] = utmSource;
      }
      if (utmMedium != undefined) {
        campaign['firsttouch-utm-medium'] = utmMedium;
      }
      if (utmCampaign != undefined) {
        campaign['firsttouch-utm-campaign'] = utmCampaign;
      }
      if (utmTerm != undefined) {
        campaign['firsttouch-utm-term'] = utmTerm;
      }
      if (utmContent != undefined) {
        campaign['firsttouch-utm-content'] = utmContent;
      }
      if (utmId != undefined) {
        campaign['firsttouch-utm-campaignId'] = utmId;
      }
      // Converts objects to strings
      for (const utmkey in campaign) {
        if (typeof campaign[utmkey] == 'Object') {
          campaign[utmkey] = JSON.stringify(campaign[utmkey]);
          // if utm is a string, convert to ISO 8859-1 friendly format
        } else if (typeof campaign[utmkey] == 'string') {
          campaign[utmkey] = encodeURIComponent(campaign[utmkey]);
        }
      }
    }
    return campaign;
  }
}
