<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="6"
      cy="6"
      r="5.6"
      stroke="currentColor"
      stroke-width="0.8"
    />
    <path
      d="M6.01316 3.366C5.43716 3.366 4.97816 3.654 4.59116 4.068L4.72616 3.231C5.04116 2.979 5.48216 2.79 6.03116 2.79C7.05716 2.79 7.69616 3.519 7.69616 4.392C7.69616 5.094 7.31816 5.481 6.94016 5.859C6.61616 6.174 6.16616 6.543 6.09416 7.2H5.72516C5.54516 6.471 6.15716 5.886 6.53516 5.481C6.79616 5.193 7.05716 4.914 7.05716 4.428C7.05716 3.843 6.67916 3.366 6.01316 3.366ZM5.92316 9.045C5.66216 9.045 5.42816 8.811 5.42816 8.55C5.42816 8.298 5.66216 8.064 5.92316 8.064C6.18416 8.064 6.41816 8.298 6.41816 8.55C6.41816 8.811 6.18416 9.045 5.92316 9.045Z"
      fill="currentColor"
    />
  </svg>
</template>
  
<script>
export default {};
</script>
