var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.teleport)?_c('portal',{attrs:{"to":"onboarding-cta-footer"}},[(_vm.$slots.default)?_c('div',{staticClass:"shadow-portal tw-py-5 tw-px-5 lg:tw-px-0 tw-bg-white tw-fixed tw-bottom-0 tw-w-screen tw-z-[5] tw-flex tw-flex-col tw-gap-4",attrs:{"id":"onboarding-cta-footer"}},[_c('div',{staticClass:"tw-h-full tw-flex tw-items-center tw-px-[1.5rem] sm:tw-px-0",style:([
          _vm.$_breakpoint_lg
            ? {
              width: `${_vm.width}px`,
              marginLeft: `${_vm.marginLeft}px`,
            }
            : {}
        ])},[_vm._t("default")],2)]):_vm._e()]):_c('div',[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }