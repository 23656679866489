<template>
  <div
    class="multiple-answer"
  >
    <h2 class="question-title">
      {{ body.question }}
    </h2>
    <p v-if="body.header">
      {{ body.header }}
    </p>

    <w-checkbox
      v-if="body.allowempty"
      id="none"
      v-model="none"
      type="checkbox"
      value="true"
      label="None"
    />

    <w-checkbox
      v-for="(list, i) in body.answerlist"
      :id="list.id"
      :key="list.name + i"
      v-model="answers"
      type="checkbox"
      :value="list.id"
      :label="list.name"
      :description="list.description"
    >
      <template
        v-if="list.image"
        #image
      >
        <div class="img-holder">
          <img
            :src="list.image"
            :alt="list.name"
          >
        </div>
      </template>
    </w-checkbox>

    <w-checkbox
      v-if="body.hasothersarray"
      id="others"
      v-model="activateOthers"
      type="checkbox"
      value="true"
      label="Other"
    />

    <Array
      v-if="activateOthers"
      :body="arrayBody"
      :others-field="others"
      @submitAnswer="checkAnswer"
      @alert="alert"
    />

    <OnboardingCTAContainer>
      <b-button
        v-if="!activateOthers"
        variant="info"
        block
        pill
        :disabled="disabled"
        class="slight-rounded"
        @click="checkAnswer"
      >
        Continue
      </b-button>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
// components
import Array from '@/components/onboarding/questions/Array';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';

export default {
  components: {
    Array,
    OnboardingCTAContainer
  },
  props: {
    body: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answers: [],
      others: [],
      activateOthers: false,
      arrayBody: {},
      none: false,
    };
  },
  watch: {
    none() {
      if (this.none) {
        this.answers = [];
        this.activateOthers = false;
      }
    },
    answers() {
      if (this.answers.length > 0) {
        this.none = false;
      }
    },
    activateOthers() {
      if (this.activateOthers) {
        this.none = false;
      }
    },
  },
  async mounted() {
    if (this.body.hasOwnProperty('answer')) {
      this.answers = this.body.answer;
      if (this.body.answer.length == 0 && !this.body.hasOwnProperty('others')) {
        this.none = true;
        return;
      }
      if (this.body.hasOwnProperty('others')) {
        this.activateOthers = true;
        this.others = this.body.others;
      }
    }
  },
  methods: {
    alert(msg) {
      this.$emit('alert', msg);
    },
    checkAnswer(_id = null, others = null, _body = null) {
      if (this.activateOthers) {
        this.others = others;
      }

      if (this.body.allowempty) {
        // * If "none" is not selected, and no other answers and others have not been supplied
        if (!this.none && this.answers.length == 0 && this.others.length == 0) {
          this.$emit('alert', 'Please select an option.');
          return;
        }
      }

      this.$emit(
        'submitAnswer',
        this.body.id,
        this.answers,
        this.body,
        null,
        this.others
      );
    },
  },
};
</script>
