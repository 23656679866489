
import $cookies from '@/utils/cookies';

export const getURLParams = () => {
  let params = '';

  const sessionPromo = $cookies.get('sessionPromo')
  
  if (sessionPromo) {
    params = `${params}sessionPromo=${sessionPromo}`
  }

  return params
}