import { UsersService } from '@/services/users.service'
import { DocumentService } from '@/services/document.service'
import { ShippingService } from '@/services/shipping.service'

export default {
  namespaced: true,
  state: {
    currentUser: {},
    healthQuestions: [],
    defaultShipping: {}
  },
  mutations: {
    SET_CURRENT_USER(state, currentUserData) {
      state.currentUser = currentUserData
    },
    SET_DEFAULT_SHIPPING(state, defaultShipping) {
      state.defaultShipping = defaultShipping
    },
    SET_HEALTH_QUESTIONS(state, healthQuestions) {
      state.healthQuestions = healthQuestions;
    },
  },
  getters: {
    getUser(state) {
      return state.currentUser;
    },
    getHealthQuestions(state) {
      return state.healthQuestions;
    },
    getDefaultShipping(state) {
      return state.defaultShipping;
    },
    getFailedTransactionReason(state) {
      if (state.currentUser.hasfailedtransaction) {
        return state.currentUser.failedtransactionreason
      }

      return null
    }
  },
  actions: {
    getUser({ commit }) {
      return UsersService.getCurrent()
        .then(user => {
          localStorage.setItem('userid', user.data.id);
          commit('SET_CURRENT_USER', user.data)
          return user.data;
        })
        .catch(error => error)
    },
    updateUser({ dispatch }, data) {
      return UsersService.updateUser(data)
        .then(async user => {
          await dispatch('getUser');
        })
        .catch(error => error)
    },
    updateShippingDetails({ dispatch }, { id, data }) {
      return ShippingService.updateShipping(id, data)
        .then(async user => {
          await dispatch('getDefaultShipping');
        })
        .catch(error => {
          if(error.status == 401) {
            return ShippingService.addShipping(data)
              .then(async user => {
                await dispatch('getDefaultShipping');
              })
              .catch(error => error);
          } else {
            return error;
          }
        })
    },
    getDefaultShipping({ commit }) {
      return ShippingService.defaultShipping()
        .then(shipping => {
          commit('SET_DEFAULT_SHIPPING', shipping.data);
          return shipping.data;
        })
        .catch(error => {
          console.log(error)
        })
    },
    getHealthQuestions({ commit }) {
      return UsersService.getHealthQuestions().then(({ data }) => {
        const tempData = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].answer) {
            tempData.push(data[i])
          }
        }
        commit('SET_HEALTH_QUESTIONS', tempData);
        return tempData;
      })
        .catch(error => error);
    },
    getDocuments({ commit }) {
      return DocumentService.getDocuments()
        .then(documents => {
          return documents.data;
        })
        .catch(error => error)
    }
  }
}