/**
 * Cookie utility that uses localStorage
 * in development and cookies in production.
 */

import Cookies from 'js-cookie';
import ms from 'ms';

const storage = () => {
  console.log('Running cookies in storage mode');

  return {
    set: (key, value) => localStorage.setItem(key, value),
    get: key => localStorage.getItem(key),
    remove: keys => {
      if (!Array.isArray(keys)) {
        keys = [keys];
      }
      keys.forEach(key => localStorage.removeItem(key));
    },
    keys: () => Object.keys(localStorage),
    isKey: key => localStorage.getItem(key) !== null,
  };
};

const cookies = () => {
  console.log('Running cookies in cookie mode');

  return {
    set: (key, value, expires) =>
      Cookies.set(key, value, {
        expires: expires ? new Date(Date.now() + ms(expires)) : undefined,
      }),
    get: key => Cookies.get(key),
    remove: (key, options) => Cookies.remove(key, options),
    keys: () => Cookies.keys(),
  };
};

const isDevelopment = process.env.VUE_APP_SERVER === 'dev';

export default isDevelopment ? storage() : cookies();
