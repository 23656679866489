<template>
  <div>
    <div>
      <div class="photo-holder fs-exclude">
        <div
          v-if="
            photo &&
              !existing &&
              photoFile.type !== 'application/pdf' &&
              !isAndroidFirefoxRestriction
          "
          class="btn-crop"
          @click="openCropModal"
        >
          <b-icon icon="crop" />
        </div>
        <div
          :class="[
            'photo',
            idSize && isImageFileFormat && !isAndroidFirefoxRestriction
              ? 'id-size'
              : '',
          ]"
          :style="{
            backgroundImage: backgroundImage,
          }"
        >
          <img
            v-if="photo != '' && !loading"
            :src="photo"
            class="fs-mask"
            :style="{
              width:
                existingPhotoFileType === 'application/pdf' ? 'auto' : '100%',
            }"
          >
          <b-spinner v-if="loading" />
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-2">
        <OnboardingCTAContainer :teleport="teleportCta">
          <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div v-if="isAndroid11WebView">
              <p class="sub-text">
                Please send your
                {{ backgroundType === "id" ? "ID document" : "photo" }} to our
                <a href="mailto:help@bywinona.com">email address</a> or via Intercom.
              </p>

              <b-button
                v-if="!dashboard"
                variant="info"
                pill
                block
                @click="skipUpload"
              >
                Continue
              </b-button>
            </div>

            <div
              v-else
              class="tw-flex tw-gap-2 tw-justify-center tw-flex-col"
            >
              <label
                v-if="photo == '' || isAndroidWebView"
                :for="`${componentID}-upload`"
                class="btn btn-outline-info btn-pill mobile-upload-label tw-m-0 slight-rounded"
              >
                Select Photo
              </label>

              <b-button
                v-if="captureText === 'RETAKE' && !isAndroidWebView"
                class="tw-m-0 slight-rounded"
                variant="outline-info"
                pill
                block
                :disabled="isImageUploadProcessing"
                @click="
                  photo = '';
                  photoFile = null;
                "
              >
                RETAKE
              </b-button>
              <label
                v-else-if="captureText !== 'RETAKE' && !isAndroidWebView"
                :for="`${componentID}-capture`"
                :class="{
                  'btn-info': captureText == 'TAKE PHOTO',
                  'btn-outline-info': captureText == 'RETAKE',
                }"
                class="btn btn-pill mobile-upload-label tw-m-0 slight-rounded"
                :disabled="isImageUploadProcessing"
              >
                {{ captureText }}
              </label>

              <input
                :id="`${componentID}-capture`"
                type="file"
                :accept="ACCEPTED_FILE_MIME_TYPES"
                class="mobile-upload"
                name="mobile-upload"
                capture="environment"
                @change="(e) => setPictureMobile(e, { isPhotoTaken: true })"
              >
              <input
                :id="`${componentID}-upload`"
                type="file"
                :accept="ACCEPTED_FILE_MIME_TYPES"
                class="mobile-upload"
                name="mobile-upload"
                @change="(e) => setPictureMobile(e, { isPhotoTaken: false })"
              >
              <b-button
                v-if="!dashboard && captureText == 'RETAKE'"
                variant="info"
                pill
                block
                :disabled="isImageUploadProcessing"
                class="slight-rounded"
                @click="submitAnswer"
              >
                <b-spinner
                  v-if="isImageUploadProcessing"
                  small
                />
                <span v-else>{{ submitText }}</span>
              </b-button>
            </div>
          </div>
        </OnboardingCTAContainer>

        <div>
          <div class="tw-text-center">
            <PersonalPhotoTroubleUpload @skip="skipUpload" />
          </div>

          <div class="tw-text-center">
            <OnboardingTLSSecurity v-if="showTlsSecurity" />
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="cropMobile"
      class="fs-exclude"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      @shown="onModalShown"
    >
      <vue-croppie
        ref="croppieRefMobile"
        :enable-orientation="false"
        :enable-resize="false"
        :boundary="{ width: 300, height: backgroundType === 'id' ? 200 : 300 }"
        :viewport="{ width: 300, height: backgroundType === 'id' ? 200 : 300 }"
      />
      <b-row class="mt-3">
        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="info"
            class="slight-rounded"
            @click="cropImage"
          >
            Crop
          </b-button>
          <div class="mb-3 d-md-none"></div>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <b-button
            pill
            block
            variant="outline-info"
            class="slight-rounded"
            @click="closeCropModal"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
// libs
import { isAndroid, isFirefox } from 'mobile-device-detect';

// assets
import pdfIcon from '@/assets/images/onboarding/pdf-icon.png';

// components
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';
import PersonalPhotoTroubleUpload from '@/components/onboarding/verification/PersonalPhotoTroubleUpload'
import OnboardingTLSSecurity from '@/components/onboarding/OnboardingTLSSecurity'

// utils
import { ACCEPTED_FILE_MIME_TYPES } from '@/constants/files';
import { isAndroidWebView, isAndroid11WebView } from '@/utils/device';
import {
  finalizeFileForUpload,
  fileToDataUrl,
  isFileTypePdf,
  getImageFileDimensions,
  base64toFile
} from '@/utils/file';

export default {
  name: 'MobileUpload',
  components: {
    OnboardingCTAContainer,
    PersonalPhotoTroubleUpload,
    OnboardingTLSSecurity
  },
  props: {
    idSize: {
      type: Boolean,
    },
    backgroundType: {
      type: String,
      default: 'id',
    },
    existingPhoto: {
      type: String,
      default: '',
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    existingPhotoFileType: {
      type: String,
      default: '',
    },
    teleportCta: {
      type: Boolean,
      default: true,
    },
    showTlsSecurity: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      photo: '',
      photoFile: null,
      existing: false,
      orientation: 0,
      photoWidth: 0,
      photoHeight: 0,
    };
  },
  computed: {
    ACCEPTED_FILE_MIME_TYPES: () => ACCEPTED_FILE_MIME_TYPES,

    isAndroidWebView: () => isAndroidWebView,

    isAndroid11WebView: () => isAndroid11WebView,

    componentID() {
      return `mobile-upload-${this.backgroundType}`;
    },
    backgroundImage() {
      let imageToUse;
      switch (this.backgroundType) {
        case 'id':
          imageToUse = `url(${require('@/assets/images/id.png')})`;
          break;
        case 'personal':
          imageToUse = `url(${require('@/assets/images/photo.jpg')})`;
          break;
      }
      return this.photo == '' ? imageToUse : '';
    },
    captureText() {
      return this.photo == '' ? 'TAKE PHOTO' : 'RETAKE';
    },
    submitText () {
      return this.photo == this.existingPhoto
        ? 'CONFIRM'
        : this.isImageUploadProcessing ? 'SUBMITTING' : 'CONFIRM';
    },
    isImageFileFormat() {
      return this.photoFile === null || this.photoFile?.type.includes('image/');
    },
    isImageUploadProcessing() {
      return this.$store.state.onboarding.isImageUploadProcessing;
    },
    // If device is on Android using Firefox and photo is > 1.04 MB
    isAndroidFirefoxRestriction() {
      return (
        isAndroid &&
        isFirefox &&
        ((this.photoFile?.size || 0) > 1065 * 1024 ||
          this.photoWidth > 4000 ||
          this.photoHeight > 4000)
      );
    },
  },
  watch: {
    existingPhoto() {
      if (this.existingPhoto) {
        this.photo = this.existingPhoto;
        this.existing = true;
      }
    },
  },
  async mounted() {
    if (!this.isAndroidWebView) {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true,
        });
      } catch (err) {
        if (err.name == 'NotAllowedError') {
          this.$emit(
            'showAlert',
            'Please enable your camera through permissions.'
          );
        }
      }
    }

    this.$emit('update:isAndroid11Webview', this.isAndroid11WebView);
  },
  beforeDestroy() {
    this.photo = '';
    this.photoFile = '';
  },
  methods: {
    openCropModal() {
      this.$bvModal.show('cropMobile');
    },
    closeCropModal() {
      this.$bvModal.hide('cropMobile');
    },
    onModalShown(){
      this.$refs.croppieRefMobile.bind({
        url: this.photo,
      });
    },
    cropImage() {
      const options = {
        format: 'jpeg',
      };
      this.$refs.croppieRefMobile.result(options, output => {
        this.photo = output;
        this.photoFile = base64toFile(output, {
          name: 'image'
        });
        this.closeCropModal();
      });
    },
    async setPictureMobile(event, { isPhotoTaken }) {
      try {
        this.existing = false;
        this.$emit('hideAlert');

        const fileToUpload = event.target.files[0];

        const { error, success } = await finalizeFileForUpload(fileToUpload);

        if (error) {
          return this.$emit('showAlert', error);
        }

        this.photoFile = success;

        const isPdf = isFileTypePdf(this.photoFile);
        const dataUrl = await fileToDataUrl(this.photoFile);
        const dimensions = await getImageFileDimensions(this.photoFile);

        this.photoWidth = dimensions.width;
        this.photoHeight = dimensions.height;
        this.photo = isPdf ? pdfIcon : dataUrl;
        this.orientation = 0;

        if (this.dashboard) {
          this.$emit('submitAnswerDashboard', {
            photo: this.photo,
            file: this.photoFile,
            orientation: this.orientation,
            isPhotoTaken,
          });
        }
      } catch (err) {
        console.log(err);
        this.$emit('showAlert', err);
      }
    },
    submitAnswer() {
      if (this.photo == '') {
        this.$emit('showAlert', 'Please submit a photo.');
        return;
      }
      this.$store.commit('onboarding/SET_IS_IMAGE_UPLOAD_PROCESSING', true);
      if (this.photo == this.existingPhoto) {
        this.$emit('skip');
      } else {
        this.$emit('submitAnswer', this.photoFile, this.orientation);
      }
    },
    skipUpload() {
      this.$emit('skip');
    },
  },
};
</script>

<style lang="scss" scoped>
.id-size {
  width: 250px !important;
  height: 158px !important;
}

.mobile-upload-label {
  border-radius: 24px;
  display: block;
}

.mobile-upload {
  display: none;
  opacity: 0;
}

.photo-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  position: relative;

  .btn-crop {
    padding: 0.75rem;
    background-color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #a783ff;
  }

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
