<template>
  <b-card
    v-if="show"
    class="
      tw-shadow-lg
      tw-fixed
      tw-w-80
      z-index-top
      tw-left-1/2
      tw--translate-x-1/2
      slight-rounded
    "
    :class="forceClasses"
  >
    <div class="text-center mb-3">
      <span>
        By clicking "Accept", you agree to the
        <a
          :href="termLink"
        >
          Terms &amp; Conditions,
        </a> <a
          :href="privacyLink"
        >Privacy Policy,</a> and
        <a
          :href="teleLink"
        >Telehealth Consent</a>
      </span>
    </div>

    <div class="tw-flex tw-justify-center">
      <b-button
        pill
        variant="info"
        class="px-4 slight-rounded"
        @click="accept"
      >
        Accept
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { OnboardingService } from '@/services/onboarding.service'

export default {
  props: {
    forceAccept: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      show: false,
      // Prevents ignoring the modal
      termLink: `${process.env.VUE_APP_WEBSITE_URL}/terms-and-conditions/`,
      privacyLink: `${process.env.VUE_APP_WEBSITE_URL}/privacy-policy/`,
      teleLink: `${process.env.VUE_APP_WEBSITE_URL}/telehealth-consent/`,
    }
  },
  computed: {
    forceClasses() {
      return this.forceAccept ? 'tw-top-1/2 tw--translate-y-1/2' : 'tw-bottom-12 lg:tw-left-10 lg:tw-translate-x-0'
    }
  },
  mounted() {
    this.show = true
  },
  methods: {
    accept () {
      // Synchronous as to prevent the closing getting blocked
      OnboardingService.acceptTermsAndConditions();

      if (this.forceAccept) {
        this.$emit('close-force');
      }

      this.show = false
    }
  }
}
</script>

<style>
.z-index-top {
  z-index: 9999 !important;
}
</style>