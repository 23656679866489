<template>
  <div>
    <h2 class="question-title with-subtext">
      Ok great! You are eligible for treatment.
    </h2>
    <p class="sub-text group-mb">
      Please get started with your doctor visit by creating an account below.
      All the information you provide is legally required to provide medical
      care.
    </p>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="customerfn"
            class="input-label"
          >First Name*</label>
          <input
            id="customerfn"
            v-model="firstname"
            name="customerfn"
            type="text"
            class="fs-mask form-control onboarding-input"
            :class="
              firstname.length > 0
                ? checkFirstNameValidity
                  ? 'is-valid'
                  : 'is-invalid'
                : ''
            "
            required
          >
          <b-form-invalid-feedback
            id="input-live-feedback"
            :state="checkFirstNameValidity"
          >
            First name must be alphanumeric
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="customerln"
            class="input-label"
          >Last Name*</label>
          <input
            id="customerln"
            v-model="lastname"
            name="customerln"
            type="text"
            class="fs-mask form-control onboarding-input"
            :class="
              lastname.length > 0
                ? checkLastNameValidity
                  ? 'is-valid'
                  : 'is-invalid'
                : ''
            "
            required
          >
          <b-form-invalid-feedback
            id="input-live-feedback"
            :state="checkLastNameValidity"
          >
            Last name must be alphanumeric
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="birthday"
            class="input-label"
          >Birthday*</label>
          <b-form-input
            id="birthday"
            v-model="birthdayDisplay"
            class="fs-mask onboarding-input"
            required
            readonly
          />
        </div>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="state"
            class="input-label"
          >
            State*
          </label>

          <b-form-input
            id="state"
            v-model="stateName"
            class="fs-mask onboarding-input"
            required
            readonly
          />
        </div>
      </b-col>

      <b-col cols="12">
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="phoneno"
            class="input-label"
          >Phone Number*</label>
          <b-form-input
            id="phoneno"
            v-model="phoneno"
            class="fs-mask onboarding-input hide-arrows"
            type="tel"
            pattern="[0-9]*"
            :formatter="formatPhoneNumber"
            required
            @keyup.enter="submitAnswer"
          />
        </div>
      </b-col>

      <b-col
        v-if="$cookies.get('referralToken')"
        cols="12"
      >
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="refcode"
            class="input-label"
          >Referral Code</label>
          <b-form-input
            id="refcode"
            v-model="refcode"
            class="fs-mask onboarding-input"
            readonly
          />
        </div>
      </b-col>

      <b-col cols="12">
        <b-form-checkbox
          v-model="optforpromotions"
          class="mb-2 checkbox-label"
          :value="true"
          :unchecked-value="false"
        >
          Yes, I'd like to occasionally receive Winona promotions via text
        </b-form-checkbox>
      </b-col>

      <b-col
        cols="12"
        class="mt-4"
      >
        <div class="tw-flex tw-flex-col tw-gap-3">
          <small
            class="text-muted"
            style="font-size: 8pt"
          >
            By checking the above box, you agree to receive automated
            promotional messages. This agreement is not a condition of purchase.
            Message frequency varies. Reply STOP to opt-out or HELP for help.
            Message & data rates apply. For questions, please
            <a
              href="javascript:;"
              @click="redirectToSite('contact')"
            >contact us.
            </a>
            <a
              href="javascript:;"
              @click="redirectToSite('privacy-policy')"
            >Privacy Policy.</a>
          </small>
        </div>
      </b-col>
    </b-row>

    <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-2 tw-justify-center">
      <small>
        <b-icon icon="lock-fill" />
        Any information you submit is
        encrypted within our secure platform.
      </small>

      <img
        src="@/assets/images/cro/hsa-fsa-eligible-badge.png"
        alt=""
        class="tw-h-[18px] tw-mx-auto"
      >
    </div>

    <OnboardingCTAContainer>
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-text-center">
        <b-button
          pill
          block
          variant="info"
          class="slight-rounded"
          :disabled="buttonDisabled"
          @click="submitAnswer"
        >
          Start my free visit
        </b-button>
      </div>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
// components
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer';

// services
import { OnboardingService } from '@/services/onboarding.service';

// mixins
import formValidation from '@/components/form/mixins';
import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';

// libs
import Vue from 'vue';

export default {
  components: {
    OnboardingCTAContainer,
  },
  mixins: [formValidation, fsaHsaPromoMixin],
  props: {
    eligibilityParams: {
      type: Object,
      default: () => ({})
    },
    registrationParams: {
      type: Object,
      default: () => ({})
    },
    stateName: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      firstname: '',
      lastname: '',
      buttonDisabled: false,
      phoneno: '',
      optforpromotions: true,
    };
  },
  computed: {
    refcode() {
      if ($cookies.get('referralToken')) {
        return $cookies.get('referralToken');
      } else {
        return '';
      }
    },
    checkFirstNameValidity() {
      return this.firstname == ''
        ? null
        : /^[\w\-\s]+$/.test(this.firstname)
          ? true
          : false;
    },
    checkLastNameValidity() {
      return this.lastname == ''
        ? null
        : /^[\w\-\s]+$/.test(this.lastname)
          ? true
          : false;
    },
    birthdayDisplay() {
      if (this.eligibilityParams.birthday) {
        const birthdayArray = this.eligibilityParams.birthday.split('-');
        return `${birthdayArray[1]}-${birthdayArray[2]}-${birthdayArray[0]}`;
      } else {
        return '';
      }
    },
  },
  watch: {
    firstname() {
      this.$emit('update-registration', 'firstname', this.firstname);
    },
    lastname() {
      this.$emit('update-registration', 'lastname', this.lastname);
    },
    phoneno() {
      this.$emit('update-registration', 'phoneno', this.phoneno);
    },
  },
  async mounted () {
    if (this.registrationParams) {
      this.firstname = this.registrationParams.firstname;
      this.lastname = this.registrationParams.lastname;
      this.phoneno = this.registrationParams.phoneno;
    }
  },
  methods: {
    redirectToSite(path) {
      console.log(`${process.env.VUE_APP_WEBSITE_URL}/${path}`);
      window.location.href = `${process.env.VUE_APP_WEBSITE_URL}/${path}`;
    },
    async submitAnswer() {
      this.buttonDisabled = true;

      // Check discount code
      try {
        if ($cookies.get('promocode')) {
          await OnboardingService.checkCoupon(
            $cookies.get('promocode')
          );
        }
      } catch (err) {
        this.$emit('showAlert', err.message);
        this.buttonDisabled = false;
        return;
      }

      if (
        !this.firstname ||
        !this.lastname ||
        !this.phoneno ||
        this.phoneno.length < 12
      ) {
        this.$emit('showAlert', 'Please fill in all fields.');
        this.buttonDisabled = false;
      } else if (
        !/^[\w\-\s]+$/.test(this.firstname) ||
        !/^[\w\-\s]+$/.test(this.lastname)
      ) {
        this.$emit(
          'showAlert',
          'First name and last name must be alphanumeric characters only.'
        );
        this.buttonDisabled = false;
      } else {
        const data = {
          phoneno: this.phoneno,
          optforpromotions: this.optforpromotions,
        };

        if (this.firstname) {
          //data.firstname = this.firstname;
          Vue.set(data, 'firstname', this.firstname);
        }
        if (this.lastname) {
          //data.lastname = this.lastname;
          Vue.set(data, 'lastname', this.lastname);
        }

        for (const key in this.eligibilityParams) {
          //if (!data[key]) data[key] = this.eligibilityParams[key];
          if (!data[key]) Vue.set(data, key, this.eligibilityParams[key]);
        }

        if ('ovariansurgery' in data && !data.ovariansurgery) {
          delete data.ovariansurgery;
        }

        try {
          this.$emit('finish', data);
          this.$_fsaHsaPromo_setSessionBannerShownToPatient();
        } catch (err) {
          if (
            err.message == 'An account with the given email already exists.'
          ) {
            this.$emit(
              'showAlert',
              `An account with the email ${this.email} already exists. You may sign in from the link below or enter a new email address to create a new account.`
            );
          } else {
            if (err.status == 500) {
              this.$store.dispatch('sendErrorLog', {
                step: 'Eligibility',
                error_log: {
                  message: err,
                },
              });
            }
            this.$emit('showAlert', err.message);
          }
          this.buttonDisabled = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"], .hide-arrows {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
