<template>
  <div class="dynamic-array">
    <h2 class="question-title">
      {{ body.question }}
    </h2>
    <!-- First allergy -->
    <b-form-input
      v-model="answers[0].allergy"
      type="text"
      placeholder="Input your allergy here"
      class="onboarding-input-lg"
    />
    <b-form-textarea
      v-if="answers[0].allergy !== ''"
      v-model="answers[0].effect"
      class="mt-3 onboarding-textarea"
      style="border-radius: 0.5rem !important"
      rows="4"
      placeholder="What happens when you take the drug above?"
    />
    <!-- Subsequent allergies -->
    <div
      v-for="(answer, index) in answers"
      :key="index"
      class="mb-3"
    >
      <b-icon
        v-if="index"
        style="float: right; cursor: pointer"
        class="mb-3"
        icon="x-circle-fill"
        @click="$delete(answers, index)"
      />
      <b-form-input
        v-if="index > 0"
        v-model="answers[index].allergy"
        type="text"
        placeholder="Input your allergy here"
        class="onboarding-input-lg"
      />
      <b-form-textarea
        v-if="index > 0"
        v-model="answers[index].effect"
        class="mt-3 onboarding-textarea"
        style="border-radius: 0.5rem !important"
        rows="4"
        placeholder="What happens when you take the drug above?"
      />
    </div>
    <u>
      <a
        v-if="activateControls"
        class="text-info"
        href="javascript:void(0)"
        @click="addAnswer"
      >+ Add another allergy
      </a>
    </u>
    <OnboardingCTAContainer>
      <b-button
        v-if="activateControls"
        pill
        block
        class="slight-rounded"
        variant="info"
        :disabled="disabled"
        @click="checkAnswer"
      >
        Continue
      </b-button>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer.vue';

export default {
  components: {
    OnboardingCTAContainer,
  },
  props: {
    body: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      answers: [{ allergy: '', effect: '' }],
    };
  },
  computed: {
    activateControls() {
      return this.answers.length > 0 && this.answers[0].allergy !== '';
    },
  },
  mounted() {
    if (this.body.hasOwnProperty('answer')) {
      this.answers = this.body.answer;
    }
  },
  methods: {
    addAnswer() {
      this.answers.push({ allergy: '', effect: '' });
    },
    checkAnswer() {
      this.$emit('submitAnswer', this.body.id, this.answers, this.body);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control[type="text"] {
  border: 2px solid #e0e0e0;
  border-radius: 0.5rem;

  &:focus {
    border-color: $primary;
  }
}

textarea.form-control {
  background-color: #fafafa;
  border: 0;

  &:focus {
    background-color: #fafafa;
  }
}
</style>
