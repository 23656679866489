<template>
  <b-container class="p-0">
    <div class="pt-6"></div>
    <b-toast
      id="alertToast"
      variant="danger"
      no-close-button
      auto-hide-delay="2500"
      toaster="b-toaster-top-center"
      solid
    >
      {{ toastMessage }}
    </b-toast>
    <HSAReceiptDialog
      :id="user.id"
      ref="HSAReceiptDialog"
      :name="`${user.firstname} ${user.lastname}`"
    />
    <h2
      v-if="step === 1"
      class="text-center mb-6 display-2 font-editorial"
    >
      Patient Care
    </h2>
    <div
      v-if="![1, 3].includes(step)"
      class="mb-5 px-3"
    >
      <p class="text-center display-4 text-bold">
        {{ mainCardTitle }}
      </p>
      <b-button
        class="back-btn d-flex align-items-center"
        size="sm"
        variant="link"
        style="min-width: inherit"
        @click="step -= 1"
      >
        <img
          src="@/assets/images/next-arrow.svg"
          style="transform: rotate(180deg)"
          width="20"
        >
        <span
          class="d-inline-block ml-2"
          style="color: #19191c"
        >Back</span>
      </b-button>
    </div>
    <template>
      <div
        v-if="step === 1"
        class="support-container pb-3 px-2"
      >
        <template v-for="item in supportList">
          <MainSupportCard
            v-if="
              (item.title === 'Manage my Treatment' &&
                hasCompletedTenWeekFollowup) ||
                item.title !== 'Manage my Treatment'
            "
            :key="item.title"
            :step="step"
            :item="item"
            @change-view="onChangeView"
            @hsa-receipt="openHSAReceipt"
          />
        </template>
      </div>
      <div
        v-else-if="step === 2"
        class="support-container pb-3 px-3"
      >
        <div
          v-for="item in subSupportList"
          :key="item.title"
        >
          <MainSupportCard
            :step="step"
            :item="item"
            @open-modal="onOpenModal"
            @hsa-receipt="openHSAReceipt"
          />
        </div>
      </div>
    </template>

    <!-- Modals -->
    <b-modal
      id="contact-us-form"
      v-bind="modalAttrs"
    >
      <contact-us-form :subject="mainCardTitle" />
    </b-modal>

    <b-modal
      id="cancel-processing-order"
      v-bind="modalAttrs"
    >
      <cancel-processing-order-screen :subject="mainCardTitle" />
    </b-modal>

    <b-modal
      id="report-issue-form"
      v-bind="modalAttrs"
    >
      <report-issue-form
        :issue="issue"
        :issue-title="selectedIssue"
      >
        <template #title>
          <p
            v-if="selectedIssue === `I'd like to report an issue`"
            class="support-modal-title text-bold my-2"
          >
            Report issue
          </p>
          <p
            v-else-if="
              selectedIssue === `I have an issue with my delivery` ||
                selectedIssue === 'Report delivery issue'
            "
            class="support-modal-title text-bold my-2"
          >
            Report issue with my delivery
          </p>
          <p
            v-else-if="selectedIssue === `I have an issue with my doctor`"
            class="support-modal-title text-bold my-2"
          >
            Report issue with my doctor
          </p>
        </template>
      </report-issue-form>
    </b-modal>

    <b-modal
      id="message-doctor-form"
      v-bind="modalAttrs"
    >
      <message-doctor-form :issue="selectedIssue">
        <template #title>
          <p
            v-if="selectedIssue === 'I have a question for my doctor'"
            class="support-modal-title text-bold my-2"
          >
            I have question for my doctor
          </p>
          <p
            v-else-if="selectedIssue === `I'd like to change my treatment`"
            class="support-modal-title text-bold my-2"
          >
            I'd like to change my treatment
          </p>
        </template>
      </message-doctor-form>
    </b-modal>

    <b-modal
      id="manage-treatment-screen"
      v-bind="modalAttrs"
    >
      <manage-treatment-screen
        :type="
          selectedIssue === `I'm experiencing side effects`
            ? 'sideeffect'
            : 'symptom'
        "
      >
        <template #title>
          <p
            v-if="selectedIssue === `I'm experiencing side effects`"
            class="support-modal-title text-bold my-2"
          >
            What are your side effects?
          </p>
          <p
            v-else-if="selectedIssue === `I have unmet symptoms`"
            class="support-modal-title text-bold my-2"
          >
            What are your unmet symptoms?
          </p>
        </template>
      </manage-treatment-screen>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState } from 'vuex';

import MainSupportCard from '@/components/support/MainSupportCard.vue';
import ContactUsForm from '../support/views/ContactUsForm.vue';
import CancelProcessingOrderScreen from '../support/views/CancelProcessingOrderScreen.vue';
import ReportIssueForm from '../support/views/ReportIssueForm.vue';
import MessageDoctorForm from '../support/views/MessageDoctorForm.vue';
import ManageTreatmentScreen from '../support/views/ManageTreatmentScreen.vue';
import HSAReceiptDialog from '@/components/dashboard/HSAReceiptDialog';

import analyticsMixin from '@/mixins/analytics';

const issueMapper = {
  "I'd like to report an issue": 'General',
  'I have an issue with my delivery': 'Delivery',
  'Report delivery issue': 'Delivery',
  'I have an issue with my doctor': 'Doctor',
};

export default {
  name: 'Support',
  components: {
    MainSupportCard,
    ContactUsForm,
    CancelProcessingOrderScreen,
    ReportIssueForm,
    MessageDoctorForm,
    ManageTreatmentScreen,
    HSAReceiptDialog,
  },
  mixins: [analyticsMixin],
  data() {
    return {
      toastMessage: '',
      step: 1,
      viewTitle: '',
      mainCardTitle: '',

      subSupportList: [],
      keyword: '',
      search: false,

      modalAttrs: {
        'hide-header': true,
        'hide-footer': true,
        centered: true,
        'no-close-on-backdrop': true,
        'no-close-on-esc': true,
        size: 'lg',
        scrollable: true,
      },

      selectedIssue: '',
    };
  },
  watch: {
    step(val, prev) {
      if (prev > val) {
        this.subSupportList = [];
      }
    },
  },
  async mounted() {
    this.$_analytics_page();
  },
  computed: {
    ...mapState('configuration', ['config']),
    ...mapState('user', {
      user: 'currentUser',
    }),
    supportList() {
      const list = [
        {
          title: 'Manage Orders',
          description:
            'Track your current order, cancel processing ones and view past shipments.',
          subList: [
            {
              title: 'Manage my subscriptions',
              description: 'View and update your treatment subsciptions.',
            },
            {
              title: 'Cancel a processing order',
              description:
                'Cancel an order that is processing and not yet shipped',
            },
            {
              title: 'I have a question for Patient Care',
              description: 'Ask us about your orders',
            },
          ],
        },
        {
          title: 'Billing & Charges',
          description:
            'Update your billing, see charge history and download receipts.',
          subList: [
            {
              title: 'Update credit card',
              description: 'Update your credit card information',
            },
            {
              title: 'Update billing address',
              description: 'Update your credit card billing address',
            },
            {
              title: 'Download Receipts',
              description: 'Download your HSA receipts',
            },
            {
              title: 'I have a question for Patient Care',
              description: 'Ask us about your billing and charges',
            },
          ],
        },
        {
          title: 'Shipping & Delivery',
          description:
            'Track your current order, cancel those in processing or report a delivery issue.',
          subList: [
            {
              title: 'Update shipping address',
              description: 'Tell us where to send your packages',
            },
            {
              title: 'Report delivery issue',
              description: 'Let us know what went wrong',
            },
            {
              title: 'I have a question for Patient Care',
              description: 'Ask us about your shipping and delivery',
            },
          ],
        },
        {
          title: 'Knowledge Hub',
          description: 'Learning everything about HRT Treatment and Winona.',
        },
        {
          title: 'Manage Account',
          description:
            'Update your email, phone, shipping, billing and password.',
          subList: [
            {
              title: 'Update my email',
              description: 'Change the email we send your updates to',
            },
            {
              title: 'Update shipping address',
              description: 'Tell us where to send your packages',
            },
            {
              title: 'Update billing address',
              description: 'Update your credit card billing address',
            },
            {
              title: 'I have a question for Patient Care',
              description: 'Ask us about updating your account',
            },
          ],
        },
        {
          title: 'Insurance & Receipts',
          description: 'Access all insurance documents and product receipts.',
          subList: [
            {
              title: 'Go to my Insurance Hub',
              description:
                'View and download all insurance related information',
            },
            {
              title: 'Download Receipts',
              description: 'Download your HSA receipts',
            },
            {
              title: 'I have a question for Patient Care',
              description: 'Ask us about insurance and receipts',
            },
          ],
        },
        {
          title: 'Manage my Treatment',
          description:
            'Updating your treatment and discuss with your doctor how to best suit your needs',
          subList: [
            {
              title: "I'm experiencing side effects",
              description: 'What side effects are you experiencing?',
            },
            {
              title: 'I have unmet symptoms',
              description: 'What symptoms are not being treated?',
            },
            {
              title: "I'd like to change my treatment",
              description:
                'Your physician will adjust your treatment to meet your needs',
            },
            {
              title: 'I have a question for my doctor',
              description: 'Ask your physician about your medical question',
            },
          ],
        },
        {
          title: 'Report Issue',
          description: 'How can we help?',
          subList: [
            {
              title: 'I have an issue with my doctor',
              description: 'How can your experience with your doctor improve?',
            },
            {
              title: 'I have an issue with my delivery',
              description: 'How can your delivery experience improve?',
            },
            {
              title: "I'd like to report an issue",
              description: 'How can we help?',
            },
            {
              title: 'I have a question for Patient Care',
              description: 'Ask us about your billing and charges',
            },
          ],
        },
        {
          title: 'Ask us Anything',
          description: 'Patient care is here to help',
        },
      ];

      return list.filter(x => !x.is_hidden);
    },
    hasCompletedTenWeekFollowup() {
      return this.user?.istenweekfollowupanswered;
    },
    issue() {
      if (!this.selectedIssue) return '';
      return issueMapper[this.selectedIssue];
    },
  },
  methods: {
    showToast(message, variant = 'error') {
      this.toastMessage = message;
      this.$bvToast.show('alertToast');
    },
    onChangeView(cardInfo) {
      if (cardInfo?.subList?.length > 0) {
        this.subSupportList = cardInfo.subList;
      }

      this.viewTitle = cardInfo.title;

      // For Ask us Anything card
      if (cardInfo.title === 'Ask us Anything') {
        this.mainCardTitle = cardInfo.title;
        this.$bvModal.show('contact-us-form');
      } else {
        this.step += 1;
        if (this.step === 2) {
          this.mainCardTitle = cardInfo.title;
        }
      }
    },
    openHSAReceipt() {
      if (this.user.hasOwnProperty('dateaccepted')) {
        const yearAccepted = new Date(
          this.user.dateaccepted * 1000
        ).getFullYear();
        const today = new Date();
        const currentYear = today.getFullYear();
        if (yearAccepted == currentYear) {
          this.$refs.HSAReceiptDialog.download(this.user.id, currentYear);
        } else {
          this.$refs.HSAReceiptDialog.showDialog = true;
        }
      } else {
        this.showToast('You have not yet been accepted as a patient.');
      }
    },
    onOpenModal(title) {
      if (title === 'I have a question for Patient Care') {
        this.$bvModal.show('contact-us-form');
      } else if (title === 'Cancel a processing order') {
        this.$bvModal.show('cancel-processing-order');
      } else if (
        [
          'I have an issue with my doctor',
          'I have an issue with my delivery',
          "I'd like to report an issue",
          'Report delivery issue',
        ].includes(title)
      ) {
        this.selectedIssue = title;
        this.$bvModal.show('report-issue-form');
      } else if (
        [
          'I have a question for my doctor',
          "I'd like to change my treatment",
        ].includes(title)
      ) {
        this.selectedIssue = title;
        this.$bvModal.show('message-doctor-form');
      } else if (
        ["I'm experiencing side effects", 'I have unmet symptoms'].includes(
          title
        )
      ) {
        this.selectedIssue = title;
        this.$bvModal.show('manage-treatment-screen');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.support-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}

@media (max-width: 1024px) {
  .support-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  .support-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.support-modal-title {
  font-size: 24px;
  font-weight: 700;
}
</style>
