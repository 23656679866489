<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 19.914L7 14.913L8.413 13.5L12 17.086L19.585 9.5L21 10.915L12 19.914Z"
      fill="currentColor"
    />
    <path
      d="M14 0.5C11.2311 0.5 8.52431 1.32109 6.22202 2.85943C3.91973 4.39777 2.12532 6.58427 1.06569 9.14243C0.00606596 11.7006 -0.271181 14.5155 0.269012 17.2313C0.809205 19.947 2.14258 22.4416 4.10051 24.3995C6.05845 26.3574 8.55301 27.6908 11.2687 28.231C13.9845 28.7712 16.7994 28.4939 19.3576 27.4343C21.9157 26.3747 24.1022 24.5803 25.6406 22.278C27.1789 19.9757 28 17.2689 28 14.5C28 10.787 26.525 7.22601 23.8995 4.6005C21.274 1.975 17.713 0.5 14 0.5ZM14 26.5C11.6266 26.5 9.30655 25.7962 7.33316 24.4776C5.35977 23.1591 3.8217 21.2849 2.91345 19.0922C2.0052 16.8995 1.76756 14.4867 2.23058 12.1589C2.69361 9.83114 3.83649 7.69295 5.51472 6.01472C7.19296 4.33649 9.33115 3.1936 11.6589 2.73058C13.9867 2.26755 16.3995 2.50519 18.5922 3.41345C20.7849 4.3217 22.6591 5.85977 23.9776 7.83316C25.2962 9.80655 26 12.1266 26 14.5C26 17.6826 24.7357 20.7348 22.4853 22.9853C20.2348 25.2357 17.1826 26.5 14 26.5Z"
      fill="currentColor"
    />
  </svg>
</template>
    
<script>
export default {};
</script>
  