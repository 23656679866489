import { AdminChatService } from '@/services/admin-chat.service';

export default {
  namespaced: true,
  state: {
    articles: []
  },
  mutations: {
    SET_ARTICLES(state, payload) {
      state.articles = payload;
    }
  },
  actions: {
    async fetchArticlesById({ commit, state }, { ids }) {
      try {
        const articleIds = state.articles.map(a => a.id);
        const newIds = ids.filter(id => !articleIds.includes(id));
        
        if (!newIds.length) {
          return state.articles.filter(a => ids.includes(a.id));
        }
    
        const { data } = await AdminChatService.getArticlesById(newIds);
        commit('SET_ARTICLES', [...state.articles, ...data]);
      } catch (error) {
        console.error(error);
        return error;
      } 
    }
  }
}