var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-container',{staticClass:"pl-4 pr-4 treatment-container"},[_c('div',{staticClass:"d-block d-md-none pt-3"}),_c('h1',{staticClass:"mt-5 heading text-center"},[_vm._v(" Orders ")]),_c('div',{staticClass:"d-block d-md-none pt-4"}),_c('b-alert',{attrs:{"show":_vm.alert.show,"variant":"warning"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","xxl":"12"}},[(_vm.prescriptions.length > 0)?_c('div',_vm._l((_vm.prescriptions),function(item,index){return _c('div',{key:index},[(
                item.medicinetype == 'NON-RX' &&
                  item.transactiontype == 'recurring'
              )?_c('SubscriptionCard',{attrs:{"patient-id":_vm.userData.id,"prescription-id":item.id},on:{"showAlert":_vm.showAlert}}):(
                item.medicinetype == 'NON-RX' &&
                  item.transactiontype == 'one_time'
              )?_c('OrderCard',{attrs:{"patient-id":_vm.userData.id,"prescription-id":item.id},on:{"showAlert":_vm.showAlert}}):_c('TreatmentCard',{attrs:{"item":item},on:{"show-modal":_vm.showModal}})],1)}),0):_c('b-row',{attrs:{"align-v":"center","align-h":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"placeholder-box"},[_c('div',{staticClass:"placeholder-img",style:({
                  backgroundImage: `url(${require('@/assets/images/doctor.svg')})`,
                })}),_c('p',{staticClass:"label-header text-center mb-4",staticStyle:{"font-size":"1.2rem"}},[_vm._v(" Your doctor is currently reviewing your treatment options. Once complete, your treatment will be here. ")]),_c('p',[_vm._v("Have a question? "),_c('a',{attrs:{"href":"/contact-us"}},[_vm._v("Contact Us")])])])])],1)],1)],1)],1),_c('b-modal',{attrs:{"hide-footer":"","header-class":"intake-header","size":"md","scrollable":"","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('h3',{staticClass:"mb-0 heading"},[_vm._v(" "+_vm._s(_vm.modal.heading)+" ")])])],1)],1),_c('b-button',{attrs:{"size":"sm","variant":"link"},on:{"click":function($event){return close()}}},[_c('b-icon',{attrs:{"icon":"x-circle-fill","variant":"primary"}})],1)]}}]),model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[(_vm.modal.text !== '')?_c('b-col',{domProps:{"innerHTML":_vm._s(_vm.modal.text)}}):_c('b-col',[_vm._v(" No available information ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }