<template>
  <b-alert
    show
    class="tw-p-3 tw-bg-[#1a6a72] tw-text-white tw-text-center tw-border-none tw-mb-0 tw-flex-inline"
  >
    <span class="tw-font-atten-medium tw-tracking-wider tw-text-md lg:tw-text-lg tw-align-middle">
      <span class="tw-uppercase tw-font-bold">Black Friday Deal</span> <span class="tw-font-atten">— Estriol + Tretinoin Skincare for Only <span class="tw-font-hatton tw-font-bold">$99</span> — Limited Time Offer!</span>
    </span>
    <div class="tw-inline-block">
      <b-button
        size="sm"
        variant="info"
        class="tw-hidden lg:tw-inline-block tw-px-5 tw-py-1 tw-font-atten-bold tw-border-transparent tw-ml-2 tw-text-xs lg:tw-text-sm !tw-rounded-md"
        @click="$emit('open-upsell')"
      >
        Get Deal
      </b-button>
      <b-button
        size="sm"
        variant="link"
        class="tw-inline-block lg:tw-hidden tw-px-1 tw-py-0 tw-font-atten-bold tw-border-transparent tw-text-sm lg:tw-text-sm !tw-rounded-md tw-text-white tw-underline"
        @click="$emit('open-upsell')"
      >
        Get Deal
      </b-button>
    </div>
  </b-alert>
</template>

<script>
export default {
  name: 'BFCMBanner',
}
</script>