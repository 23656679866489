<template>
  <div
    id="certified-logo"
    class="certified-logo tw-text-center"
  >
  </div>
</template>

<script>
import postscribe from 'postscribe';

export default {
  async mounted () {
    const script = document.createElement('script')
    script.src = 'https://static.legitscript.com/seals/8131176.js'
    postscribe('#certified-logo', script.outerHTML)
  },
}
</script>
