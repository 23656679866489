
<template>
  <div
    id="payment-request-button"
    ref="paymentRequestButton"
  ></div>
</template>

<script>
import { waitCallbackTruthy } from '@/utils/waitCallbackTruthy';

export default {
  props: {
    totalPrice: {
      type: [Number, null],
      default: null
    },
  },
  data() {
    return {
      pk: process.env.VUE_APP_STRIPE_CLIENT_KEYS,
      canMakePayment: false,
    }
  },
  async mounted () {
    await waitCallbackTruthy(() => this.totalPrice !== null)
    this.loadPaymentRequest();
  },
  methods: {
    loadPaymentRequest() {
      const stripe = Stripe(this.pk);
      const paymentRequest = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Total',
          amount: this.totalPrice * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
        shippingOptions: [{
          id: 'usps',
          label: 'USPS Priority',
          detail: 'USPS Priority',
          amount: 0,
        }],
      });

      const elements = stripe.elements();
      const prButton = elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
      });
      // Check the availability of the Payment Request API first.
      paymentRequest.canMakePayment().then(result => {
        this.canMakePayment =
          result != null &&
          (result?.applePay || result?.googlePay);
        if (result) {
          prButton.mount('#payment-request-button');
        } else {
          document.getElementById('payment-request-button').style.display =
            'none';
        }
      });

      paymentRequest.on('paymentmethod', ev => {
        this.$emit('success', ev)
        ev.complete('success');
      });
    },
  }
}
</script>

<style lang="scss" scoped>

</style>