<template>
  <section
    id="fsa-has-store-banner"
    class="md:tw-px-7 tw-bg-lavender-purple tw-items-center tw-justify-center tw-z-[999] tw-h-[60px] md:tw-h-[86px] tw-w-screen tw-text-white tw-font-atten-medium"
    :class="[
      $_fsaHsaPromo_isActiveFsaStore30? 'tw-px-2': 'tw-px-4',
      $_fsaHsaPromo_isActiveFsaHsaPromo? 'tw-fixed tw-top-0 tw-left-0 tw-flex': 'tw-hidden'
    ]"
  >
    <div class="tw-flex tw-items-center tw-max-w-[292px] sm:tw-max-w-[400px] md:tw-max-w-[520px] lg:tw-max-w-max">
      <span class="tw-flex-none">
        <a
          :href="$_fsaHsaPromo_isActiveFsaStore30? 'https://fsastore.com/': 'https://hsastore.com/'"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconFSAStoreLogo
            v-if="$_fsaHsaPromo_isActiveFsaStore30"
            class="tw-w-[94.93px] tw-h-[20px] md:tw-w-[151.89px] md:tw-mb-[0.325rem] md:tw-h-[32px] lg:tw-w-[189.86px] lg:tw-h-[40px]"
          />
          <IconHSAStoreLogo
            v-else
            class="tw-w-[82.99px] tw-h-[20px] md:tw-w-[132.78px] md:tw-mb-[0.625rem]   md:tw-h-[32px] lg:tw-w-[165.98px] lg:tw-h-[40px]"
          />
        </a>
      </span>
      <span class="tw-bg-white tw-h-[32px] md:tw-h-[42px] tw-w-[1px] tw-flex tw-mx-3 md:tw-mx-7"></span>
      <span class="tw-mt-[2px] tw-text-sm md:tw-text-2xl lg:tw-text-3xl">Receive 30% off and use your
        <span v-if="$_fsaHsaPromo_isActiveFsaStore30">FSA</span>
        <span v-if="$_fsaHsaPromo_isActiveHsaStore30">HSA</span>
        funds at checkout.</span>
    </div>
  </section>
</template>

<script>
import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';

import IconFSAStoreLogo from '@/components/icon/fsa-hsa/IconFSAStoreLogo.vue';
import IconHSAStoreLogo from '@/components/icon/fsa-hsa/IconHSAStoreLogo.vue';

export default {
  components: {
    IconFSAStoreLogo,
    IconHSAStoreLogo,
  },
  mixins: [fsaHsaPromoMixin]
}

</script>