<template>
  <b-container class="account-main my-5 my-md-6">
    <b-row align-h="center">
      <b-col class="col-auto">
        <h3 class="display-4 text-primary heading">
          Insurance Documents
        </h3>
      </b-col>
    </b-row>
    <b-toast
      id="alertToast"
      variant="danger"
      no-close-button
      auto-hide-delay="2500"
      toaster="b-toaster-top-center"
      solid
    >
      {{ toastMessage }}
    </b-toast>
    <b-row
      align-h="center"
      align-v="center"
      class="mt-6"
    >
      <b-col class="mb-5 mx-4 mx-md-0 mb-md-0">
        <b-card class="pt-3 insurance-card">
          <b-row>
            <b-col class="pt-3 text-center text-md-left">
              <h2>HSA Receipt</h2>
            </b-col>
            <b-col
              class="text-right"
              cols="12"
              md="4"
              lg="3"
            >
              <a
                href="javascript:;"
                @click="openHSADialog"
              >
                <b-button
                  variant="info"
                  pill
                  size="lg"
                  block
                  class="px-3"
                >Download</b-button>
              </a>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="pt-3 insurance-card">
          <b-row>
            <b-col class="pt-3 text-center text-md-left">
              <h2>FSA Receipt</h2>
            </b-col>
            <b-col
              class="text-right"
              cols="12"
              md="4"
              lg="3"
            >
              <a
                href="javascript:;"
                @click="openFSADialog"
              >
                <b-button
                  variant="info"
                  pill
                  size="lg"
                  block
                  class="px-3"
                >Download</b-button>
              </a>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="pt-3 insurance-card">
          <b-row>
            <b-col class="pt-3 text-center text-md-left">
              <h2>NDC Form</h2>
            </b-col>
            <b-col
              class="text-right"
              cols="12"
              md="4"
              lg="3"
            >
              <a
                href="javascript:;"
                @click="openNDCDialog"
              >
                <b-button
                  variant="info"
                  pill
                  size="lg"
                  block
                  class="px-3"
                >Download</b-button>
              </a>
            </b-col>
          </b-row>
        </b-card>

        <p class="insurance-info px-4 pt-4">
          We understand that some of our patients may be looking to submit their
          medical expenses to their insurance provider for reimbursement. To
          assist with this process, we have made it easy for you to access the
          necessary documents, such as the Health Savings Account (HSA) and
          Flexible Spending Account (FSA) and National Drug Codes (NDC),
          directly from our website. By accessing these documents and submitting
          them to your insurance provider, you may be able to recoup some of the
          costs of your medical treatment. Please note that the submission and
          management of these claims is the patient's responsibility.
        </p>
      </b-col>
    </b-row>
    <HSAReceiptDialog
      :id="id"
      ref="HSAReceiptDialog"
      :name="name"
    />
    <FSAReceiptDialog
      ref="FSAReceiptDialog"
      :row-data="fsaData"
      @show-toast="showToast"
    />
    <NDCFormDialog
      ref="NDCFormDialog"
      :row-data="fsaData"
      @show-toast="showToast"
    />
  </b-container>
</template>

<script>
import { UsersService } from '@/services/users.service';

import HSAReceiptDialog from '@/components/dashboard/HSAReceiptDialog';
import FSAReceiptDialog from '@/components/dashboard/FSAReceiptDialog';
import NDCFormDialog from '@/components/dashboard/NDCFormDialog';

export default {
  components: {
    HSAReceiptDialog,
    FSAReceiptDialog,
    NDCFormDialog,
  },
  data() {
    return {
      id: '',
      name: '',
      fsaData: {
        firstname: '',
        lastname: '',
      },
      ndcData: {
        firstname: '',
        lastname: '',
      },
      toastMessage: '',
    };
  },
  mounted() {
    this.getUserData();
  },
  methods: {
    async getUserData() {
      const { data } = await UsersService.getCurrent();
      this.id = data.id;
      this.name = data.firstname + ' ' + data.lastname;
      this.fsaData = {
        firstname: data.firstname,
        lastname: data.lastname,
        id: data.id,
      };
    },
    openHSADialog() {
      this.$refs.HSAReceiptDialog.showDialog = true;
    },
    openFSADialog() {
      this.$refs.FSAReceiptDialog.showDialog = true;
    },
    openNDCDialog() {
      this.$refs.NDCFormDialog.showDialog = true;
    },
    showToast(message, variant = 'error') {
      this.toastMessage = message;
      this.$bvToast.show('alertToast');
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-card {
  background-color: #f4f2ff;
}

.insurance-card-dark {
  background-color: #e7e8e9;
}

.insurance-badge {
  background-color: #d1d2d4;
  color: #000000;
}

.insurance-info {
  font-size: 1.1rem;
}
</style>
