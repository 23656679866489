<template>
  <div
    class="tw-relative xl:tw-absolute xl:tw-w-[263px] tw-w-full tw-mt-3 xl:tw-mt-0"
    :style="[
      $_breakpoint_xl && {
        bottom: `${bottom}px`,
        left: `${left}px`
      }
    ]"
  >
    <p class="label-header">
      Ensure that:
    </p>

    <p
      v-for="item in items"
      :key="item"
      class="mb-0"
    >
      - {{ item }}
    </p>
  </div>
</template>

<script>
// utils
import { observeResize } from '@corefront/utils/observeResize';

// mixins
import { breakpoint } from '@corefront/mixins-v2/breakpoint';

function contentRectToObject(rect) {
  const { width, height, top, right, bottom, left, x, y } = rect;
  return { width, height, top, right, bottom, left, x, y };
}

export default {
  mixins: [breakpoint],
  data() {
    return {
      onboardingContentRect: null,
      promoBannerRect: null,
      items: [
        'Your ID is not cutoff',
        'The photo is not blurry or dark',
        'Your ID is government issued and not expired',
      ],
    }
  },
  computed: {
    bottom () {
      if (!this.onboardingContentRect || !this.promoBannerRect) {
        return 0
      }

      return window.innerHeight -
        this.onboardingContentRect.bottom || 0 -
        this.promoBannerRect.height || 0
    },
    left () {
      if (!this.onboardingContentRect) {
        return 0
      }

      return this.onboardingContentRect.left
    }
  },
  mounted () {
    observeResize(['.onboarding-content'], els => {
      const [onboardingContent] = els
      this.onboardingContentRect = contentRectToObject(onboardingContent.contentRect)
    })

    observeResize(['#fsa-has-store-banner'], els => {
      const [promoBanner] = els
      this.promoBannerRect = contentRectToObject(promoBanner.contentRect)
    })
  },
}
</script>