<template>
  <div class="tw-flex tw-items-center tw-pl-5 tw-gap-2 tw-w-full tw-h-[59px] tw-bg-pastel-mint">
    <IconPackage class="tw-text-black tw-shrink-0" />
    <div>
      <span class="tw-block tw-text-black tw-font-atten-medium tw-leading-tight tw-text-[11.58px]">Over 1,000,000 shipped orders</span>
      <span class="tw-block tw-text-black tw-leading-tight tw-text-[10.75px]">You're in safe hands with us.</span>
    </div>
  </div>
</template>

<script>
import IconPackage from '@/components/icon/checkout/IconPackage.vue';

export default {
  components: {
    IconPackage
  },
}

</script>