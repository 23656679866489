<template>
  <b-container
    class="p-0"
    fluid
  >
    <AdminChat
      v-if="seeMessage"
      id="chatList"
      :conversation="conversation"
      :userid="userid"
      @scroll.passive="onScroll"
    />
  </b-container>
</template>

<script>
import { ChatService } from '@/services/chat.service.js';
import { mapGetters } from 'vuex';

import AdminChat from '@/components/messages/AdminChat';
export default {
  name: 'AdminChatBase',
  components: {
    AdminChat,
  },
  data() {
    return {
      seeMessage: false,
      userid: '',
      isLoading: true,
      conversation: {},
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  async mounted() {
    if (window.Intercom) {
      this.$nextTick(() => {
        window.Intercom('boot', {
          app_id: process.env.VUE_APP_INTERCOM_APP_ID,
          hide_default_launcher: true,
          custom_launcher_selector: '.intercom-launcher',
        });
      });
    }
    await this.getConversation();
    await this.$store.dispatch('user/getUser');
    this.userid = localStorage.getItem('userid');
    this.seeMessage = true;
  },
  methods: {
    onScroll(e) {
      this.$emit('scroll', e);
    },
    async getConversation() {
      try {
        this.isLoading = true;
        const { data: conversation } = await ChatService.listConversation();
        this.conversation = conversation[conversation.length - 1];
        const conversationData = await ChatService.getConversation(
          conversation[conversation.length - 1]?.id
        );
        this.conversation.banned_date = conversationData?.data?.banned_date;
        this.conversation.isbanned = conversationData?.data?.isbanned;
        this.isLoading = false;
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>
