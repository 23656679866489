<template>
  <b-container
    class="p-0"
    fluid
  >
    <template v-if="prescription == 'rx'">
      <b-row>
        <b-col
          v-if="coupon.show"
          class="bg-info text-center py-2 text-light"
        >
          {{ coupon.message }}
          <a
            v-if="coupon.showBlossomLink"
            href="javascript:;"
            class="text-light"
            @click="showUpsellModal({ upsellcode: 'BLOSSOM' })"
          ><u>Claim now</u>
          </a>
        </b-col>
        <b-col
          v-if="couponerror.show"
          class="bg-danger text-center py-2 text-light"
        >
          {{ couponerror.message }}
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col
          cols="12"
          :md="show ? 6 : 9"
        >
          <b-row class="mb-5 hero-banner">
            <b-col
              v-if="id"
              cols="12"
            >
              <div
                :class="[
                  'alert alert-danger mb-3 text-center verify-status',
                  !verificationstatus ? 'not-verify' : '',
                ]"
              >
                Action Required. You have something that needs updated or
                confirmed in your account.
                <router-link
                  v-slot="{ navigate }"
                  to="/account-settings"
                  custom
                >
                  <a
                    href="javascript:void(0)"
                    class="color-inherit text-underline"
                    @click="navigate"
                  >Visit here</a>
                </router-link>
                .
              </div>
              <div
                class="alert alert-danger mb-3 text-center verify-status"
                :class="showSkipAlert ? 'not-verify' : ''"
              >
                For our doctor to accept you, please upload your
                {{ iduploaded == "SKIPPED" ? "ID" : "" }}
                {{
                  iduploaded == "SKIPPED" && photouploaded == "SKIPPED"
                    ? "and"
                    : ""
                }}
                {{ photouploaded == "SKIPPED" ? "Personal Photo" : "" }}
                by clicking
                <router-link
                  v-slot="{ navigate }"
                  to="/account-settings"
                  custom
                >
                  <a
                    href="javascript:void(0)"
                    class="color-inherit text-underline"
                    @click="navigate"
                  >here</a>
                </router-link>
              </div>
              <b-alert
                v-if="followups.length > 0 && isTwoWeeksBeforeFollowup"
                style="
                  background: #1a6a72;
                  color: #cdfcb1;
                  border-color: #1a6a72;
                "
                show
                class="mb-5 h3"
              >
                <span v-if="followups[0].type === '1YEAR'">
                  1 Year Follow Up:
                </span>
                <span v-else>10 Week Follow Up: </span>
                Your next check-in is scheduled for
                {{ parseDate(followups[0].followupdatestamp) }}.
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
        <DashboardHero
          :name="name"
          @apc-redirect="redirectToAdminChat"
        />
        <DashboardTimeline :timeline="timeline" />
        <b-col
          cols="10"
          class="mt-4 text-center"
        >
          <h2 class="display-4 font-weight-normal">
            Patient-Only Offers from Winona
          </h2>
        </b-col>
      </b-row>
      <div class="tw-w-full tw-max-w-[1200px] tw-mx-auto tw-px-4 md:tw-px-10 tw-mt-6">
        <CarouselSlider
          autoplay
          :autoplay-timeout="12000"
          animation="opacity"
        >
          <CarouselItem
            v-for="upsell in availableDashboardUpsell"
            :key="upsell.id"
          >
            <UpsellDisplayCard
              :variant="upsell.upsellcode === 'BLOSSOM' ? 'purple' : 'green'"
              :upsell-item="upsell"
              :processing="processing"
              @order="showUpsellModal(upsell)"
            />
          </CarouselItem>
        </CarouselSlider>
      </div>
      <b-row
        align-h="center"
        style="background-color: #f7f7f7"
        class="mb-5 mt-6"
      >
        <b-col class="text-center py-5">
          <h3 style="font-size: 2rem">
            Have <b>questions</b> before proceeding?
          </h3>
          <a href="https://help.bywinona.com/en/collections/2893290-faq">
            <b-button
              pill
              variant="info"
              size="lg"
              class="mt-3 px-6 px-lg-5 mb-3 slight-rounded"
            >Browse FAQs</b-button>
          </a>
          <br>
          <div class="px-6">
            <a
              class="dashboard-link text-uppercase text-underline align-middle"
              href="javascript:;"
              @click="redirectToAdminChat"
            >Talk to a member of our patient care team<span
              class="next-arrow-purple align-middle d-none d-lg-inline-block"
            ></span></a>
          </div>
        </b-col>
      </b-row>
    </template>

    <template v-if="prescription == 'non-rx'">
      <b-row>
        <b-col cols="12">
          <banner
            :name="name"
            button-text="view your order"
            :background-image="require('@/assets/images/login/bg.png')"
            height="calc(100vh - 150px)"
          />
        </b-col>
      </b-row>
    </template>

    <b-row class="px-4 pt-4 mx-0">
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="px-2 px-xl-4 mb-4 mb-lg-0"
      >
        <d-card
          bg-color="#9485b3"
          border-color="#a6a6a6"
          :image="require('@/assets/images/dashboard/dashboard_phone.png')"
          link="/messages"
          text="Message Provider"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="px-2 px-xl-4 mb-4 mb-lg-0"
      >
        <d-card
          bg-color="#E8F2EC"
          border-color="#cdfcb1"
          :image="require('@/assets/images/dashboard/dashboard_laptop.jpg')"
          url="https://help.bywinona.com/en/"
          text="Support Center"
        />
      </b-col>
      <b-col
        cols="12"
        lg="4"
        class="px-2 px-xl-4 mb-4 mb-lg-0 d-none d-lg-block"
      >
        <!-- Contact Care Team Card - Desktop -->
        <b-card
          style="height: 100%; background-color: #F2ECFF; border-radius: 25px;"
          class="default-bg"
        >
          <b-row
            align-v="stretch"    
          >
            <b-col
              cols="12"
              style="height: 270px"
            >
              <img
                :src="require('@/assets/images/dashboard/people.svg')"
                width="50px"
                alt=""
                class="mb-4"
              >
              <h2
                class="mb-3 heading text-default"
                style="font-size: 24px;"
              >
                How can we help you?
              </h2>
              <p
                class="mb-0 sub-text"
                style="font-size: 20px; line-height: 30px;"
              >
                Have a question about your membership, or trouble accessing your
                account? Your friends at the Winona are here to help!
              </p>
            </b-col>
            <b-col
              cols="12"
              align-self="end"
            >
              <h2 class="mb-0 py-4">
                <router-link
                  :to="
                    isUserSupportsAdminPatientChat
                      ? '/messages/admin'
                      : '/contact-us'
                  "
                  class="text-uppercase"
                  style="letter-spacing: 2px; color: #A783FF; font-weight: 700; font-size: 16px;"
                >
                  <div class="tw-flex tw-justify-between">
                    <a href="javascript:;">Contact Care Team</a>
                    <span class="next-arrow-purple"></span>
                  </div>
                </router-link>
              </h2>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="px-4 pb-4 pt-lg-4 mx-0">
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="px-2 px-xl-4 mb-4 mb-lg-0"
      >
        <d-card
          bg-color="#E8F2EC"
          border-color="#C7BDFF"
          :image="require('@/assets/images/dashboard/dashboard_trio.jpg')"
          url="https://www.facebook.com/groups/141206008222366"
          text="Join Facebook Community"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="px-2 px-xl-4 mb-4 mb-lg-0"
      >
        <d-card
          bg-color="#E8F2EC"
          border-color="#C7BDFF"
          :image="require('@/assets/images/dashboard/dashboard_laptop2.jpg')"
          url="https://app.livestorm.co/winona"
          text="Register for the next live Q&A"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="px-2 px-xl-4 mb-4 mb-lg-0 d-block d-lg-none"
      >
        <!-- Contact Care Team Card - Mobile -->
        <b-card
          style="height: 100%; background-color: #F2ECFF; border-radius: 25px;"
          class="default-bg"
        >
          <b-row
            align-v="stretch"    
          >
            <b-col
              cols="12"
              style="height: 270px"
            >
              <img
                :src="require('@/assets/images/dashboard/people.svg')"
                width="50px"
                alt=""
                class="mb-4"
              >
              <h2
                class="mb-3 heading text-default"
                style="font-size: 24px;"
              >
                How can we help you?
              </h2>
              <p
                class="mb-0 sub-text"
                style="font-size: 20px; line-height: 30px;"
              >
                Have a question about your membership, or trouble accessing your
                account? Your friends at the Winona are here to help!
              </p>
            </b-col>
            <b-col
              cols="12"
              align-self="end"
            >
              <h2 class="mb-0 py-4">
                <router-link
                  :to="
                    isUserSupportsAdminPatientChat
                      ? '/messages/admin'
                      : '/contact-us'
                  "
                  class="text-uppercase"
                  style="letter-spacing: 2px; color: #A783FF; font-weight: 700; font-size: 16px;"
                >
                  <div class="tw-flex tw-justify-between">
                    <a href="javascript:;">Contact Care Team</a>
                    <span class="next-arrow-purple"></span>
                  </div>
                </router-link>
              </h2>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="px-2 px-xl-4"
      >
        <b-card
          style="height: 100%; background-color: #F2ECFF; border-radius: 25px;"
          class="default-bg"
        >
          <b-row
            align-v="stretch"
          >
            <b-col
              cols="12"
              style="height: 270px;"
            >
              <img
                :src="require('@/assets/images/dashboard/plus.svg')"
                width="50px"
                alt="physician"
                class="mb-4"
              >
              <h2
                class="mb-3 heading text-default"
                style="font-size: 24px;"
              >
                Winona Physician
              </h2>
              <p
                class="mb-0 sub-text"
                style="font-size: 20px; line-height: 30px;"
              >
                For questions regarding your health, treatment, and symptoms
              </p>
            </b-col>
            <b-col
              cols="12"
              align-self="end"
            >
              <h2 class="mb-0 pt-4">
                <router-link
                  v-slot="{ navigate }"
                  to="/messages/doctor"
                  custom
                  style="letter-spacing: 2px; color: #A783FF; font-weight: 700; font-size: 16px;"
                >
                  <a
                    href="javascript:void(0)"
                    class="text-uppercase tw-flex tw-justify-between"
                    @click="navigate"
                  >
                    Contact Winona Physician
                    <span class="next-arrow-purple"></span>
                  </a>
                </router-link>
              </h2>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <OneClickUpsell
      v-show="isUpsellModalVisible"
      :is-upsell-modal-visible.sync="isUpsellModalVisible"
      upsell-from="Patient Portal Upsell"
      :couponid="$_fsaHsaPromo_sessionPromoValue || $cookies.get('promocode')"
      :upsell-code="selectedUpsellCode"
      :all-upsell="allUpsell"
      @success="onUpsellSuccess"
    >
      <template #modal>
        <modal
          id="upsellSuccess"
          ref="modal"
          size="md"
          centered
          hide-header
          hide-footer
        >
          <div class="px-5 py-4">
            <h2 class="h1 heading text-primary mb-3">
              Success!
            </h2>
            <p class="sub-text">
              {{ selectedProduct }} has been added to your treatment plan
            </p>
  
            <b-button
              pill
              block
              variant="info"
              @click="$bvModal.hide('upsellSuccess')"
            >
              Close
            </b-button>
          </div>
        </modal>
      </template>
    </OneClickUpsell>

    <modal
      id="upsellError"
      size="md"
      centered
      hide-header
      hide-footer
      content-class="tw-px-5 tw-py-6"
    >
      <h1
        class="tw-mb-1 text-primary"
        style="font-family: 'hatton-medium'"
      >
        We're Sorry!
      </h1>
      <p>Something went wrong while trying to process your request. Please contact Patient Services for assistance.</p>
      <b-button
        block
        pill
        variant="info"
        @click="redirectToAdminChat"
      >
        Message Patient Services
      </b-button>
    </modal>

    <modal
      id="upsellFirstOrder"
      size="md"
      centered
      hide-header
      hide-footer
      content-class="tw-px-5 tw-py-6"
    >
      <h1
        class="tw-mb-4 text-primary"
        style="font-family: 'hatton-medium'"
      >
        It looks like you've already placed your first order!
      </h1>
      <p>The $120 discounted offer is only for <span class="tw-underline">first-time orders</span> of Estriol Cream with Tretinoin. For subsequent orders, the price is $150.</p>
      <p>Thanks for understanding!</p>
      <b-button
        block
        pill
        variant="info"
        @click="onFirstOrderModalContinue"
      >
        Continue
      </b-button>
    </modal>

    <portal to="bfcm">
      <BFCMBanner
        v-if="hasBFCMPromo"
        @open-upsell="onOpenBFCMUpsell"
      />
    </portal>
  </b-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import { mapGetters } from 'vuex';

// components
import CarouselSlider from '@corefront/components-v2/Carousel/CarouselSlider.vue';
import CarouselItem from '@corefront/components-v2/Carousel/CarouselItem.vue';
import dCard from '@/components/partials/Card';
import banner from '@/components/partials/Banner';
import OneClickUpsell from '@/components/onboarding/checkout/OneClickUpsell';
import UpsellDisplayCard from '@/components/partials/UpsellDisplayCard.vue';
import DashboardHero from '@/components/dashboard/sections/DashboardHero.vue';
import DashboardTimeline from '@/components/dashboard/sections/DashboardTimeline.vue';
import BFCMBanner from '@/components/dashboard/bfcm/BFCMBanner';

// services
import LocalStorageService from '@/localStorage';
const localStorageService = LocalStorageService.getService();
import { PromocodeService } from '@/services/promocode.service';
import { OnboardingService } from '@/services/onboarding.service';
import { UpsellService } from '@/services/upsell.service';

import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';
import analyticsMixin from '@/mixins/analytics';

export default {
  name: 'Dashboard',
  components: {
    // articleCard,
    CarouselSlider,
    CarouselItem,
    dCard,
    banner,
    OneClickUpsell,
    UpsellDisplayCard,
    DashboardHero,
    DashboardTimeline,
    BFCMBanner
    // GiftCard,
  },
  mixins: [fsaHsaPromoMixin, analyticsMixin],
  data() {
    return {
      name: '',
      id: '',
      prescription: 'rx',
      verificationstatus: false,
      show: false,
      iduploaded: null,
      photouploaded: null,

      isUpsellModalVisible: false,
      coupon: {
        show: false,
        amount: '',
        message: '',
        showBlossomLink: false,
      },
      couponerror: {
        show: false,
        message: '',
      },
      followups: [],
      timeline: [],

      selectedUpsellCode: '',
      selectedProduct: '',
      processing: false,

      allUpsell: null
    };
  },
  computed: {
    ...mapGetters('configuration', ['isUserSupportsAdminPatientChat']),
    showSkipAlert() {
      return this.iduploaded == 'SKIPPED' || this.photouploaded == 'SKIPPED';
    },
    availableDashboardUpsell() {
      return this.$store.state.upsell.availableDashboardUpsell;
    },
    isTwoWeeksBeforeFollowup() {
      if (this.followups.length > 0) {
        const followupUnix = this.followups[0]?.followupdatestamp;
        return moment().isAfter(moment.unix(followupUnix).subtract(2, 'week'));
      } else {
        return false;
      }
    },
    hasBFCMPromo() {
      return this.availableDashboardUpsell.some(
        upsell => upsell.upsellcode === 'CREAM' && upsell.metadata.bfcm === 'true'
      );
    },
  },
  watch: {
    async isUpsellModalVisible(val) {
      if (val === false) {
        this.allUpsell = null;
        document.documentElement.classList.remove('tw-overflow-hidden');
        await this.$store.dispatch('upsell/fetchAvailableDashboardUpsell');
      } else {
        document.documentElement.classList.add('tw-overflow-hidden');
      }
    },
  },
  async mounted() {
    if (!localStorageService.getItem('accessToken')) {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'upsell')) {
        if (this.$route.query.upsell === 'cream') {
          return this.$router.push({ name: 'login', query: { redirectTo: 'cream_modal' } });
        } else {
          return this.$router.push('/login');
        }
      } else {
        return this.$router.push('/login');
      }
    }

    this.$_analytics_page();

    const blossom_promocodes = [
      'BFBLOSSOM40',
      'BFBLOSSOM40FIXED',
      'BLACKFRIDAY2022',
      'CYBERMONDAY2022',
      'BFBLOSSOM10',
    ];

    this.getuser();
    await this.$store.dispatch('upsell/fetchAvailableDashboardUpsell');

    // WIN-14968
    // Apply existing customer promocode from cookie
    if ($cookies.get('promocode') && $cookies.get('promocodeExistingID')) {
      try {
        const existingId = $cookies.get('promocodeExistingID');
        const res = await PromocodeService.applyExistingCustomerPromocode(
          existingId,
          $cookies.get('promocode')
        );
        if (res.status == 200) {
          const coupon = $cookies.get('promocode');
          this.coupon.message = `Coupon CODE: ${coupon} applied successfully! Coupon will reflect on your next prescription.`;
          this.coupon.show = true;
          this.$cookies.remove('promocode');
          this.$cookies.remove('promocodeExistingID');
        }
      } catch (err) {
        if (err.message) {
          this.couponerror.message = err.message;
        } else {
          this.couponerror.message =
            'This promocode is not applicable for this user.';
        }
        this.couponerror.show = true;
      }
    }

    // Check promocode for Blossom
    if (blossom_promocodes.includes($cookies.get('promocode'))) {
      const { data } = await OnboardingService.checkCoupon(
        $cookies.get('promocode')
      );
      this.coupon.show = true;
      this.coupon.message = 'You have an active discount for 40% off Blossom.';
      this.coupon.amount = data.percent_off;
      this.coupon.showBlossomLink = true;
    }

    const upsellQuery = this.$route.query.upsell;
    if (upsellQuery) {
      if (upsellQuery == 'cream') {
        try {
          const upsell = this.availableDashboardUpsell.find(
            upsell => upsell.upsellcode === 'CREAM'
          );
          if (upsell) {
            if (upsell.firstorder) {
              this.showUpsellModal(upsell);
            } else {
              this.$bvModal.show('upsellFirstOrder');
            }
          } else {
            this.$bvModal.show('upsellFirstOrder');
          }
        } catch (err) {
          this.$bvModal.show('upsellError');
        }
      } else {
        this.$bvModal.show('upsellError');
      }
    }
  },
  methods: {
    getuser() {
      this.$store
        .dispatch('user/getUser')
        .then(data => {
          this.$store.commit('onboarding/SET_STATE', {
            state: 'onboardingPatient',
            value: data,
          })
          this.$token = localStorageService.getItem('accessToken');
          if (data.onboardingstatus === 'PENDING') {
            localStorageService.setItem('onboardingstep', data.onboardingstep);
          }
          this.id = data.id;
          if (data.firstname) {
            this.name = data.firstname;
          } else {
            this.name = data.email;
          }
          if (data.hasOwnProperty('identificationupload')) {
            this.iduploaded = data.identificationupload;
          }
          if (data.hasOwnProperty('photoupload')) {
            this.photouploaded = data.photoupload;
          }

          if (data.verificationstatus === undefined) {
            this.verificationstatus = true;
          } else {
            if (data.verificationstatus === 'OK') {
              this.verificationstatus = true;
            } else {
              this.verificationstatus = false;
            }
          }
          if (data.checklist) {
            this.timeline = [];
            for (const step of data.checklist) {
              this.timeline[step.seqno - 1] = {
                done: step.checked,
                title: step.title,
                desc: step.description,
                after: step.remarks,
              };
            }
          }

          this.followups = data.followup;
        })
        .catch(error => {
          console.log(error.message);
        });
    },
    async showUpsellModal(upsell) {
      this.selectedUpsellCode = upsell.upsellcode;
      this.selectedProduct = upsell.name || '';
      this.isUpsellModalVisible = true;
    },
    parseDate(unixDate) {
      const date = new Date(unixDate * 1000);
      return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      });
    },
    redirectToAdminChat() {
      if (this.isUserSupportsAdminPatientChat) {
        this.$router.push({ name: 'message-admin' });
      } else {
        this.$router.push({ name: 'contact-us' });
      }
    },
    onUpsellSuccess() {
      this.coupon.show = false;
      this.$bvModal.show('upsellSuccess');
    },
    async onFirstOrderModalContinue() {
      const upsellList = await UpsellService.getDashboardUpsellList(true)
      this.$bvModal.hide('upsellFirstOrder'); 
      
      const upsell = upsellList.data.find(
        upsell => upsell.upsellcode === 'CREAM'
      );

      if (upsell) {
        this.allUpsell = upsellList.data.map(upsell => ({
          ...upsell,
          isExternalLink: true
        }));
        this.showUpsellModal(upsell);
      }
    },
    onOpenBFCMUpsell() {
      const upsell = this.availableDashboardUpsell.find(
        upsell => upsell.upsellcode === 'CREAM'
      );
      if (upsell) {
        this.showUpsellModal(upsell);
      }
    }
  },

};
</script>

<style lang="scss" scoped>
.verify-status {
  display: none;

  &.not-verify {
    display: block;
  }
}

.banner-box {
  background-color: #e9f5f3;
  min-height: 260px;
}

$size: 20px;

.hero-banner {
  padding: 2.25rem;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  @include media-breakpoint-up(xxl) {
    padding: 2.25rem * $scale-font;
    margin-top: 4.5rem * $scale-font;
  }
}

.dashboard-link {
  font-family: "Atten New";
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 2px;
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.upsell-banner {
  color: #fff;
  position: fixed;
  background: #1a6a72;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  text-align: center;
  padding: 1rem;

  span {
    color: #ceccea;
    font-weight: 700;
  }
}

::v-deep .slider > div {
  overflow: visible !important;
}
</style>
