<template>
  <div>
    <portal
      v-if="teleport"
      to="onboarding-cta-footer"
    >
      <div
        v-if="$slots.default"
        id="onboarding-cta-footer"
        class="shadow-portal tw-py-5 tw-px-5 lg:tw-px-0 tw-bg-white tw-fixed tw-bottom-0 tw-w-screen tw-z-[5] tw-flex tw-flex-col tw-gap-4"
      >
        <div
          class="tw-h-full tw-flex tw-items-center tw-px-[1.5rem] sm:tw-px-0"
          :style="[
            $_breakpoint_lg
              ? {
                width: `${width}px`,
                marginLeft: `${marginLeft}px`,
              }
              : {}
          ]"
        >
          <slot></slot>
        </div>
      </div>
    </portal>

    <div v-else>
      <slot></slot>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

// utils
import { observeResize } from '@corefront/utils/observeResize'

// mixins
import { breakpoint } from '@corefront/mixins-v2/breakpoint'

export default {
  mixins: [breakpoint],

  props: {
    teleport: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      width: 0,
      marginLeft: 0,
      disconnectResizeObserver: null
    }
  },

  computed: {
    ...mapState('cro', ['isAnchoredCtaTracked']),
  },

  async mounted () {
    this.initWidth()
  },

  methods: {
    async initWidth () {
      if (this.disconnectResizeObserver) {
        this.disconnectResizeObserver()
      }

      this.disconnectResizeObserver = await observeResize('.onboarding-content', els => {
        const [onboardingContent] = els

        this.width = onboardingContent.contentRect.width
        this.marginLeft = onboardingContent.contentRect.left
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.shadow-portal {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
</style>