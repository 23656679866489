<template>
  <b-container
    class="p-0"
    fluid
  >
    <b-container class="pl-4 pr-4 treatment-container">
      <div class="d-block d-md-none pt-3"></div>
      <h1 class="mt-5 heading text-center">
        Orders
      </h1>
      <div class="d-block d-md-none pt-4"></div>
      <b-alert
        :show="alert.show"
        variant="warning"
      >
        {{
          alert.message
        }}
      </b-alert>
      <b-row class="mt-2">
        <b-col
          cols="12"
          xxl="12"
        >
          <div v-if="prescriptions.length > 0">
            <div
              v-for="(item, index) in prescriptions"
              :key="index"
            >
              <SubscriptionCard
                v-if="
                  item.medicinetype == 'NON-RX' &&
                    item.transactiontype == 'recurring'
                "
                :patient-id="userData.id"
                :prescription-id="item.id"
                @showAlert="showAlert"
              />
              <OrderCard
                v-else-if="
                  item.medicinetype == 'NON-RX' &&
                    item.transactiontype == 'one_time'
                "
                :patient-id="userData.id"
                :prescription-id="item.id"
                @showAlert="showAlert"
              />
              <TreatmentCard
                v-else
                :item="item"
                @show-modal="showModal"
              />
            </div>
            <!-- <b-row>
              <b-col cols="12" class="mb-8">
                <GiftCard
                  :image="require('@/assets/images/dashboard/giftcard-bg.png')"
                />
              </b-col>
            </b-row> -->
          </div>
          <b-row
            v-else
            align-v="center"
            align-h="center"
          >
            <b-col
              cols="12"
              md="4"
            >
              <div class="placeholder-box">
                <div
                  class="placeholder-img"
                  :style="{
                    backgroundImage: `url(${require('@/assets/images/doctor.svg')})`,
                  }"
                ></div>
                <p
                  class="label-header text-center mb-4"
                  style="font-size: 1.2rem"
                >
                  Your doctor is currently reviewing your treatment options.
                  Once complete, your treatment will be here.
                </p>
                <p>Have a question? <a href="/contact-us">Contact Us</a></p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modals -->
    <b-modal
      v-model="modal.show"
      hide-footer
      header-class="intake-header"
      size="md"
      scrollable
      centered
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row>
            <b-col>
              <h3 class="mb-0 heading">
                {{ modal.heading }}
              </h3>
            </b-col>
          </b-row>
        </b-container>

        <b-button
          size="sm"
          variant="link"
          @click="close()"
        >
          <b-icon
            icon="x-circle-fill"
            variant="primary"
          />
        </b-button>
      </template>
      <b-container fluid>
        <b-row>
          <b-col
            v-if="modal.text !== ''"
            v-html="modal.text"
          />
          <b-col v-else>
            No available information
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
// Services
import { UsersService } from '@/services/users.service';
// Components
import TreatmentCard from '@/components/partials/TreatmentCard.vue';
import SubscriptionCard from '@/components/partials/SubscriptionCard.vue';
import OrderCard from '@/components/partials/OrderCard.vue';
// import GiftCard from "@/components/partials/GiftCard";
// import Subscription from '../partials/Subscription.vue';
import analyticsMixin from '@/mixins/analytics';

export default {
  components: {
    TreatmentCard,
    SubscriptionCard,
    OrderCard,
    // GiftCard,
  },
  mixins: [analyticsMixin],
  data() {
    return {
      alert: {
        show: false,
        message: '',
      },
      modal: {
        show: false,
        heading: '',
        text: '',
      },
      userData: {},
      prescriptions: [],
    };
  },
  async mounted() {
    this.$_analytics_page();
    await this.getUser();
    await this.getPrescriptions();
  },
  methods: {
    showAlert(show, message = '') {
      this.alert.message = message;
      this.alert.show = show;
    },
    showModal(heading, text) {
      this.modal.show = true;
      this.modal.heading = heading;
      this.modal.text = text;
    },
    async getUser() {
      this.alert.show = false;
      try {
        const res = await UsersService.getCurrent();
        this.userData = res.data;
      } catch (err) {
        if (err.message) {
          console.log(error.message);
        }
      }
    },
    async getPrescriptions() {
      try {
        const res = await UsersService.getPrescriptionorder();
        this.prescriptions = res.data.filter(o =>
          ['PRESCRIBED', 'PAUSED', 'SCHEDULED'].includes(o.prescriptionstatus)
        );
      } catch (err) {
        if (err.message) {
          console.log(err.message);
        }
      }
    },
  },
};
</script>

<style scoped>
/deep/.modal-header.intake-header {
  border-bottom: 0;
  align-items: center;
  padding: 1rem;
}

/deep/.modal-body img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/deep/.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 1px !important;
}

/deep/.b-avatar .b-avatar-text {
  text-transform: capitalize;
  font-size: 1rem !important;
}

/deep/.b-avatar.non-rx .b-avatar-text {
  font-size: 12px !important;
}
</style>

<style lang="scss" scoped>
.non-rx {
  // margin-top: 2rem;
  background-color: #e9f5f3;
  border-bottom: 10px solid $primary;
}

.non-rx-action {
  .list-group-item {
    border: 0;
    text-transform: initial;
    text-decoration: underline;
  }
}
.custom-p {
  font-size: 22px;
}
p:last-of-type {
  margin-bottom: 0;
}
.list-group-item {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: $primary;
  text-transform: uppercase;
}
.icon-holder {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 1rem;
}
.c-card {
  height: auto;
  min-height: 250px;
  border: 0;
}
@media (min-width: 767px) {
  .c-card {
    height: 250px;
    min-height: 250px;
  }
}
.placeholder-box {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.placeholder-img {
  width: 300px;
  height: 300px;
  margin-bottom: 25px;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
