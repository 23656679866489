<template>
  <div>
    <h2 class="question-title with-subtext">
      Let’s get started
    </h2>
    <p class="sub-text group-mb">
      Our doctors will help prescribe what’s right for you. Share your email so
      we can save your answers. We won’t fill up your inbox!
    </p>
    <b-row>
      <b-col cols="12">
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="email"
            class="input-label"
          >Email Address*</label>
          <b-form-input
            id="email"
            v-model="email"
            class="fs-mask onboarding-input"
            type="email"
            autocomplete="off"
            :state="emailState"
            required
          />
          <b-form-invalid-feedback id="input-live-feedback">
            The email must have '@email.com'
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="password"
            class="input-label"
          >Password*</label>
          <b-form-input
            id="password"
            v-model="password"
            class="fs-mask onboarding-input"
            type="password"
            :state="passwordState"
            autocomplete="off"
            required
          />
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Password must be a minimum of 8 characters and a maximum of 50.
          </b-form-invalid-feedback>
        </div>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <div
          role="group"
          class="group-mb"
        >
          <label
            for="cpassword"
            class="input-label"
          >Confirm Password*</label>
          <b-form-input
            id="cpassword"
            v-model="cpassword"
            class="fs-mask onboarding-input"
            type="password"
            :state="cpasswordState"
            autocomplete="off"
            required
            @keyup.enter="register"
          />
          <!-- This will only be shown if the preceding input has an invalid state -->
          <b-form-invalid-feedback id="input-live-feedback">
            Password do not match
          </b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>

    <div class="tw-flex tw-flex-col tw-gap-2">
      <div class="tw-text-center">
        <small>
          Already have an account?
          <router-link to="/login">Sign In</router-link>
        </small>
      </div>

      <div class="tw-text-center">
        <small class="text-muted">
          <b-icon icon="lock-fill" /> Any information you submit is
          encrypted within our secure platform.
        </small>
      </div>

      <img
        src="@/assets/images/cro/hsa-fsa-eligible-badge.png"
        alt=""
        class="tw-h-[18px] tw-mx-auto"
      >
    </div>

    <OnboardingCTAContainer>
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-2">
        <b-button
          pill
          block
          class="mb-3 mt-3 slight-rounded"
          variant="info"
          :disabled="buttonDisabled"
          @click="register"
        >
          Agree and continue
        </b-button>
      </div>
    </OnboardingCTAContainer>
  </div>
</template>

<script>
// libs
import VueCookies from 'vue-cookies';

// components
import formValidation from '@/components/form/mixins';
import OnboardingCTAContainer from '@/components/onboarding/OnboardingCTAContainer.vue';

// services
import { AuthService } from '@/services/auth.service';
import { OnboardingService } from '@/services/onboarding.service';

// utils
import { isValidEmail } from '@/utils/string';
import $cookies from '@/utils/cookies';

import analyticsMixin from '@/mixins/analytics';
import { fsaHsaPromoMixin } from '@/mixins/fsaHsaPromo';

import { mapActions } from 'vuex';

import crypto from 'crypto'

export default {
  components: {
    OnboardingCTAContainer,
  },
  mixins: [formValidation, analyticsMixin, fsaHsaPromoMixin],
  props: {
    eligibilityParams: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      email: '',
      password: '',
      cpassword: '',
      buttonDisabled: false,
    };
  },
  methods: {
    ...mapActions('onboarding', ['checkCoupon', 'applyCoupon']),
    async fbqTrack(auth) {
      try {
        // FB Pixel Tracking
        fbq('init', '348219289912097', {
          external_id: auth.userid,
          extern_id: auth.userid,
        });
        // fbq("track", "PageView");
        const pageViewBody = { userid: auth.userid };
        OnboardingService.onboardingEvent('PageView', pageViewBody);
        fbq(
          'trackCustom',
          'AccountCreated',
          {
            external_id: auth.userid,
            extern_id: auth.userid,
            ...(await this.$store.dispatch('getFBQParams')),
            state: this.eligibilityParams.state,
          },
          {
            eventID: auth.eventid,
          }
        );
      } catch (err) {
        console.log(err);
      }
    },
    async register() {
      try {
        if (this.password != this.cpassword) {
          return this.$emit('showAlert', 'The password fields do not match.');
        }

        if (this.email.trim().length == 0 || this.password.trim().length == 0) {
          return this.$emit('showAlert', 'Please fill in all the fields.');
        }

        if (!this.emailState || !isValidEmail(this.email)) {
          return this.$emit('showAlert', 'Please input a valid email.');
        }

        this.buttonDisabled = true;

        const data = {
          email: this.email,
          password: this.password,
          role: 'PATIENT',
          state: this.eligibilityParams.state,
        };

        // * Onboarding Flow Split
        const flow = $cookies.get('onboardingflow') || $cookies.get('flow');
        if (flow) {
          switch (flow) {
            case 'b':
            case 'flowB':
              data.onboardingflow = 'flowB';
              break;
            case 'a':
            case 'flowA':
              data.onboardingflow = 'flowA';
              break;
            default:
              data.onboardingflow =
                $cookies.get('onboardingflow') || $cookies.get('flow');
              break;
          }
          $cookies.remove('onboardingflow');
          $cookies.remove('flow');
        }

        // * Referrals
        if ($cookies.get('referralToken')) {
          //data.referralcode = $cookies.get("referralToken");
          this.$set(data, 'referralcode', $cookies.get('referralToken'));
        }

        const { data: auth } = await AuthService.signup(data);
        if (auth.idToken) {
          AuthService.setBearer(auth.idToken);
          const sasCookie = $cookies.get('sas_m_awin') || undefined;

          if (sasCookie != undefined && sasCookie.clickId != undefined) {
            await OnboardingService.sendSASClickId(sasCookie.clickId);
            $cookies.remove('sas_m_awin');
          }
        }

        // * FB Track
        if (auth.eventid) {
          const userid = auth.userid;
          const signature = crypto
            .createHmac('sha256', process.env.VUE_APP_INTERCOM_SECRETKEY)
            .update(userid)
            .digest('hex');
          if (userid != undefined) {
            window.analytics.identify(
              userid,
              {
                email: this.email,
                customerId: userid,
              },
              {
                integrations: {
                  Intercom: {
                    user_hash: signature,
                  },
                },
              }
            );
          }

          // * Segment Event
          await this.$_analytics_track(
            'Account Created',
            {
              customerId: auth.userid,
              messageID: auth.eventid,
              email: this.email,
              state: this.eligibilityParams.state,
            },
          );

          localStorage.setItem('authenticatedUserId', auth.userid);
          const userIdPopulated = new Event('userIdPopulated');
          window.dispatchEvent(userIdPopulated);

          // Tatari
          if (tatari) {
            console.log('Tatari Identify: ', auth.userid);
            tatari.identify(`$${[auth.userid]}`);
            console.log('Tatari Track: Account Created');
            tatari.track('Account Created');
          }

          await this.fbqTrack(auth);

          // Reboot intercom to show "Schedule a meeting" component
          window.Intercom('shutdown')
          window.Intercom('boot', {
            app_id: process.env.VUE_APP_INTERCOM_APP_ID,
            user_id: auth.userid
          });
        } else if (auth.eventid && process.env.VUE_APP_SERVER != 'production') {
          // alert('FB TRACKING AccountCreated: ' + auth.eventid);
          console.log('FB TRACKING AccountCreated');
          console.log('FB TRACKING AccountCreated: ' + auth.eventid);
        }

        // * FSA HSA sessionPromo
        if (this.$_fsaHsaPromo_isActiveFsaHsaPromo) {
          const couponResult = await OnboardingService.checkCoupon(
            this.$_fsaHsaPromo_sessionPromoValue
          );

          if (couponResult?.data?.id){
            await OnboardingService.applyCoupon({
              couponid: this.$_fsaHsaPromo_sessionPromoValue,
            });

            $cookies.remove('promocode')
          }
        }
        // * Promocode
        else if ($cookies.get('promocode') && !$cookies.get('promocodeExistingID')) {
          let getCoupon;
          try {
            getCoupon = await OnboardingService.checkCoupon(
              $cookies.get('promocode')
            );
          } catch (err) {
            getCoupon = null;
            $cookies.remove('promocode');
          }
          
          if (getCoupon?.data?.id) {
            await OnboardingService.applyCoupon({
              couponid: $cookies.get('promocode'),
            });
  
            if (getCoupon.data.hasOwnProperty('applies_to')) {
              // Skips cookie removal if code applies to Blossom
              if (
                getCoupon.data.applies_to.products[0] != 'prod_Jxqgr2Hh9YGwjZ' &&
                getCoupon.data.applies_to.products[0] != 'prod_K0rnRpVxfgGP4y'
              ) {
                this.$cookies.remove('promocode');
              }
            } else {
              this.$cookies.remove('promocode');
            }
          }
        }

        const onboardingStartedTimestamp = $cookies.get(
          'onboardingStartedTimestamp'
        );
        const identifyBody = {
          id: auth.userid,
          name: 'Onboarding Started',
          value: onboardingStartedTimestamp,
        };

        await OnboardingService.onboardingIdentify(identifyBody);

        this.buttonDisabled = false;
        this.$emit('setId', auth.userid);
        this.$emit('setEmail', this.email);
        this.$emit('setStep', 'birthday');
      } catch (err) {
        if (err.message == 'An account with the given email already exists.') {
          this.$emit(
            'showEmailAlert',
            `An account with the email ${this.email} already exists. You may sign in or enter a different email address to create a new account.`,
            this.email
          );
        } else {
          if (err.status == 500) {
            this.$store.dispatch('sendErrorLog', {
              step: 'Eligibility',
              error_log: {
                message: err,
              },
            });
          }
          this.$emit('showAlert', err.message);
        }
        this.buttonDisabled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.hide-arrows {
  -moz-appearance: textfield;
}
</style>
